import React, { Component } from 'react';

import {connect} from "react-redux";
import {getStore} from "../../application/store/reducer";
import {Helmet} from "react-helmet/es/Helmet";
import AppConfig from "../../application/common/AppConfig";

class CustomStyle extends Component{

    hexToRGB(h) {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (h.length == 4) {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];

            // 6 digits
        } else if (h.length == 7) {
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
        }

        return ""+ +r + "," + +g + "," + +b;
        // return "rgb("+ +r + "," + +g + "," + +b + ")";
    }
    getColorCustomStyle(color){
        let rgbColor = this.hexToRGB(color);
        return (
            <style>{`
                a {
  color: ${color};
}

.body .btn-primary {
  border-color: ${color};
  background: ${color};
}

.body .btn-primary:hover, .body .btn-primary.active, .body .btn-primary.active:hover {
  color: #fff;
  border-color: rgba(${rgbColor}, 0.7);
  background-color: rgba(${rgbColor}, 0.7);
}
.body .btn-primary.disabled, .body .btn-primary:disabled{
  border-color: ${color};
  background: ${color};
}

.body .btn-primary-light {
  color: ${color};
  border-color: rgba(${rgbColor}, 0.2);
  background-color: rgba(${rgbColor}, 0.2);
}

.body .btn-primary-light:hover {
  color: #fff;
  border-color: rgba(${rgbColor}, 0.5);
  background-color: rgba(${rgbColor}, 0.5);
}

.body .loader {
  border-top-color: ${color};
}
.body .modal-full .close:hover {
  background-color: ${color};
}

.body .cart .card {
  border-bottom-color: rgba(${rgbColor}, 0.2);
}

.body .cover .cover-content .cover-content-center-content .badge-default{
  background-color: rgba(${rgbColor}, 0.2);
}
.body .container-menu-picker .nav-link.active{
  background-color: rgba(${rgbColor}, 0.2);
}
.body .menu .menu-items .menu-item:hover .card, .body .menu .menu-items .menu-item .card:hover {
  border-color: ${color};
}
.body .nav-tabs-top .nav-item.active .nav-link {
  border-bottom-color: ${color};
}
.body .nav-tabs-top {
  box-shadow: rgba(${rgbColor}, 0.2) 0px -3px 0px inset;
}

.design-v2 .modal-product-full .product-option-with-image.active {
  border-color: ${color};
}
.design-v2 .check-circle svg, .design-v2 .check-circle path {
  fill: ${color};
}
.design-v2 .modal-product-full .product-item .product-modifier-option-pick input:checked + label {
  background-color: ${color};
}
.design-v2 .price {
  color: ${color};
}
.design-v2 .btn-primary {
  background-color: ${color};
  border-color: ${color};
}
.design-v2 .btn-primary:hover {
  border-color: rgba(${rgbColor}, 0.7);
  background-color: rgba(${rgbColor}, 0.7);
}
.design-v2 .btn-primary .price {
  color: #fff;
}
.design-v2 .btn-outline-primary {
  border-color: ${color};
  color: ${color};
}
.design-v2 .btn-outline-primary:hover {
  background-color: ${color};
  color: #fff;
}
.design-v3 .container-menu-picker .menu-item-content, .design-v3 .container-menu-picker .btn-choose-menu{
    color: ${color};
}
.body.design-v3 .container-menu-picker .nav-link.active {
    background-color: #F4F4F4;
}
                    `}</style>
        );
    }
    getBgCustomStyle(bgColor, textColor){
        let rgbBgColor = bgColor ? this.hexToRGB(bgColor) : null;
        return (
            <style>{`
                .body .App {
  background-color: ${bgColor};
  color: ${textColor};
}
.body .App table {
  color: ${textColor};
}
.widget .footer {
  background-color: rgba(${rgbBgColor}, 0.2);
}
.body .App .footer {
  background-color: rgba(0,0,0,0.1);
  color: ${textColor};
}
.body .App .sticky-fixed, .body .App .nav-tabs-top {
  background-color: ${bgColor};
  .cart-sticky-header, .cart-sticky-body, .scoller {
    background-color: rgba(0,0,0,0.1);
  }
}
.App .nav-tabs-top .nav-item.active .nav-link, .App .nav-tabs-top .nav-item .nav-link {
  color: ${textColor};
}
.App .menu .menu-items .card {
  background-color: rgba(0,0,0,0.1);
  color: ${textColor};
}
.modal-dialog {
  background-color: ${bgColor};
}
.modal-content {
  background-color: rgba(0,0,0,0.1);
}
.header-strike span {
  background-color: ${bgColor};
}
.modal-full .product-item-footer, .modal-footer {
  // background-color: rgba(0,0,0,0.1);
}
.modal-content {
  background-color: transparent;
}
.App .form-group input.form-control, .App .form-group select.form-control {
  background-color: rgba(0, 0, 0, 0.1);
  color: ${textColor};
}

.App .form-control {
  background-color: rgba(0,0,0,0.1);
  color: ${textColor};
}

.body .App .footer a {
  color: ${textColor};
}
                    `}</style>
        );
    }
    render() {
        const {store} = this.props;
        if(store === undefined || !store || !store.name){
            return null;
        }
        // let faviconView = null;
        // if(store.ico_link){
        //     faviconView = (
        //         <link href={store.ico_link} rel="shortcut icon" type="image/png" />
        //     )
        // }
        let color = store.color;
        let css = store.css;
        let settings = store.settings;
        let colorText = null;
        if(settings.color_text){
            colorText = settings.color_text;
        }
        let colorBg = null;
        if(settings.color_bg){
            colorBg = settings.color_bg;
        }

        if(colorBg === null && color === null){
            return null;
        }

        let customColorStyle = null;
        let customBgStyle = null;
        // if(!AppConfig.isDesignV3()){
            if(color){
                customColorStyle = this.getColorCustomStyle(color);
            }
            if(colorBg){
                customBgStyle = this.getBgCustomStyle(colorBg, colorText);
            }
        // }
        let customCssStyle = null;
        if(css){
            customCssStyle = (
                <style>{`${css}`}</style>
            );
        }
        return (

            <Helmet>
                {customColorStyle}
                {customBgStyle}
                {customCssStyle}
                {/*{faviconView}*/}
            </Helmet>
        )
    }
}

const mapStateToProps = state => ({
    store: getStore(state.store),
})
export default connect(mapStateToProps)(CustomStyle)
// export default connect(mapStateToProps)(NavbarSearch)
