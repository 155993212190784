const ErrorFormHelper = (errors, field) => {
    let isInvalid = false;
    if(field === undefined){
        isInvalid = errors.length > 0 ? true : false;
    }else{
        let fieldSplit = field.split(",");

        isInvalid = errors.some(error => fieldSplit.includes(error.field));
    }
    if(isInvalid){
        return " is-invalid";
    }
    return '';
}

export default ErrorFormHelper;
