import {UPDATE_SELECTED_LOCATION, UPDATE_SELECTED_VALUE_LOCATION, UPDATE_LOCATION} from './actions';
import {emptyOrValue, getAddressName} from "../common/StringHelper";
import StorageStore from "../common/storageStore";


let selected_address = [];
let address = {
    description: '',
    fullAddres: '',
    city: '',
    street: '',
    floor: '',
    build_nr: '',
    flat_nr: '',
    country: 'Polska'
}
selected_address.push(address);

const initialState = {
    address: address,
    addresses: [],
    selected_address:selected_address
}

const locationReducer= (state = initialState,action)=>{
    switch(action.type) {
        case UPDATE_SELECTED_VALUE_LOCATION:
            let value = action.value;
            state.selected_address[0].description = value;
            let newSelectedAddress = state.selected_address[0];
            newSelectedAddress.description = value;
            // newSelectedAddress = {
            //     description: value
            // }
            return {
                ...state,
                selected_address: [newSelectedAddress]
            }
        case UPDATE_SELECTED_LOCATION:
            var address = action.selected;
            if(address == null){
                address = initialState.address;
                address.description = '';
                address.fullAddres = '';
            }
            StorageStore.setItem('location', JSON.stringify(address));
            return {
                ...state,
                address: address,
                selected_address: [address]
            }
        case UPDATE_LOCATION:
            var actionAddress = action.location;
            var newAddress = initialState.address;
            if(actionAddress == null){
                newAddress.description = '';
                newAddress.fullAddres = '';
                newAddress.city = '';
                newAddress.street = '';
                newAddress.build_nr = '';
                newAddress.zip_code = '';
                newAddress.flat_nr = '';
                newAddress.country = '';
            }else{
                newAddress.city = emptyOrValue(actionAddress.city);
                newAddress.street = emptyOrValue(actionAddress.street);
                newAddress.build_nr = emptyOrValue(actionAddress.build_nr);
                newAddress.zip_code = emptyOrValue(actionAddress.zip_code);
                newAddress.country = actionAddress.country != null ? actionAddress.country : state.address.country;
                newAddress.flat_nr = actionAddress.flat_nr != null ? actionAddress.flat_nr : state.address.flat_nr;
                newAddress.fullAddres = getAddressName(newAddress);
                newAddress.description = newAddress.fullAddres;
            }
            StorageStore.setItem('location', JSON.stringify(newAddress));
            return {
                ...state,
                address: newAddress,
                selected_address: [newAddress]
            }
        default:
            return state;
    }
}

export default locationReducer;
export const getLocation = state => state;
