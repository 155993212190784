import React from 'react';
import {connect} from "react-redux";
import {
    getAvailabilities,
    getItems,
    getMenuPending,
    getMenus,
    getModifierGroups,
    getPromotions, isItemMultiLevel,
    isStoreOpen
} from "../../../../../application/menu/reducer";
import {getCart, getOrderPending, getPickDate} from "../../../../../application/cart/reducer";
import LoadingContainer from "../../../../common/LoadingContainer";
import ItemHelper from "../../../../../application/common/ItemHelper";
import {getLocation} from "../../../../../application/location/reducer";
import ErrorFormAlertView from "../../../../common/ErrorFormAlertView";
import DateService from "../../../../../application/service/DateService";
import AvailableHelper from "../../../../../application/common/AvailableHelper";
import Utils from "../../../../utils/Utils";
import Money from "../../../../common/Money";
import {withTranslation} from "react-i18next";
import {getLocale, isPreview} from "../../../../../application/store/reducer";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import {ReactComponent as StarIco} from "../../../../../images/icons/star.svg";
import MenuItemModifiers from "./MenuItemModifiers";
import MenuEditItemViewHelper from "./MenuEditItemViewHelper";
import StateItemHelper from "./StateItemHelper";


const MenuEditItem = (props)=>{
    let errors = props.errors;
    let stateItem = props.stateItem;
    const {cart, location, items, modifier_groups, menus, availabilities, pick_date, preview, promotions, pending, t} = props;

    const addQuantity = () => {
        setQuantity(stateItem.quantity+1);
    };
    const subQuantity = () => {
        setQuantity(stateItem.quantity-1);
    };
    const addItemInternal = ()=>{
        let menuId = parseInt(props.menuId);
        let newItem = stateItem;
        props.addItem(menuId, newItem);
    }

    const setQuantity = (quantity) => {
        let newQuantity = quantity;
        if(newQuantity <=0){
            newQuantity = 1;
        }
        stateItem.quantity = newQuantity;
        updateState();
    }

    const updateState = (newStateItem) => {
        let copyItem = newStateItem ? Object.assign({}, newStateItem) : Object.assign({}, stateItem);
        StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        // setCurrentItem(copyItem);
        props.onUpdate(copyItem);
    }
    const getItemView = (items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date) => {
        return MenuEditItemViewHelper.getItemView(items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date, stateItem, props.itemMultiLevel);
    }
    const validateState = (itemView, availabilities, pick_date, lvl) => {
        let isValid = true;

        itemView.modifier_groups.forEach(itemModifierGroup => {

            let quantity = itemModifierGroup.quantity;
            if(quantity < itemModifierGroup.min_permitted){

                if(itemModifierGroup.max_permitted !== null && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1){
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_not_empty")});
                }else{
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            if(itemModifierGroup.max_permitted !== null && quantity > itemModifierGroup.max_permitted){
                itemModifierGroup.errors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach(itemModifierGroupItem => {
                let quantityItem = itemModifierGroupItem.quantity;

                if(quantityItem > 0 && itemModifierGroupItem.disabled){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }

                // if(quantityItem > 0 && !AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, itemModifierGroupItem.availability_id), DateService.emptyOrNow(pick_date))){
                //     itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                //     itemModifierGroupItem.isValid = false;
                //     isValid = false;
                // }
                if(quantityItem < itemModifierGroupItem.min_permitted){

                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if(itemModifierGroupItem.max_permitted !== null && quantityItem > itemModifierGroupItem.max_permitted){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                let newLvl = lvl ? lvl + 1 : 1;
                if(newLvl <= 2){
                    if(itemModifierGroupItem.quantity > 0){
                        if(!validateState(itemModifierGroupItem, undefined, undefined, newLvl)){
                            isValid = false;
                        }
                    }
                }
            });
        });
        return isValid;
    }
    const renderPromotions = () => {
        // let item = ItemHelper.getItem(this.props.items, this.state.item.id);
        let promotions = PromotionHelper.getForItem(props.promotions, stateItem.item_id);
        if(promotions === undefined || promotions.length <=0) return null;
        return (
            <div className={"promotions"}>
                {promotions.map(promotion => {
                    return (
                        <div className={"btn btn-promotion"}>
                            <div className={"star"}>
                                <StarIco />
                            </div>
                            <div className={"content"}>
                                {promotion.name}
                                <small>
                                    Min. wartość koszyka: <Money value={promotion.min_basket_money} />
                                </small>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }


    if(props.menuPending){
        return (
            <LoadingContainer/>
        )
    }
    // let id = parseInt(match.params.id);
    // let menuId = parseInt(match.params.menuId);
    let id = parseInt(props.id);
    let menuId = parseInt(props.menuId);
    let itemView = getItemView(items, modifier_groups, availabilities, id, null, null, 1, pick_date);
    if(itemView=== null){
        return (
            <LoadingContainer/>
        )
    }
    let isValid = validateState(itemView, availabilities, pick_date);

    let isMenuAvailabile = true;
    let errorView = null;
    let menusById = menus.filter(menu => menu.id === menuId);
    if(menusById.length > 0){
        let menu = menusById[0];
        let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id)
        if(!AvailableHelper.isAvailabile(availability, DateService.emptyOrNow(pick_date))){

            if(!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(pick_date))){
                let pickDateText = "";
                if(menu.pick_date){
                    pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date");
                }else{
                    pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today");
                }
                errorView = (
                    <div className="alert alert-danger">
                        {pickDateText}
                    </div>
                )
            }else{
                // if(!ItemHelper.isMenuAvailabile(menusById[0], DateService.emptyOrNow(pick_date))){
                isMenuAvailabile = false;
                let todayOpenHours = ItemHelper.getTodayOpenHoursString(menusById[0].availability, DateService.emptyOrNow(pick_date));
                let todayOpenHoursView = null;
                if(todayOpenHours !== null){
                    todayOpenHoursView = t("components.menu.available_time") + ": " + todayOpenHours
                }else{
                    todayOpenHoursView = t("components.menu.unavailable_today");
                }
                errorView = (
                    <div className="alert alert-danger">
                        {todayOpenHoursView}
                    </div>
                )
            }
        }
    }

    let disabled = pending || !isValid || !isMenuAvailabile || itemView.disabled || cart.type === null;
    let loading = pending;
    let productImgView = null;
    if(itemView.image_link){
        productImgView = (
            <div className="product-item-img">
                <img src={itemView.image_link.default} />
            </div>
        )
    }
    let disabledView = null;
    if(itemView.disabled){
        let errorMEssage = t("components.item.unavailable");
        disabledView = (
            <ErrorFormAlertView errors={errorMEssage} />
        );
    }else if(cart.type === null){
        // if(!preview){
            let errorMEssage = [t("errors.internal.order_type_required")];
            disabledView = (
                <ErrorFormAlertView errors={errorMEssage} />
            );
        // }
    }
    // let product = this.props.product;
    return(
        <React.Fragment>
            <div className="product-item">
                {props.children}
                {disabledView}
                {renderPromotions()}

                {itemView.content
                    ? <p className="product-item-description"  dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.content)}}></p>
                    : itemView.description
                        // ? <p className="product-item-description">
                        //     {Utils.nl2br(itemView.description)}
                        // </p>
                        ? <p className="product-item-description" dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.description)}}></p>
                        : null
                }
                {/*{!preview && itemView.modifier_groups.length > 0 ? (*/}
                {/*    <MenuItemModifiers item={props.item} stateItem={stateItem} itemView={itemView} onUpdate={updateState} onEditSubItem={props.onEditSubItem} />*/}
                {/*) : null}*/}
                {itemView.modifier_groups.length > 0 ? (
                    <MenuItemModifiers item={props.item} stateItem={stateItem} itemView={itemView} onUpdate={updateState} onEditSubItem={props.onEditSubItem} />
                ) : null}

                {errorView}

                {/*{!preview && (*/}
                    <div className="product-item-footer">
                        <ErrorFormAlertView errors={errors}/>
                        <div className="btn-group btn-group-submit">
                            {props.changedQuantityVisibility && (
                                <div className="input-group input-group-quantity">
                                    <div className="input-group-prepend">
                                        <button type="button" className="btn btn-secondary" onClick={subQuantity}>-</button>
                                    </div>
                                    <span className="input-group-text">{stateItem.quantity}</span>
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-secondary" onClick={addQuantity}>+</button>
                                    </div>
                                </div>
                            )}


                            <button className={"btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom" + (loading ? ' loading' : '')} variant="primary" disabled={disabled} onClick={()=>{addItemInternal()}}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {t("components.menu.add_item", {quantity: stateItem.quantity})}
                                <div className="right btn-cart-price">
                                    {stateItem.discount_price !== undefined ? (
                                        <div className={"prices"}>
                                         <span className="price price-discount">
                                             <Money
                                                 value={stateItem.total_price}
                                                 currency='PLN' />
                                         </span>
                                            <span className="price">
                                             <Money
                                                 value={stateItem.discount_price}
                                                 currency='PLN' />
                                         </span>
                                        </div>
                                    ) : (

                                        <span className="price">
                                            <Money
                                                value={stateItem.total_price}
                                                currency='PLN' />
                                        </span>
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                {/*)}*/}
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        menus: getMenus(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        menuPending: getMenuPending(state.menu),
        availabilities: getAvailabilities(state.menu),
        isStoreOpen: isStoreOpen(state.menu),
        pick_date: getPickDate(state.cart),
        locale: getLocale(state.store),
        preview: isPreview(state.store),
        promotions: getPromotions(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuEditItem))