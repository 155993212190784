import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getStore, isPreview} from "../../application/store/reducer";
import {getLocation} from "../../application/location/reducer";
import {getAddress, getCart, getOrderType, getPickDate} from "../../application/cart/reducer";
import classNames from "classnames";
import NavbarForm from "./form/NavbarForm";
import {getAvailabilities, getLocationStore, getMenus, getOrderTypes} from "../../application/menu/reducer";
import {parseDate} from "../../application/common/DateHelper";
import FormattedTime from "../common/FormattedTime";
import {getAddressName} from "../../application/common/StringHelper";
import {Modal} from "react-bootstrap";

function NavbarSearch(props){
    const { t, type } = props;
    const [collapse, setCollapse] = useState(false);
    let nameType = type ? t("models.order.type." + type) : t("common.action.select_order_type");

    const change = () => {
        setCollapse(!collapse)
    }
    const isPickDateEnabled = () => {
        return !props.preview && props.locationStore.pick_date;
    }
    const isGoogleSearch = () => {
        if(props.store.order_custom_fields.some(x => x.slug == "search")){
            return true;
        }
        return false;
    }
    const renderPickDateInfo = () => {
        if(!isPickDateEnabled()) return null;
        if(!props.pick_date) return t("common.word.now");
        let date = props.pick_date ? parseDate(props.pick_date) : undefined;
        return (
            <React.Fragment>
                <FormattedTime
                    value={date}
                    day="numeric"
                    month="long"/>
            </React.Fragment>
        )
    }
    const isAnyOrderTypes = () => {

    }
    const renderSearchInfo = () => {
        let defaultValue = (
            <>
                {nameType}:{" "}{renderPickDateInfo()}
            </>
        )
        let actionEdit = (
            <>
                {" "}<span className={"link-primary"}>Zmień</span>
            </>
        )
        switch (props.type){
            case "DELIVERY":
                return (
                    <>
                        {defaultValue} <br />
                        {props.location.address && props.location.address.description ? (
                            <span className={(props.locationStore.in_delivery_area ? "" : "link-danger")}>{props.location.address.description}</span>
                        ) : (
                            <span className={"link-primary"}>{t("layout.navbar.select_location")}</span>
                        )}
                        {actionEdit}
                    </>
                );
            case "PICK_UP":
                return (
                    <>
                        {defaultValue} <br />
                        {getAddressName(props.store.address)}
                        {actionEdit}
                    </>
                )
            case "DINE_IN":
                return (
                    <>
                        {defaultValue}  <br />
                        {t("common.word.table")}: {props.cart.number}
                        {actionEdit}
                    </>
                )
            case "ROOM_SERVICE":
                return (
                    <>
                        {defaultValue}  <br />
                        {t("common.word.room")}: {props.cart.number}
                        {actionEdit}
                    </>
                )
        }
        return null;
    }
    if(props.types.length <=0) return null;
    return (
        <>
           <button className={"navbar-actions"} onClick={() => change()}>
               <div className={"content"}>
                   {renderSearchInfo()}
                   {/*Dostawa: 16:00 - 16:30 <a href={""}>Zmień</a><br />*/}
                   {/*ul. Rakowicka 43a/2, 31-126 Kraków*/}
               </div>
               <div className={"btn btn-default"}>
                   {nameType}
               </div>
           </button>
            {props.modal ? (
                <>
                    {collapse && (
                        <Modal dialogClassName="modal-full" show={collapse} onHide={() => setCollapse(false)} onShow={null}>
                            <div className="">
                                <Modal.Header>
                                    <Modal.Title>
                                        Ustawienia
                                    </Modal.Title>
                                    <button onClick={() => setCollapse(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </Modal.Header>
                            </div>
                            <Modal.Body>
                                <div>
                                    <NavbarForm googleSearch={isGoogleSearch()} preview={props.preview} cart={props.cart} menus={props.menus} availabilities={props.availabilities} store={props.store} locationStore={props.locationStore} type={props.type} location={props.location} onHide={() => setCollapse(false)}/>
                                </div>
                            </Modal.Body>
                            {/*<Modal.Footer>*/}
                            {/*    <button className={"btn-spinner w-100 btn-primary btn btn-submit" + (this.state.loading ? ' loading' : '')} variant="primary" onClick={()=>{this.save()}}>*/}
                            {/*        <span className="left spinner-border spinner-border-sm"></span>*/}
                            {/*        {t("common.action.save")}*/}
                            {/*    </button>*/}
                            {/*</Modal.Footer>*/}
                        </Modal>
                    )}
                </>
            ) : (
                <div className={classNames(`collapse navbar-collapse`, {
                    'show': collapse
                })} id="navbarNav">
                    {collapse && (
                        <div className={"navbar-form"}>
                            <NavbarForm googleSearch={isGoogleSearch()} preview={props.preview} cart={props.cart} menus={props.menus} availabilities={props.availabilities} store={props.store} locationStore={props.locationStore} type={props.type} location={props.location} onHide={() => setCollapse(false)}/>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
        store: getStore(state.store),
        location: getLocation(state.location),
        address: getAddress(state.cart),
        cart: getCart(state.cart),
        locationStore: getLocationStore(state.menu),
        type: getOrderType(state.cart),
        pick_date: getPickDate(state.cart),
        preview: isPreview(state.store),
        availabilities: getAvailabilities(state.menu),
        menus: getMenus(state.menu),
        types: getOrderTypes(state.menu)
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarSearch))
