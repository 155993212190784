import {fetchMenuError, fetchMenuPending, fetchMenusLocationSuccess, fetchMenusSuccess} from './actions';
import getToken from "../security/securityService";
import ApiService from "../common/apiService";
import {parseError} from "../common/apiResponse";
import axios from 'axios'
import {fetchStoreError, fetchStorePending, fetchStoreSuccess} from "../store/actions";
import {updateStoreData} from "../cart/actions";
import DateService from "../service/DateService";


function getHeaders(token) {
    let headers = {
        // 'Authorization': 'Bearer ' + token,
    }
    return ApiService.getHeaders(
        headers
    );
}


export function fetchMenu(address) {
    let params = {};
    if(address !== undefined && address){
        if(address.country){
            params.country = address.country
        }
        if(address.city){
            params.city = address.city
        }
        if(address.street){
            params.street = address.street
        }
        if(address.build_nr){
            params.build_nr = address.build_nr
        }
    }
    return dispatch => new Promise(function(resolve, reject) {
        dispatch(fetchMenuPending());
        dispatch(fetchStorePending());

        getToken().then(token => {
            axios.get(ApiService.getBaseUrl() + '/menus', {
                params: params,
                headers: getHeaders(token)
            })
                .then(response => {
                    let data = response.data;
                    DateService.init(data.data.date);
                    dispatch(fetchMenusSuccess(data.data));
                    dispatch(fetchStoreSuccess(data.data));
                    dispatch(updateStoreData(data.data));
                    resolve(response);
                    return data.data;
                })
                .catch(error => {
                    error = parseError(error);
                    dispatch(fetchMenuError(error));
                    dispatch(fetchStoreError(error));
                    reject(error);
                });
        }).catch(error => {
            console.log(error);
            dispatch(fetchMenuError(error));
            dispatch(fetchStoreError(error));
            reject(error);
        });
    });
}
export function fetchPaymentMethodGateways(paymentMethodId) {
    return new Promise(function(resolve, reject) {
        axios.get(ApiService.getBaseUrl() + '/payment/' + paymentMethodId + "/gateways", {
            headers: getHeaders()
        })
            .then(response => {
                resolve(response.data);
                return response.data;
            })
            .catch(res => {
                let error = parseError(res);
                reject(error);
            });
    });
}

export function getLocationRequest(address) {
    let params = {};
    if(address !== undefined && address){
        if(address.country){
            params.country = address.country
        }
        if(address.city){
            params.city = address.city
        }
        if(address.street){
            params.street = address.street
        }
        if(address.build_nr){
            params.build_nr = address.build_nr
        }
        if(address.zip_code){
            params.zip_code = address.zip_code
        }
    }
     return axios.get(ApiService.getBaseUrl() + '/menus/locations', {
         params: params,
         headers: getHeaders()
     });
}
export function fetchLocation(address) {
    let params = {};
    if(address !== undefined && address){
        if(address.city){
            params.city = address.city
        }
        if(address.street){
            params.street = address.street
        }
        if(address.build_nr){
            params.build_nr = address.build_nr
        }
        if(address.country){
            params.country = address.country
        }
        if(address.zip_code){
            params.zip_code = address.zip_code
        }
    }
    return dispatch => new Promise(function(resolve, reject) {
        dispatch(fetchMenuPending());
        axios.get(ApiService.getBaseUrl() + '/menus/locations', {
            params: params,
            headers: getHeaders()
        })
            .then(response => {
                let data = response.data;
                dispatch(fetchMenusLocationSuccess(data.data));
                resolve(response.data);
                return response.data;
            })
            .catch(res => {
                let error = parseError(res);
                console.log(error);
                reject(error);
                dispatch(fetchMenuError(error));
            });
    });
}
export async function getCities(query) {
    let params = {
        query: query
    };
    let response = await axios.get(ApiService.getBaseUrl() + '/menus/cities', {
        params: params,
        headers: getHeaders()
    });
    return response.data.data;
}
export async function getStreets(city, query) {
    let params = {
        query: query,
        city: city
    };
    let response = await axios.get(ApiService.getBaseUrl() + '/menus/streets', {
        params: params,
        headers: getHeaders()
    });
    return response.data.data;
}
export async function getPromotion(promotionId) {
    let params = {};
    let response = await axios.get(ApiService.getBaseUrl() + '/promotions/' + promotionId + "?include=conditions", {
        params: params,
        headers: getHeaders()
    });
    return response.data.data;
}

export default fetchMenu;