import React from "react";

class Utils {

    static getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }
    static nl2br(text){
        if(!text) return null;
        return text.split('\n').map((item, key) => {
            return <React.Fragment key={key}>{item}<br/></React.Fragment>
        });
    }
    static nl2brString(text){
        if(!text) return null;
        return text.split('\n').map((item, key) => {
            return `${item}<br/>`
        }).join("");
    }
    static timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }
    static countries(countriesValue){
        if(!countriesValue) return [];
        // let codes = [];
        let countries = countriesValue.split(",");
        return countries.map(x => {
            if(x.includes(":")){
                return {
                    key: x.split(":")[0],
                    value: x.split(":")[1],
                };
            }
            return {
                key: x,
                value: x
            };
        })
    }
    static zeroIfNull(value){
        return value ?? 0;
    }
}
export default Utils;
