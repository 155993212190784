import React, {useState} from 'react';
import {connect} from "react-redux";
import {getCart, getCartPromotions} from "../../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import {ReactComponent as PlusCircleIco} from "../../../images/icons/plus-circle.svg";
import {addVoucherToOrder} from "../../../application/cart/cartService";
import {getUser, getVouchers} from "../../../application/user/reducer";
import {getLocation} from "../../../application/location/reducer";
import {getLocale} from "../../../application/store/reducer";

const CartUserVouchers = (props)=>{
    const [loading, setLoading] = useState(false);

    const addVoucher = async (voucher) => {
        setLoading(true);
        let orderData = {
            address: props.location.address,
            contact: props.cart.contact,
            type: props.cart.type,
            estimated_preparation_at: props.cart.estimated_preparation_at,
            pickup_at: props.cart.pickup_at,
            number: props.cart.number,
            locale: props.locale
        }
        try {
            await props.dispatch(addVoucherToOrder(voucher.code, orderData));
        } catch (e){

        }
        setLoading(false)
    }
    const isAvailableVoucher = (voucher) => {
        return !props.orderPromotions.some(orderPromotion => voucher.promotion_id == orderPromotion.promotion_id && orderPromotion.reference_id == voucher.id);
        // return true;
    }
    let vouchers = props.vouchers.filter(x => isAvailableVoucher(x)).sort((a, b) => a.name.localeCompare(b.name))
    return (
        <>
            <div className={"container cart-promotions"}>
                {vouchers.map(voucher => {
                    return (
                        <button key={voucher.id} className={"btn btn-promotion highlight btn-spinner" + (loading ? ' loading' : '')} onClick={() => addVoucher(voucher)}>
                            <div className={"content"}>
                                {voucher.name}
                                <small>
                                    Kliknij aby dodać skorzystać z vouchera
                                </small>
                            </div>
                            <div className={"icon"}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                <PlusCircleIco />
                            </div>
                        </button>
                    )
                })}
            </div>
        </>
    )

}
const mapStateToProps = (state)=>{
    return {
        cart: getCart(state.cart),
        vouchers: getVouchers(state.user),
        orderPromotions: getCartPromotions(state.cart),
        location: getLocation(state.location),
        locale: getLocale(state.store),
        user: getUser(state.user)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartUserVouchers))