import React from 'react';
import {connect} from "react-redux";
import {getItems, getModifierGroups} from "../../../../../application/menu/reducer";
import LoadingContainer from "../../../../common/LoadingContainer";
import ItemHelper from "../../../../../application/common/ItemHelper";
import {withTranslation} from "react-i18next";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import MenuModifierGroupsNew from "./MenuModifierGroupsNew";
import StateItemHelper from "./StateItemHelper";


const MenuItemModifiers = (props)=>{
    let stateItem = props.stateItem;
    const {items, modifier_groups, itemView} = props;


    const setQuantityItem = (modifierGroup, item, quantity) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = getOrCreateStateModifierGroup(modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = quantity;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    const subQuantityItem = (modifierGroup, item) => {
        let modifierGroupItem = getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = modifierGroupItem.quantity - 1;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    const addQuantityItem = (modifierGroup, item) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = getOrCreateStateModifierGroup(modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = modifierGroupItem.quantity + 1;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    const refreshStateModifierGroupItemOption = (modifierGroupId) => {
        let stateModifierGroup = getOrCreateStateModifierGroup(modifierGroupId);
        let modifierGroup = ItemHelper.getModifierGroup(modifier_groups, modifierGroupId);
        stateModifierGroup.selected_items = stateModifierGroup.selected_items.filter(selectedItem => selectedItem.quantity > 0);

        let item = ItemHelper.getItem(items, stateItem.item_id);
        StateItemHelper.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);

        if(stateModifierGroup.selected_items.length <= 0){
            stateItem.modifier_groups = stateItem.modifier_groups.filter(itemModifierGroup => itemModifierGroup.modifier_group_id !== stateModifierGroup.id);
        }
        updateState();
    }

    const getOrCreateStateModifierGroup = (modifierGroupId) => {
        let modifierGroup = stateItem.modifier_groups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
        if(modifierGroup === undefined){
            modifierGroup = {
                modifier_group_id: modifierGroupId,
                selected_items: []
            };
            stateItem.modifier_groups.push(modifierGroup);
        }
        return modifierGroup;
    }
    const getOrCreateStateModifierGroupItemOption = (modifierGroupId, item) => {
        let modifierGroup = getOrCreateStateModifierGroup(modifierGroupId);
        let modifierGroupItem = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item.id)[0];
        if(modifierGroupItem === undefined){
            modifierGroupItem = StateItemHelper.create(item.id, props.items, props.modifier_groups, undefined, modifierGroupId, stateItem.item_id);
            modifierGroup.selected_items.push(modifierGroupItem);
        }

        return modifierGroupItem;
    }

    const updateState = (newItem) => {
        let copyItem = newItem ? Object.assign({}, newItem) : Object.assign({}, stateItem);
        StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        // setCurrentItem(copyItem);
        props.onUpdate(copyItem);
    }


    if(props.menuPending){
        return (
            <LoadingContainer/>
        )
    }

    return(
        <React.Fragment>
            <div className="product-item-modifiers">
                {
                    itemView.modifier_groups.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((modifierGroup) =>
                        <MenuModifierGroupsNew onEditSubItem={props.onEditSubItem} subQuantityItem={subQuantityItem} setQuantityItem={setQuantityItem} addQuantityItem={addQuantityItem} stateItem={stateItem} modifierGroup={modifierGroup} key={modifierGroup.id}></MenuModifierGroupsNew>
                    )
                }
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuItemModifiers))