import React, { Component } from 'react';
import {connect} from "react-redux";
import PaylaneCardPaymentMethodIframe from "./PaylaneCardPaymentMethodIframe";

class PaylaneCardPaymentMethod extends Component{

    constructor(props) {
        super(props);


        this.state = {
            show: false,
            // number: '41111111111111111',
            token: '',
            loading: false,
            error: ''

        };
        this.save = this.save.bind(this);
        this.changeCard = this.changeCard.bind(this);
    }
    addErrorCard(errorMessage){
        this.setState(prevState => ({
            ...prevState,
            error: errorMessage
        }));
    }
    changeCard(){
        this.setState({ token: null, loading: false, error:null });
        this.props.updatePaymentSettings({'token': null});
    }
    save(token){
        this.setState({ token: token, loading: false, error:null });
        this.props.updatePaymentSettings({'token': token});
    }

    render(){

        let tokeView = null;
        let tokeAddCard = null;
        if(this.state.token){
            tokeView = (
                <div className="alert alert-success">Karta dodana</div>
            )
            tokeAddCard = (
                <button className="btn btn-primary" onClick={this.changeCard}>Dodaj nową karte</button>
            )
        }else{
            tokeAddCard = (
                <div>
                    <PaylaneCardPaymentMethodIframe save={this.save} paymentMethod={this.props.paymentMethod}/>
                </div>
            )
        }
        // if(this.state.error !== null && this.state.error){
        //     errorView = (
        //         <ErrorFormAlertView errors={[this.state.error]} />
        //     )
        // }
        return(
            <div>
                {tokeView}
                {tokeAddCard}
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(PaylaneCardPaymentMethod)