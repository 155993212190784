import React, { Component } from 'react';
import {connect} from "react-redux";
import {getCart} from "../../../application/cart/reducer";
import {getPaymentMethods} from "../../../application/menu/reducer";
import PaylaneCardPaymentMethod from "./paymentMethod/paylane/PaylaneCardPaymentMethod";
import LoadingContainer from "../../common/LoadingContainer";
import PaylaneBankTransferPaymentMethod from "./paymentMethod/paylane/PaylaneBankTransferPaymentMethod";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import ErrorFormAlertView from "../../common/ErrorFormAlertView";
import BluemediaBankTransferPaymentMethod from "./paymentMethod/bluemedia/BluemediaBankTransferPaymentMethod";
import BluemediaCardPaymentMethod from "./paymentMethod/bluemedia/BluemediaCardPaymentMethod";
import {withTranslation} from "react-i18next";
import {getStore} from "../../../application/store/reducer";
import StoreHelper from "../../../application/common/StoreHelper";
import TpayBankTransferPaymentMethod from "./paymentMethod/tpay/TpayBankTransferPaymentMethod";

class CartPayment extends Component{

    constructor(props) {
        super(props);
        let availablePaymentMethods = StoreHelper.getAvailablePaymentMethods(props.store, props.paymentMethods, props.cart.type);
        let paymentMethodId = StoreHelper.getDefaultPaymentMethodId(props.cart.payment_method_id, availablePaymentMethods);
        this.state = {
            payment_method_id: paymentMethodId,
            updatedPaymentMethod: false
        };
        // this.handleChangeFloor = this.handleChangeFloor.bind(this);
        this.showAllPaymentMethods = this.showAllPaymentMethods.bind(this);
        this.updatePaymentSettings = this.updatePaymentSettings.bind(this);
    }
    setPaymentMethod(paymentMethod){
        this.setState({
            payment_method_id:paymentMethod.id,
            updatedPaymentMethod: true
        });
        this.props.cart.payment_method_id = paymentMethod.id;
    }
    showAllPaymentMethods(){
        this.setState({payment_method_id:null});
        this.props.cart.payment_method_id = null;
        this.props.cart.payment_method_name = null;
        this.props.cart.payment_settings = {};
    }

    updatePaymentSettings(settings){
        this.props.cart.payment_settings = settings;
        // this.props.cart.payment_method_id = null;
    }
    // componentWillReceiveProps(nextProps){
    //     // console.log('UPD');
    //     // console.log(nextProps);
    //     // console.log(this.props.address);
    //     this.setState({
    //         payment_method_id: nextProps.cart.payment_method_id
    //     });
    // }
    getPaymentMethod(paymentMethodId, paymentMethods){
        if(!paymentMethodId){
            return null;
        }

        let paymentMethod = paymentMethods.filter(paymentMethod => {
            return paymentMethod.id === paymentMethodId;
        })[0];
        if(paymentMethod !== undefined && paymentMethod){
            return paymentMethod;
        }
        return null;
    }
    render(){
        const {paymentMethods, cart, store, t} = this.props;
        let paymentMethodsView = null;

        let availablePaymentMethods = StoreHelper.getAvailablePaymentMethods(store, paymentMethods, cart.type);
        let paymentMethodId = StoreHelper.getDefaultPaymentMethodId(this.state.payment_method_id, availablePaymentMethods);
        let paymentMethod = this.getPaymentMethod(paymentMethodId, availablePaymentMethods);
        if(paymentMethod){

            if(paymentMethod === null){
                paymentMethodsView =  (
                    <LoadingContainer/>
                )
            }else{
                let paymentMethodView = null;
                if(paymentMethod.gateway === "PAYLANE"){
                    if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'CARD'){
                        paymentMethodView = (
                            <PaylaneCardPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        );
                    }else if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'BANK_TRANSFER'){
                        paymentMethodView = (
                            <PaylaneBankTransferPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        );
                    }
                }else if(paymentMethod.gateway === "BLUEMEDIA"){
                    if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'CARD'){
                        paymentMethodView = (
                            <BluemediaCardPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        );
                    }else if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'BANK_TRANSFER'){
                        paymentMethodView = (
                            <BluemediaBankTransferPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        );
                    }
                }else if(paymentMethod.gateway === "TPAY"){
                    if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'CARD'){
                        // paymentMethodView = (
                        //     <BluemediaCardPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        // );
                    }else if(paymentMethod.settings.type !== undefined && paymentMethod.settings.type === 'BANK_TRANSFER'){
                        paymentMethodView = (
                            <TpayBankTransferPaymentMethod openDialog={this.state.updatedPaymentMethod} updatePaymentSettings={this.updatePaymentSettings} paymentMethod={paymentMethod} />
                        );
                    }
                }
                paymentMethodsView = (
                    <div>
                        <div className="list-group-item list-group-item-action" key={paymentMethod.id}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{paymentMethod.name}</h5>
                                <small>{paymentMethod.gateway}</small>
                            </div>
                            <p className="mb-1">
                                {paymentMethod.description}
                            </p>
                            {paymentMethodView}
                        </div>
                        {availablePaymentMethods.length > 1 && (
                            <>
                                <div className="header-strike one"><span>lub</span></div>
                                <button type="button" className="btn btn-default w-100" onClick={this.showAllPaymentMethods}>
                                    Wybierz inną metodę płatności
                                </button>
                            </>
                        )}
                    </div>
                )
            }

        }else{
            paymentMethodsView = (
                <div className="list-group">
                    {
                        availablePaymentMethods.map((paymentMethod) =>

                            <div className="list-group-item list-group-item-action" key={paymentMethod.id} onClick={(e) => this.setPaymentMethod(paymentMethod)}>
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">{paymentMethod.name}</h5>
                                    <small>{paymentMethod.gateway}</small>
                                </div>
                                <p>
                                    {paymentMethod.description}
                                </p>
                            </div>
                        )
                    }
                </div>
            );
        }
        return(
            <div className={"card card-cart card-payment" + ErrorFormHelper(this.props.errors, "paymentMethodId,paymentMethod")}>
                <ErrorFormAlertView errors={this.props.errors} field="paymentMethodId" />
                <ErrorFormAlertView errors={this.props.errors} field="paymentMethod" />
                <div className="card-body">
                    <h3>
                        {t("components.cart.payment.title")}
                    </h3>
                    {paymentMethodsView}
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        paymentMethods: getPaymentMethods(state.menu),
        cart: getCart(state.cart),
        store: getStore(state.store)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartPayment))