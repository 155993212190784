import React, { Component } from 'react';
import {connect} from "react-redux";
import MenuProductListItem from "./MenuProductListItem";
import Utils from "../../../utils/Utils";

class MenuItemsGrid extends Component{

    render(){
        let menu = this.props.menu;
        let menuCategories = this.props.categories;
        let preview = this.props.preview;
        return(
            <div>
                {
                    menuCategories.map((item) =>
                        <section className="menu-category container scrollspy-section" id={'category-' + item.id} key={item.id}>
                            <h4>{item.name}</h4>
                            <p dangerouslySetInnerHTML={{__html: Utils.nl2brString(item.description)}}></p>
                            <div className="menu-items menu-items-grid">
                                {
                                    item.entities.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((itemEntity) =>
                                        <MenuProductListItem promotions={this.props.promotions} preview={preview} menuId={menu.id} addItem={this.props.addItem} categoryId={item.id} product={itemEntity} key={'item-' + item.id + '-' +  itemEntity.id}/>
                                    )
                                }
                            </div>
                        </section>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(MenuItemsGrid)