

export const parseError = (error)=>{
    let newError = {
        errors:[]
    }
    if(error.response !== undefined){
        let errorResponse = error.response;
        newError.status = errorResponse.status;
        if(errorResponse.data.errors !== undefined && errorResponse.data.errors.length > 0){
            newError.message = errorResponse.data.errors[0];
            newError.errors = errorResponse.data.errors;
        }else{
            if(errorResponse.data.error !== undefined){
                newError.message = errorResponse.data.error;
                newError.errors.push(errorResponse.data.error);
            }else{
                newError.message = error.message;
                newError.errors.push(error.message);
            }
        }
    }else{
        if(error.errors !== undefined && error.errors.length > 0){
            newError.message = error.errors[0];
            newError.errors = error.errors;
        }else{
            newError.message = error.error;
            newError.errors.push(error.error);
            if(error.message){
                newError.code = error.message;
            }
        }
    }
    return newError;
}
export const parseResponse= (data)=>{
}