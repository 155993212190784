import React from 'react';
import {useTranslation} from "react-i18next";
const Money = (props)=>{
    const { t, i18n } = useTranslation();
    let money = null;
    if(props.value === undefined || props.value === null){
        money = {
            amount: 0,
            currency: "PLN"
        }
    }else{
        if(typeof props.value === 'object'){
            money = props.value
        }else{
            money = {
                amount: props.value,
                currency: props.currency !== undefined && props.currency ? props.currency : 'PLN'
            };
        }
    }
    return  Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: money.currency
    }).format(money.amount);
}

export default Money;