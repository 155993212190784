export const REFRESH_BASKET = 'REFRESH_BASKET';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const GET_ORDER = 'GET_ORDER';
export const ORDER_PENDING = 'ORDER_PENDING';
export const ORDER_ERROR = 'ORDER_ERROR';
export const CART_INIT_DATA = 'CART_INIT_DATA';
export const ORDER_CHANGE_TYPE = 'ORDER_CHANGE_TYPE';
export const ACCEPT_RULES = 'ACCEPT_RULES';
export const ORDER_STORE_UPDATE = 'ORDER_STORE_UPDATE';
export const ORDER_INVALID = 'ORDER_INVALID';

export const acceptRules= (acceptRules)=>{
    return {
        type: ACCEPT_RULES,
        accepted_rules: acceptRules
    };
}

export const changeOrderType= (type)=>{
    return {
        type: ORDER_CHANGE_TYPE,
        orderType: type
    };
}


export const updateStoreData= (data)=>{
    return {
        type: ORDER_STORE_UPDATE,
        data: data
    };
}

export const updateInitData = (data)=>{
    data.type = CART_INIT_DATA;
    return data;
}

export const refreshBasket= (data)=>{
    return {
        type: REFRESH_BASKET,
        order: data
    };
}

export const clearBasket= ()=>{
    return {
        type: CLEAR_BASKET
    };
}

export function orderPending() {
    return {
        type: ORDER_PENDING
    }
}
export function orderError(error) {
    return {
        type: ORDER_ERROR,
        errors: error.errors
    }
}
export function orderErrorNotValid(error) {
    return {
        type: ORDER_INVALID,
        errors: error.errors
    }
}


export function fetchOrderSuccess(order) {
    return {
        type: GET_ORDER,
        order: order,
    }
}
