import React, {useState} from 'react';
import {connect} from "react-redux";
import {getCart} from "../../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import Money from "../../common/Money";
import {ReactComponent as PlusRemoveIco} from "../../../images/icons/plus-remove.svg";
import {removePromotion} from "../../../application/cart/cartService";

const CartVouchers = (props)=>{
    const [loading, setLoading] = useState(false);

    const remove = async (orderPromotion) => {

        setLoading(true);
        try {
            await props.dispatch(removePromotion(orderPromotion.id));
        } catch (e){
            console.log(e);
        }
        setLoading(false)

    }
    let sortedPromotions = props.cart.promotions.sort((a, b) => a.name.localeCompare(b.name))
    sortedPromotions = sortedPromotions.filter(x => x.type == "VOUCHER");
    return (
        <>

            <div className={"container cart-promotions"}>
                {sortedPromotions.map(promotion => {
                    return (
                        <div className={"btn btn-promotion btn-spinner" + (loading ? ' loading' : '')} key={promotion.id}>
                            <div className={"content"}>
                                Voucher: {promotion.name}
                                <small>
                                    <Money value={promotion.value_money}/>
                                </small>
                            </div>
                            <div className={"icon"}  onClick={() => remove(promotion)}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                <PlusRemoveIco />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )

}
const mapStateToProps = (state)=>{
    return {
        cart: getCart(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartVouchers))