import {clearBasket, orderError, orderErrorNotValid, orderPending, refreshBasket} from "./actions";
import axios from 'axios'
import getToken from "../security/securityService";
import {addFlash} from "../notification/actions";
import {parseError} from "../common/apiResponse";
import ApiService from "../common/apiService";
import {updateLocation} from "../location/actions";
import {fetchLocation} from "../menu/fetchMenu";
import OrderHelper from "../common/OrderHelper";
import OrderContext from "../common/OrderContext";
import UserContext from "../common/UserContext";
import AppConfig from "../common/AppConfig";
import Analytics from "../common/Analytics";

function getHeaders(accessToken) {

    let headers = {
    }
    let orderToken = OrderContext.getOrderToken();
    let userToken = UserContext.getToken();
    if(orderToken !== undefined && orderToken){
        headers["order-token"] = orderToken;
    }
    if(userToken !== undefined && userToken){
        headers["user-token"] = userToken;
    }

    return ApiService.getHeaders(
        headers
    );
}
export function cleanCart(dispatch) {

    OrderContext.cleanOrder();
    dispatch(clearBasket());
}
function handleError(error, dispatch) {
    if(error.status === 403){
        if(dispatch !== null){
            if(error.code !== undefined && (error.code==='order_token_wrong' || error.code==='order_token_not_found')){
                cleanCart(dispatch);
            }
        }
    }
}
export function createOrder(newItem, orderData) {

    let url = ApiService.getBaseUrl() + '/orders';

    orderData.items = [];
    if(newItem !== undefined && newItem !== null){
        orderData.items.push(newItem);
    }
    if(AppConfig.isNativeMobile()){
        orderData.source = {
            name: "GoOrderApp"
        }
    }

    return dispatch => new Promise(function(resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios.post(url, orderData, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    let orderId = data.data.id;
                    OrderContext.setOrderId(orderId);
                    if(data.data.token !== undefined){
                        OrderContext.setOrderToken(data.data.token);
                    }
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                    Analytics.addToCartNewOrder(data.data);
                    // return data.data;
                })
                .catch(error => {
                    error = parseError(error);
                    if(error.status === 422){
                        // let errorMessage = error.response.data.errors[0].message;
                        dispatch(addFlash('error', error.errors));
                    }else{
                        alert(error.message);
                    }
                    dispatch(orderError(error));
                    reject(error);
                });
        });
    });
}
export function addItem(newItem, orderData) {
    let orderId = OrderContext.getOrderId();
    if(orderId === undefined || !orderId){
        return createOrder(newItem, orderData);
    }
    let data = newItem;
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/items';
    let method = null;
    if(newItem.id !== undefined){
        url = url + "/" + newItem.id;
        method = 'put';
    }else{
        method = 'post';
    }

    return dispatch => new Promise(function(resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios({
                method: method,
                url: url,
                data: data,
                headers: getHeaders(accessToken)
            })
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                    Analytics.addToCart(newItem);
                    // return data.data;
                })
                .catch(res => {
                    let error = parseError(res);
                    // alert(error.message);
                    dispatch(orderError(error));
                    dispatch(addFlash('error', error.errors));
                    reject(error);
                });
        }).catch(error => {
            error = parseError(data);
            dispatch(orderError(error));
            reject(error);
        });
    });

}

export function updateItem(id, value) {
    let orderId = OrderContext.getOrderId();
    if(orderId === undefined || !orderId){
        return dispatch => new Promise(function(resolve, reject) {
            resolve();
        });
    }
    let url = null;
    let data = null;
    let method = null;
    if(value === undefined || value === 0){
        url = ApiService.getBaseUrl() + '/orders/' + orderId + '/items/' + id;
        data = null;
        method = 'delete';
    }else{
        url = ApiService.getBaseUrl() + '/orders/' + orderId + '/items/' + id;
        data = {
            quantity: value
        }
        method = 'put';
    }

    return dispatch => new Promise(function (resolve) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios({
                method: method,
                url: url,
                data: data,
                headers: getHeaders(accessToken)
            })
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data);
                })
                .catch(error => {
                    error = parseError(error);
                    // alert(error.message);
                    dispatch(orderError(error));
                    dispatch(addFlash('error', error.errors));
                });
        }).catch(error => {
            error = parseError(data);
            dispatch(orderError(error));
        });
    });
}

export function updateAddress(address, cart) {
    let data = {
        address: address
    }

    return dispatch => Promise.all([
        dispatch(updateOrderData(data, cart)),
        dispatch(updateLocation(address)),
        dispatch(fetchLocation(address)),
    ]);
}

export function updateOrderData(orderData, cart) {

    if(cart !== undefined){
        if(orderData['number'] === undefined){
            orderData.number = cart.number;
        }
        if(orderData['pickup_at'] === undefined){
            orderData.pickup_at = cart.pickup_at;
        }
    }else{
    }
    let orderId = OrderContext.getOrderId();
    if (orderId === undefined || !orderId) {
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(orderData));
            resolve();
        });
    }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId;

    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        // let hearders = getHeaders();
        // hearders['Content-Type'] = 'application/x-www-form-urlencoded';
        getToken().then(accessToken => {
            axios.put(url, orderData, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}


export function updateOrderCoupon(couponCode, orderData) {

    let orderId = OrderContext.getOrderId();
    // if (orderId === undefined || !orderId) {
    //     return dispatch => new Promise(function(resolve, reject) {
    //         dispatch(refreshBasket(null));
    //         resolve();
    //     });
    // }

    let coupoonData = {
        code: couponCode
    }
    
    const dispatchCoupon = (url, dispatch, resolve, reject) => {
        getToken().then(accessToken => {
            axios.post(url, coupoonData, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    }
    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        if (orderId === undefined || !orderId) {
            dispatch(createOrder(undefined, orderData)).then(res => {
                let url = ApiService.getBaseUrl() + '/orders/' + res.id + '/coupon';
                dispatchCoupon(url, dispatch, resolve, reject);
            }).catch(error => {
                error = parseError(error);
                dispatch(orderError(error));
                reject(error);
            });
        }else{
            let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/coupon';
            dispatchCoupon(url, dispatch, resolve, reject);
        }
    });
}

export function addVoucherToOrder(couponCode, orderData) {

    let orderId = OrderContext.getOrderId();
    let data = {
        code: couponCode
    }

    const dispatchCoupon = (url, dispatch, resolve, reject) => {
        getToken().then(accessToken => {
            axios.post(url, data, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    }
    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        if (orderId === undefined || !orderId) {
            dispatch(createOrder(undefined, orderData)).then(res => {
                let url = ApiService.getBaseUrl() + '/orders/' + res.id + '/vouchers';
                dispatchCoupon(url, dispatch, resolve, reject);
            }).catch(error => {
                error = parseError(error);
                dispatch(orderError(error));
                reject(error);
            });
        }else{
            let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/vouchers';
            dispatchCoupon(url, dispatch, resolve, reject);
        }
    });
}


export function removePromotion(id) {

    let orderId = OrderContext.getOrderId();
    if (orderId === undefined || !orderId) {
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(null));
            resolve();
        });
    }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/promotions/' + id;

    return dispatch => new Promise(function (resolve, reject) {
        getToken().then(accessToken => {
            axios.delete(url, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}
export function payOrder(paymentMethodId, paymentSettings, acceptedRules) {
    let orderId = OrderContext.getOrderId();

    if (orderId === undefined || !orderId) {
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(null));
            resolve();
        });
    }
    let data = { ...paymentSettings };
    data.payment_method_id = paymentMethodId;
    data.accepted_rules = acceptedRules;
    // if(acceptedRules['acceptMarketing']){
    //     data.accept_marketing = true;
    // }else{
    //     data.accept_marketing = false;
    // }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + "/pay";

    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios.post(url, data, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    // dispatch(refreshBasket(data.data));
                    cleanCart(dispatch);
                    resolve(data);
                })
                .catch(error => {
                    error = parseError(error);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        error.errors.map(err => {
                            if(err.message && err.message.includes(" ||")){
                                let splitErrorFirst =  err.message.split(" ||")[0];
                                err.code = splitErrorFirst;
                            }
                            return err;
                        })
                        dispatch(addFlash('error', error.errors));
                    }

                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
        });
    });
}

export function fixOrder() {
    let orderId = OrderContext.getOrderId();
    if (orderId === undefined || !orderId) {
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(null));
            resolve();
        });
    }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + "/fix";

    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios.post(url, {}, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data);
                })
                .catch(error => {
                    error = parseError(error);
                    dispatch(orderErrorNotValid(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }

                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
        });
    });
}
export function fetchOrder() {
    let orderId = OrderContext.getOrderId();
    if(orderId === undefined || !orderId){
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(null));
            resolve();
        });
        // return dispatch => {
        //     dispatch(refreshBasket(null));
        // };
    }

    return dispatch => new Promise(function(resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            fetch(ApiService.getBaseUrl() + '/orders/' + orderId + '?include=all&checkStatus=1', {
                headers: getHeaders(accessToken)
            })
                .then(res => {
                    if(res.status !== 200){
                        return res.json().then(resJson => {
                            let error = parseError(resJson);
                            error.status = res.status;
                            return Promise.reject(error);
                        });
                    }
                    return res.json();
                })
                .then(res => {
                    let error = parseError(res);
                    if(res.error) {
                        throw(res.error);
                    }
                    if(res.data.status === 'OPEN'){
                        dispatch(refreshBasket(res.data));
                    }else if(OrderHelper.isConfirmed(res.data)){
                        // cleanCart(dispatch);
                        dispatch(refreshBasket(res.data));
                    }else{
                        cleanCart(dispatch);
                    }
                    resolve(res.data);
                })
                .catch(error => {

                    if(error.status === 404){
                        cleanCart(dispatch);
                    }
                    handleError(error, dispatch);
                    dispatch(orderErrorNotValid(error));
                    reject(error);
                })
        }).catch(error => {
            handleError(error, dispatch);
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}

export function getOrder(id, token, withRefresh, checkStatus) {
    return new Promise(function(resolve, reject) {

        getToken().then(accessToken => {
            let headers = getHeaders(accessToken);
            headers["order-token"] = token;
            let refreshString = withRefresh !== undefined && withRefresh ? "&refresh=1" : "";
            let checkStatusString = checkStatus !== undefined && checkStatus ? "&checkStatus=1" : "";
            fetch(ApiService.getBaseUrl() + '/orders/' + id + '?include=all' + refreshString + checkStatusString, {
                headers: headers
            })
                .then(res => {
                    if(res.status !== 200){
                        return res.json().then(resJson => {
                            let error = parseError(resJson);
                            error.status = res.status;
                            return Promise.reject(error);
                        });
                    }
                    return res.json();
                })
                .then(res => {
                    if(res.error) {
                        throw(res.error);
                    }
                    resolve(res.data);
                })
                .catch(error => {
                    error = parseError(error);
                    reject(error);
                });
        }).catch(error => {
            reject(error);
        });
    });

}


export function cancelOrder(orderId, token, reason) {
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + "/fix";
    return new Promise(function(resolve, reject) {

        getToken().then(accessToken => {
            let headers = getHeaders(accessToken);
            headers["order-token"] = token;
            let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/cancel?include=all';
            axios.post(url, {'reason': reason}, {headers: headers})
                .then(response => {

                    if(response.error) {
                        throw(response.error);
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    error = parseError(error);
                    reject(error);
                });
        }).catch(error => {
            reject(error);
        });
    });
}

export function copyOrder(orderId, token) {
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + "/copy";
    return dispatch => new Promise(function(resolve) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            let headers = getHeaders(accessToken);
            headers["order-token"] = token;
            axios.post(url, {}, {headers: headers})
                .then(response => {
                    let data = response.data;
                    let orderId = data.data.id;
                    OrderContext.setOrderId(orderId);
                    if(data.data.token !== undefined){
                        OrderContext.setOrderToken(data.data.token);
                    }
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                    // return data.data;
                })
                .catch(error => {
                    error = parseError(error);
                    if(error.status === 422){
                        // let errorMessage = error.response.data.errors[0].message;
                        dispatch(addFlash('error', error.errors));
                    }else{
                        alert(error.message);
                    }
                    dispatch(orderError(error));
                });
        });
    });
}


export function fetchContact(provider, id) {

    return dispatch => new Promise(function(resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            fetch(ApiService.getBaseUrl() + '/contacts/search?referenceId=' + id + '&provider=' + provider, {
                headers: getHeaders(accessToken)
            })
                .then(res => {
                    if(res.status !== 200){
                        return res.json().then(resJson => {
                            let error = parseError(resJson);
                            error.status = res.status;
                            return Promise.reject(error);
                        });
                    }
                    return res.json();
                })
                .then(res => {
                    let error = parseError(res);
                    if(res.error) {
                        throw(res.error);
                    }
                    // if(res.data.status === 'OPEN'){
                    //     dispatch(refreshBasket(res.data));
                    // }else if(OrderHelper.isConfirmed(res.data)){
                        cleanCart(dispatch);
                        // dispatch(refreshBasket(res.data));
                    // }else{
                    //     cleanCart(dispatch);
                    // }
                    resolve(res.data);
                })
                .catch(error => {

                    if(error.status === 404){
                        cleanCart(dispatch);
                    }
                    handleError(error, dispatch);
                    dispatch(orderErrorNotValid(error));
                    reject(error);
                })
        }).catch(error => {
            handleError(error, dispatch);
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}
//
// export function login(provider, login, password) {
//
//
//     let url = ApiService.getBaseUrl() + '/contacts/login';
//
//     let loginData = {
//         login: login,
//         password: password
//     }
//     return dispatch => new Promise(function (resolve, reject) {
//         dispatch(orderPending());
//         getToken().then(accessToken => {
//             axios.post(url, orderData, {headers: getHeaders(accessToken)})
//                 .then(response => {
//                     let data = response.data;
//                     dispatch(refreshBasket(data.data));
//                     resolve(data.data);
//                 })
//                 .catch(data => {
//                     let error = parseError(data);
//                     handleError(error, dispatch);
//                     dispatch(orderError(error));
//                     reject(error);
//                     if(error.status !== 422){
//                         alert(error.message);
//                     }else{
//                         dispatch(addFlash('error', error.errors));
//                     }
//                 });
//         }).catch(error => {
//             error = parseError(error);
//             dispatch(orderError(error));
//             reject(error);
//         });
//     });
// }


export function updateOrderChannelContact(token, dataContact) {

    let orderId = OrderContext.getOrderId();


    if (orderId === undefined || !orderId) {
        let data = dataContact && dataContact.email ? {
            contact: {
                email: dataContact.email
            }
        } : null;
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(data));
            resolve();
        });
    }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/channel/contact';

    let orderData = {
        token: token
    }
    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios.post(url, orderData, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}

export function deleteOrderContact() {

    let orderId = OrderContext.getOrderId();
    if (orderId === undefined || !orderId) {
        return dispatch => new Promise(function(resolve, reject) {
            dispatch(refreshBasket(null));
            resolve();
        });
    }
    let url = ApiService.getBaseUrl() + '/orders/' + orderId + '/contact';

    return dispatch => new Promise(function (resolve, reject) {
        dispatch(orderPending());
        getToken().then(accessToken => {
            axios.delete(url, {headers: getHeaders(accessToken)})
                .then(response => {
                    let data = response.data;
                    dispatch(refreshBasket(data.data));
                    resolve(data.data);
                })
                .catch(data => {
                    let error = parseError(data);
                    handleError(error, dispatch);
                    dispatch(orderError(error));
                    reject(error);
                    if(error.status !== 422){
                        alert(error.message);
                    }else{
                        dispatch(addFlash('error', error.errors));
                    }
                });
        }).catch(error => {
            error = parseError(error);
            dispatch(orderError(error));
            reject(error);
        });
    });
}