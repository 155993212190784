import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateOrderData} from "../../application/cart/cartService";
import {DateHelper, parseDate} from "../../application/common/DateHelper";
import {getCart, getOrderType, getPickDate} from "../../application/cart/reducer";
import {getAvailabilities, getLocationStore, getMenus} from "../../application/menu/reducer";
import AvailableHelper from "../../application/common/AvailableHelper";
import {withTranslation} from "react-i18next";
import ChangeDateModal from "./ChangeDateModal";

class ChangePickDateModal extends Component{

    constructor(props) {
        super(props);
        this.savePickDate = this.savePickDate.bind(this);
    }

    savePickDate(date){
        let dateValue = date !== null ? DateHelper.format(date) : "";
        let data = {
            pickup_at: dateValue
        }
        return new Promise((resolve, reject) => {

            this.props.dispatch(updateOrderData(data, this.props.cart)).then(data => {
                resolve();
                this.props.hidePickDateModal();
            }).catch(error => {
                reject(error);
            });
        });
    }
    render(){
        const {t, pickDate, showModal, onHide} = this.props;

        let menus = this.props.menuId !== undefined ? this.props.menus.filter(menu => menu.id == this.props.menuId) : this.props.menus;

        let dates = AvailableHelper.getAllDates(menus, this.props.availabilities);
        let weekDays = AvailableHelper.getAllWeekDays(menus, this.props.availabilities);
        let menuAvailabilities =AvailableHelper.getAvailabilities(this.props.availabilities, menus.map(menu => menu.availability_id));
        return(
            <ChangeDateModal orderType={this.props.orderType} locationStore={this.props.locationStore} dates={dates} weekDays={weekDays} availabilities={menuAvailabilities} button_clear_title={t("common.action.now")} onSave={this.savePickDate} title={t("components.pick_date_modal.title")} value={pickDate} showModal={showModal} onHide={onHide} />

        )
    }

}
const mapStateToProps = (state)=>{
    return {
        pick_date: getPickDate(state.cart),
        cart: getCart(state.cart),
        orderType: getOrderType(state.cart),
        locationStore: getLocationStore(state.menu),
        availabilities: getAvailabilities(state.menu),
        menus: getMenus(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(ChangePickDateModal))