import React, {Component} from 'react';
import {connect} from 'react-redux'
import LoadingContainer from "../common/LoadingContainer";
import ErrorView from "../common/ErrorView";
import {cancelOrder, copyOrder, getOrder} from "../../application/cart/cartService";
import SummaryItems from "./summary/SummaryItems";
import SummaryShipment from "./summary/SummaryShipment";
import SummaryPayment from "./summary/SummaryPayment";
import SummaryContact from "./summary/SummaryContact";
import SummarySummary from "./summary/SummarySummary";
import {ScrollToTop} from "../utils/ScrollToTop";
import NavbarCart from "../layout/NavbarCart";
import OrderStatus from "../common/order/OrderStatus";
import OrderPaymentStatus from "../common/order/OrderPaymentStatus";
import {getStore, isPreview} from "../../application/store/reducer";
import Money from "../common/Money";
import {parseDate} from "../../application/common/DateHelper";
import Analytics from "../../application/common/Analytics";
import ErrorFormAlertView from "../common/ErrorFormAlertView";
import DateService from "../../application/service/DateService";
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "../common/ErrorFormHelper";
import ErrorFormView from "../common/ErrorFormView";
import Utils from "../utils/Utils";
import FormattedDate from "../common/FormattedDate";
import FormattedTime from "../common/FormattedTime";
import {withTranslation} from "react-i18next";
import {getItems} from "../../application/cart/reducer";
import {getModifierGroups} from "../../application/menu/reducer";
import Pixel from "../../application/common/Pixel";

class Summary extends Component {
    state = {
        pending: true,
        order: null,
        error: null,
        cancelModal: false,
        cancelLoading: false,
        orderAgainLoading: false,
        cancelReason: '',
        cancelErrors: []
    };
    token = null;

    constructor(props) {
        super(props);
        this.handleChangeCancelReason = this.handleChangeCancelReason.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.hideCancelModal = this.hideCancelModal.bind(this);
        this.orderAgain = this.orderAgain.bind(this);
    }

    componentDidMount() {
        const {match} = this.props;
        let id = parseInt(match.params.id);
        let token = match.params.token;
        this.token = token;
        this.setState({pending: true})
        this.refreshOrder(id, token);
    }

    shouldRefreshing() {
        if (this.state.order == null) {
            return true;
        }
        if (this.state.order.status == 'OPEN' || this.state.order.status == 'CONFIRMED' || this.state.order.status == 'WAITING_FOR_ACCEPTED') {
            return true;
        }
        return false;
    }

    refreshOrder(id, token, withoutPending, withRefresh) {

        if (!this.shouldRefreshing()) {
            this.setState({pending: false})
            return;
        }
        getOrder(id, token, withRefresh).then(data => {
            if (this.state.order === null) {
                Analytics.purchase(data);
                Pixel.purchase(data);
            }
            this.setState({order: data, pending: false})
            if (data.status === 'CONFIRMED' && data.payment_status === 'PROGRESS') {
                withRefresh = true;
            }
            this.intervalID = setTimeout(() => {
                this.refreshOrder(id, token, true, withRefresh)
            }, 5000);

        }).catch(error => {
            alert(error);
            console.log(error);
            this.setState({pending: false, error: error})
        });
    }

    cancel(id, token) {
        this.setState({cancelLoading: false})
        cancelOrder(id, token, this.state.cancelReason).then(data => {
            this.setState({order: data.data, pending: false})
        }).catch(error => {
            this.setState({cancelLoading: false, cancelErrors: error.errors})
        });
    }

    orderAgain(id, token) {
        this.setState({orderAgainLoading: true})
        this.props.dispatch(copyOrder(id, token)).then(data => {
            this.setState({orderAgainLoading: false})
            this.props.history.push("/");
        }).catch(error => {
            this.setState({orderAgainLoading: false})
        });
    }

    componentWillUnmount() {

        clearInterval(this.intervalID);
    }

    shouldComponentRender() {
        if (this.state.pending === false) return true;
        return false;
    }

    showCancelModal() {
        this.setState({
            cancelModal: true
        })
    }

    hideCancelModal() {
        this.setState({
            cancelModal: false
        })
    }

    handleChangeCancelReason(event) {
        this.setState({
            cancelReason: event.target.value
        });
    }

    render() {
        const {order, error} = this.state;
        const {t, store, preview} = this.props;

        if (!this.shouldComponentRender()) return (
            <div>
                <LoadingContainer/>
            </div>
        );
        if (error || preview) {
            return (
                <div>
                    <ErrorView>
                        {error}
                    </ErrorView>
                </div>
            );
        }
        let numberView = null;
        if (order.number !== undefined) {
            let yourNumber = t("components.summary.your_number");
            if (order.type === 'ROOM_SERVICE') {
                yourNumber = t("components.summary.your_room_number");
            } else if (order.type === 'DINE_IN') {
                yourNumber = t("components.summary.your_table_number");
            }
            numberView = (
                <div className="cart-response">
                    <h2>
                        {yourNumber}: <br/>
                        {order.number}
                    </h2>
                </div>
            )
        }
        let loadingForAcceptance = null;
        let cancelView = null;

        if (order.status === "WAITING_FOR_ACCEPTED" || order.status === "ACCEPTED") {
            if (this.props.store.cancellation_time > 0) {
                let confirmedAt = parseDate(order.confirmed_at);
                let now = DateService.now();
                let addMiliseconds = this.props.store.cancellation_time * 60 * 1000;
                let nowWithCancellationTime = new Date(now.getTime() - addMiliseconds);
                if (confirmedAt > nowWithCancellationTime) {
                    cancelView = (
                        <React.Fragment>
                            <ErrorFormAlertView errors={this.state.cancelErrors}/>
                            <button className="btn btn-danger"
                                    onClick={(e) => this.showCancelModal(order.id, order.token)}>
                                {t("components.summary.cancel_order")}
                            </button>
                            <Modal dialogClassName="modal-full" show={this.state.cancelModal}
                                   onHide={this.hideCancelModal} onShow={this.onShow}>
                                <div className="">
                                    <Modal.Header>
                                        <Modal.Title>
                                            {t("components.summary.reason_cancellation")}
                                        </Modal.Title>
                                        <button onClick={this.hideCancelModal} className="btn-close"
                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                    </Modal.Header>
                                </div>
                                <Modal.Body>
                                    <div>
                                        <ErrorFormAlertView errors={this.state.cancelErrors}/>

                                        <div className="form-group">
                                            <input type="text" value={this.state.cancelReason}
                                                   onChange={this.handleChangeCancelReason}
                                                   className={"form-control" + ErrorFormHelper(this.state.cancelErrors, "cancel_reason")}
                                                   id="street" placeholder={t("components.summary.cancel_reason")}/>
                                            <label htmlFor="street">{t("components.summary.cancel_reason")}</label>
                                            <ErrorFormView errors={this.state.cancelErrors} field="cancel_reason"/>
                                        </div>
                                    </div>
                                    <button
                                        className={"btn-spinner btn-block btn-primary btn btn-submit" + (this.state.cancelLoading ? ' loading' : '')}
                                        variant="primary" onClick={(e) => this.cancel(order.id, order.token)}>
                                        <span className="left spinner-border spinner-border-sm"></span>
                                        {t("components.summary.cancel_order")}
                                    </button>
                                </Modal.Body>
                                <Modal.Footer>
                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    )
                }
            }
        }
        if (order.status === "WAITING_FOR_ACCEPTED") {

            let textWaitingForAccept = this.props.store.settings.text_waiting_for_confirmation;
            if (!textWaitingForAccept) {
                textWaitingForAccept = t("components.summary.waiting_for_accept");
            }
            loadingForAcceptance = (
                <React.Fragment>
                    <div className="summary-waiting">
                        <h3>{textWaitingForAccept}</h3>
                        <div className="summary-loading">
                            <LoadingContainer>{" "}</LoadingContainer>
                        </div>
                        {cancelView}
                    </div>
                </React.Fragment>
            )
        } else if (order.status === "ACCEPTED") {
            let addDate = null;
            if (order.pickup_at) {
                if (order.estimated_preparation_at) {
                    addDate = (
                        <React.Fragment>
                            <FormattedDate value={parseDate(order.estimated_preparation_at)}/>&nbsp;
                            <FormattedTime value={parseDate(order.estimated_preparation_at)}/>
                        </React.Fragment>
                    )
                } else {
                    if(order.pickup_at){
                        addDate = (
                            <React.Fragment>
                                <FormattedDate value={parseDate(order.pickup_at)}/>&nbsp;
                                <FormattedTime value={parseDate(order.pickup_at)}/>
                            </React.Fragment>
                        )
                    }
                }
            } else {
                if(order.estimated_preparation_at){
                    addDate = (
                        <React.Fragment>
                            <FormattedTime value={parseDate(order.estimated_preparation_at)}/>
                        </React.Fragment>
                    )
                }
            }
            let timeToDelivery = null;
            if (order.estimated_preparation_at !== undefined && order.estimated_preparation_at) {
                if (order.type === 'DELIVERY') {
                    timeToDelivery = (
                        <React.Fragment>
                            {t("components.summary.shipment.estimate_delivery_time")}: <br/>
                            <strong>
                                {addDate}
                            </strong>
                        </React.Fragment>
                    )
                } else if (order.type === 'DINE_IN' || order.type === 'ROOM_SERVICE') {
                    timeToDelivery = (
                        <React.Fragment>
                            {t("components.summary.shipment.estimate_preparation_time")}: <br/>
                            <strong>
                                {addDate}
                            </strong>
                        </React.Fragment>
                    )
                } else {
                    timeToDelivery = (
                        <React.Fragment>
                            {t("components.summary.shipment.estimate_pickup_time")}: <br/>
                            <strong>
                                {addDate}
                            </strong>
                        </React.Fragment>
                    )
                }
            }
            let timeToDeliveryView = null;
            let storeSuccessInfo = store.customer_success_info ? (
                <small><br/>{Utils.nl2br(store.customer_success_info)}</small>
            ) : null;
            timeToDeliveryView = (

                <div className="cart-response">
                    <h3 className={"text-success"}>
                        {t("components.summary.accepted")}. <br/>
                    </h3>
                    {timeToDelivery && (
                        <>
                            <div className={"cart-response-info"}>
                                {timeToDelivery}
                            </div>
                        </>
                    )}
                    {storeSuccessInfo && (
                        <div>
                        {storeSuccessInfo}
                        </div>
                    )}
                </div>
            )

            loadingForAcceptance = (
                <div className="cart-response">
                    {timeToDeliveryView}
                    {cancelView}
                </div>
            )
        } else if (order.status === "CANCELED" || order.status === "REJECTED") {
            let storeRejectInfo = order.status === "REJECTED" && store.customer_reject_info ? (
                <small>{Utils.nl2br(store.customer_reject_info)}</small>
            ) : null;
            loadingForAcceptance = (
                <div className="cart-response">
                    <h3 className={"text-danger"}>
                        {t("components.summary.rejected")}<br/>
                    </h3>
                    {order.reason && (
                        <div className={"cart-response-info"}>
                            Powód:
                            <strong>{Utils.nl2br(order.reason)}</strong>
                        </div>
                    )}
                    {storeRejectInfo && (
                        <div>
                            {storeRejectInfo}
                        </div>
                    )}
                    <button className="btn btn-primary" onClick={(e) => this.orderAgain(order.id, order.token)}>
                        {t("components.summary.order_again")}
                    </button>
                </div>
            )
        } else if (order.status === "CONFIRMED") {
            if (order.payment_status === "FAILURE") {
                loadingForAcceptance = (
                    <div className="cart-response">
                        <h3 className={"text-danger"}>
                            {t("components.summary.payment_error")}
                        </h3>
                    </div>
                )
            } else if (order.payment_status === "PROGRESS") {
                loadingForAcceptance = (
                    <div className="summary-waiting">
                        <h3>{t("components.summary.waiting_payment")}</h3>
                        <div className="summary-loading">
                            <LoadingContainer>{" "}</LoadingContainer>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div className="summary-container">
                <ScrollToTop/>
                <NavbarCart/>
                <div className="cart">
                    <div className={"summar-header"}>
                        <div className="card-body">
                            <h5>
                                {order.status !== "WAITING_FOR_ACCEPTED" && (
                                    <>
                                        <OrderStatus status={order.status}/>{" "}
                                    </>
                                )}

                                <OrderPaymentStatus
                                    status={order.payment_status}/> {t("common.word.order")} ({order.items.length})
                            </h5>
                            <small>
                                {t("components.cart.from_restaurant")} {this.props.store.translation.name} <Money
                                value={order.total_money}/>
                            </small>
                        </div>
                    </div>
                    <div className="cart-response-restaurant">

                        {numberView}
                        {loadingForAcceptance}
                    </div>
                    <SummaryItems orderItems={order.items} items={this.props.items}
                                  modifierGroups={this.props.modifierGroups}></SummaryItems>
                    <SummaryShipment order={order}></SummaryShipment>
                    <SummaryContact order={order}></SummaryContact>
                    <SummaryPayment order={order}></SummaryPayment>
                    <SummarySummary order={order}></SummarySummary>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    store: getStore(state.store),
    preview: isPreview(state.store),
    items: getItems(state.menu),
    modifierGroups: getModifierGroups(state.menu),
})

export default withTranslation()(connect(mapStateToProps)(Summary))