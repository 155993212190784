import React from 'react';
import {getLangDisplayName} from "../../lib/i18n";
import {getLanguages, getLocale} from "../../application/store/reducer";
import {connect} from "react-redux";
import {ReactComponent as FlagPL} from "../../images/icons/languages/pl.svg";
import {ReactComponent as FlagEN} from "../../images/icons/languages/en.svg";
import {changeLocale} from "../../application/service/languageService";
import classNames from "classnames";

const Languages = (props)=>{
    let languages = props.languages;
    const getIconLang = function(locale){
        switch (locale) {
            case "pl":
                return (<FlagPL className="icon" />);
            case "en":
                return (<FlagEN className="icon" />);
        }
        return locale;
    }

    const changeLocaleInternal = function(locale){
        props.dispatch(changeLocale(locale))
    }
    const sizeClass = props.size !== undefined ? " languages-" + props.size : "";
    return(
        <>
            {languages && languages.length > 1 && (
                <div className={classNames(`languages${sizeClass}`)} title={getLangDisplayName(props.locale)}>
                    {languages.map(language => {
                        return (
                            <a className="btn" key={language}  href="#" onClick={() => changeLocaleInternal(language)} title={getLangDisplayName(language)}>
                                {getIconLang(language)}
                            </a>
                        )
                    })}
                </div>
            )}
        </>
    )
}

const mapStateToProps = state => ({
    languages: getLanguages(state.store),
    locale: getLocale(state.store)
})
export default connect(mapStateToProps)(Languages)
