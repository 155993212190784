import React from 'react';
import {useTranslation} from "react-i18next";


const SummaryPayment = (props)=>{
    const { t } = useTranslation();

    return(
        <div className="card card-cart card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.summary.payment.title")}
                </h3>
                <dl>
                    <dt>{props.order.payment_method_name}</dt>
                    <dd>{t("components.summary.payment.method")}</dd>
                </dl>
            </div>

        </div>
    )
}

export default SummaryPayment;