import React, {useState} from 'react';
import {connect} from "react-redux";
import {getStore} from "../../../../application/store/reducer";
import {getHours, getLocationStore} from "../../../../application/menu/reducer";
import {getAddressName, getOpenHours} from "../../../../application/common/StringHelper";
import StoreInfoHours from "./StoreInfoHours";
import {getOrderType} from "../../../../application/cart/reducer";
import ChangeLocationModal from "../../../common/ChangeLocationModal";
import {getLocation} from "../../../../application/location/reducer";
import DateService from "../../../../application/service/DateService";
import {withTranslation} from "react-i18next";

const StoreInfoV2 = (props) => {
    const [addressModal, setAddressModal] = useState(false);
    const {store, hours, locationStore, t} = props;

    const renderOpenHours = (hours, locationStore) => {
        let openHours = getOpenHours(hours, DateService.now());
        let isOpenView = null;
        let isOpen = locationStore.isOpen;
        if (isOpen !== null) {
            if (isOpen) {
                isOpenView = (
                    <span className="badge bg-success">{t("components.store.status.open")}</span>
                );
            } else {
                isOpenView = (
                    <span className="badge bg-danger">{t("components.store.status.closed")}</span>
                );
                if (openHours == null || openHours.length <= 0) {
                    return (
                        <div className="cover-time">
                            <span className="badge bg-danger">{t("components.store.status.closed_today")}</span>
                        </div>
                    );
                }
            }
        }

        return (
            <div className="cover-time">
                <ul className="list-inline">
                    {
                        openHours.map((openHour, index) =>
                                <li key={index + "-" + openHour.hour_from + "-" + openHour.hour_to}>{openHour.hour_from} - {openHour.hour_to}</li>
                            // <span key={openHour.hour_from}>{openHour.hour_from} - {openHour.hour_to}</span>
                        )
                    }
                </ul>
                {isOpenView}
            </div>
        );
    }


    let addressView = null;
    if (store.address) {
        let addressFullString = getAddressName(store.address);
        addressView = (
            <div className="cover-address">
                {addressFullString}
            </div>
        )
    }

    let openHours = renderOpenHours(hours, locationStore, locationStore);
    let estimateTimeView = null;

    let isAvailable = true;
    let isDeliveryAreaView = null;
    let shopInfoNotAvailableView = null;
    if (props.store.status !== "ACTIVE") {
        shopInfoNotAvailableView = (
            <div className="cover-content-center-info">
                {t("errors.store_paused")}
            </div>
        )
        isAvailable = false;
    }
    let isAvailableView = null;
    if (!isAvailable) {
        isAvailableView = (
            <div className="cover-content-center-bg-close"></div>
        )
    }
    let coverImgView = null;
    if (store.cover_link) {
        coverImgView = (
            <img src={store.cover_link.default}/>
        )
    } else {
        coverImgView = (
            <img src="/images/test/cover.jpg"/>
        )
    }

    return (
        <div className={"cover-wrapper"}>
            <div className="cover2">
                <div className="cover-img">
                    <div>
                        {coverImgView}
                    </div>
                </div>
                <div className="cover-content">

                    <div className={"cover-content-center"}>
                        <div className={"cover-content-center-bg-close"}></div>
                        <div className={"cover-content-center-content"}>
                            <h1>
                                {store.translation.name}
                            </h1>
                            <div className={"cover-info"}>
                                {openHours}
                                {addressView}
                            </div>
                            <StoreInfoHours className={"btn btn-more"}/>
                        </div>
                    </div>
                </div>
                <ChangeLocationModal location={props.location} showModal={addressModal}
                                     onHide={() => setAddressModal(false)}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        store: getStore(state.store),
        hours: getHours(state.menu),
        // isStoreOpen: isStoreOpen(state.menu),
        orderType: getOrderType(state.cart),
        locationStore: getLocationStore(state.menu),
        location: getLocation(state.location)
    }
}

export default withTranslation()(connect(mapStateToProps)(StoreInfoV2))