import axios from 'axios'
import getToken from "../security/securityService";
import {addFlash} from "../notification/actions";
import {parseError} from "../common/apiResponse";
import ApiService from "../common/apiService";
import {login, logout, vouchers} from "./actions";
import UserContext from "../common/UserContext";
import {deleteOrderContact, updateOrderChannelContact} from "../cart/cartService";

class UserService {

    static getHeaders(params){
        let headers = {
        }

        let userToken = UserContext.getToken();
        if(userToken !== undefined && userToken){
            headers["user-token"] = userToken;
        }
        headers = {...headers, ...params};
        return ApiService.getHeaders(headers);
    }
    static login(provider, username, password) {

        let url = ApiService.getBaseUrl() + '/login';

        let loginData = {
            username: username,
            password: password,
            provider: provider
        }
        return dispatch => new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            getToken().then(accessToken => {
                axios.post(url, loginData, {headers: ApiService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            dispatch(addFlash('error', "NOT_FOUND"));
                            reject("NOT_FOUND");
                            return;
                        }
                        let provider = data.data.provider;
                        let token = data.data.token;
                        if(token !== undefined){
                            UserContext.setToken(token);
                        }
                        dispatch(login(data.data));
                        dispatch(updateOrderChannelContact(token, loginData.contact))

                        dispatch(UserService.syncVouchers());
                        resolve(data.data);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        // dispatch(orderError(error));
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                            // alert(error.message);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                // dispatch(orderError(error));
                reject(error);
            });
        });
    }
    static register(email, name, phone, password, confirmPassword, acceptRules, acceptMarketingRules) {

        let url = ApiService.getBaseUrl() + '/login/register';

        let registerData = {
            email: email,
            password: password,
            name: name,
            phone: phone,
            passwordConfirmation: confirmPassword,
            acceptRules: acceptRules,
            acceptMarketingRules: acceptMarketingRules
        }
        return dispatch => new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            getToken().then(accessToken => {
                axios.post(url, registerData, {headers: ApiService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            dispatch(addFlash('error', "NOT_FOUND"));
                            reject("NOT_FOUND");
                            return;
                        }
                        let provider = data.data.provider;
                        let token = data.data.token;
                        if(token !== undefined){
                            UserContext.setToken(token);
                        }
                        dispatch(login(data.data));
                        dispatch(updateOrderChannelContact(token, data.data.contact))

                        dispatch(UserService.syncVouchers());
                        resolve(data.data);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        // dispatch(orderError(error));
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                            // alert(error.message);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                // dispatch(orderError(error));
                reject(error);
            });
        });
    }

    static delete(password) {

        let url = ApiService.getBaseUrl() + '/login/delete';

        let deleteData = {
            password: password
        }
        return dispatch => new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            getToken().then(accessToken => {
                axios.post(url, deleteData, {headers: UserService.getHeaders(accessToken)})
                    .then(response => {
                        dispatch(logout());
                        resolve();
                    })
                    .catch(data => {
                        let error = parseError(data);
                        // dispatch(orderError(error));
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                            // alert(error.message);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                // dispatch(orderError(error));
                reject(error);
            });
        });
    }

    static refresh(){
        let url = ApiService.getBaseUrl() + '/contacts/get';
        return dispatch => new Promise(function (resolve, reject) {
            if(UserContext.getToken() === undefined || !UserContext.getToken()){
                return resolve();
            }
            // dispatch(orderPending());
            let token = UserContext.getToken();
            getToken().then(accessToken => {
                axios.get(url, {headers: UserService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            dispatch(addFlash('error', "NOT_FOUND"));
                            reject("NOT_FOUND");
                            return;
                        }
                        let loginData = {
                            token: token,
                            contact: data.data
                        }
                        if(token !== undefined){
                            UserContext.setToken(token);
                        }
                        dispatch(login(loginData));
                        dispatch(updateOrderChannelContact(token, loginData.contact))

                        dispatch(UserService.syncVouchers());
                        resolve(loginData);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        // dispatch(orderError(error));
                        reject(error);
                        if(error.status === 404) {
                            UserContext.setToken(undefined);
                            dispatch(logout());
                            reject(error);
                        }else if(error.status !== 422){
                            if(error.message && error.message.code && error.message.code === "resource_not_found"){
                                UserContext.setToken(undefined);
                                dispatch(logout());
                            }
                            reject(error);
                            // alert(error.message);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                // dispatch(orderError(error));
                reject(error);
            });
        });
    }

    static logout() {
        console.log("logout");
        return dispatch => new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            UserContext.setToken(undefined);
            console.log("logout #1");
            dispatch(logout());
            console.log("logout #2");
            dispatch(deleteOrderContact())
            console.log("logout #3");
            resolve()
        });
    }
    static getProducts(provider) {
        let url = ApiService.getBaseUrl() + '/contacts/products';
        return new Promise(function (resolve, reject) {
            getToken().then(accessToken => {
                axios.get(url, {headers: ApiService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            return;
                        }
                        resolve(data.data);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        // dispatch(orderError(error));
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                reject(error);
            });
        })
    }
    static async resetPassword(email) {
        let url = ApiService.getBaseUrl() + '/login/reset-password';

        let requestData = {
            email: email
        }
        let accessToken = await getToken();
        let response = await axios.post(url, requestData, {headers: ApiService.getHeaders(accessToken)});
        return response.data;
    }
    static async changePassword(email, code, password, passwordConfirmation) {
        let url = ApiService.getBaseUrl() + '/login/change-password';

        let requestData = {
            email: email,
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
        }
        let accessToken = await getToken();
        let response = await axios.post(url, requestData, {headers: ApiService.getHeaders(accessToken)});
        return response.data;
    }
    static async getQrcode(){
        let url = ApiService.getBaseUrl() + '/login/qrcode';
        try {

            let accessToken = await getToken();
            let response = await axios.get(url, {headers: UserService.getHeaders(accessToken)});
            if(response === undefined){
                return;
            }
            console.log(response.data)
            return response.data;
        }catch (err){
            throw err;
            // let error = parseError(data);
        }
    }

    static async getVoucherQrcode(){
        let url = ApiService.getBaseUrl() + '/login/qrcode';
        try {

            let accessToken = await getToken();
            let response = await axios.get(url, {headers: UserService.getHeaders(accessToken)});
            if(response === undefined){
                return;
            }
            console.log(response.data)
            return response.data;
        }catch (err){
            throw err;
            // let error = parseError(data);
        }
    }
    static async activation(email, code) {
        let url = ApiService.getBaseUrl() + '/login/register-activation';

        let requestData = {
            email: email,
            code: code
        }
        let accessToken = await getToken();
        let response = await axios.post(url, requestData, {headers: ApiService.getHeaders(accessToken)});
        return response.data;
    }
    static async sendActivation(email) {
        let url = ApiService.getBaseUrl() + '/login/activation-link';

        let requestData = {
            email: email
        }
        let accessToken = await getToken();
        let response = await axios.post(url, requestData, {headers: ApiService.getHeaders(accessToken)});
        return response.data;
    }
    static syncVouchers(contactId){

        let url = ApiService.getBaseUrl() + '/contacts/vouchers';

        return dispatch => new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            getToken().then(accessToken => {
                axios.get(url, {headers: UserService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            reject("NOT_FOUND");
                            return;
                        }
                        //@TODO: Dispatch vouchers
                        dispatch(vouchers(data.data))
                        resolve(data.data);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                reject(error);
            });
        });
    }
    static buyVoucher(provider, productId) {

        let url = ApiService.getBaseUrl() + '/contacts/vouchers';

        let data = {
            provider: provider,
            product_id: productId
        }
        return new Promise(function (resolve, reject) {
            // dispatch(orderPending());
            getToken().then(accessToken => {
                axios.post(url, data, {headers: UserService.getHeaders(accessToken)})
                    .then(response => {
                        let data = response.data;
                        if(data.data === undefined){
                            reject("NOT_FOUND");
                            return;
                        }
                        resolve(data.data);
                    })
                    .catch(data => {
                        let error = parseError(data);
                        reject(error);
                        if(error.status === 404) {
                            reject(error);
                        }else if(error.status !== 422){
                            reject(error);
                            // alert(error.message);
                        }else{
                            reject(error);
                        }
                    });
            }).catch(error => {
                error = parseError(error);
                // dispatch(orderError(error));
                reject(error);
            });
        });
    }

}
export default UserService;