import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../../../application/user/reducer";
import ErrorFormAlertView from "../../../common/ErrorFormAlertView";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import UserService from "../../../../application/user/userService";
import {Link, useHistory} from "react-router-dom";
import LoadingContainer from "../../../common/LoadingContainer";


const UserActivationPage = (props) => {
    const [successSendLink, setSuccessSendLink] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const queryParams = new URLSearchParams(history.location.search);
    const getQueryParam = (param) => {
        const value = queryParams.get(param);
        return value !== null ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');
    const isValid = () => {
        return code && email;
    }

    useEffect(() => {
        if (isValid()) {
            activation();
        }
    }, []);


    const activation = async () => {
        setLoading(true);
        try {
            let response = await UserService.activation(email, code)
            setLoading(false);
        } catch (e) {
            console.log(e)
            setErrors([{
                message: "Nie udało się aktywować konta, niepoprawny link."
            }]);
            setLoading(false);
        }
    }
    const send = async () => {
        setLoading(true)
        try {
            let response = await UserService.sendActivation(email)
            setLoading(false);
            setSuccessSendLink(true);
        } catch (e) {
            console.log(e)
            setErrors([{
                message: "Nie udało się wysłać linku aktywacyjnego."
            }]);
            setLoading(false);
        }
    }
    if (loading) {
        return (
            <>
                <LoadingContainer/>
            </>
        )
    }
    if (successSendLink) {
        return (
            <>
                <div>
                    <h4>
                        Weryfikacja konta
                    </h4>
                </div>
                <div className="alert alert-light" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        Na Twój adres email wysłaliśmy wiadomość z linkiem do weryfikacji konta
                    </small>
                </div>
                <div className={"login-box-register-info"}>
                    <hr/>
                    <div className={"text-center"}>
                        Wiadomość nie dotarła? <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        send();
                    }}>
                        Wyślij ponownie
                    </a>
                    </div>
                </div>
            </>
        )
    }
    if (!code || !email) {
        return (
            <>
                <div className="alert alert-danger" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        Niepoprawny link do aktywacji konta
                    </small>
                </div>
                <div className={"login-box-register-info"}>
                    <hr/>
                    <div className={"text-center"}>
                        Niepoprawny link aktywacyjny? <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        send();
                    }}>
                        Wyślij jeszcze raz
                    </a>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={"login-box"}>
                <>
                    <div>
                        <h4>
                            Gotowe!
                        </h4>
                    </div>
                    {(errors && errors.length > 0) ? (
                        <>
                            <ErrorFormAlertView errors={errors}/>
                            <div className={"login-box-register-info"}>
                                <hr/>
                                <div className={"text-center"}>
                                    Niepoprawny link aktywacyjny? <a href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    send();
                                }}>
                                    Wyślij jeszcze raz
                                </a>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="alert alert-success" role="alert">
                                <small>
                                    <InfoSVG className={"icon me-2"}/>
                                    Twoje konto zostało aktywowane ({email}).
                                </small>
                            </div>

                            <Link
                                to={"/"}
                                className={"btn-block btn-primary btn btn-submit"}>
                                Przejdz do strony głównej
                            </Link>
                        </>
                    )}
                </>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserActivationPage))
