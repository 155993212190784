import React, { Component } from 'react';
import {connect} from "react-redux";
import CartItems from "./CartItems";
import CartButton from "../../common/CartButton";
import {getItems} from "../../../application/cart/reducer";
import CartSummaryData from "./CartSummaryData";
import {withTranslation} from "react-i18next";
import AppConfig from "../../../application/common/AppConfig";
import CartVouchers from "./CartVouchers";

class CartSticky extends Component{

    render(){
        const {t} = this.props;
        let cartButtoView = null;
        if(this.props.goToCart !== undefined){
            cartButtoView = (
                <CartButton goToCart={this.props.goToCart} />
            );
        }

        return(
            <div className="cart-sticky sticky-fixed  sticky-fixed-cart fixed-height-100">
                <div className="cart-sticky-header">
                    <h3>
                        {t("components.cart.basket.title")}
                    </h3>
                </div>
                <div className="cart-sticky-body">
                    <div className="scoller">
                        <div className="scoller-content">
                            <div className="cart-sticky-items">
                                <CartItems></CartItems>
                            </div>
                            <div className="cart-sticky-summary">
                                <CartSummaryData></CartSummaryData>
                            </div>
                        </div>
                        {AppConfig.paid && (
                            <div className="scoller-btn">
                                {cartButtoView}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartSticky))