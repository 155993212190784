import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import StateItemHelper from "../../MenuShowItem/StateItemHelper";
import PromotionHelper from "../../../../../../application/common/PromotionHelper";
import ItemHelper from "../../../../../../application/common/ItemHelper";
import {getItems, getModifierGroups, getPromotions} from "../../../../../../application/menu/reducer";
import {getCart} from "../../../../../../application/cart/reducer";
import Money from "../../../../../common/Money";

const MenuShowItemGroupV3 = (props) => {
    let stateItem = props.stateItem;
    let itemGroup = props.itemGroup;
    let selectedItemId = stateItem.item_id;

    const handleChangeRadio = (itemId, e) => {
        let newCurrentStateItem = StateItemHelper.create(itemId, props.items, props.modifier_groups);
        StateItemHelper.refreshItemPrice(newCurrentStateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        props.onUpdate(newCurrentStateItem);
    }
    const renderItemGroupTitle = (itemGroup, item) => {
        let itemGroupTitle = itemGroup.translation.name;
        let itemTitle = item.translation.name;
        if(itemTitle.includes(itemGroupTitle)){
            let regex = new RegExp('^' + itemGroupTitle);
            itemTitle = itemTitle.replace(regex, '').trim();
        }
        return itemTitle;
    }
    return (
        <div className="product-item-variant product-item-modifiers">
            <fieldset className="form-group">
                <legend>
                    Wybierz wariant
                </legend>
                <div className={"row row-cols-2 gx-2"}>
                    {
                        itemGroup.items.sort((a, b) => a.position - b.position || (a.name && b.name && a.name.localeCompare(b.name))).map((itemGroupItem) => {
                            let item = ItemHelper.getItem(props.items, itemGroupItem.item_id)
                            if (!item) return null;
                            let checked = selectedItemId === itemGroupItem.item_id;
                            let itemPrice = (item.price) ? item.price.amount : 0;
                            return (
                                <div className={"product-modifier-option-pick"}
                                     key={`${itemGroup.reference_id}-${itemGroupItem.item_id}`}>
                                    <input className={"btn-check"} type="radio" checked={checked}
                                           onChange={(e) => handleChangeRadio(itemGroupItem.item_id, e)}
                                           name={'variant'}
                                           id={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}
                                           value={itemGroupItem.item_id}/>
                                    <label className="input-single"
                                           htmlFor={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}>
                                        {renderItemGroupTitle(itemGroup, item)}
                                        {itemPrice && (
                                            <span className="product-modifier-option-price ml-auto price"><Money
                                                value={itemPrice}
                                                currency='PLN'/></span>
                                        )}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </fieldset>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        promotions: getPromotions(state.menu),
        cart: getCart(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuShowItemGroupV3))