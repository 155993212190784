import React from "react";

export const FormError = ({formErrors}) => {
    if(Array.isArray(formErrors)){
        return (
            <div className='formErrors'>
                {Object.keys(formErrors).map((fieldName, i) => {
                    if(formErrors[fieldName].length > 0){
                        return (
                            <div className="invalid-feedback" key={i}>
                                {formErrors[fieldName]}
                            </div>
                        )
                    } else {
                        return '';
                    }
                })}
            </div>
        )
    }
    return (
        <div className="invalid-feedback">
            {formErrors}
        </div>
    )
}