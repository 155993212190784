import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class Alert extends Component {

    componentDidMount() {
        this.timer = setTimeout(
            this.props.onClose,
            this.props.timeout
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    alertClass (type) {
        let classes = {
            error: 'alert-danger',
            alert: 'alert-warning',
            notice: 'alert-info',
            success: 'alert-success'
        };
        return classes[type] || classes.success;
    }

    render() {
        const { t } = this.props;
        const message = this.props.message;
        const alertClassName = `alert alert-dismissible ${ this.alertClass(message.type) } fade1 in`;

        let translationMessage = t("errors." + message.code)
        return(
            <div className={ alertClassName }>
                {translationMessage}
                <button type="button" className="btn-close" onClick={ this.props.onClose } data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        );
    }
}

Alert.propTypes = {
    onClose: PropTypes.func,
    timeout: PropTypes.number,
    message: PropTypes.object.isRequired
};

Alert.defaultProps = {
    timeout: 7000
};
export default withTranslation()(Alert);