import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

const middlewares = [thunk];
const initialState = undefined;
const store =createStore(rootReducer, initialState, applyMiddleware(...middlewares));


ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));