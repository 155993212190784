import React from 'react';
import {connect} from "react-redux";
import {getCart, getContact, getOrderType} from "../../../application/cart/reducer";
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {withTranslation} from "react-i18next";
import {getStore} from "../../../application/store/reducer";
import CustomFieldUtils from "../../utils/CustomFieldUtils";
import {CartCustomFields} from "./CartCustomFields";
import {getAvailabilities, getCustomFields} from "../../../application/menu/reducer";
import MenuHelper from "../../../application/common/MenuHelper";

const CartClientData = (props)=>{

    const updateContactData = (key, value) => {
        // props.onChange(key, value);
        props.onChange({
            ...props.data,
            contact: {
                ...props.data.contact,
                [key]: value
            }
        })
        // props.contact[key] = value;
    }
    const handleChangeName = (value) => {
        updateContactData("name", value);
        props.contact.name = value;
    }
    const handleChangeEmail = (value) => {
        updateContactData("email", value);
        props.contact.email = value;
    }
    const handleChangePhone = (value) => {
        updateContactData("phone", value);
        props.contact.phone = value;
    }
    const handleChangeComments = (value) => {
        props.onChange({
            ...props.data,
            comment: value
        })
    }
    const handleChangeTaxIdNo = (value) => {
        props.onChange({
            ...props.data,
            tax_id_no: value
        })
    }
    const { t, type, store } = props;

    let phoneVisible = CustomFieldUtils.getVisible("phone", store.order_custom_fields, type);
    let emailVisible = CustomFieldUtils.getVisible("email", store.order_custom_fields, type);
    let nameVisible = CustomFieldUtils.getVisible("name", store.order_custom_fields, type);
    let commentVisible = CustomFieldUtils.getVisible("comment", store.order_custom_fields, type);
    let taxIdNoVisible = CustomFieldUtils.getVisible("taxIdNo", store.order_custom_fields, type);

    return(
        <div className="card card-cart card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.cart.client.title")}
                </h3>
                <div>
                    {/*<CartAppsClient contact={props.contact} email={props.data.contact.email} onChangeEmail={(email) => handleChangeEmail(email)} errors={props.errors}/>*/}
                    <div className="form-row row">
                        {CustomFieldUtils.isVisible(nameVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.name} onChange={(e) => handleChangeName(e.target.value)} className={"form-control" + ErrorFormHelper(props.errors, "contact.name")} id="name" placeholder={t("common.form.name") + (CustomFieldUtils.isRequired(nameVisible) ? "*" : "")} />
                                <label htmlFor="name">{t("common.form.name")}{CustomFieldUtils.isRequired(nameVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.name" />
                            </div>
                        )}
                        {CustomFieldUtils.isVisible(emailVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.email} onChange={(e) => handleChangeEmail(e.target.value)} className={"form-control" + ErrorFormHelper(props.errors, "contact.email")} id="email" placeholder={t("common.form.email")+(CustomFieldUtils.isRequired(emailVisible) ? "*" : "")} />
                                <label htmlFor="email">{t("common.form.email")}{CustomFieldUtils.isRequired(emailVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.email" />
                            </div>
                        )}
                        {CustomFieldUtils.isVisible(phoneVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.phone} onChange={(e) => handleChangePhone(e.target.value)} className={"form-control" + ErrorFormHelper(props.errors, "contact.phone")} id="phone" placeholder={t("common.form.phone") + (CustomFieldUtils.isRequired(phoneVisible) ? "*" : "")} />
                                <label htmlFor="phone">{t("common.form.phone")}{CustomFieldUtils.isRequired(phoneVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.phone" />
                            </div>
                        )}
                        {CustomFieldUtils.isVisible(taxIdNoVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.tax_id_no} onChange={(e) => handleChangeTaxIdNo(e.target.value)} className={"form-control" + ErrorFormHelper(props.errors, "taxIdNo")} id="taxIdNo" placeholder={t("common.form.taxIdNo") + (CustomFieldUtils.isRequired(taxIdNoVisible) ? "*" : "")} />
                                <label htmlFor="phone">{t("common.form.taxIdNo")}{CustomFieldUtils.isRequired(taxIdNoVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="taxIdNo" />
                            </div>
                        )}
                        {CustomFieldUtils.isVisible(commentVisible) && (
                            <div className="form-group col-lg-6">
                                <textarea rows="3" value={props.data.comment} onChange={(e) => handleChangeComments(e.target.value)} className={"form-control" + ErrorFormHelper(props.errors, "comment")} placeholder={t("components.cart.client.form.comment")+(CustomFieldUtils.isRequired(commentVisible) ? "*" : "")} id="comment"></textarea>
                                <label htmlFor="comment">{t("components.cart.client.form.comment")}{CustomFieldUtils.isRequired(commentVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="comment" />
                            </div>
                        )}
                        {MenuHelper.isCustomFieldExist(props.custom_fields, props.type, props.store, "CONTACT") && (
                            <CartCustomFields availabilities={props.availabilities} section={"CONTACT"} store={store} orderType={props.type} customFields={props.custom_fields} valueCustomFields={props.cart.custom_fields} errors={props.errors} onChange={props.onChangeCustomField} />
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        contact: getContact(state.cart),
        type: getOrderType(state.cart),
        store: getStore(state.store),
        cart: getCart(state.cart),
        custom_fields: getCustomFields(state.menu),
        availabilities: getAvailabilities(state.menu)
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartClientData))