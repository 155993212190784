import React, {useState} from 'react';
import ErrorFormHelper from "../../ErrorFormHelper";
import ErrorFormView from "../../ErrorFormView";
import ErrorFormAlertView from "../../ErrorFormAlertView";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import UserService from "../../../../application/user/userService";
import {getUser} from "../../../../application/user/reducer";
import {getStore} from "../../../../application/store/reducer";

function LoginUserComponent(props) {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const t = props.t;
    const dispatch = useDispatch();

    const onShow = () => {
        setErrors([]);
    }
    const TranslationsInitialized = () => {
        t("errors.wrong_login");
        t("errors.username_notempty");
    }
    const addClient = async () => {
        setLoading(true);
        try {
            let response = await props.dispatch(UserService.login("GOCRM", email, password))
            setLoading(false);
            props.onSuccess()
        } catch (e) {
            console.log(e);
            if (e.status === 403 || e.status === 404) {
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            } else if (e.status == 422) {
                // setErrors(e.errors);
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            } else {
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Nie udało się zalogować"
                    }
                ])
            }
            setLoading(false);
            // setErrors(e.errors)
            // console.log(e);
            // throw e;
        }
    }

    return (
        <>
            <div className={"login-box-login"}>
                <h4>
                    Zaloguj się
                </h4>
                <ErrorFormAlertView errors={errors}/>
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                           className={"form-control" + ErrorFormHelper(errors, "username")}
                           placeholder={t("components.apps.loyalty.form.login")} aria-label="Recipient's username"
                           aria-describedby="cart-username"/>
                    <label htmlFor="cart-username">{t("components.apps.loyalty.form.login")}</label>
                    <ErrorFormView errors={errors} show_message={true} field="username"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                           className={"form-control" + ErrorFormHelper(errors, "password")}
                           placeholder={t("components.apps.loyalty.form.password")} aria-label="Recipient's username"
                           aria-describedby="cart-password"/>
                    <label htmlFor="cart-password">{t("components.apps.loyalty.form.password")}</label>
                    <ErrorFormView errors={errors} show_message={true} field="password"/>
                </div>
            </div>
            <a className={"login-box-lost-password"} href={"#"}
               onClick={(e) => {
                   e.preventDefault();
                   props.setPage("LOST_PASSWORD")
               }}>Zapomniałeś hasła?</a>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                    variant="primary" onClick={() => {
                addClient()
            }}>
                <span className="left spinner-border spinner-border-sm"></span>
                {t("common.action.login")}
            </button>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        store: getStore(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(LoginUserComponent))
