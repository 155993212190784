export const ADD_FLASH = 'ADD_FLASH';
export const REMOVE_FLASH = 'REMOVE_FLASH';

export const addFlash= (type, message)=>{
    let messages = [];
    if(!Array.isArray(type)){
        if(Array.isArray(message)){
            let newMessages = message.map(messageItem => {
                let code = messageItem.code;
                let text = messageItem.message;
                if(!text){
                    text = code;
                }
                return {type: type, text: text, code: code}
            });
            messages = newMessages;
        }else{
            messages.push({type: type, text:message});
        }
    }else{
        messages = type;
    }
    return {
        type: ADD_FLASH,
        messages: messages
    };
}
export const removeFlash= (data)=>{
    return {
        type: REMOVE_FLASH,
        message: data
    };
}