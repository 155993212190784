import React from 'react';
import {parseDate} from "../../../application/common/DateHelper";
import FormattedDate from "../../common/FormattedDate";
import FormattedTime from "../../common/FormattedTime";
import {useTranslation} from "react-i18next";

const SummaryShipment = (props)=>{
    const { t } = useTranslation();

    let addressView = null;


    let deliveryInfoView = null;

    let addDate = null;
    if(props.order.pickup_at){
        addDate = (
            <React.Fragment>
                <FormattedDate value={parseDate(props.order.pickup_at)} />&nbsp;
            </React.Fragment>
        )
    }
    if(props.order.type === 'DELIVERY'){
        let timeToDelivery = null;
        if(props.order.status === "ACCEPTED"){
            if(props.order.estimated_preparation_at !== undefined && props.order.estimated_preparation_at){
                timeToDelivery = (
                    <React.Fragment>
                        <dt>
                            {addDate}
                            <FormattedTime value={parseDate(props.order.estimated_preparation_at)} />
                        </dt>
                        <dd>{t("components.summary.shipment.estimate_delivery_time")}</dd>
                    </React.Fragment>
                )
            }
        }else if(props.order.status === "WAITING_FOR_ACCEPTED"){
            if(props.order.estimated_preparation_at !== undefined && props.order.estimated_preparation_at){
                timeToDelivery = (
                    <React.Fragment>
                        <dt>
                            {addDate}
                            <FormattedTime value={parseDate(props.order.estimated_preparation_at)} />
                        </dt>
                        <dd>{t("components.summary.shipment.estimate_delivery_time")}</dd>
                    </React.Fragment>
                )
            }
        }
        deliveryInfoView = (
            <React.Fragment>
                <dt>{props.order.address.street} {props.order.address.build_nr} / {props.order.address.flat_nr}, {props.order.address.zip_code} {props.order.address.city}, {props.order.address.country}</dt>
                <dd>{t("components.summary.shipment.delivery_to_door")}</dd>
                {timeToDelivery}
            </React.Fragment>
        )
    }
    if(props.order.type === 'PICK_UP'){
        let timeToDelivery = null;
        if(props.order.status === "ACCEPTED"){
            if(props.order.estimated_preparation_at !== undefined && props.order.estimated_preparation_at) {
                timeToDelivery = (
                    <React.Fragment>
                        <dt>
                            {addDate}
                            <FormattedTime value={parseDate(props.order.estimated_preparation_at)}/>
                        </dt>
                        <dd>{t("components.summary.shipment.estimate_pickup_time")}</dd>
                    </React.Fragment>
                )
            }
        }else if(props.order.status === "WAITING_FOR_ACCEPTED"){
            if(props.order.estimated_preparation_at !== undefined && props.order.estimated_preparation_at){
                timeToDelivery = (
                    <React.Fragment>
                        <dt>
                            {addDate}
                            <FormattedTime value={parseDate(props.order.estimated_preparation_at)} />
                        </dt>
                        <dd>{t("components.summary.shipment.estimate_pickup_time")}</dd>
                    </React.Fragment>
                )
            }
        }
        let pickupTime = null;
        if(props.order.pickup_at !== undefined && props.order.pickup_at){
            pickupTime = (
                <React.Fragment>
                    <dt>
                        <FormattedDate value={parseDate(props.order.pickup_at)} />
                        &nbsp;
                        <FormattedTime value={parseDate(props.order.pickup_at)} />
                    </dt>
                    <dd>{t("components.summary.shipment.choosen_pickup_time")}</dd>
                </React.Fragment>
            )
        }
        deliveryInfoView = (
            <React.Fragment>
                <dt>{t("components.summary.shipment.pickup_in_restaurant")}</dt>
                <dd>{t("components.summary.shipment.pickup_type")}</dd>
                {timeToDelivery}
                {pickupTime}
            </React.Fragment>
        )
    }

    if(props.order.type === 'DINE_IN'){
        let timeToDelivery = null;
        let pickupTime = null;
        deliveryInfoView = (
            <React.Fragment>
                <dt>{t("components.summary.shipment.dinein_in_restaurant")}: {t("components.summary.shipment.dinein_table")}: {props.order.number}</dt>
                <dd>{t("components.summary.shipment.pickup_type")}</dd>
                {timeToDelivery}
                {pickupTime}
            </React.Fragment>
        )
    }

    if(props.order.type === 'ROOM_SERVICE'){
        let timeToDelivery = null;
        let pickupTime = null;
        deliveryInfoView = (
            <React.Fragment>
                <dt>{t("components.summary.shipment.dinein_in_restaurant")}: {t("components.summary.shipment.room_service_number")}: {props.order.number}</dt>
                <dd>{t("components.summary.shipment.pickup_type")}</dd>
                {timeToDelivery}
                {pickupTime}
            </React.Fragment>
        )
    }

    if(props.order.address != null){
        addressView = (
            <dl>
                {deliveryInfoView}
            </dl>
        );
    }else{
        addressView = "Brak";
    }
    return(
        <div className="card card-cart card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.summary.shipment.title")}
                </h3>
                {addressView}
            </div>

        </div>
    )
}

export default SummaryShipment;