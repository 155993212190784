import i18n from "i18next";
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them)

const languages = {
    "en": {
        "locale": "en",
        "nativeName": "English",
        "englishName": "English"
    },
    "pl": {
        "locale": "pl",
        "nativeName": "Polski",
        "englishName": "Polish"
    },
}

export const getLangDisplayName = (locale) => {
    return languages[locale]?.nativeName;
}
i18n
    .use(Backend) // passes i18n down to react-i18next
    // .init({
    //     // lng: "en",
    //     debug: true,
    //     lng: 'en',
    //     fallbackLng: 'en',
    //     interpolation: {
    //         format: (value, rawFormat, lng) => {
    //             const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
    //             switch (format) {
    //                 case 'uppercase':
    //                     return value.toUpperCase();
    //                 case 'money':
    //                     return Intl.NumberFormat(lng, {
    //                         style: 'currency',
    //                         currency: additionalValues[0]
    //                     }).format(value);
    //             }
    //         }
    //     },
    //     // ns: ['translation'],
    //     // defaultNS: 'translation',
    //     // preload: ['pl'],
    //     // resources: resources,
    //     // react: {
    //     //     useSuspense: false
    //     // },
    //     backend: {
    //         // parsePayload: function(namespace, key, fallbackValue) {
    //         //     console.log(namespace);
    //         //     console.log(key);
    //         //     return { key }
    //         // },
    //         // parse: function(data) {
    //         //     console.log(data);
    //         //     return data.replace(/a/g, '');
    //         //     },
    //         // for all available options read the backend's repository readme file
    //         loadPath: '/translations/{{lng}}/{{ns}}.json'
    //         // loadPath: '/translations/{{lng}}.json'
    //     }
    // })
;
    // .init({
    //     // resources,
    //     // lng: "en",
    //
    //     keySeparator: false, // we do not use keys in form messages.welcome
    //
    //     interpolation: {
    //         escapeValue: false // react already safes from xss
    //     }
    // });
// export default i18n;
export default i18n;
// export default {i18n, languages, getLangDisplayName};
// export default languages;
// export default getLangDisplayName;