import {useEffect} from 'react';
import * as CookieConsent from "vanilla-cookieconsent";
import Cookies from "universal-cookie/lib";
let cookies = new Cookies();
const CookiesAlert = (props) => {
    useEffect(() => {
        let path = props.path;
        if(path && path !== "/"){
            if (path.endsWith('/')) {
                path = path.slice(0, -1);
            }
        }else{
            path = "/";
        }
        CookieConsent.run({
            cookie: {
                path: path,
            },
            onConsent: ({cookie}) => {
                if(cookie.categories.includes("marketing")){
                    cookies.set("cookie_marketing", true);
                }else{
                    cookies.set("cookie_marketing", false);
                }
                if(cookie.categories.includes("analytics")){
                    cookies.set("cookie_analytics", true);
                }else{
                    cookies.set("cookie_analytics", false);
                }
            },
            guiOptions: {
                consentModal: {
                    layout: 'bar inline',
                    position: 'bottom center'
                }
            },
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {},
                marketing: {
                    // enabled: false,
                    // readOnly: false,
                }
            },

            language: {
                default: 'pl',
                translations: {
                    pl: {
                        consentModal: {
                            description: 'Strona używa plików cookies, aby ułatwić Ci korzystanie z udostępnianych treści oraz zapewnić Ci bezpieczeństwo. Wykorzystujemy także pliki cookies w celu dostosowania treści, personalizacji reklam oraz do anonimowej analizy aktywności użytkowników naszej strony. Pliki cookies mogą być udostępniane naszym partnerom w zakresie mediów społecznościowych, reklam i analiz. ',
                            acceptAllBtn: 'Zaakceptuj wszystkie',
                            showPreferencesBtn: 'Dostosuj'
                        },
                        preferencesModal: {
                            title: 'Zarządzaj ustawieniami dotyczącymi prywatności',
                            acceptAllBtn: 'Zaakceptuj wszystkie',
                            acceptNecessaryBtn: 'Odrzuć wszystkie',
                            savePreferencesBtn: 'Zaakceptuj zaznaczone',
                            closeIconLabel: 'Zamknij',
                            sections: [
                                {
                                    title: 'Niezbędne pliki cookies',
                                    description: 'Pliki cookies niezbędne do działania usług dostępnych na stronie internetowej, umożliwiające przeglądanie ofert lub dokonywanie rezerwacji, wspierające mechanizmy bezpieczeństwa, m.in.: uwierzytelnianie użytkowników i wykrywanie nadużyć. Te pliki są wymagane do prawidłowego funkcjonowania strony internetowej. Nie wymagają Twojej zgody.',
                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Analityczne pliki cookies',
                                    description: 'Pliki cookies umożliwiające zbieranie informacji o sposobie korzystania przez użytkownika ze strony internetowej w celu optymalizacji jej funkcjonowania oraz dostosowania do oczekiwań użytkownika. Wyrażając zgodę na te pliki cookies, zgadzasz się na przetwarzanie danych dotyczących Twojej aktywności na stronie w celach analitycznych.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'Marketingowe pliki cookies',
                                    description: 'Pliki cookies umożliwiające wyświetlanie użytkownikowi treści marketingowych dostosowanych do jego preferencji oraz kierowanie do niego powiadomień o ofertach marketingowych odpowiadających jego zainteresowaniom, obejmujących informacje dotyczące aktywności użytkownika, produktów i usług administratora strony i podmiotów trzecich. Zgoda na te pliki cookies oznacza, że Twoje dane mogą być używane do personalizacji reklam oraz analizy skuteczności naszych kampanii reklamowych.',
                                    linkedCategory: 'marketing'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }, []);
    return null;
}
export default CookiesAlert;