import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {getCart, getCartTotal, getItems, getOrderError, getOrderPending} from '../../application/cart/reducer';
import CartItems from "./cart/CartItems";
import CartShipment from "./cart/CartShipment";
import CartPayment from "./cart/CartPayment";
import {fetchOrder, payOrder, updateOrderData} from "../../application/cart/cartService";
import CartClientData from "./cart/CartClientData";
import LoadingContainer from "../common/LoadingContainer";
import ErrorView from "../common/ErrorView";
import CartSticky from "./cart/CartSticky";
import CartSubmit from "./cart/CartSubmit";
import CartSummaryData from "./cart/CartSummaryData";
import NavbarCart from "../layout/NavbarCart";
import {getLocation} from "../../application/location/reducer";
import {ScrollToTop} from "../utils/ScrollToTop";
import Money from "../common/Money";
import {getStore, isPreview} from "../../application/store/reducer";
import CartRules from "./cart/CartRules";
import {emptyOrValue} from "../../application/common/StringHelper";
import CartCoupon from "./cart/CartCoupon";
import {Link} from "react-router-dom";
import IframeUtils from "../utils/IframeUtils";
import classNames from "classnames";
import AppConfig from "../../application/common/AppConfig";
import {withTranslation} from "react-i18next";
import CartCustomField from "./cart/CartCustomField";
import {getPaymentMethods} from "../../application/menu/reducer";
import CartApps from "./cart/CartApps";
import StoreHelper from "../../application/common/StoreHelper";
import CartUserVouchers from "./cart/CartUserVouchers";
import analytics from "../../application/common/Analytics";
import {Navbar} from "react-bootstrap";
import {ReactComponent as LeftIco} from "../../images/icons/arrow-left.svg";

const CartPreview = (props) => {
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState(props.cart);
    const {items, total, error, cart, store, t} = props;

    const updateItem = (id, value) => {
        props.dispatch(updateItem(id, value)).then(newData => {
        });
    }

    useEffect(() => {
        props.dispatch(fetchOrder());
    }, [])
    useEffect(() => {
        if(cart.id !== null){
            analytics.beginCheckout(cart)
        }
    }, [cart.id])
    const shouldComponentRender = () => {
        const {pending} = props;
        if (pending === false) return true;
        return false;
    }

    if (cart.id === null && !shouldComponentRender()) return (
        <div>
            <LoadingContainer/>
        </div>
    );
    const updateCustomFieldValue = (slug, value) => {
        if (props.cart.custom_fields === undefined) {
            props.cart.custom_fields = {};
        }
        props.cart.custom_fields[slug] = value;
        setData({
            ...data,
            custom_fields: {
                ...data.custom_fields,
                [slug]: value
            }
        });
    }

    if (error) {
        return (
            <div>
                <ErrorView>
                    {error.message}
                </ErrorView>
            </div>
        );
    }
    return (
        <React.Fragment>
            <ScrollToTop/>
            {!AppConfig.isDesignV3() ? (
                <NavbarCart/>
            ) : (
                <Navbar className={"navbar-back fixed-top"}>
                    <div className={"navbar-brand-container"}>
                        <Link to="/">
                            <LeftIco/> {t("common.action.back")}
                        </Link>
                    </div>
                </Navbar>
            )}
            <div className={`${AppConfig.isDesignV3() ? "" : ""}`}>
                <CartSticky updateItem={updateItem}></CartSticky>

                <div className={classNames('body-container', {
                    'body-container-cart': AppConfig.isCart(),
                })}>

                    <div className="cart cart-container">

                        {AppConfig.isDesignV3() ? (
                            <>
                                <div className="card card-cart cart-items">
                                    <div className="card-body">
                                        <h5>Twoje zamówienie ({items.length})</h5>
                                    </div>
                                    <CartItems updateItem={updateItem} items={items}></CartItems>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="card-body">
                                    <Link to="/">
                                        &#x27E8; {t("common.action.back")}
                                    </Link>
                                </div>

                                <div className="card card-cart cart-items">
                                    <div className="card-body">
                                        <h5>{t("components.cart.items")} ({items.length})</h5>
                                        <small>
                                            {t("components.cart.from_restaurant")} {store.translation.name} <Money
                                            value={total}/>
                                        </small>
                                    </div>
                                    <CartItems updateItem={updateItem} items={items}></CartItems>
                                </div>
                            </>
                        )}
                        <div className="card card-cart">
                            <div className="card-body">
                                <h3>
                                    {t("components.summary.title")}
                                </h3>

                                <div className="cart-summary">
                                    <CartSummaryData/>
                                </div>
                                <div className={"alert alert-info"}>
                                    Zamawianie w resauracji jest wyłączone. Strona służy jedynie do prezentacji menu.
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    total: getCartTotal(state.cart),
    items: getItems(state.cart),
    cart: getCart(state.cart),
    pending: getOrderPending(state.cart),
    error: getOrderError(state.cart),
    location: getLocation(state.location),
    store: getStore(state.store),
    preview: isPreview(state.store),
    paymentMethods: getPaymentMethods(state.menu)
})

export default withTranslation()(connect(mapStateToProps)(CartPreview))