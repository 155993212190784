import React from "react";
import AvailableHelper from "./AvailableHelper";
import DateService from "../service/DateService";

class MenuHelper {

    static getMenuErrors(menu, orderType, pickDate, availabilities){
        let errors = [];
        if(orderType === "DELIVERY"){
            if(!menu.delivery){
                errors.push("unavailable_type");
            }
        }else if(orderType === "PICK_UP"){
            if(!menu.takeaway){
                errors.push("unavailable_type");
            }
        }else if(orderType === "DINE_IN") {
            if (!menu.dine_in) {
                errors.push("unavailable_type");
            }
        }
        if(pickDate && !menu.pick_date){
            errors.push("unavailable_pickup_date");
        }else{
            let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate));

            if(openHours === null || openHours.length <=0) {
                errors.push("unavailable_date");
                // return false;
            }else{
                if(!AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate))){
                    errors.push("unavailable_time");
                    // return false;
                }
            }
        }
        return errors;
    }
    static isMenuAvailable(menu, orderType, pickDate, availabilities){
        if(orderType === "DELIVERY"){
            if(!menu.delivery){
                return false;
            }
        }else if(orderType === "PICK_UP"){
            if(!menu.takeaway){
                return false;
            }
        }else if(orderType === "DINE_IN") {
            if (!menu.dine_in) {
                return false;
            }
        }

        if(pickDate && !menu.pick_date){
            return false;
        }else{
            let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate));

            if(openHours === null || openHours.length <=0) {
                return false;
            }else{
                if(!AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate))){
                    return false;
                }
            }
        }
        return true;
    }
    static isCustomFieldExist(customFields, orderType, store, section){
        if(customFields === undefined || !customFields || customFields.length <=0){
            return false;
        }
        if(store.order_custom_fields === undefined || !store.order_custom_fields || !store.order_custom_fields.some(customField => {
            let customFieldTemplate = customFields.filter(x => x.slug === customField.slug)[0];
            if(customFieldTemplate === undefined){
                return false;
            }
            return orderType === customField.type && (customField.visibility === "VISIBLE" || customField.visibility === "REQUIRED") &&
                (
                    customField.section === section || ((!customField.section || customField.section === undefined) && (!section || section === undefined))
                )
                ;
        })){
            return false;
        }
        return true;
    }
}


export default MenuHelper;