import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {getStore} from "../../../application/store/reducer";
import {getLocation} from "../../../application/location/reducer";
import {getAddress, getCart} from "../../../application/cart/reducer";
import {updateAddress} from "../../../application/cart/cartService";
import {withTranslation} from "react-i18next";
import {GoogleApiWrapper} from "google-maps-react-17";
import LocationSearch from "./LocationSearch";

const LocationSearchWitSave = (props)=>{
    const onChangeFromSearch = (newAddress) => {
        props.dispatch(updateAddress(newAddress, props.cart));
    }
    return (
        <LocationSearch onChange={onChangeFromSearch} store={props.store} location={props.location} address={props.location.address} />
    )
}

const mapStateToProps = state => ({
    store: getStore(state.store),
    location: getLocation(state.location),
    address: getAddress(state.cart),
    cart: getCart(state.cart)
})
export default withTranslation()(connect(mapStateToProps)(GoogleApiWrapper({apiKey: (process.env.REACT_APP_API_GOOGLE_MAPS)})(LocationSearchWitSave)))