import {setTimeByString, isTimeAllDay} from "./DateHelper";

export const emptyOrValue = (value)=>{
    return value === undefined || value === null ? '' : value;
}

export const getAddressName = (address) => {
    let name = '';
    if(address === undefined || (!address.city && !address.street)){
        return  '';
    }
    if(address.street){
        name += address.street;
        if(address.build_nr){
            name += " " + address.build_nr;
        }
    }
    if(address.city){
        if(name){
            name += ", "
        }
        if(address.zip_code){
            name += address.zip_code + " ";
        }
        name += address.city;
        if(!address.street){
            if(address.build_nr){
                name += " " + address.build_nr;
            }
        }
    }
    if(address.country){
        if(name){
            name += ", "
        }
        name += address.country;
    }
    return name;
}
export const getWeekday = () => {
    var weekday = new Array(7);
    weekday[0] = "sunday";
    weekday[1] = "monday";
    weekday[2] = "tuesday";
    weekday[3] = "wednesday";
    weekday[4] = "thursday";
    weekday[5] = "friday";
    weekday[6] = "saturday";
    return weekday;
}

export const getDayOfWeekNumber = (dayOfWeek) => {
    let weekday= getWeekday();
    var index = weekday.indexOf(dayOfWeek.toLowerCase());
    return index;
}
export const getOpenHours = (hours, date) => {
    if(hours === undefined){
        return null;
    }
    var d = date;
    let dayOfWeekIndex = d.getDay();
    let currentHours = [];
    hours.forEach(hourItem => {
        let dayFromIndex = getDayOfWeekNumber(hourItem.day_from);
        let dayToIndex = getDayOfWeekNumber(hourItem.day_to);
        let hourFrom = hourItem.hour_from;
        let hourTo = hourItem.hour_to;
        if(hourFrom > hourTo){
            if(dayOfWeekIndex === 0){
                if(dayToIndex === 7){
                    let newHourTo = "00:00";
                    if(newHourTo != hourTo){
                        currentHours.push({
                            hour_from: newHourTo,
                            hour_to: hourTo,
                        });
                    }
                    }
                // hourTo = "24:00";
            }else{
                if(dayToIndex === dayOfWeekIndex - 1 ){
                    let newHourTo = "00:00";
                    if(newHourTo != hourTo){
                        currentHours.push({
                            hour_from: newHourTo,
                            hour_to: hourTo,
                        })
                    }
                }
                // hourTo = "24:00";
            }
        }
        if(dayOfWeekIndex>=dayFromIndex && dayOfWeekIndex <= dayToIndex){
            currentHours.push({
                hour_from: hourFrom,
                hour_to: hourTo,
            })
        }
    });
    return currentHours
}
export const isOpen = (openHours, date) => {
    // var currentD = DateService.now();
    let dayOpenHours = getOpenHours(openHours, date);
    openHours = dayOpenHours.filter(
        openHour => {
            let hourFrom = openHour.hour_from;
            let hourTo = openHour.hour_to;
            if(isTimeAllDay(hourFrom) && isTimeAllDay(hourTo)){
                return true;
            }

            var hourFormD = new Date(date.getTime());
            setTimeByString(hourFormD, hourFrom);
            // hourFormD.setHours(hourFrom.substring(0, 2), hourFrom.substring(3, 5),0); // 5.30 pm
            var hourToD = new Date(date.getTime());
            if(hourFrom > hourTo){
                hourToD.setDate(hourToD.getDate() + 1);
            }
            setTimeByString(hourToD, hourTo);
            // hourToD.setHours(hourTo.substring(0, 2), hourTo.substring(3, 5),0); // 6.30 pm

            if(date >= hourFormD && date < hourToD ){
                return true;
            }else{
                return false;
            }
        }
    );
    if(openHours.length === 0){
        return false;
    }
    return true;
}
export const padTo2Digits = (num) => {
    return String(num).padStart(2, '0');
}
