import React, { Component } from 'react';

class Test extends Component{

    render(){
        return (
            <div>
                Test
            </div>
        )
    }

}
export default Test