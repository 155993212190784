class StoreHelper {

    static getAvailablePaymentMethods(store, paymentMethods, orderType){
        let availablePaymentMethods = paymentMethods.filter(x => {
            let storePaymentMethod = store.payment_methods.filter(y => y.id == x.id)[0];
            return storePaymentMethod.order_types === undefined || storePaymentMethod.order_types.length === 0 || storePaymentMethod.order_types.includes(orderType);
        });
        return availablePaymentMethods;
    }

    static getPaymentMethod(paymentMethodId, paymentMethods){
        return paymentMethods.filter(x => x.id === paymentMethodId)[0];
    }

    static getDefaultPaymentMethodId(paymentMethodId, paymentMethods){
        let paymentMethod = undefined;
        if(paymentMethodId !== undefined && paymentMethodId){
            paymentMethod = paymentMethods.filter(x => x.id === paymentMethodId)[0];
        }
        if(paymentMethod === undefined){
            if(paymentMethods.length === 1){
                paymentMethod = paymentMethods[0];
            }
        }
        if(paymentMethod === undefined){
            return null;
        }
        return paymentMethod.id;
    }
}


export default StoreHelper;