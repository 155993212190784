import Cookies from "universal-cookie/lib";
import ReactPixel from "react-facebook-pixel";

let cookies = new Cookies();

class Pixel {

    static facebookPixelId = null;
    static ReactPixel = null;
    static initialized = false;

    static isAcceptCookies = () => {
        return cookies.get("cookie_marketing");
    }
    static checkInitialized() {
        if(this.initialized) return;
        if(!this.isAcceptCookies()) return;
        const ReactPixel =  require('react-facebook-pixel');
        this.ReactPixel = ReactPixel.default;
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };
        ReactPixel.default.init(this.facebookPixelId, undefined, options);
        this.initialized = true;
    }
    static add(id){
        this.shopFacebookPixelId = id;
        if(!this.isAcceptCookies()) return;
        this.checkInitialized();
    }
    static pageView(path){
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.ReactPixel) return;
        this.ReactPixel.pageView();
    }
    static purchase(order){
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.ReactPixel) return;
        var items = [];
        for(var i = 0; i < order.items.length;i++){
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "contents": items,
        };
        this.ReactPixel.track("Purchase", purchaseData);
    }

}
export default Pixel;