import TagManager from 'react-gtm-module'

const ReactGtag = {

    initializeTagManager: function (id){
        const tagManagerArgs = {
            gtmId: id
        }
        TagManager.initialize(tagManagerArgs)
    },
    initialize: function(id, config){

        if (typeof window === 'undefined') {
            return;
        }
        var gtag = 'gtag';
        if (document.getElementById(gtag)) {
            return;
        }
        var script = document.createElement('script');
        script.id = gtag;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
        script.type = 'text/javascript';
        script.async = true;
        document.head.appendChild(script);
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', id, config);
    },
    gtag: function(command, value, params){
        window.gtag(command, value, params);
    },
    ecommerce: function(event, data){
        // console.log("ecommerce", event, data)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: event,
            ecommerce: data
        });
    }
}

export default ReactGtag;
// class ReactGtag {
//     initialize(id, config){
//
//         if (typeof window === 'undefined') {
//             return;
//         }
//         var gtag = 'gtag';
//         if (document.getElementById(gtag)) {
//             return;
//         }
//         var script = document.createElement('script');
//         script.id = gtag;
//         script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
//         script.type = 'text/javascript';
//         script.async = true;
//         document.head.appendChild(script);
//         if (!window.dataLayer) {
//             window.dataLayer = [];
//         }
//         window.gtag = function () {
//             window.dataLayer.push(arguments);
//         };
//         window.gtag('js', new Date());
//         window.gtag('config', id, config);
//     }
//     gtag(command, value, params){
//         window.gtag(command, value, params);
//     }
// }
// module.exports = new ReactGtag();
// export default ReactGtag;
// // export function initialize(id, config) {
// // }
//
//
// // module.exports = ReactGtag;