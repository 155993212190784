import React from 'react';
import {useTranslation} from "react-i18next";

const FormattedTime = (props)=>{
    const { i18n } = useTranslation();
    let options = {
        hour: 'numeric',
        minute: 'numeric',
        // day: '2-digit',
        // month: '2-digit',
        // year: 'numeric'
    }
    if(props.day !== undefined) options.day = props.day;
    if(props.month !== undefined) options.month = props.month;
    return new Intl.DateTimeFormat(i18n.language, options).format(props.value);
}

export default FormattedTime;