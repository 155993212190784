import React, { Component } from 'react';
import {connect} from "react-redux";
import paylaneImage from '../../../../../images/gateway/paylane.png';
import {FormError} from "../../../../common/FormError";
import {FormErrors} from "../../../../common/FormErrors";
import PayLaneClient from "../../../../../vendor/paylane";

class PaylaneCardPaymentMethodIframe extends Component{

    constructor(props) {
        super(props);
        let number_1 = "";
        let number_2 = "";
        let number_3 = "";
        let number_4 = "";
        let name = "";
        let cvv = "";
        if ((process.env.NODE_ENV && process.env.NODE_ENV === 'development') || (process.env.REACT_APP_ENV && process.env.REACT_APP_DEBUG === 'true')) {
            let number = "4012001037167778";
            // number = "4012001038443335";
            let numberSplit = number.match(/.{1,4}/g);
            number_1 = numberSplit[0];
            number_2 = numberSplit[1];
            number_3 = numberSplit[2];
            number_4 = numberSplit[3];
            name = "Test Card";
            cvv = "222";

        }
        this.state = {
            formErrors: {
                number: '',
                date: '',
                name: '',
                cvv: '',
                year: '',
                month: '',
                unknown: null
            },
            show: false,
            // number: '41111111111111111',
            number_1: number_1,
            number_2: number_2,
            number_3: number_3,
            number_4: number_4,
            name: name,
            year: 2021,
            month: 1,
            cvv: cvv,
            token: '',
            loading: false,
            error: ''

        };
        this.save = this.save.bind(this);

        this.handleCardChange = this.handleCardChange.bind(this);
    }
    getNumber(){
        return this.state.number_1 + "" + this.state.number_2 + "" + this.state.number_3 + "" + this.state.number_4;
    }
    handleCardChange(name, event) {
        const target = event.target;
        const value = target.value;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));

    }
    addErrorCard(key, errorMessage){
        this.setState(prevState => ({
            ...prevState,
            formErrors: {
                ...prevState.formErrors,
                [key]: errorMessage
            }
        }));
    }

    save(e){
        // e.preventDefault();
        this.setState({ loading: true });
    }
    initPaylaneJS(){
        let that = this;
        const {paymentMethod} = this.props;
        let publicApiKey = paymentMethod.settings.publicApi;
        try {
            new window.PayLaneClient({
                publicApiKey : publicApiKey,
                paymentForm  : 'paylane-form',
                isCallbackHandlerOverwritten: true,
                callbackHandler: function(token){
                    // let options = {
                    //     'paymentToken': token
                    // };
                    that.props.save(token);
                    that.setState({ token: token, loading: false });
                },
                errorHandler: function (type, code, description) {
                    if(type === 2){
                        switch(code){
                            case 411:
                            case 430:
                                that.addErrorCard('number', "Niepoprawny numer karty");
                                break;
                            case 417:
                                that.addErrorCard('name', "Nazwa na karcie jest niepoprawna");
                                break;
                            case 416:
                                that.addErrorCard('cvv', "Niepoprawy kod CVV");
                                break;
                            case 412:
                                that.addErrorCard('year', "Rok nie jest poprawny");
                                break;
                            case 413:
                                that.addErrorCard('month', "Miesiąc nie jest poprawny");
                                break;
                            default:
                                that.addErrorCard('unknown', "Niepoprawne dane: " + description);
                                break;
                        }
                    }
                    that.setState({ loading: false });
                }
            });
        }
        catch (e)
        {
            console.log(e); // exceptions are fatal
        }
    }
    componentDidMount(){
        this.initPaylaneJS();
    };

    buildOptions(min, max) {
        var arr = [];

        for (let i = min; i <= max; i++) {
            let iS = i +"";
            if(i < 10){
                iS = "0" + iS;
            }
            arr.push(<option key={i} value={iS}>{iS}</option>)
        }

        return arr;
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    render(){
        return (
            <div>
                <form id="paylane-form" action="#">

                    <div className="form-payment-card">

                        <FormErrors formErrors={this.state.formErrors.unknown} />

                        <input type="hidden" name="first-name" value="" />
                        <input type="hidden" name="last-name" value="" />
                        <input type="hidden" name="email" value="" />
                        <input type="hidden" name="address" value="" />
                        <input id="card_no" type="hidden" value={this.getNumber()} className="form-control" data-paylane="cc-number" />

                        <div className="form-group">
                            <label htmlFor="card_no">Numer Karty</label>

                            <div className={`equal ${this.errorClass(this.state.formErrors.number)}`}  data-cc-number-error="">
                                <div className="col">
                                    <input type="num" id="card-number" name="card.number_1" onChange={(e) => this.handleCardChange("number_1", e)}
                                           data-input-card-number="1"
                                           className={`form-control input-cart-number ${this.errorClass(this.state.formErrors.number)}`}
                                           maxLength="4" value={this.state.number_1} />
                                </div>
                                <div className="col">
                                    <input type="num" id="card-number-2" name="card.number_2" onChange={(e) => this.handleCardChange("number_2", e)}
                                           className={`form-control input-cart-number ${this.errorClass(this.state.formErrors.number)}`}
                                           maxLength="4" value={this.state.number_2} />
                                </div>
                                <div className="col">
                                    <input type="num" id="card-number-3" name="card.number_3" onChange={(e) => this.handleCardChange("number_3", e)} className={`form-control input-cart-number ${this.errorClass(this.state.formErrors.number)}`} maxLength="4" value={this.state.number_3} />
                                </div>
                                <div className="col">
                                    <input type="num" id="card-number-4" name="card.number_4" onChange={(e) => this.handleCardChange("number_4", e)}
                                           className={`form-control input-cart-number ${this.errorClass(this.state.formErrors.number)}`}
                                           maxLength="4" value={this.state.number_4} />
                                </div>
                            </div>
                            <FormError formErrors={this.state.formErrors.number} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="card_name">Imię i nazwisko posiadacza</label>
                            <div>
                                <input id="card_name" type="text" value={this.state.name} name="card.name" onChange={(e) => this.handleCardChange("name", e)}  className={`form-control ${this.errorClass(this.state.formErrors.name)}`} data-paylane="cc-name-on-card" />
                                <FormError formErrors={this.state.formErrors.name} />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <div className="form-group">
                                    <label htmlFor="card_expiry_month">Data wygaśnięcia</label>
                                    <div className={`input-group ${this.errorClass(this.state.formErrors.year)} ${this.errorClass(this.state.formErrors.month)}`}>
                                        <select id="card_expiry_month" className={`form-control ${this.errorClass(this.state.formErrors.month)}`} name="card.month" onChange={(e) => this.handleCardChange("month", e)}
                                                required="required" value={this.state.month}
                                                data-paylane="cc-expiry-month">
                                            {this.buildOptions(1,12)}
                                        </select>
                                        <span
                                            className="input-group-addon input-group-separator"></span>
                                        <select className={`form-control ${this.errorClass(this.state.formErrors.year)}`} value={this.state.year} name="card.year" onChange={(e) => this.handleCardChange("year", e)}
                                                data-paylane="cc-expiry-year">
                                            {this.buildOptions(2020,2027)}
                                        </select>
                                    </div>
                                    <FormError formErrors={this.state.formErrors.year} />
                                    <FormError formErrors={this.state.formErrors.month} />
                                </div>
                            </div>
                            <div className="ml-auto">
                                <div className="form-group">
                                    <label htmlFor="card_cvc">CVV</label>
                                    <div>
                                        <input type="text" id="card_cvc"
                                               className={`form-control ${this.errorClass(this.state.formErrors.cvv)}`} placeholder="CVV" name="card.cvv" onChange={(e) => this.handleCardChange("cvv", e)}
                                               required="required" value={this.state.cvv}
                                               data-paylane="cc-cvv" />
                                        <FormError formErrors={this.state.formErrors.cvv} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-card-provider">
                            <img src={paylaneImage} />
                            <span>
                                    Płatności są obsługiwane przez spółkę PayLane sp. z o.o.
                                </span>
                        </div>
                        <button className={"btn-spinner w-100 btn-primary btn" + (this.state.loading ? ' loading' : '')} onClick={(e) => this.save(e)}>Zapisz</button>
                    </div>

                </form>
            </div>
        )
    }

}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(PaylaneCardPaymentMethodIframe)