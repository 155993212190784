import React, {useState} from 'react';
import {connect} from "react-redux";
import {getContact, getOrderCustomFields, getOrderType} from "../../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import {getStore} from "../../../application/store/reducer";
import {getAvailabilities, getCustomFields} from "../../../application/menu/reducer";
import {CartCustomFields} from "./CartCustomFields";
import MenuHelper from "../../../application/common/MenuHelper";

const getDefaultCustomFields =(orderCustomFields) => {
    let customFields = []
    for (var key in orderCustomFields) {
        var value = orderCustomFields[key];
        customFields.push({
            slug: key,
            value: value
        })
    }
    return customFields
}
const CartCustomField = (props)=>{
    const [showDateModal, setShowDateModal] = useState({});
    // const [customFields, setCustomFields] = useState(getDefaultCustomFields(props.order_custom_fields));
    const customFields = getDefaultCustomFields(props.data);
    const updateCustomFieldValue = (slug, value) => {
        // let newCustomFields = [...customFields];
        //
        // let customFieldObj = customFields.filter(x => x.slug == slug)[0];
        // if(customFieldObj === undefined) {
        //     customFieldObj = {
        //         slug: slug,
        //         value: value
        //     }
        //     newCustomFields.push(customFieldObj);
        // }else{
        //     customFieldObj.value = value;
        // }
        //
        // setCustomFields(newCustomFields);
        // props.order_custom_fields[slug] = value;
        props.onChange(slug, value);
    }

    //
    // const updateShowDateModal = (slug) => {
    //     let newShowDateModal = showDateModal;
    //     newShowDateModal[slug] = true;
    //     setShowDateModal(newShowDateModal);
    // }
    // const hideDateModal = (slug) => {
    //     let newShowDateModal = showDateModal;
    //     newShowDateModal[slug] = false;
    //     setShowDateModal(newShowDateModal);
    // }

    const { store, custom_fields, orderType, t } = props;
    if(!MenuHelper.isCustomFieldExist(props.custom_fields, props.orderType, props.store)){
        return null;
    }
    // if(custom_fields === undefined || !custom_fields || custom_fields.length <=0){
    //     return null;
    // }
    // if(store.order_custom_fields === undefined || !store.order_custom_fields || !store.order_custom_fields.some(customField => {
    //     return orderType === customField.type && (customField.visibility === "VISIBLE" || customField.visibility === "REQUIRED");
    // })){
    //     return null;
    // }

    return(
        <div className="card card-cart card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.cart.custom_field.title")}
                </h3>
                <div className="form-row row">
                    <CartCustomFields availabilities={props.availabilities} store={store} orderType={orderType} customFields={custom_fields} valueCustomFields={props.data} errors={props.errors} onChange={updateCustomFieldValue} />
                    {/*{this.renderCustomFields(store, orderType, custom_fields)}*/}
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        contact: getContact(state.cart),
        custom_fields: getCustomFields(state.menu),
        store: getStore(state.store),
        // order_custom_fields: getOrderCustomFields(state.cart),
        orderType: getOrderType(state.cart),
        availabilities: getAvailabilities(state.menu)
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartCustomField))