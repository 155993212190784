import React from 'react';
import {useTranslation} from "react-i18next";

const SummaryContact = (props)=>{
    const { t } = useTranslation();
    return (
        <div className="card card-cart card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.summary.contact.title")}
                </h3>

                <dl>
                    <dt>{props.order.contact.name} ({props.order.contact.email} / {props.order.contact.phone})</dt>
                    <dd>{t("components.summary.contact.contact_data")}</dd>
                    {props.order.comment && (
                        <>
                            <dt>{props.order.comment}</dt>
                            <dd>{t("components.summary.contact.comment")}</dd>
                        </>
                    )}
                </dl>
            </div>

        </div>
    )
}
export default SummaryContact;