import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getUser} from "../../../../application/user/reducer";
import {getStore} from "../../../../application/store/reducer";
import {getOrganization} from "../../../../application/menu/reducer";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import UserService from "../../../../application/user/userService";

function VerificationUserComponent(props) {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = props.user;

    useEffect(() => {
    }, [user.verified])
    const logout = async () => {
        try {
            await props.dispatch(UserService.logout());
            props.setPage("LOGIN");
        } catch (err) {
            props.setPage("LOGIN");
            props.onHide();
        }

    }
    const retry = async () => {
        setLoading(true);
        try {
            // let response = await props.dispatch(UserService.register(email, name, phone, password, confirmPassword, acceptRules, acceptMarketingRules))
            setLoading(false);
            props.onSuccess()
        } catch (e) {
            console.log(e);
            if (e.status === 403 || e.status === 404) {
                setErrors([
                    {
                        "code": "wrong_registration",
                        "message": "Niepoprawne dane do rejestracji"
                    }
                ])
            } else if (e.status == 422) {
                setErrors(e.errors);
            } else {
                setErrors([
                    {
                        "code": "wrong_registration",
                        "message": "Nie udało się zarejestrować"
                    }
                ])
            }
            setLoading(false);
        }

    }
    return (
        <>
            <div>
                <h4>
                    Weryfikacja konta
                </h4>
            </div>
            <div className="alert alert-light" role="alert">
                <small>
                    <InfoSVG className={"icon me-2"}/>
                    Na Twój adres email wysłaliśmy wiadomość z linkiem do weryfikacji konta
                </small>
            </div>
            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    Wiadomość nie dotarła? <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    retry("LOGIN")
                }}>
                    Wyślij ponownie
                </a>
                </div>
                <div className={"text-center"}>
                    Chcesz zalogować się ponownie? <a href={"#"} onClick={logout}>
                    Wyloguj się
                </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        store: getStore(state.store),
        organization: getOrganization(state.menu),
    }
}
export default withTranslation()(connect(mapStateToProps)(VerificationUserComponent))
