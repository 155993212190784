import React, {useCallback, useEffect, useRef, useState} from 'react';

import {connect} from "react-redux";
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import PoweredByGoogle from '../../../images/logo/powered_by_google_on_white.png';
import ChangeLocationModal from "../../common/ChangeLocationModal";
import {withTranslation} from "react-i18next";
import {unstable_batchedUpdates} from "react-dom";
import {GoogleApiWrapper} from "google-maps-react-17";
import {getAddressName} from "../../../application/common/StringHelper";
import Utils from "../../utils/Utils";

const LocationSearch = (props)=>{
    const [position, setPosition] = useState(null);
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [addressModal, setAddressModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState([props.address]);
    const myRef = useRef(null)
    // console.log({...props.address, description: getAddressName(props.address)});
    useEffect(() => {
        if(props.address){
            setSelectedValue([{...props.address, description: getAddressName(props.address)}])
        }else{
            setSelectedValue([])
        }
    }, [props.address, props.address?.description])
    let autocompleteService = new props.google.maps.places.AutocompleteService();


        const _renderMenu = (results, menuProps, state) => {
            const { t } = props;
            let index = 0;
            // const regions = groupBy(results, 'region');
            const items = results.map((option, index) => (
                <MenuItem  key={index} option={option} position={index}>
                    {option.description}
                </MenuItem>
            ));

            return <Menu {...menuProps}>
                {items && items.length > 0 ? items : (
                    <div className={"dropdown-item"}> {t("layout.navbar.search.placeholder")}</div>
                )}
                <div className="dropdown-divider"></div>
                <div className={"dropdown-footer"}>
                    {props.modalAddress && (
                        <button type="button" className="dropdown-item" onClick={() => setAddressModal(true)}>
                            {t("layout.navbar.search.address_manual")}
                        </button>
                    )}
                    <div className="powered-by-google">
                        <img src={PoweredByGoogle} />
                    </div>
                </div>
            </Menu>;
        }
        const geolocate = () => {}
        const searchOnBlur = () => {
            // if(props.location.address !== null){
            //     setSelectedValue(props.location.address.fullAddres);
            //     // props.dispatch(updateSelectedValue(props.location.address.fullAddres));
            // }
        }
        const filterByCallback = (option, props) => {
            return true;
        }
        const handleSearch = useCallback((query) => {
            const { google } = props;
            if(query.length <=1){
                return;
            }
            setLoading(true);

            let codeCountries = ["pl"];
            if(props.store.settings && props.store.settings.countries){
                codeCountries = Utils.countries(props.store.settings.countries).map(x => x.key);
            }
            let requestQuery = {
                input: query,
                types: ['geocode'],
                "componentRestrictions": { "country": codeCountries },
                'fields': ["address_component"]
            };
            let coordinates = props.store.coordinates;
            if(coordinates !== undefined && coordinates){
                requestQuery.location =  new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
                requestQuery.radius = 5000;
            }

            autocompleteService.getPlacePredictions(requestQuery, (predictions, status)  => {
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    unstable_batchedUpdates(() => {
                        setLoading(false);
                        setOptions([]);
                    })
                    return;
                }
                unstable_batchedUpdates(() => {
                    setLoading(false);
                    setOptions(predictions);
                })
            });
        }, []);
        const handleSearchOnChange= (selected, e) => {
            let value = myRef.current.getInput().value;
            if(!value){
                props.onChange(null);
                return
            }
            if(selected === undefined || selected.length <= 0){
                return;
            }
            let address = parseAddress(selected[0]);
            props.onChange(address);
        }

        const handleSearchValue= (newSelected) => {
            setSelectedValue([
                {
                    ...selectedValue[0],
                    "description": newSelected
                }
            ]);
            // props.dispatch(updateSelectedValue(newSelected));
        }

        const parseAddress = (selected) => {
            let description = selected.description;
            let descriptionSplit = description.split(",");
            let address = {
                street: '',
                city: '',
                country: ''
            };
            if(descriptionSplit.length === 3){
                let street = descriptionSplit[0];
                parseStreet(address, street);
                parseCity(address, descriptionSplit[1]);
                address.country = parseCountry(descriptionSplit[2]);
            }
            if(descriptionSplit.length == 2){
                parseCity(address, descriptionSplit[0]);
                address.country = parseCountry(descriptionSplit[1]);
            }
            if(descriptionSplit.length === 1){
                address.country = parseCountry(descriptionSplit[0]);
            }
            address.description = description;
            address.fullAddres = description;
            address.place_id = selected.place_id;
            return address;
        }
        const parseCountry = (text) => {
            return text ? text.trim() : text;
        }
        const parseStreet = (address, text) => {
            let textSplit = text.split(" ");
            let build_nr = textSplit[textSplit.length - 1];
            if(!isNaN(build_nr)){
                text = textSplit.slice(0, textSplit.length - 1).join(" ");
                address.build_nr = build_nr;
            }else{
                let lastCharStreet = build_nr.slice(-1);
                if(isNaN(lastCharStreet)){
                    let buildNrToTest = build_nr.substring(0, build_nr.length - 1);
                    if(!isNaN(buildNrToTest)){
                        text = textSplit.slice(0, textSplit.length - 1).join(" ");
                        address.build_nr = build_nr;
                    }
                }
            }
            address.street = text ? text.trim() : text;
        }
        const parseCity = (address, text) => {
            let textSplit = text.trimStart().split(" ");
            let zip_code = textSplit[0];
            let zip_codeSplit = zip_code.split("-");
            if(zip_codeSplit.length === 2 && !isNaN(zip_codeSplit[0]) && !isNaN(zip_codeSplit[1])){
                text = textSplit.slice(1, textSplit.length).join(" ");
                address.zip_code = zip_code;
            }
            address.city = text ? text.trim() : text;
        }
    return (
        <div className="input-group search-input search-input-basic">
            <AsyncTypeahead
                position={position}
                location={location}
                isLoading={loading}
                options={options}
                clearButton
                renderMenu={_renderMenu}
                id="location-cart-search"
                filterBy={filterByCallback}
                onFocus={geolocate}
                onBlur={searchOnBlur}
                labelKey="description"
                useCache={true}
                delay={500}
                minLength={0}
                selected={selectedValue}
                // selected={[selected]}
                onSearch={handleSearch}
                onChange={handleSearchOnChange}
                onInputChange={handleSearchValue}
                placeholder="Wpisz adres dostawy"
                ref={myRef}
                renderMenuItemChildren={(option, props) => (
                    <div>
                        {option.description}
                    </div>
                )}
            />
            <ChangeLocationModal location={props.location} showModal={addressModal} onHide={() => setAddressModal(false)} />
        </div>
    )
}

const mapStateToProps = state => ({
})
export default withTranslation()(connect(mapStateToProps)(GoogleApiWrapper({apiKey: (process.env.REACT_APP_API_GOOGLE_MAPS)})(LocationSearch)))