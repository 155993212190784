import React from 'react';
import {getAddressName} from "../../../../../application/common/StringHelper";

const Rules2021 = (props)=>{
    let organization = props.organization;
    let companyName = null;
    let email = null;
    let phone = null;
    let addressView = null;
    let taxIdNoView = null;
    if(organization !== null){
        companyName = organization.company_name;
        if(organization.address !== null){
            addressView = getAddressName(organization.address);
        }
        taxIdNoView = organization.tax_id_no;
        email = organization.email;
        phone = organization.phone;
    }
    let paymentProviders = [];
    if(props.paymentMethods.some(paymentMethod => paymentMethod.gateway == 'BLUEMEDIA')){
        let paymentProviderData = {
            "name": "Autopay S.A.",
            "full_name": " Autopay S.A. z siedzibą w Sopocie przy ulicy Powstańców Warszawy 6, kod 81-718. Spółka została zarejestrowana w Sądzie Rejonowym Gdańsk-Północ VIII Wydział Gospodarczy KRS pod nr 0000320590, NIP 585-13-51-185, REGON 191781561.",
            "img": "autopay.png"
        };
        paymentProviders.push(paymentProviderData);
    }
    if(props.paymentMethods.some(paymentMethod => paymentMethod.gateway == 'PAYLANE')){
        let paymentProviderData = {
            "name": "PayLane sp. z o.o.",
            "full_name": " PayLane sp. z o.o. z siedzibą w Gdańsku przy ul. Arkońskiej 6/A3, kod pocztowy: 80-387, KRS: 0000227278."
        };
        paymentProviders.push(paymentProviderData);
    }
    if(props.paymentMethods.some(paymentMethod => paymentMethod.gateway == 'TPAY')){
        let paymentProviderData = {
            "name": "Krajowy Integrator Płatności spółka akcyjna",
            "full_name": " Krajowy Integrator Płatności spółka akcyjna z siedzibą w Poznaniu, plac Andersa 3, 17 piętro, 61-894 Poznań, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział Krajowego Rejestru Sądowego pod numerem KRS 0000412357, NIP 7773061579, REGON 300878437.",
            "img": "tpay.svg"
        };
        paymentProviders.push(paymentProviderData);
    }
    return (
        <>
            <h2>Regulamin</h2>
            <h5>
                1. Dane Firmy
            </h5>

            <p>
                {companyName}<br />
                {addressView}<br />
                NIP: {taxIdNoView}<br />
                Telefon: {phone}<br />
                Adres e-mail: {email}<br />
            </p>
            <h5>
                2. Zasady Świadczenia Usług
            </h5>

            <p>
                1. Do prawidłowego korzystania z wszystkich funkcjonalności Serwisu niezbędne jest: <br/>
                a. posiadanie dostępu do sieci Internet;<br/>
                b. korzystanie ze standardowego oprogramowania w postaci systemu operacyjnego oraz przeglądarki internetowej, przy czym przeglądarka internetowa powinna obsługiwać pliki „cookies”;<br/>
                c. posiadanie własnego adresu poczty elektronicznej;<br/>
                d. posiadanie numeru telefonu.<br/>
                2. W przypadku braku spełnienia powyższych wymogów korzystanie z Serwisu może być utrudnione lub niemożliwe.<br/>
            </p>

            <h5>
                3. Oferta
            </h5>

            <p>
                1. Przedmiotem Zamówień mogą być Posiłki prezentowane przez Restauracje za pośrednictwem Serwisu. <br />
                2. Informacje o dostępnych Posiłkach prezentowane przez Restauracje za pośrednictwem Serwisu nie stanowią oferty w rozumieniu Kodeksu cywilnego, lecz są jedynie zaproszeniem do składania ofert przez Użytkowników.<br />
                4. Restauracja określa obszar, w ramach którego Zamówienie może zostać zrealizowane. Jeżeli Użytkownik wskaże jako miejsce dostawy Zamówienia adres znajdujący się poza określonym przez Restaurację obszarem, zostanie poinformowany, że realizacja Zamówienia przez wybraną Restaurację nie jest możliwa. <br />
                5. Ceny zawarte w cennikach Restauracji podawane są w złotych polskich oraz zawierają podatek od towarów i usług (VAT) naliczony zgodnie z aktualnie obowiązującymi przepisami.<br />
                6. Zamówienie należy złożyć poprzez wypełnienie formularza zamówienia dostępnego na stronie internetowej Serwisu podając dane osobowe niezbędne do złożenia Zamówienia.<br />
                7. W formularzu Zamówienia należy podać: imię, nazwisko, adres dostawy, telefon oraz adres mailowy.<br />
                8. Użytkownik powinien wybrać zamawiane Posiłki (ich rodzaj oraz ilość), formę płatności oraz rodzaj dostawy. W przypadku skorzystania z kuponu rabatowego Użytkownik powinien użyć funkcji "Kod rabatowy" i wpisać kod rabatowy we wskazane pole w ramach Serwisu.<br />
                9. Złożenie Zamówienia poprzez stronę internetową Serwisu następuje w wyniku wysłania Zamówienia. Wysłanie Zamówienia następuje po akceptacji i potwierdzeniu przez Użytkownika wszystkich jego istotnych elementów oraz naciśnięciu przycisku „Złóż zamówienie”. W trakcie składania Zamówienia - do momentu naciśnięcia przycisku „Złóż zamówienie” - Użytkownik ma możliwość modyfikacji wprowadzonych danych, w tym w zakresie wyboru Posiłku. W tym celu należy kierować się wyświetlanymi Użytkownikowi komunikatami oraz informacjami dostępnymi w ramach Serwisu.<br />
                10. Zamówienie stanowi ofertę w rozumieniu Kodeksu cywilnego i jest przesyłane przez system informatyczny Serwisu wraz z koniecznymi do realizacji Zamówienia danymi osobowymi Użytkownika do Restauracji.<br />
                11. Po otrzymaniu przez Użytkownika od Restauratora informacji o przyjęciu Zamówienia w formie komunikatu wyświetlanego na stronie Serwisu oraz wiadomości email wysłanej na adres poczty elektronicznej podany przez Użytkownika między Użytkownikiem a wybranym przez niego Restauratorem zostaje zawarta (z momentem otrzymania ww. wiadomości email) Umowa sprzedaży Posiłku. Realizacja Zamówienia przez Restauratora dokonywana jest niezwłocznie po uznaniu płatności internetowej za dokonaną, zgodnie z § 8 ust. 2 poniżej.<br />
                12. W przypadku zdarzeń losowych uniemożliwiających lub utrudniających Restauratorowi przyjęcie Zamówienia lub realizację zamówienia przez Restauratora umowy sprzedaży posiłku, Restaurator skontaktuje się niezwłocznie z Użytkownikiem w celu:<br />
                a) ustalenia sposobu realizacji Umowy sprzedaży Posiłku lub<br />
                b) złożenia Użytkownikowi oświadczenia o odstąpieniu od zawartej z nim Umowy sprzedaży Posiłku lub<br />
                c) przyjęcia od Użytkownika oświadczenia o odstąpieniu od zawartej z Restauratorem Umowy sprzedaży Posiłku.<br />
                13. Zamówienia są realizowane w cenach obowiązujących w chwili składania Zamówienia. W przypadku Zamówień składanych w ramach obowiązujących promocji są one realizowane z uwzględnieniem rabatów wynikających z warunków konkretnej promocji, obliczanych od regularnych cen Posiłków z chwili składania Zamówienia.<br />
                14. W przypadku sprzedaży dokonywanej w ramach ogłaszanych promocji Operator / Restauratorzy zastrzegają możliwość wprowadzenia ograniczeń ilościowych, czasowych lub innych ograniczeń w sprzedaży Posiłków. Ograniczenia, o których mowa powyżej określane są w warunkach promocji.<br />
                15. Czas realizacji zamówienia w przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - liczony jest od momentu uznania rachunku bankowego Sprzedawcy.<br />
                16. Ewentualne koszty dostawy są dodawane do koszyka i są widoczne przy składaniu zamówienia.<br />
                17. Informacje na temat przetwarzania danych osobowy, informacje o podstawie prawnej przetwarzania danych oraz o polityce prywatności można znaleźć pod adresem <a href={"https://gopos.pl/tos/privacy-policy"}>https://gopos.pl/tos/privacy-policy</a>
            </p>

            <h5>
                4. Płatność
            </h5>

            <p>
                1. Użytkownicy mogą płacić za zamówienie za pośrednictwem Serwisu:<br />
                a. gotówką, bezpośrednio do rąk pracownika w chwili odbioru przedmiotu Zamówienia,<br />
                b. w formie płatności internetowych za pośrednictwem dostępnych w Serwisie systemów płatności o ile wybrana przez Użytkownika Restauracja akceptuje taką formę płatności.<br />
                c. karty płatnicze:<br />
                - Visa<br />
                - Visa Electron<br />
                - Mastercard<br />
                - MasterCard Electronic<br />
                - Maestro<br />
                2. Płatność internetowa jest uznana za dokonaną w momencie otrzymania przez system informatyczny Serwisu potwierdzenia akceptacji (w przeciągu 8 min) transakcji przez dostawcę usług płatniczych pośredniczącego w danej operacji transferu środków płatniczych<br />
                3. Podmiotem świadczącym obsługę płatności online jest: &nbsp;
                {

                    paymentProviders.map((pamentProvider) => pamentProvider.name)
                } <br />
                4. Płatności on-line realizuje:<br />
                {

                    paymentProviders.map((paymentProvider) =>
                        <React.Fragment key={paymentProvider}>
                            - {paymentProvider.full_name} <br/>
                            {paymentProvider.img ? (
                                <img style={{padding:20}} className={"w-100"} src={"/images/providers/" + paymentProvider.img} />
                            ): ""}
                        </React.Fragment>
                    )
                }
                5. W przypadku wystąpienia konieczności zwrotu środków za transakcję sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył konsument, chyba że konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.<br />
            </p>

            <h5>
                5. Rozpatrywanie reklamacji
            </h5>

            <p>
                1. Reklamacje Konsumentów dotyczące Oferty, Zamówienia lub realizacji Umowy winny być składane bezpośrednio do Restauracji z wykorzystaniem jej danych kontaktowych wymienionych w aplikacji.<br />
                2. Po otrzymaniu reklamacji, zostanie ona niezwłocznie rozpatrzona, jednak nie później niż w terminie 14 dni od dnia jej otrzymania. Spółka potwierdzi otrzymanie reklamacji złożonej przez Konsumenta na podany przez niego podczas zgłoszenia adres mailowy.<br />
            </p>
        </>
    )
}
export default Rules2021;