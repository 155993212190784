// import {ItemHelper} from "../../../../../application/common/ItemHelper";

import ItemHelper from "../../../../../application/common/ItemHelper";
import ItemViewHelper from "../../../../../application/common/ItemViewHelper";

class StateItemHelper {
    static create = (id, items, modiferGroups, currentItem, modifierGroupId, parentItemId) => {
        if(currentItem && currentItem.item_id === id){
            return null;
        }
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        if(currentItem === undefined){
            currentItem =  {
                name: null,
                quantity: 1,
                modifier_groups:[],
                price: 0,
                unit_total_price: 0,
                total_price: 0
            }
        }
        let itemPrice = item.price !== undefined ? item.price.amount : 0;
        let itemPriceOvveride = item.price_info_overrides.filter(x => x.context_type == "ITEM" && x.context_id == parentItemId)[0];
        if(itemPriceOvveride !== undefined && itemPriceOvveride){
            itemPrice = itemPriceOvveride.price.amount;
        }else{
            let modifierGroupPriceOvveride = item.price_info_overrides.filter(x => x.context_type == "MODIFIER_GROUP" && x.context_id == modifierGroupId)[0];
            if(modifierGroupPriceOvveride !== undefined && modifierGroupPriceOvveride){
                itemPrice = modifierGroupPriceOvveride.price.amount;
            }
        }

        let stateItem = {
            item_id: item.id,
            price: itemPrice,
            name: item.translation.name,
            unit_total_price: itemPrice,
            total_price: itemPrice,
            quantity: currentItem.quantity,
            modifier_groups:[]
        }
        if(modifierGroupId){
            stateItem.parent_modifier_group_id = modifierGroupId;
        }
        item.modifier_groups.forEach(itemModifierGroup => {
            let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
            if(modifierGroup === null){
                return;
            }
            let stateModifierGroup = {
                selected_items: [],
                modifier_group_id: modifierGroup.id
            }
            modifierGroup.options.forEach(modifierGroupOption => {
                let modifierGroupItem = ItemHelper.getItem(items, modifierGroupOption.entity_id);
                if(modifierGroupItem !== null){

                    let quantityInfo = ItemHelper.getItemQuantityInfo(modifierGroupItem, item, modifierGroup);
                    if(quantityInfo !== null && quantityInfo.default_quantity > 0){

                        let stateModifierGroupItemPrice = ItemHelper.getPriceOvveride(modifierGroupItem, item, modifierGroup);
                        let quantity = quantityInfo.default_quantity !== null ? quantityInfo.default_quantity : 0;
                        // let stateModifierGroupItem = {
                        //     item_id: modifierGroupItem.id,
                        //     price: stateModifierGroupItemPrice,
                        //     name: modifierGroupItem.name,
                        //     unit_total_price: stateModifierGroupItemPrice,
                        //     total_price: stateModifierGroupItemPrice * quantity,
                        //     quantity: quantity,
                        //     quantity_free: 0,
                        //     modifier_groups: []
                        // }
                        let stateModifierGroupItem = StateItemHelper.createSubItem(modifierGroupItem.id, items, modiferGroups, currentItem, modifierGroup.id, id, quantity)
                        if(stateModifierGroupItemPrice !== stateModifierGroupItem.price){
                            throw new Error('stateModifierGroupItemPrice !== stateModifierGroupItem.price');
                        }
                        stateModifierGroup.selected_items.push(stateModifierGroupItem);
                    }
                }
            })

            StateItemHelper.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);
            if(stateModifierGroup.selected_items.length > 0){
                stateItem.modifier_groups.push(stateModifierGroup);
            }
        });
        StateItemHelper.refreshPrice(stateItem);
        return stateItem;
    }

    static createSubItem = (id, items, modiferGroups, currentItem, modifierGroupId, parentItemId, itemQuantity) => {
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        let itemPrice = item.price !== undefined ? item.price.amount : 0;
        let itemPriceOvveride = item.price_info_overrides.filter(x => x.context_type == "ITEM" && x.context_id == parentItemId)[0];
        if(itemPriceOvveride !== undefined && itemPriceOvveride){
            itemPrice = itemPriceOvveride.price.amount;
        }else{
            let modifierGroupPriceOvveride = item.price_info_overrides.filter(x => x.context_type == "MODIFIER_GROUP" && x.context_id == modifierGroupId)[0];
            if(modifierGroupPriceOvveride !== undefined && modifierGroupPriceOvveride){
                itemPrice = modifierGroupPriceOvveride.price.amount;
            }
        }

        let stateItem = {
            item_id: item.id,
            price: itemPrice,
            name: item.translation.name,
            unit_total_price: itemPrice,
            total_price: itemPrice,
            quantity: itemQuantity,
            modifier_groups:[]
        }
        if(modifierGroupId){
            stateItem.parent_modifier_group_id = modifierGroupId;
        }
        item.modifier_groups.forEach(itemModifierGroup => {
            let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
            if(modifierGroup === null){
                return;
            }
            let stateModifierGroup = {
                selected_items: [],
                modifier_group_id: modifierGroup.id
            }
            modifierGroup.options.forEach(modifierGroupOption => {
                let modifierGroupItem = ItemHelper.getItem(items, modifierGroupOption.entity_id);
                if(modifierGroupItem !== null){

                    let quantityInfo = ItemHelper.getItemQuantityInfo(modifierGroupItem, item, modifierGroup);
                    if(quantityInfo !== null && quantityInfo.default_quantity > 0){

                        let stateModifierGroupItemPrice = ItemHelper.getPriceOvveride(modifierGroupItem, item, modifierGroup);
                        let quantity = quantityInfo.default_quantity !== null ? quantityInfo.default_quantity : 0;
                        let stateModifierGroupItem = {
                            item_id: modifierGroupItem.id,
                            price: stateModifierGroupItemPrice,
                            name: modifierGroupItem.name,
                            unit_total_price: stateModifierGroupItemPrice,
                            total_price: stateModifierGroupItemPrice * quantity,
                            quantity: quantity,
                            quantity_free: 0,
                            modifier_groups: []
                        }
                        stateModifierGroup.selected_items.push(stateModifierGroupItem);
                    }
                }
            })

            StateItemHelper.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);
            if(stateModifierGroup.selected_items.length > 0){
                stateItem.modifier_groups.push(stateModifierGroup);
            }
        });
        StateItemHelper.refreshPrice(stateItem);
        return stateItem;
    }

    static refreshPrice = (item) => {
        if(item === null){
            return;
        }
        let total_price = 0;
        total_price = item.modifier_groups.map(modifierGroup => {
            let modifierGroupPrice = modifierGroup.selected_items.reduce((prev,next) => prev + next.total_price,0);
            return modifierGroupPrice;
        }).reduce((prev,next) => prev + next,0);
        item.unit_total_price = item.price + total_price;
        item.total_price = item.unit_total_price * item.quantity;
    }
    static refreshItemPrice = (item, itemPromotions, cart) => {
        if(item === null){
            return;
        }
        let total_price = 0;
        total_price = item.modifier_groups.map(modifierGroup => {
            let modifierGroupPrice = modifierGroup.selected_items.reduce((prev,next) => prev + next.total_price,0);
            return modifierGroupPrice;
        }).reduce((prev,next) => prev + next,0);
        item.unit_total_price = item.price + total_price;
        item.total_price = item.unit_total_price * item.quantity;
        let discountedPrice = ItemViewHelper.getDiscountPrice(item.item_id, item.quantity, item.unit_total_price, itemPromotions, cart, item.price);
        item.discount_price = discountedPrice;
    }
    static refreshItemPriceWithParent = (stateItem, itemPromotions, cart, parentModifierGroupId, items, modifier_groups) => {
        StateItemHelper.refreshItemPrice(stateItem, itemPromotions, cart);
        if(parentModifierGroupId){
            StateItemHelper.refreshStateModifierGroupItemOption(stateItem, parentModifierGroupId, items, modifier_groups);
        }
    }
    static updateStateSelectedItemsChargeAbove = (stateSelectedItems, items, parentItem, modifierGroup) => {
        let modifierGroupQuantityInfo = ItemHelper.getModifierGroupQuantityInfo(modifierGroup, parentItem);
        stateSelectedItems.forEach(stateSelectedItem => {
            StateItemHelper.updateStateSelectedItemChargeAbove(stateSelectedItem, items, parentItem, modifierGroup);
        });
        if(modifierGroupQuantityInfo !== null && modifierGroupQuantityInfo.charge_above > 0){

            let quantityFree = stateSelectedItems.reduce((prev,next) => prev + next.quantity_free,0);
            let chargeAboveQuantity = modifierGroupQuantityInfo.charge_above - quantityFree;
            if(chargeAboveQuantity){
                stateSelectedItems.filter(stateSelectedItem => stateSelectedItem.quantity > stateSelectedItem.quantity_free).sort((a, b) => b.price - a.price).forEach(stateSelectedItem => {
                    if(chargeAboveQuantity <= 0){
                        return;
                    }
                    let quantityToFreeCharge = stateSelectedItem.quantity - stateSelectedItem.quantity_free;
                    quantityToFreeCharge = quantityToFreeCharge > chargeAboveQuantity ? chargeAboveQuantity : quantityToFreeCharge;
                    stateSelectedItem.quantity_free += quantityToFreeCharge;
                    stateSelectedItem.total_price = stateSelectedItem.total_price - (stateSelectedItem.price * quantityToFreeCharge);
                    chargeAboveQuantity = chargeAboveQuantity - quantityToFreeCharge;
                });
            }
        }
    }

    static updateStateSelectedItemChargeAbove = (stateSelectedItem, items, parentItem, modifierGroup) => {
        // let totalPrice = stateSelectedItem.total_price;
        let totalPrice = stateSelectedItem.unit_total_price * stateSelectedItem.quantity;
        let selectedItem = ItemHelper.getItem(items, stateSelectedItem.item_id);
        let selectedItemQuantityInfo = ItemHelper.getItemQuantityInfo(selectedItem, parentItem, modifierGroup);
        if(selectedItemQuantityInfo !== null && selectedItemQuantityInfo.charge_above > 0){
            let quantityChargeAbove = stateSelectedItem.quantity - selectedItemQuantityInfo.charge_above;
            quantityChargeAbove = quantityChargeAbove > 0 ? quantityChargeAbove : 0;
            let quantityChargeBelow = stateSelectedItem.quantity - quantityChargeAbove;
            totalPrice = 0;
            if(quantityChargeAbove > 0){
                totalPrice = stateSelectedItem.unit_total_price * quantityChargeAbove;
            }
            stateSelectedItem.quantity_free = quantityChargeBelow;
        }else{
            stateSelectedItem.quantity_free = 0;
        }
        stateSelectedItem.total_price = totalPrice;
    }
    static getOrCreateStateModifierGroup = (stateItem, modifierGroupId) => {
        let modifierGroup = stateItem.modifier_groups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
        if(modifierGroup === undefined){
            modifierGroup = {
                modifier_group_id: modifierGroupId,
                selected_items: []
            };
            stateItem.modifier_groups.push(modifierGroup);
        }
        return modifierGroup;
    }
    static getOrCreateStateModifierGroupItemOption = (stateItem, modifierGroupId, item, items, modifier_groups) => {
        let modifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroupId);
        let modifierGroupItem = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item.id)[0];
        if(modifierGroupItem === undefined){
            modifierGroupItem = StateItemHelper.create(item.id, items, modifier_groups, undefined, modifierGroupId, stateItem.item_id);
            modifierGroup.selected_items.push(modifierGroupItem);
        }

        return modifierGroupItem;
    }
    static getOrCreateStateModifierGroupItemOptions = (stateItem, modifierGroupId, item, items, modifier_groups) => {
        let modifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroupId);
        let modifierGroupItems = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item.id);
        if(modifierGroupItems.length === 0){
            let modifierGroupItem = StateItemHelper.create(item.id, items, modifier_groups, undefined, modifierGroupId, stateItem.item_id);
            modifierGroupItems.push(modifierGroupItem);
            modifierGroup.selected_items.push(modifierGroupItem);
        }

        return modifierGroupItems;
    }
    static addQuantityItem = (stateItem, modifierGroup, item, items, modifier_groups) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = StateItemHelper.getOrCreateStateModifierGroupItemOption(stateItem, modifierGroup.id, item, items, modifier_groups);
        modifierGroupItem.quantity = modifierGroupItem.quantity + 1;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        StateItemHelper.refreshStateModifierGroupItemOption(stateItem, modifierGroup.id, items, modifier_groups);
    }
    static subQuantityItem = (stateItem, modifierGroup, item, items, modifier_groups) => {
        let modifierGroupItem = StateItemHelper.getOrCreateStateModifierGroupItemOption(stateItem, modifierGroup.id, item, items, modifier_groups);
        modifierGroupItem.quantity = modifierGroupItem.quantity - 1;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        StateItemHelper.refreshStateModifierGroupItemOption(stateItem, modifierGroup.id, items, modifier_groups);
    }
    static setQuantityItem = (stateItem, modifierGroup, item, quantity, items, modifier_groups) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = StateItemHelper.getOrCreateStateModifierGroupItemOption(stateItem, modifierGroup.id, item, items, modifier_groups);
        modifierGroupItem.quantity = quantity;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;
        StateItemHelper.refreshStateModifierGroupItemOption(stateItem, modifierGroup.id, items, modifier_groups);
    }
    static setItemOption = (stateItem, modifierGroupId, itemId, quantity, items, modifier_groups, id) => {
        let stateModifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroupId);
        let selectedItem = stateModifierGroup.selected_items[id-1];
        let modifierGroupItem = StateItemHelper.create(itemId, items, modifier_groups, undefined, modifierGroupId, stateItem.item_id);
        modifierGroupItem.quantity = quantity;
        modifierGroupItem.total_price = modifierGroupItem.unit_total_price * modifierGroupItem.quantity;

        if(selectedItem === undefined){
            stateModifierGroup.selected_items.push(modifierGroupItem);
        }else{
            stateModifierGroup.selected_items[id-1] = modifierGroupItem;
        }
        StateItemHelper.refreshStateModifierGroupItemOption(stateItem, modifierGroupId, items, modifier_groups);
    }
    static refreshStateModifierGroupItemOption = (stateItem, modifierGroupId, items, modifier_groups) => {
        let stateModifierGroup = StateItemHelper.getOrCreateStateModifierGroup(stateItem, modifierGroupId);
        let modifierGroup = ItemHelper.getModifierGroup(modifier_groups, modifierGroupId);
        stateModifierGroup.selected_items = stateModifierGroup.selected_items.filter(selectedItem => selectedItem.quantity > 0);

        let item = ItemHelper.getItem(items, stateItem.item_id);
        StateItemHelper.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);

        if(stateModifierGroup.selected_items.length <= 0){
            stateItem.modifier_groups = stateItem.modifier_groups.filter(itemModifierGroup => itemModifierGroup.modifier_group_id !== stateModifierGroup.id);
        }
    }
}
export default StateItemHelper;