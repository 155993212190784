import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AgeRestrictedContext from "../../application/common/AgeRestrictedContext";

function AgeRestrictedAddItem(props) {
    const [loading, setLoading] = useState(false);
    const save = () => {
        AgeRestrictedContext.accept();
        props.onSave()
    }
    const hide = () => {
        props.onHide()
    }

    return(
        <React.Fragment>
            <div className={"p-4"}>
                <p>
                    Ten produkt może kupić tylko osoba pełnoletnia.
                    Czy potwierdzasz, że masz ukończone 18 lat?
                </p>
                <div>
                    <button type="button" className={"btn btn-secondary"  + (loading ? ' loading' : '')} data-dismiss="modal" onClick={()=>hide()}>
                        NIE MAM 18 LAT, REZYGNUJĘ
                    </button>{" "}
                    <button type="button" className={"btn btn-primary"  + (loading ? ' loading' : '')} onClick={()=>{save()}}>
                        TAK, MAM 18 LAT
                    </button>
                </div>
            </div>
            {/*<div className={"alert alert-warning"}>*/}
            {/*    <p>*/}
            {/*        Ten produkt może kupić tylko osoba pełnoletnia.*/}
            {/*        Czy potwierdzasz, że masz ukończone 18 lat?*/}
            {/*    </p>*/}
            {/*    {!modal && (*/}
            {/*        <button type="button" className={"btn btn-primary"  + (loading ? ' loading' : '')} onClick={() => setModal(true)}>*/}
            {/*            Zaakceptuj*/}
            {/*        </button>*/}
            {/*    )}*/}
            {/*</div>*/}
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {}
}
export default withTranslation()(connect(mapStateToProps)(AgeRestrictedAddItem))
