import React, { Component } from 'react';
import {connect} from "react-redux";
import {fetchPaymentMethodGateways} from "../../../../../application/menu/fetchMenu";
import LoadingContainer from "../../../../common/LoadingContainer";

class BluemediaBankTransferPaymentMethod extends Component{

    constructor(props) {
        super(props);
        this.state = {
            gateway: null,
            loading: true,
            gateways: []
        };
        this.setGateway = this.setGateway.bind(this);
    }

    componentDidMount(){
        let paymentMethod = this.props.paymentMethod;
        fetchPaymentMethodGateways(paymentMethod.id).then(res => {
            this.setState({
                loading: false,
                gateways: res.data
            });
        }).catch(res => {
            this.setState({
                loading: false
            });
        });
    }
    setGateway(gateway){
        this.setState({ gateway: gateway });
        this.props.updatePaymentSettings({gateway_id: gateway.id});
    }
    render(){

        if(this.state.loading){
            return (
                <div>
                    <LoadingContainer>Trwa pobieranie metod płatności ...</LoadingContainer>
                </div>
            )
        }
        return(
            <div className="banks row">
                {
                    this.state.gateways.map((gateway, i) =>

                        <div key={i} className={"bank-item" + (this.state.gateway === gateway ? ' active' : '')} onClick={() => this.setGateway(gateway)}><img src={gateway.icon_link} /></div>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(BluemediaBankTransferPaymentMethod)