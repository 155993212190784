import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import StateItemHelper from "../MenuShowItem/StateItemHelper";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import ItemHelper from "../../../../../application/common/ItemHelper";
import {
    getAvailabilities,
    getItems,
    getMenuPending,
    getModifierGroups,
    getPromotions
} from "../../../../../application/menu/reducer";
import {getCart, getPickDate} from "../../../../../application/cart/reducer";
import {getLocation} from "../../../../../application/location/reducer";
import MenuModifierGroupsPickOneV2 from "./components/MenuModifierGroupsPickOneV2";
import MenuEditItemViewHelper from "../MenuShowItem/MenuEditItemViewHelper";
import MenuEditItemVariantV3 from "./MenuEditItemVariantV3";
import MenuShowItemGroupV3 from "./components/MenuShowItemGroupV3";
import MenuShowItemModalV3 from "./components/MenuShowItemModalV3";
import LoadingContainer from "../../../../common/LoadingContainer";
import {addItem} from "../../../../../application/cart/cartService";
import MenuItemBaseModal from "./components/MenuItemBaseModal";

const MenuShowItemSingleV3 = (props) => {
    const [errors, setErrors] = useState([]);
    const [stateItem, setStateItem] = useState(props.stateItem ? JSON.parse(JSON.stringify(props.stateItem)) : StateItemHelper.create(props.itemId, props.items, props.modifier_groups, undefined));
    const [page, setPage] = useState(undefined);
    const [variant, setVariant] = useState(ItemHelper.getItem(props.items, props.itemId));

    useEffect(() => {
        StateItemHelper.refreshItemPrice(stateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        let copyItem = Object.assign({}, stateItem);
        setStateItem(copyItem);
    }, []);
    
    if (props.menuPending) {
        return (
            <LoadingContainer/>
        )
    }

    let itemGroup = props.itemGroup;
    let itemId = stateItem.item_id;

    let data = {
        items: props.items,
        modifier_groups: props.modifier_groups,
        availabilities: props.availabilities,
        pick_date: props.pick_date
    };
    let itemView = itemId ? MenuEditItemViewHelper.getItemViewObj(itemId, stateItem, data) : undefined;
    // let itemView = MenuEditItemViewHelper.getItemView(props.items, props.modifier_groups, props.availabilities, itemViewId, null, null, 1, props.pick_date, stateItem);
    const update = (newStateItem) => {
        StateItemHelper.refreshItemPrice(newStateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        let copyItem = Object.assign({}, newStateItem);
        setStateItem(copyItem);
        if(page) changePage(undefined)
    }

    const changePage = (name, data, render) => {
        if (!name) {
            setPage(undefined);
            return;
        }
        switch (name) {
            case "PICK_ONE":
                setPage({name: name, data: data, render: render})
                break;
            default:
                setPage({name: name, data: data, render: render})
                break;
        }
    }
    const isModal = () => {
        return true;
    }
    const renderPage = () => {
        if (page === undefined) return null;
        let pageData = page.data;
        let image = pageData.image;
        switch (page.name) {
            case "PICK_ONE":
                let modifierGroup = pageData.modifier_group;
                if(isModal()){
                    return (
                        <MenuItemBaseModal image={image}>
                            <MenuModifierGroupsPickOneV2 modal={true} modifierGroupId={modifierGroup.id} id={pageData.id}
                                                         stateItem={stateItem} onUpdate={update} onChangePage={changePage}/>
                        </MenuItemBaseModal>
                    )
                }
                return (
                    <>
                        <MenuModifierGroupsPickOneV2 modifierGroupId={modifierGroup.id} id={pageData.id}
                                                     stateItem={stateItem} onUpdate={update} onChangePage={changePage}/>
                    </>
                )
            case "MODAL_EDIT":
                return (
                    <>
                        <MenuItemBaseModal image={image}>
                            {page.render()}
                        </MenuItemBaseModal>
                    </>
                );
                break;
            default:
                return page.render();
        }
        return null;
    }
    const addItemInternal = (menuId, newItem) => {
        if(props.parent){
            props.onUpdate(newItem, menuId);
            return ;
        }
        setErrors([]);
        let orderData = {
            address: props.location.address,
            contact: props.cart.contact,
            type: props.cart.type,
            estimated_preparation_at: props.cart.estimated_preparation_at,
            pickup_at: props.cart.pickup_at,
            number: props.cart.number,
            locale: props.locale
        }
        props.dispatch(addItem(newItem, orderData)).then(response => {
            // props.addItem(menuId, newItem);
            props.onUpdate(newItem, menuId);
        }).catch(resError => {
            setErrors(resError.errors);
        });
    }
    const renderItemGroup = () => {
        if (!itemGroup) return null;
        return (
            <MenuShowItemModalV3 title={itemGroup.translation ? itemGroup.translation.name : itemGroup.name}
                                 onHide={props.onHide}>
                <MenuEditItemVariantV3 parent={props.parent} onChangePage={changePage} changedQuantityVisibility={true}
                                       errors={errors} id={itemId} menuId={props.menu ? props.menu.id : undefined}
                                       addItem={addItemInternal} onUpdate={update} stateItem={stateItem}>
                    {itemGroup && (
                        <MenuShowItemGroupV3 onUpdate={update} stateItem={stateItem} itemGroup={itemGroup}/>
                    )}
                </MenuEditItemVariantV3>
            </MenuShowItemModalV3>
        )
    }
    const renderItem = () => {
        return (
            <MenuShowItemModalV3 title={variant.translation.name} onHide={props.onHide} back={props.parent}>
                <MenuEditItemVariantV3 parent={props.parent} onChangePage={changePage} changedQuantityVisibility={true}
                                       errors={errors} id={itemId} menuId={props.menu ? props.menu.id : undefined}
                                       addItem={addItemInternal} onUpdate={update} stateItem={stateItem}/>
            </MenuShowItemModalV3>
        )
    }
    if(isModal()){
        return (
            <>
                <>
                    {itemGroup ? (
                        <>{renderItemGroup()}</>
                    ) : (
                        <>{renderItem()}</>
                    )}
                </>
                {page !== undefined && (
                    <>
                        {renderPage()}
                    </>
                )}
            </>
        )
    }
    return (
        <>
            {page === undefined ? (
                <>
                    {itemGroup ? (
                        <>{renderItemGroup()}</>
                    ) : (
                        <>{renderItem()}</>
                    )}
                </>
            ) : (
                <>
                    {renderPage()}
                </>
            )}
        </>
    )

}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        promotions: getPromotions(state.menu),
        availabilities: getAvailabilities(state.menu),
        pick_date: getPickDate(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        menuPending: getMenuPending(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuShowItemSingleV3))