
class MenuEditItemStateHelper {

    static getStateModifierGroup = (modifierGroups, modifierGroupId) => {
        if(!modifierGroups) return undefined;
        return modifierGroups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
    }
    static getStateModifierGroupSelectedItem = (modifierGroup, item_id) => {
        if(!modifierGroup){
            return null;
        }
        return modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item_id)[0];
    }
    static getStateModifierGroupSelectedItems = (modifierGroup, item_id) => {
        if(!modifierGroup){
            return [];
        }
        return modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item_id);
    }
}
export default MenuEditItemStateHelper;