import React from 'react';
import {useTranslation} from "react-i18next";

const ErrorView = (value)=>{
    const { t } = useTranslation();
    if(value.message){
        return(
            <div className="container text-center">
                {value.message}
            </div>
        )
    }
    return(
        <div className="container text-center">
            {t("errors.loading_data_error")}<br />
            {value.children}
        </div>
    )
}

export default ErrorView;
