import React, { Component } from 'react';
import {ScrollToTop} from "../../utils/ScrollToTop";
import CartSticky from "../cart/CartSticky";
import StoreInfo from "./components/StoreInfo";
import Footer from "../../layout/Footer";
import CartButton from "../../common/CartButton";
import {getStore} from "../../../application/store/reducer";
import {connect} from "react-redux";
import {getOrganization, getPaymentMethods} from "../../../application/menu/reducer";
import {getAddressName} from "../../../application/common/StringHelper";
import classNames from "classnames";
import AppConfig from "../../../application/common/AppConfig";
import {withTranslation} from "react-i18next";
import Rules2020 from "./components/Rules/Rules2020";
import Rules2021 from "./components/Rules/Rules2021";

class Rules extends Component{

    render(){
        const {t} = this.props;
        let props = this.props;
        let isDefaultRules = false;
        if(props.store && props.store.links && props.store.links.length > 0){
            if(props.store.links.some(link => link.link === 'rules')){
                isDefaultRules = true;
            }
        }
        if(!isDefaultRules){
            return t("errors.internal.page_not_exist");
        }

        return (
            <React.Fragment>
                <ScrollToTop/>
                <div className="menu-page">
                    <CartSticky goToCart={this.goToCart}></CartSticky>
                    <StoreInfo/>

                    <div className={classNames('page body-container', {
                        'body-container-cart': AppConfig.isCart(),
                    })}>
                        <div className="container">
                            <Rules2021 paymentMethods={props.paymentMethods} organization={props.organization}/>
                        </div>
                        <Footer/>
                    </div>
                    <div className="btn-submit-bottom fixed-bottom btn-submit-container">
                        <CartButton goToCart={this.goToCart} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => ({
    store: getStore(state.store),
    organization: getOrganization(state.menu),
    paymentMethods: getPaymentMethods(state.menu)
})
export default withTranslation()(connect(mapStateToProps)(Rules))
