import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import UserService from "../../../application/user/userService";
import LoadingContainer from "../LoadingContainer";
import {getUser, getVouchers} from "../../../application/user/reducer";
import Vouchers from "../../routes/base/components/Voucher/Vouchers";
import {addVoucherToOrder} from "../../../application/cart/cartService";
import {getCart, getCartPromotions} from "../../../application/cart/reducer";
import {getLocation} from "../../../application/location/reducer";
import {getLocale} from "../../../application/store/reducer";
import Utils from "../../utils/Utils";

function UserProductsModal(props) {
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const t = props.t;
    const dispatch = useDispatch();

    const getProducts = async () => {
        let data = await UserService.getProducts("GOCRM");
        setProducts(data);
        setLoading(false)
        // return data;
    }
    const buy = async (product) => {
        try {
            let response = await UserService.buyVoucher(product.provider, product.id);
            // let data = await UserService.buyVoucher(product.provider, product.id);
            await props.dispatch(UserService.syncVouchers());

        }catch (e) {
            if(e.status === 403 || e.status === 404){
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            }else if(e.status == 422){
                setErrors(e.errors);
            }else{
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Nie udało się zalogować"
                    }
                ])
            }
        }

    }
    const onShow = () => {
        setLoading(true)
        getProducts()
        // setProducts(getProducts());
    }
    const save = () => {

        // UserService.login("GOCRM", email, password)
        // let data = {
        //     number: roomNumber,
        //     type: "ROOM_SERVICE"
        // }
        // dispatch(updateOrderData(data, props.cart)).then((newData) => {
        //     // resolve();
        //     props.onSave();
        // }).catch(error => {
        //     setErrors(error.errors);
        //     setLoading(false);
        // });
    }

    const useVoucher = async (voucher) => {
        let orderData = {
            address: props.location.address,
            contact: props.cart.contact,
            type: props.cart.type,
            estimated_preparation_at: props.cart.estimated_preparation_at,
            pickup_at: props.cart.pickup_at,
            number: props.cart.number,
            locale: props.locale
        }
        try {

            await props.dispatch(addVoucherToOrder(voucher.code, orderData));
        } catch (e){

        }
    }

    return(
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("components.vouchers.your_points")}: {props.user.points}
                        </Modal.Title>
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {loading ? (
                            <LoadingContainer/>
                        ) : (
                        <>
                            <Vouchers vouchers={props.vouchers} useVoucher={useVoucher} orderPromotions={props.orderPromotions}/>
                            {(products && products.length > 0) && (
                                <section className="vouchers">
                                    <h4>
                                        {t("components.vouchers.coupons")}
                                    </h4>
                                    <div className="voucher-items">
                                        {loading ? (
                                            <LoadingContainer/>
                                        ) : (
                                            <>
                                                {products.map(product => {
                                                    return (
                                                        <div className="voucher-item" key={product.id} onClick={() => buy(product)}>
                                                            <div className="card">
                                                                <span className={"badge bg-success"}>{t("components.vouchers.item_points", {"points": product.cost})}</span>
                                                                <img src={product.image} className="card-img-top" alt="..."/>
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{product.name}</h5>
                                                                    {product.description && (
                                                                        <p className="card-text" dangerouslySetInnerHTML={{__html: Utils.nl2brString(product.description)}}></p>
                                                                    )}
                                                                    {/*<a href="#" className="btn btn-transparent stretched-link w-100 text-left">Kup</a>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // <button key={product.id} className="card" onClick={() => buy(product)}>
                                                        //     <div className="card-img"><img
                                                        //         src="http://app.goorder.local/images/1/product/small/fb60acd9-a4a2-4266-ab4f-228ff32c3c19.png" />
                                                        //     </div>
                                                        //     <div className="card-body"><h5 className="card-title">Sos pomidorowy</h5><p
                                                        //         className="card-text">{product.name}</p>
                                                        //         <span className="price">{product.cost} punktów</span></div>
                                                        // </button>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </div>
                                </section>
                            )}
                        </>
                    )}

                    </div>

                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {
        vouchers: getVouchers(state.user),
        orderPromotions: getCartPromotions(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        locale: getLocale(state.store),
        user: getUser(state.user)
    }
}
export default withTranslation()(connect(mapStateToProps)(UserProductsModal))
