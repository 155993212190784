import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../../../application/user/reducer";
import ErrorFormAlertView from "../../../common/ErrorFormAlertView";
import ErrorFormHelper from "../../../common/ErrorFormHelper";
import ErrorFormView from "../../../common/ErrorFormView";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import UserService from "../../../../application/user/userService";
import {useHistory} from "react-router-dom";


const UserResetPasswordPage = (props) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();

    const queryParams = new URLSearchParams(history.location.search);
    const getQueryParam = (param) => {
        const value = queryParams.get(param);
        return value !== null ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');

    const changePassword = async () => {
        setLoading(true);
        try {
            let response = await UserService.changePassword(email, code, password, confirmPassword)
            setLoading(false);
            setSuccess(true);
        } catch (e) {
            setErrors([{
                message: "Nie udało się zmienić hasła, niepoprawne dane."
            }]);
            setLoading(false);
        }
    }
    if (!code || !email) {
        return (
            <>
                <div className="alert alert-danger" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        Niepoprawny link do resetowania hasła
                    </small>
                </div>
            </>
        )
    }

    return (

        <>
            <div className={"login-box"}>
                <>
                    <div>
                        <h4>
                            Resetowanie hasło - ustaw nowe hasło
                        </h4>
                    </div>
                    <div className="alert alert-light" role="alert">
                        <small>
                            <InfoSVG className={"icon me-2"}/>
                            Aby zresetować hasło dla konta powiązanego z adresem e-mail <strong>{email}</strong>, wprowadź nowe hasło
                            poniżej.
                            Jeśli to nie Ty zainicjowałeś(-aś) proces resetowania hasła, nie wprowadzaj żadnych zmian i
                            upewnij się, że Twoje konto jest bezpieczne.
                        </small>
                    </div>
                    <>
                        <ErrorFormAlertView errors={errors}/>

                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                                   className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Hasło"
                                   aria-label="Recipient's username" aria-describedby="cart-password"/>
                            <label htmlFor="cart-password">Hasło</label>
                            <ErrorFormView errors={errors} show_message={true} field="password"/>
                        </div>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)}
                                   value={confirmPassword}
                                   className={"form-control" + ErrorFormHelper(errors, "confirm_password")}
                                   placeholder="Potwierdz hasło" aria-label="Recipient's username"
                                   aria-describedby="cart-confirm-password"/>
                            <label htmlFor="cart-confirm-password">Potwierdz hasło</label>
                            <ErrorFormView errors={errors} show_message={true} field="confirm_password"/>
                        </div>
                        <button
                            className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            variant="primary" onClick={() => {
                            changePassword()
                        }}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Ustaw hasło
                        </button>
                    </>
                </>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserResetPasswordPage))
