import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Money from "../../../../../common/Money";

const MenuItemOptionBasic = (props) => {
    const {t} = props;
    let optionItem = props.optionItem;
    let optionStateItem = props.stateItem;
    let modifierGroupId = props.modifierGroupId;
    let optionItemView = props.optionItemView;
    let price = optionItemView.price;

    let onClickItem = (e) => {
        if (optionStateItem) {
            props.onUpdate(modifierGroupId, optionItemView, 0);
        } else {
            props.onUpdate(modifierGroupId, optionItemView, 1);
        }
    }
    let id = `modifierGroupItemId-${modifierGroupId}-${optionItemView.id}`;
    let checked = optionStateItem ? true : false;
    return (
        <div className="product-modifier-option-pick" key={id}>
            <input className={"btn-check"} type="radio" checked={checked} onChange={onClickItem}
                   name={'modifierGroup[' + modifierGroupId + '].options'} id={id} value={optionItemView.id}/>
            <label className="input-single" htmlFor={id}>
                {optionItemView.name}
                {price ? (
                    <span className="product-modifier-option-price ml-auto price"><Money
                        value={price}
                        currency='PLN'/></span>
                ) : null}
            </label>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {}
}

export default withTranslation()(connect(mapStateToProps)(MenuItemOptionBasic))