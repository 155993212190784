import React, {useEffect, useState} from 'react';
import IframeWidget from "./IframeWidget";
import AppConfig from "../../application/common/AppConfig";

const ScrollMenuTo = (value)=>{

    let sectionMargin = 30;
    if(AppConfig.isWidget()){
        sectionMargin = 90;
    }
    function scrollTo(elementId, e) {
        e.preventDefault();
        let element = document.getElementById(elementId);
        if(element !== null){
            let scrollToValue = element.offsetTop - sectionMargin;
            if(AppConfig.isWidget()){
                IframeWidget.scrollTo(scrollToValue);
            }else{
                window.scrollTo(0, scrollToValue);
            }
        }
    }
    return(
        <a href="#" className="nav-link" onClick={(e) => scrollTo(value.to, e)}>
            {value.children}
        </a>
    )
}
export default ScrollMenuTo;

