import React, { Component } from 'react';
import {connect} from "react-redux";
import Money from "../../../common/Money";
import {withTranslation} from "react-i18next";
import PromotionHelper from "../../../../application/common/PromotionHelper";
import Utils from "../../../utils/Utils";

const MenuProductListItem = (props)=>{
    const {product, menuId, categoryId, preview, t} = props;
    let item = product;
    let itemName = item.name;
    if(product.type && product.type ==="ITEM_GROUP"){
        item = product.item;
        itemName = product.name;
    }

    const addItem = (itemId, categoryId, menuId, e) => {
        e.stopPropagation();
        e.preventDefault();
        props.addItem(itemId, categoryId, menuId);
    }

    const renderDescription = (item) => {
        if(item.description !== undefined && item.description){
            return item.description;
        }
        if(!item.selected_items || item.selected_items.length <= 0){
            return null;
        }
        let descriptions = [];
        item.selected_items.forEach(function (selectedItem) {
            descriptions.push(selectedItem.quantity > 1 ? selectedItem.quantity + "x" + selectedItem.translation.name: selectedItem.translation.name);
        });
        return descriptions.join(", ");
    }
    const renderTags = (item) => {
        let promotions = PromotionHelper.getForItem(props.promotions, item.id);
        if(promotions === undefined || promotions.length <=0) return null;
        return (
            <div className={"tags"}>
                {promotions.map(promotion => {
                    return (
                        <span key={promotion.id} className="badge bg-success">{promotion.name}</span>
                    )
                })}
            </div>
        )
    }
    let productImgView = null;
    if(item.image_link){
        productImgView = (
            <div className="card-img">
                <img src={item.image_link.small} />
            </div>
        )
    }
    return(
        <a href="#" className="menu-item" onClick={(e) => addItem(item.id, categoryId, menuId, e)}>
            <div className={"card" + (item.disabled ? ' disabled' : '')}>
                {productImgView}
                <div className="card-body">
                    <h5 className="card-title">{itemName}</h5>
                    <p className="card-text" dangerouslySetInnerHTML={{__html: Utils.nl2brString(renderDescription(item))}}></p>
                    {renderTags(item)}
                    {item.discount_price !== undefined ? (
                                 <div className={"prices price-bottom"}>
                                     <span className="price price-discount">
                                         <Money
                                             value={item.price}
                                             currency='PLN' />
                                     </span>
                                     <span className="price">
                                         <Money
                                             value={item.discount_price}
                                             currency='PLN' />
                                     </span>
                                 </div>
                    ) : (item.price && item.price > 0) ? (
                        <span className="price price-bottom">
                            <Money
                                value={item.price}
                                currency='PLN' />
                        </span>
                    ) : null}
                </div>
            </div>
        </a>
    )
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuProductListItem))