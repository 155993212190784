import React, { Component } from 'react';
import {connect} from "react-redux";

class PaylaneBankTransferPaymentMethod extends Component{

    banks = [
        'AS',
        'AB',
        'BLIK',
        'BS',
        'CA',
        'CT',
        'GB',
        'IG',
        'IN',
        'IP',
        'MI',
        'MT',
        // 'Paypal',
        'PB',
        'PCZ',
        'PL',
        'PO',
        'WB',
    ];
    constructor(props) {
        super(props);
        this.state = {
            bank: null
        };
        this.setBank = this.setBank.bind(this);
    }

    componentDidMount(){

    }
    setBank(bank){
        this.setState({ bank: bank });
        this.props.updatePaymentSettings({'gateway_id': bank});
    }
    render(){

        return(
            <div className="banks row">
                {
                    this.banks.map((bank, i) =>

                        <div key={i} className={"bank-item" + (this.state.bank === bank ? ' active' : '')} onClick={() => this.setBank(bank)}><img src={"/images/banks/" + bank + ".png"} /></div>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(PaylaneBankTransferPaymentMethod)