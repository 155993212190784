import React, {Component} from 'react';
import {connect} from "react-redux";
import Money from "../../../../common/Money";
import {withTranslation} from "react-i18next";

class MenuProductListItem extends Component{
    state = {
        quantity: 1
    };
    constructor(props) {
        super(props);
        this.subQuantityItem = this.subQuantityItem.bind(this);
        this.addQuantityItem = this.addQuantityItem.bind(this);
    }
    handleChangeCheckbox = (modifierGroup, item, event)=> {
        let newQuantity = item.quantity === 1 ? 0 : 1;
        this.props.setQuantityItem(modifierGroup, item, newQuantity);
    }
    handleChangeRadio = (modifierGroup, item, event)=>{
        this.props.setQuantityItem(modifierGroup, item, 1);
    }
    subQuantityItem = (modifierGroup, item) => {
        let newQuantity = item.quantity - 1;
        this.props.setQuantityItem(modifierGroup, item, newQuantity);
    }
    addQuantityItem = (modifierGroup, item) => {
        let newQuantity = item.quantity + 1;
        this.props.setQuantityItem(modifierGroup, item, newQuantity);
    }
    setQuantity(item, quantity){
        let newQuantity = quantity;
        if(newQuantity <=0){
            newQuantity = 1;
        }
        item.quantity = newQuantity;
    }

    getItem(items, id){
        return items.filter(item => item.id === id)[0];
    }
    getModifierGroup(modiferGroups, id){
        return modiferGroups.filter(modifierGroup => modifierGroup.id === id)[0];
    }
    getModifierGroupItem(itemModiferGroups, modifigerGroupId, itemId){
        let modifierGroup = itemModiferGroups.filter(itemModiferGroup => itemModiferGroup.modifier_group_id === modifigerGroupId)[0];
        if(modifierGroup === undefined){
            return null;
        }
        let modifierGroupItem = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === itemId)[0];
        if(modifierGroupItem === undefined){
            return null;
        }
        return modifierGroupItem;
    }
    renderInput(item, modifierGroup, optionItem){
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? null : modifierGroup.max_permitted;
      
        let priceView = null;
        let inputView = null;
        let labelName = null;
        let quantityFreeView = null;
        if(optionItem.price){
            if(optionItem.quantity_free){
                quantityFreeView = (
                    <small>
                        <br />
                        &nbsp;({optionItem.quantity_free} darmowe)
                    </small>
                )
            }
            priceView =(
                <span className="product-modifier-option-price ml-auto price">
                <Money
                    value={optionItem.price}
                    currency='PLN' />
                    {quantityFreeView}
            </span>
            )
        }
        if(min_permitted === 1 && max_permitted === 1){
            let checked = optionItem.quantity == 1 ? true : false;
            inputView =  (
                <label className="input-single" htmlFor={`modifierGroupItemId-${modifierGroup.id}-${optionItem.id}`}>
                    <input type="radio" checked={checked} onChange={(e) => this.handleChangeRadio(modifierGroup, optionItem, e)} name={'modifierGroup[' + modifierGroup.id + '].options'} id={`modifierGroupItemId-${modifierGroup.id}-${optionItem.id}`} value={optionItem.id} />
                    {optionItem.name}
                </label>
            )
        }else if(
            (max_permitted === null || max_permitted > 0)){
            let disableAdd = false;
            if(modifierGroup.max_permitted !== null && modifierGroup.max_permitted <= modifierGroup.quantity){
                disableAdd = true;
            }
            let modifierGroupItem = this.getModifierGroupItem(item.modifier_groups, modifierGroup.id, optionItem.id);
            let quantity = optionItem.quantity;
            let quantityInfoItemMinPermitted = 0;
            let quantityInfoItemMaxPermitted = 0;
            if(modifierGroupItem !== null){
                quantity = modifierGroupItem.quantity;
            }
            quantityInfoItemMinPermitted = optionItem.min_permitted === undefined ? 0 : optionItem.min_permitted;
            quantityInfoItemMaxPermitted = optionItem.max_permitted === undefined ? null : optionItem.max_permitted;

            if(quantityInfoItemMaxPermitted == 0){
                return null;
            }else if(quantityInfoItemMinPermitted === 0 && quantityInfoItemMaxPermitted ===1){
                inputView = (
                    <label className="input-single">
                        <input type="checkbox" disabled={disableAdd && optionItem.quantity !== 1} checked={optionItem.quantity === 1} onChange={(e) => this.handleChangeCheckbox(modifierGroup, optionItem, e)} name={'modifierGroup[' + modifierGroup.id + '].options'} id={'modifierGroupItemId-' + optionItem.id} value={optionItem.id} />
                        {optionItem.name}
                    </label>
                );
            }else{
                inputView = (
                    <div className="input-group input-group-quantity">
                        {quantity > 0
                            ?
                            <div className="input-group-prepend">
                                <button type="button" className="btn btn-secondary" id="basic-addon1" onClick={(e) => this.subQuantityItem(modifierGroup, optionItem, e)}>-</button>
                            </div>
                            : null
                        }
                        {quantity > 0
                            ?
                            <span className="input-group-text">{quantity}</span>
                            : null
                        }
                        <div className="input-group-append">
                            <button disabled={disableAdd} type="button" className="btn btn-secondary" id="basic-addon1" onClick={(e) => this.addQuantityItem(modifierGroup, optionItem, e)}>+</button>
                        </div>
                    </div>
                );
                labelName = (
                    <span>
                        {optionItem.name}
                    </span>
                )
            }
        }
        let errorView = null;
        if(optionItem.errors !== undefined){
            errorView = (
                <React.Fragment>
                    {
                        optionItem.errors.map((error) =>
                            <p className="text-danger" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="product-modifier-option" key={modifierGroup.id + "-" + optionItem.id}>
                    {inputView}
                    {labelName}
                    {priceView}
                </div>
                {errorView}
            </React.Fragment>
        )
    }
    getRenderHelpQuantityInfo(modifierGroup){
        const { t } = this.props;
        let additionalInfos = [];
        if(modifierGroup.min_permitted !== null && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== null && modifierGroup.max_permitted === 1){

        }else{
            if(modifierGroup.min_permitted !== null && modifierGroup.min_permitted > 0){
                additionalInfos.push(t("components.menu.min_permitted") + ": " + modifierGroup.min_permitted);
            }
            if(modifierGroup.max_permitted !== null){
                additionalInfos.push(t("components.menu.max_permitted") + ": " + modifierGroup.max_permitted);
            }
            if(modifierGroup.quantity !== null){
                additionalInfos.push(t("components.menu.default_quantity") + ": " + modifierGroup.quantity);
            }
            if(modifierGroup.charge_above !== null){
                additionalInfos.push(t("components.menu.charge_above") + ": " + modifierGroup.charge_above);
            }
        }
        let errorView = null;
        if(modifierGroup.errors !== undefined){
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error) =>
                            <p className="text-danger" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if(additionalInfos.length !== 0){
            additionalInfoView = (
                <React.Fragment>
                    <ul className="list-inline">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={additionalInfo}>{additionalInfo}</li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    render(){
        let modifierGroup = this.props.modifierGroup;
        let item = this.props.item;
        if(modifierGroup.max_permitted !== null && modifierGroup.max_permitted <=0){
            return null;
        }
        return(
            <fieldset className="form-group">
                <legend>
                    {modifierGroup.name} {this.props.quantity}
                </legend>
                {this.getRenderHelpQuantityInfo(modifierGroup)}

                {
                    modifierGroup.options.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem) =>
                        <div key={modifierGroup.id + "-" + optionItem.id}>
                            {this.renderInput(item, modifierGroup, optionItem)}
                        </div>
                    )
                }

            </fieldset>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuProductListItem))