import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AgeRestrictedContext from "../../application/common/AgeRestrictedContext";

function AgeRestrictedModal(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(true);
    const t = props.t;

    const onShow = () => {
    }

    const save = () => {
        AgeRestrictedContext.accept();
        props.onSave()
    }
    const hide = () => {
        setModal(false);
    }

    return(
        <React.Fragment>
            <div className={"alert alert-warning"}>
                <p>
                    Strona zawiera materiały informacyjne oraz reklamowe dotyczące napojów alkoholowych.
                    Treści są przeznaczone wyłącznie dla osób pełnoletnich.
                </p>
                {!modal && (
                    <button type="button" className={"btn btn-primary"  + (loading ? ' loading' : '')} onClick={() => setModal(true)}>
                        Zaakceptuj
                    </button>
                )}
            </div>
            <Modal dialogClassName="modal-full" show={modal} onHide={hide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            Potwierdź pełnoletność
                        </Modal.Title>
                        <button onClick={hide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        Strona zawiera materiały informacyjne oraz reklamowe dotyczące napojów alkoholowych.
                        Treści są przeznaczone wyłącznie dla osób pełnoletnich.

                        Czy masz ukończone 18 lat?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        <button type="button" className={"btn btn-secondary"  + (loading ? ' loading' : '')} data-dismiss="modal" onClick={()=>hide()}>
                            Nie
                        </button>
                        <button type="button" className={"btn btn-primary"  + (loading ? ' loading' : '')} onClick={()=>{save()}}>
                            Tak
                        </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {}
}
export default withTranslation()(connect(mapStateToProps)(AgeRestrictedModal))
