import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../application/user/reducer";
import LoginUserModal from "../common/user/LoginUserModal";
import {ReactComponent as UserIco} from "../../images/icons/user.svg";
import {getLoginProviders, isPreview} from "../../application/store/reducer";
import {Link} from "react-router-dom";

function NavbarUser(props) {
    const {t, user, loginProviders} = props;
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (!props.preview) {
            // login();         //@TODO: REMOVE!! MOCK!!
        }
    }, [])
    if (!loginProviders || loginProviders.length <= 0) return null;

    const showModal = () => {
        setModal(true)
    }

    return (
        <>
            {(user && user.name) ? (
                <Link to={"/user"} className={"btn btn-transparent"}>
                    <UserIco/>
                </Link>
            ) : (
                <>
                    <button className={"btn btn-transparent"} onClick={() => showModal()}>
                        <UserIco/>
                    </button>
                </>
            )}
            {modal && (
                <LoginUserModal onHide={() => setModal(false)} show={true}/>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        preview: isPreview(state.store),
        loginProviders: getLoginProviders(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarUser))
