import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import MenuEditItem from "./MenuShowItem/MenuEditItem";
import {addItem} from "../../../../application/cart/cartService";
import PromotionHelper from "../../../../application/common/PromotionHelper";
import {getItems, getModifierGroups, getPromotions} from "../../../../application/menu/reducer";
import StateItemHelper from "./MenuShowItem/StateItemHelper";
import ItemHelper from "../../../../application/common/ItemHelper";
import {unstable_batchedUpdates} from "react-dom";
import {getCart} from "../../../../application/cart/reducer";
import {getLocation} from "../../../../application/location/reducer";
import IframeWidget from "../../../utils/IframeWidget";
import {Modal} from "react-bootstrap";
import Money from "../../../common/Money";
import AgeRestrictedAddItem from "../../../common/AgeRestrictedAddItem";
import AgeRestrictedContext from "../../../../application/common/AgeRestrictedContext";

const MenuShowItemSingle = (props) => {
    const [errors, setErrors] = useState([]);
    const [stateItem, setStateItem] = useState(StateItemHelper.create(props.item.id, props.items, props.modifier_groups, undefined));
    const [stateSubItem, setStateSubItem] = useState(undefined);
    const [variant, setVariant] = useState(props.item);
    const [showAgeRestricted, setShowAgeRestricted] = useState(variant.alcohol || variant.energy_drink);
    let itemGroup = props.itemGroup;

    useEffect(() => {
        let ageRestrictedAccepted = AgeRestrictedContext.isAccepted();
        if (showAgeRestricted && ageRestrictedAccepted) {
            setShowAgeRestricted(false)
        }
    }, [])
    const update = (newStateItem) => {
        setStateItem(newStateItem);
    }
    const editSubItem = (item, modifierGroupId) => {
        if (item.state) {
            setStateSubItem(item.state);
            return;
        }
        let stateItem = StateItemHelper.create(item.id, props.items, props.modifier_groups, item.state);
        StateItemHelper.refreshItemPrice(stateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        setStateSubItem(stateItem);
    }
    const updateSubItem = (newStateItem) => {
        setStateSubItem(newStateItem);
    }
    const addSubItem = (menuId, newStateItem) => {
        setStateSubItem(newStateItem);
        let modifierGroupId = newStateItem.parent_modifier_group_id;
        let copyItem = Object.assign({}, stateItem);
        copyItem.modifier_groups.forEach(mg => {
            if (mg.modifier_group_id !== modifierGroupId) return;
            mg.selected_items = mg.selected_items.filter(x => x.item_id !== newStateItem.item_id);
            mg.selected_items.push(newStateItem);
        });
        StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        setStateItem(copyItem);
        setStateSubItem(undefined);
    }

    const addItemInternal = (menuId, newItem) => {
        setErrors([]);
        let orderData = {
            address: props.location.address,
            contact: props.cart.contact,
            type: props.cart.type,
            estimated_preparation_at: props.cart.estimated_preparation_at,
            pickup_at: props.cart.pickup_at,
            number: props.cart.number,
            locale: props.locale
        }
        props.dispatch(addItem(newItem, orderData)).then(response => {
            props.addItem(menuId, newItem);
        }).catch(resError => {
            setErrors(resError.errors);
        });
    }

    const handleChangeRadio = (itemId, e) => {
        let newItem = ItemHelper.getItem(props.items, itemId)
        let newCurrentStateItem = StateItemHelper.create(itemId, props.items, props.modifier_groups);
        if (stateItem) {
            newCurrentStateItem.quantity = stateItem.quantity;
        }
        StateItemHelper.refreshItemPrice(newCurrentStateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        unstable_batchedUpdates(() => {
            setStateItem(newCurrentStateItem)
            setVariant(newItem);
        });

    }
    const hideModal = () => {
        setStateSubItem(undefined);
    }
    const renderModal = () => {
        return (
            <Modal dialogClassName="modal-full modal-product" show={true} onHide={hideModal}
                   onShow={IframeWidget.openModal}>
                {/*{productImgView}*/}
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {props.item.name} > {stateSubItem.name}
                            {/*{itemGroup ? itemGroup.name : item.translation.name}*/}
                            {/*{item.translation.name}*/}
                        </Modal.Title>
                        <button onClick={hideModal} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <MenuEditItem errors={errors} id={stateSubItem.item_id} menuId={props.menu.id} addItem={addSubItem}
                                  onUpdate={updateSubItem} stateItem={stateSubItem}/>
                </Modal.Body>
            </Modal>
        )
    }
    if (showAgeRestricted) {
        return (
            <>
                <AgeRestrictedAddItem onHide={props.onHide} onSave={() => setShowAgeRestricted(false)}/>
            </>
        )
    }
    return (
        <>
            {stateSubItem ? (
                <>
                    {renderModal()}
                </>
            ) : null}
            <MenuEditItem changedQuantityVisibility={true} errors={errors} id={variant ? variant.id : props.item.id}
                          menuId={props.menu.id} addItem={addItemInternal} onUpdate={update} stateItem={stateItem}
                          onEditSubItem={editSubItem}>
                {itemGroup ? (
                    <div className="product-item-variant product-item-modifiers">
                        <fieldset className="form-group">
                            <legend>
                                Wybierz wariant
                            </legend>
                            {
                                itemGroup.items.sort((a, b) => a.position - b.position || (a.name && b.name && a.name.localeCompare(b.name))).map((itemGroupItem) => {
                                    let item = ItemHelper.getItem(props.items, itemGroupItem.item_id)
                                    if (!item) return null;
                                    let checked = variant.id === itemGroupItem.item_id;
                                    let itemPrice = (item.price) ? item.price.amount : 0;
                                    // let itemPrice = (item.min_possible_price && item.min_possible_price > 0) ? item.min_possible_price : item.full_price;
                                    // console.log(itemGroup, item, itemPrice);

                                    return (
                                        <div className={"product-modifier-option"}
                                             key={`${itemGroup.reference_id}-${itemGroupItem.item_id}`}>

                                            <label className="input-single"
                                                   htmlFor={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}>
                                                <input type="radio" checked={checked}
                                                       onChange={(e) => handleChangeRadio(itemGroupItem.item_id, e)}
                                                       name={'variant'}
                                                       id={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}
                                                       value={itemGroupItem.item_id}/>
                                                {item.translation.name}
                                            </label>
                                            {itemPrice && (
                                                <span className="product-modifier-option-price ml-auto price"><Money
                                                    value={itemPrice}
                                                    currency='PLN'/></span>
                                            )}
                                        </div>
                                    )
                                    return null;
                                })
                            }
                        </fieldset>
                    </div>
                ) : (
                    <></>
                )}
            </MenuEditItem>
        </>
    )

}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        promotions: getPromotions(state.menu),
        cart: getCart(state.cart),
        location: getLocation(state.location)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuShowItemSingle))