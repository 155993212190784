import ReactGtag from '../../lib/react-gtag/index'
import Cookies from "universal-cookie/lib";
import StorageStore from "./storageStore";

let cookies = new Cookies();

class Analytics {

    static initialized = false;
    static goorderAnalyticsId = null;
    static goorderAnalyticsInitialized = false;
    static shopAnalyticsId = null;
    static googleAnalyticIds = [];

    static isAcceptCookies = () => {
        return cookies.get("cookie_marketing");
    }
    static checkInitialized() {
        // if(!this.isAcceptCookies()) return;
        if(!this.goorderAnalyticsInitialized){
            ReactGtag.initialize(this.goorderAnalyticsId, {
                send_page_view: false
            });
            this.goorderAnalyticsInitialized = true;
        }

        if(!this.initialized){
            this.googleAnalyticIds.forEach(x => {
                if (x.includes("GTM-")) {
                    ReactGtag.initializeTagManager(x)
                }else{
                    ReactGtag.gtag('config', x, {
                        send_page_view: false,
                    });
                }
            })
            this.initialized = true;
        }
    }

    static initGA(goorderAnalyticsId) {
        this.goorderAnalyticsId = goorderAnalyticsId;
        if (!this.isAcceptCookies()) return;
        ReactGtag.initialize(goorderAnalyticsId, {
            send_page_view: false
        });
    }

    static addGA(googleAnalyticsId) {
        if (googleAnalyticsId.includes("GTM-")) {
            // ReactGtag.initializeTagManager(googleAnalyticsId)
            this.googleAnalyticIds.push(googleAnalyticsId);
        } else {
            this.shopAnalyticsId = googleAnalyticsId;
            this.googleAnalyticIds.push(googleAnalyticsId);
            // if (!this.isAcceptCookies()) return;
            // ReactGtag.gtag('config', googleAnalyticsId, {
            //     send_page_view: false,
            // });
        }
        this.initialized = false;
        this.checkInitialized();
    }

    static pageView(path) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        ReactGtag.gtag('event', 'page_view', {
            'page_title': path,
            'page_path': path
        });
    }

    static purchase(order) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        const isPurchaseAlready = StorageStore.getItem(`analytics_purchase_${order.id}`);
        if(isPurchaseAlready) return;;

        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "shipping": 0,
            // "tax": 1.24,
            // "shipping": data.shipmentPrice,
            "items": items,
        };
        ReactGtag.gtag('event', 'purchase', purchaseData);
        StorageStore.setItem(`analytics_purchase_${order.id}`, "true");
    }

    static async addToCart(orderDataItem) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        let data = {
            currency: "PLN",
            value: orderDataItem.total_price,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: orderDataItem.total_price,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('add_to_cart', data);
    }

    static async removeFromCart(orderDataItem) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        let value = orderDataItem.total_money ? orderDataItem.total_money.amount : undefined;
        let data = {
            currency: "PLN",
            value: value,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: value,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('remove_from_cart', data);
    }

    static async viewItem(item) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('view_item', data);
    }


    static async selectItem(item) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('select_item', data);
    }

    static async addToCartNewOrder(order) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('add_to_cart', data);
    }

    static async beginCheckout(cart) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if (!cart) return;
        var items = [];
        for (var i = 0; i < cart.items.length; i++) {
            var orderDataItem = cart.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": cart.id,
            // "affiliation": "Google online store",
            "value": cart.total.amount,
            "currency": cart.total.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('begin_checkout', data);
    }

}

export default Analytics;