import React, { Component } from 'react';
import Alert from './Alert';
import {connect} from "react-redux";
import {getMessages} from "../../application/notification/reducer";
import {removeFlash} from "../../application/notification/actions";

class FlashMessages extends Component {

    constructor(props) {
        super(props);
    }


    render () {
        const alerts = this.props.messages.map( message =>
            <Alert key={ message.id } message={ message }
                   onClose={ () =>  this.props.dispatch(removeFlash(message)) } />
        );

        if(alerts === undefined || alerts.length <=0){
            return null;
        }
        return(
            <div className="alerts sticky-fixed">{ alerts }</div>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        messages: getMessages(state.notification)
    }
}

export default connect(mapStateToProps)(FlashMessages);
