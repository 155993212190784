import React from 'react';
import {parseDate} from "../../../application/common/DateHelper";
import Money from "../../common/Money";
import OrderHelper from "../../../application/common/OrderHelper";

const SummaryItems = (props)=>{
    const visibileModifierGroupName = false;
    const renderItemPrice = function(item){
        if(item.discount_money !== undefined && item.discount_money !== null && item.discount_money.amount !== 0){
            let subTotalPrice = item.total_money.amount + item.discount_money.amount;
            return (
                <React.Fragment>
                    <span className="text-danger">
                        <del>
                        <Money
                            value={subTotalPrice}
                            style="currency"
                            currency='PLN' />
                        </del>
                    </span>
                    <Money
                        value={item.total_money.amount}
                        style="currency"
                        currency='PLN' />
                </React.Fragment>
            )
        }
        return (
            <Money
                value={item.total_money.amount}
                style="currency"
                currency='PLN' />
        )
    }
    let orderItems = props.orderItems;

    const renderSubItems = (itemModifierGroups, modifierGroups, items, lvl) => {
        if(!itemModifierGroups || itemModifierGroups.length<=0) return null;
        if(!lvl) lvl = 0;
        if(visibileModifierGroupName){
            return (
                <>
                    {
                        itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).map((itemModifierGroup) =>
                            <ul className="list-inline" key={itemModifierGroup.id}>
                                <h5>
                                    {OrderHelper.getItemName(modifierGroups, itemModifierGroup.modifier_group_id, itemModifierGroup.name)}
                                </h5>
                                {
                                    itemModifierGroup.selected_items.sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>
                                        <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`} key={selectedItem.id}>
                                            {selectedItem.quantity}x {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}
                                            {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl+1)}
                                        </li>
                                    )
                                }
                            </ul>
                        )
                    }
                </>
            )
        }
        return (
            <ul className="list-inline">
                {
                    itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).flatMap(x => x.selected_items).sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>
                        <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`} key={selectedItem.id}>
                            {isModifiers(selectedItem) ? (
                                <h5>
                                    {selectedItem.quantity}x {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}
                                </h5>
                            ) : (
                                <>
                                    {selectedItem.quantity}x {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}
                                </>
                            )}
                            {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl+1)}
                        </li>
                    )
                }
            </ul>
        )
    }
    const isModifiers = (item) => {
        return item.modifier_groups && item.modifier_groups.length > 0;
    }
    return(
        <div>
            <table className="table table-items">
                <tbody>
                {
                    orderItems.sort((a, b) => parseDate(a.created_at) - parseDate(b.created_at)).map((item) =>
                        <tr key={item.id}>
                            <td className="cart-items-name">
                                <h4>
                                    {OrderHelper.getItemName(props.items, item.item_id, item.name)}
                                </h4>
                                <div>
                                    {renderSubItems(item.modifier_groups, props.modifierGroups, props.items)}
                                    {/*{*/}
                                    {/*    item.modifier_groups.sort((a, b) => a.name.localeCompare(b.name)).map((itemModifierGroup) =>*/}
                                    {/*        <ul className="list-inline" key={itemModifierGroup.id}>*/}
                                    {/*            <h5>*/}
                                    {/*                {OrderHelper.getItemName(props.modifierGroups, itemModifierGroup.modifier_group_id, itemModifierGroup.name)}*/}
                                    {/*            </h5>*/}
                                    {/*            {*/}
                                    {/*                itemModifierGroup.selected_items.sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>*/}
                                    {/*                    <li className="list-inline-item" key={selectedItem.id}>*/}
                                    {/*                        {selectedItem.quantity}x {OrderHelper.getItemName(props.items, selectedItem.item_id, selectedItem.name)}*/}
                                    {/*                        {renderSubItem(selectedItem, props.modifierGroups, props.items)}*/}
                                    {/*                    </li>*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*        </ul>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                </div>
                            </td>
                            <td>
                                {item.quantity}x
                            </td>
                            <td className="text-end">
                                {renderItemPrice(item)}
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    )
}
export default SummaryItems;