import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getItems, getModifierGroups, isItemMultiLevel} from "../../../../../application/menu/reducer";
import StateItemHelper from "../MenuShowItem/StateItemHelper";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import MenuItemOptionWithImage from "./components/MenuItemOptionWithImage";
import MenuItemOptionBasic from "./components/MenuItemOptionBasic";
import MenuShowItemSingleV3 from "./MenuShowItemSingleV3";

const MenuModifierGroupsNewV2 = (props) => {
    const {t, stateItem, modifierGroup, items, modifier_groups} = props;


    const setQuantityItem = (modifierGroup, itemView, quantity) => {
        StateItemHelper.setQuantityItem(stateItem, modifierGroup, itemView, quantity, items, modifier_groups)
        updateState();
    }

    const updateState = () => {
        props.onUpdate(stateItem);
    }

    const editSubLevel = (optionStateItem) => {
        props.onChangePage("PICK_ONE", {
            stateItem: optionStateItem,
            render: () => {
                const updateSubItem = (newStateItem) => {
                    // let modifierGroupId = newStateItem.parent_modifier_group_id;
                    let copyItem = Object.assign({}, stateItem);
                    // copyItem.modifier_groups.forEach(mg => {
                    //     if (mg.modifier_group_id !== modifierGroupId) return;
                    //     mg.selected_items[id - 1] = newStateItem;
                    // });
                    StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
                    props.onUpdate(copyItem);
                    props.onChangePage(undefined);
                }
                return (
                    <>
                        <MenuShowItemSingleV3 parent={stateItem} onHide={() => props.onChangePage(undefined)}
                                              errors={props.errors} itemId={optionStateItem.item_id}
                                              onUpdate={updateSubItem} stateItem={optionStateItem}/>
                    </>
                )
            }
        })
    }
    const renderInput = (modifierGroup, optionItemView) => {
        let optionStateItem = optionItemView.state;
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? null : modifierGroup.max_permitted;
        let disableAdd = false;
        if(modifierGroup.max_permitted !== null && modifierGroup.max_permitted <= modifierGroup.quantity){
            disableAdd = true;
        }else{
            if(optionItemView.max_permitted && optionItemView.max_permitted <= optionItemView.quantity){
                disableAdd = true;
            }
        }
        let errors = optionItemView.errors;
        if (min_permitted === 1 && max_permitted === 1) {
            let checked = optionItemView.quantity === 1;
            let id = `modifierGroupItemId-${modifierGroup.id}-${optionItemView.id}`;
            return (<MenuItemOptionBasic errors={errors} disableAdd={disableAdd} stateItem={optionStateItem} checked={checked} modifierGroupId={modifierGroup.id} optionItemView={optionItemView} onUpdate={(modifierGroupId, optionItemView, quantity) => setQuantityItem(modifierGroup, optionItemView, quantity)} />)
        }
        // let singlePick = min_permitted === 1 && max_permitted === 1;
        let quantityInfoItemMinPermitted = optionItemView.min_permitted === undefined ? 0 : optionItemView.min_permitted;
        let quantityInfoItemMaxPermitted = optionItemView.max_permitted === undefined ? null : optionItemView.max_permitted;
        let singlePick = quantityInfoItemMinPermitted === 1 && quantityInfoItemMaxPermitted === 1;

        return (
            <MenuItemOptionWithImage errors={errors} disableAdd={disableAdd} singlePick={singlePick} stateItem={optionStateItem}
                                     modifierGroupId={modifierGroup.id} optionItemView={optionItemView}
                                     onUpdate={(modifierGroupId, optionItemView, quantity) => setQuantityItem(modifierGroup, optionItemView, quantity)}
                                     onEdit={editSubLevel}/>
        );
    }
    const getRenderHelpQuantityInfo = (modifierGroup) => {
        let additionalInfos = [];
        if (modifierGroup.min_permitted !== null && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== null && modifierGroup.max_permitted === 1) {

        } else {
            if (modifierGroup.min_permitted !== null && modifierGroup.min_permitted > 0) {
                additionalInfos.push({title: t("components.menu.min_permitted"), value: modifierGroup.min_permitted});
            }
            if (modifierGroup.max_permitted !== null) {
                additionalInfos.push({title: t("components.menu.max_permitted"), value: modifierGroup.max_permitted});
            }
            if (modifierGroup.quantity !== null) {
                additionalInfos.push({title: t("components.menu.default_quantity"), value: modifierGroup.quantity});
            }
            if (modifierGroup.charge_above !== null) {
                additionalInfos.push({title: t("components.menu.charge_above"), value: modifierGroup.charge_above});
            }
        }
        let errorView = null;
        if (modifierGroup.errors !== undefined) {
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error) =>
                            <p className="text-danger error" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if (additionalInfos.length !== 0) {
            additionalInfoView = (
                <React.Fragment>
                    <ul className="list-inline">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={`${additionalInfo.title}_${additionalInfo.value}`}>{additionalInfo.title}: <strong>{additionalInfo.value}</strong>
                                </li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    if (modifierGroup.max_permitted !== null && modifierGroup.max_permitted <= 0) {
        return null;
    }
    const isSinglePick = (modiferGroup) => {
        if (modifierGroup.min_permitted == 1 && modiferGroup.max_permitted == 1) return true;
        return false;
    }
    const singlePick = isSinglePick(modifierGroup);
    return (
        <fieldset className="form-group">
            <legend>
                {modifierGroup.name} {props.quantity}
            </legend>
            {getRenderHelpQuantityInfo(modifierGroup)}
            <div className={`${singlePick ? "row row-cols-2 gx-2" : ""}`}>

                {
                    modifierGroup.options.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem) => {
                        return (
                            <React.Fragment key={modifierGroup.id + "-" + optionItem.id}>
                                {renderInput(modifierGroup, optionItem, singlePick)}
                            </React.Fragment>
                        )
                    })
                }
            </div>

        </fieldset>
    )
}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuModifierGroupsNewV2))