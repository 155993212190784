import React, {useState} from 'react';
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {useTranslation} from "react-i18next";
import ChangeDateModal from "../../common/ChangeDateModal";
import {DateHelper} from "../../../application/common/DateHelper";
import classNames from "classnames";
import AppConfig from "../../../application/common/AppConfig";


function renderCustomField(customField, required, valueCustomFields, errors, onChange, t, dateModal, setDateModal, section, availabilities){
    let name = customField.name;
    if(required){
        name += "*";
    }
    let fieldName = `customFields[${customField.slug}]`;
    let fieldvalue = valueCustomFields !== undefined ? valueCustomFields[customField.slug] : undefined;
    if(fieldvalue === undefined) fieldvalue = "";

    let fieldId = "custom_field_" + customField.slug;

    const saveCustomFieldDate = (slug, date) => {
        return new Promise((resolve, reject) => {
            let dateValue = date !== null ? DateHelper.format(date) : null;
            onChange(slug, dateValue);
            resolve();
        });
    }

    const handleChangeField = (slug, event) => {
        var value = event.target.value;
        onChange(slug, value);
    }
    const handleChangeCheckboxField = (slug, event) => {
        var checked = event.target.checked;
        if(checked){
            onChange(slug, true);
        }else{
            onChange(slug, "");
        }
    }
    const showDateModal = (slug) => {
        let newDateModal = {}
        newDateModal[slug] = true;
        setDateModal(newDateModal);
    }
    const hideDateModal = (slug) => {
        let newDateModal = {}
        newDateModal[slug] = false;
        setDateModal(newDateModal);
    }

    if(customField.type === 'DATE'){
        if(dateModal[customField.slug] === undefined){
            dateModal[customField.slug] = false;
        }

        return (
            <div className={classNames('', {
                "form-group col-lg-6": section !== "RULES",
            })} key={customField.slug}>
                <button type="button" className="btn btn-default btn-block btn-pick-date" onClick={() => showDateModal(customField.slug)}>
                    <h5>
                        {name}
                        <small>
                            {fieldvalue ? fieldvalue : "Wybierz datę"}
                        </small>
                    </h5>
                </button>
                <ChangeDateModal availabilities={availabilities} button_clear={true} onSave={(value) => saveCustomFieldDate(customField.slug, value)} title={t("common.word.pick_date") + ' "' + customField.name + "'"} value={fieldvalue} showModal={dateModal[customField.slug]} onHide={() => hideDateModal(customField.slug)} />
            </div>
        )
    }
    if(customField.type === 'CHECKBOX'){
        if(customField.help_text){
            name = customField.help_text;
        }
        return (
            <div className={classNames('', {
                "form-group col-lg-6": section !== "RULES",
            })} key={customField.slug}>
                <div className="form-check" key={customField.slug}>
                    <input checked={fieldvalue ? true : false} onChange={(e) => handleChangeCheckboxField(customField.slug, e)} className={"form-check-input" + ErrorFormHelper(errors, fieldName)}  type="checkbox" value="1"  id={fieldId} />
                    <label className="form-check-label" htmlFor={fieldId} value={name} dangerouslySetInnerHTML={{
                        __html: name
                    }}>
                    </label>
                    <ErrorFormView errors={errors} field={fieldName} />
                </div>
            </div>
        )
    }
    if(customField.options !== undefined && customField.options.length > 0){
        return (
            <div className={classNames('', {
                "form-group col-lg-6": section !== "RULES",
            })} key={customField.slug}>
                <select value={fieldvalue} onChange={(e) => handleChangeField(customField.slug, e)} className={"form-control" + ErrorFormHelper(errors, fieldName)} id={fieldId} placeholder={name}>
                    <option>{t("common.word.choose")} {customField.name}</option>
                    {customField.options.map(customFieldOption => {
                        return (
                            <option key={customFieldOption.value}>{customFieldOption.value}</option>
                        );
                    })}
                </select>
                <label htmlFor={fieldId}>{name}</label>
                <ErrorFormView errors={errors} field={fieldName} />
            </div>
        )
    }
    return (
        <div className={classNames('', {
            "form-group col-lg-6": section !== "RULES",
        })} key={customField.slug}>
            <input type="text" value={fieldvalue} onChange={(e) => handleChangeField(customField.slug, e)} className={"form-control" + ErrorFormHelper(errors, fieldName)} id={fieldId} placeholder={name} />
            <label htmlFor={fieldId}>{name}</label>
            <ErrorFormView errors={errors} field={fieldName} />
        </div>
    )
}
export function CartCustomFields(props) {
    const [dateModal, setDateModal] = useState({});

    const { t } = useTranslation();
    let store = props.store;
    let orderType = props.orderType;
    let customFields = props.customFields;
    let valueCustomFields = props.valueCustomFields;
    let section = props.section;
    let errors = props.errors;
    let onChange = props.onChange;
    let sortCustomFields = [];
    store.order_custom_fields.forEach(storeCustomField => {
        let customField = customFields.filter(x => x.slug === storeCustomField.slug)[0];
        if(customField !== undefined && (storeCustomField.section === section || (!storeCustomField.section && !section))){
            if(orderType){
                // if(section !== undefined){
                //     if(section !== storeCustomField.section){
                //         return;
                //     }
                // }
                if(storeCustomField.type === orderType){
                    sortCustomFields.push({
                        custom_field: customField,
                        required: storeCustomField.required
                    })
                }
            }
        }
    })
    sortCustomFields = sortCustomFields.sort((a, b) => a.custom_field.name.localeCompare(b.custom_field.name));

    let customfieldsView = [];
    sortCustomFields.forEach(customField => {
        customfieldsView.push(renderCustomField(customField.custom_field, customField.required, valueCustomFields, errors, onChange, t, dateModal, setDateModal, section, props.availabilities))
    })
    return customfieldsView;
}