import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {getAcceptedRules, getOrderCustomFields, getOrderType} from '../../../application/cart/reducer';
import {getPageComponents, getStore} from "../../../application/store/reducer";
import {acceptRules} from "../../../application/cart/actions";
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {Link} from "react-router-dom";
import {CartCustomFields} from "./CartCustomFields";
import {getAvailabilities, getCustomFields, getOrganization} from "../../../application/menu/reducer";
import CustomFieldUtils from "../../utils/CustomFieldUtils";
import IframeWidget from "../../utils/IframeWidget";
import {Modal} from "react-bootstrap";
import MenuShowItemSingle from "../base/components/MenuShowItemSingle";
import {getAddressName} from "../../../application/common/StringHelper";

const CartRules = (props)=>{
    const [customFields, setCustomFields] = useState([]);
    const [rulesUrl, setRulesUrl] = useState(undefined);
    const [rulesModal, setRulesModal] = useState(false);


    const getRules = () => {
        const {store, accepted_rules} = props;
        let stateAcceptRules = [];
        store.links.filter(x => x.type === "RULES").sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map(storeLink => {
            let stateAcceptRule = {
                id: storeLink.id,
                link: storeLink.link,
                name: storeLink.name,
                accepted: false
            }
            let acceptedRule = accepted_rules.filter(acceptedRule => {
                return acceptedRule == storeLink.id;
            })[0];
            if(acceptedRule !== undefined){
                stateAcceptRule.accepted = true;
            }
            stateAcceptRules.push(stateAcceptRule);
        })
        return stateAcceptRules;
    }
    const updateRuleAccepted = (linkId, e) => {
        const {store, accepted_rules} = props;
        const target = e.target;
        const value = target.checked;
        let newAcceptedRules = [];
        accepted_rules.forEach(acceptedRule => {
            if(linkId !== acceptedRule){
                newAcceptedRules.push(acceptedRule);
            }
        });
        if(value){
            newAcceptedRules.push(linkId);
        }

        props.dispatch(acceptRules(newAcceptedRules));
    }
    const openModaRules = (e, link) => {
        e.preventDefault()
        setRulesUrl(link);
        setRulesModal(true)
    }
    const isIframeExternalUrl = (link) => {
         return false;
    }
    const renderRuleView = (storeLink) => {
        if(storeLink.link == 'rules'){
            return (
                <Link to={storeLink.link}  target="_blank">
                    Link
                </Link>
            )
        }
        let links = [];
        let storeLinks = storeLink.link.split("|");
        if(storeLinks.length > 1){
            storeLinks.forEach(storeLink => {
                let storeLinkSplit = storeLink.split("::");
                if(storeLinkSplit.length > 1){
                    links.push({
                        "title": storeLinkSplit[0],
                        "link": storeLinkSplit[1]
                    })
                }else{
                    links.push({
                        "title": "Link",
                        "link": storeLinkSplit[0]
                    })
                }
            });
        }else{
            links.push({
                "title": "Link",
                "link": storeLink.link
            })
        }
        if(isIframeExternalUrl(storeLink.link)){
            return (
                <>
                    {links.map((link, index) => {
                        return (
                            <>
                                <a onClick={(e) => openModaRules(e, link.link)} href={link.link}>{link.title}</a> {index < links.length-1 && ", "}
                            </>
                        )
                    })}
                </>
            )
        }
        return (
            <>
                {links.map((link, index) => {
                    return (
                        <>
                            <a href={link.link} target="_blank">{link.title}</a>{index < links.length-1 && ", "}
                        </>
                    )
                })}
            </>
        )
    }
    const updateCustomFieldValue = (slug, value) => {
        let newCustomFields = customFields;

        let customFieldObj = customFields.filter(x => x.slug == slug)[0];
        if(customFieldObj === undefined) {
            customFieldObj = {
                slug: slug,
                value: value
            }
            newCustomFields.push(customFieldObj);
        }else{
            customFieldObj.value = value;
        }

        setCustomFields(newCustomFields);

        props.order_custom_fields[slug] = value;
    }

    const {store, orderType, custom_fields, accepted_rules, organization} = props;

    let linksView = null;
    let rules = getRules();
    let isAnyExternalRules = rules.some(rule => isIframeExternalUrl(rule.link));
    if(rules && rules.length > 0){
        linksView = (
            <>
                {
                    rules.map((storeLink) =>
                        <div className="form-check" key={storeLink.id}>
                            <input checked={storeLink.accepted} onChange={(e) => updateRuleAccepted(storeLink.id, e)} className={"form-check-input" + ErrorFormHelper(props.errors, "acceptedRules")}  type="checkbox" value="1"  id={'rules-link-' + storeLink.id} />
                            <label className="form-check-label" htmlFor={'rules-link-' + storeLink.id} value={storeLink.link}>
                                {storeLink.name} ({renderRuleView(storeLink)})
                            </label>
                            <ErrorFormView errors={props.errors} field="acceptedRules" />
                        </div>
                    )
                }
                <CartCustomFields availabilities={props.availabilities} section={"RULES"} store={store} orderType={orderType} customFields={custom_fields} orderCustomFields={customFields} errors={props.errors} onChange={updateCustomFieldValue} />
            </>
        )
    }
    let renderMarketingContent = () => {
        let organization = props.organization;
        let companyName = null;
        let addressView = null;
        if(organization !== null){
            companyName = organization.company_name;
            if(organization.address !== null){
                addressView = getAddressName(organization.address);
            }
        }
        if(props.store.settings && props.store.settings.rules_marketing_text){
            if(props.store.settings.rules_marketing_text.includes("VARIANT_2")){
                let additionalInfos = props.store.settings.rules_marketing_text.split("|");
                if(additionalInfos.length > 1 && additionalInfos[1]){
                    companyName = additionalInfos[1];
                }
                if(additionalInfos.length > 2 && additionalInfos[2]){
                    addressView = additionalInfos[2];
                }
                return (
                    <>
                        Wyrażam zgodę na przesyłanie informacji handlowych przez {companyName} z siedzibą w {addressView} za pośrednictwem komunikacji elektronicznej (SMS, e-mail). Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przesyłania informacji handlowych przez T-Pizza sp. z o.o. Podstawą prawną przetwarzania danych osobowych jest wyrażona przeze mnie zgoda. Rozumiem, że przysługuje mi prawo do cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
                    </>
                )
            }
        }

        return (
            <>
                {(props.store.settings && props.store.settings.rules_marketing_text) ? props.store.settings.rules_marketing_text : `Wyrażam zgodę na wykorzystanie moich danych osobowych przez ${companyName} z siedzibą w ${addressView} do celów marketingowo-reklamowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady EU z dnia 27 kwietnia 2016 r. „o ochronie osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE” zwanym dalej „RODO” oraz na otrzymywanie na powyższy adres e-mail oraz numer telefonu informacji handlowych drogą elektroniczną.`}
            </>
        )
    }
    let acceptMarketingVisible = CustomFieldUtils.getVisible("acceptMarketing", store.order_custom_fields, orderType);
    const pageComponents = props.pageComponents.filter(x => x.placement == "before_rules");
    return (
        <>
            {(pageComponents && pageComponents.length > 0) && (
                <>
                    {pageComponents.map((pageComponent) => <>
                        <div key={pageComponent.id} dangerouslySetInnerHTML={{__html: pageComponent.content}} />
                    </>)}
                    <hr />
                </>
            )}
            {linksView}
            {CustomFieldUtils.isVisible(acceptMarketingVisible) && (
                <div className="form-check" key={"acceptMarketing"}>
                    <input checked={accepted_rules.some(acceptedRule => acceptedRule === "acceptMarketing")} onChange={(e) => updateRuleAccepted("acceptMarketing", e)} className={"form-check-input" + ErrorFormHelper(props.errors, "acceptMarketing")}  type="checkbox" value="1"  id={'rules-link-acceptMarketing'} />
                    <label className="form-check-label" htmlFor={'rules-link-acceptMarketing'}>
                        {renderMarketingContent()}
                    </label>
                    <ErrorFormView errors={props.errors} field="acceptMarketing" />
                </div>
            )}
            {isAnyExternalRules && (
                <Modal dialogClassName="modal-full modal-lg modal-product" show={rulesModal} onHide={() => setRulesModal(false)} onShow={IframeWidget.openModal}>
                    <Modal.Header>
                        <Modal.Title>
                            Regulamin
                        </Modal.Title>
                        <button onClick={() => setRulesModal(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe style={{width: "100%"}} src={rulesUrl} title="Embedded Website" className="iframe"></iframe>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}
const mapStateToProps = state => ({
    store: getStore(state.store),
    accepted_rules: getAcceptedRules(state.cart),
    order_custom_fields: getOrderCustomFields(state.cart),
    orderType: getOrderType(state.cart),
    custom_fields: getCustomFields(state.menu),
    organization: getOrganization(state.menu),
    availabilities: getAvailabilities(state.menu),
    pageComponents: getPageComponents(state.store)
})

export default connect(mapStateToProps)(CartRules)