import React, {Component} from 'react';
import IframeWidget from "./IframeWidget";
import AppConfig from "../../application/common/AppConfig";

class MenuScrollSpy extends Component{
    sections = [];
    menu_links = [];
    sectionMargin = 40;
    currentActive = 0;
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollIframe = this.handleScrollIframe.bind(this);
        if(AppConfig.isWidget()){
            this.sectionMargin = 110;
        }
    }
    menuItemChanged(e, z){
        if(e === undefined){
            return;
        }
        var els = document.querySelectorAll("a[href='#" + e.id + "']");
        let navScrollSpy = document.getElementById("nav-scrollspy");
        if(navScrollSpy !== null && els[0] !== undefined){
            let navScrollSpyNav = navScrollSpy.getElementsByClassName("nav");
            if(navScrollSpyNav.length > 0){
                navScrollSpyNav[0].scrollLeft = els[0].offsetLeft;
            }
        }
    }
    componentDidMount() {
        this.sections = document.querySelectorAll(".scrollspy-section");
        this.menu_links = document.querySelectorAll(".parent-top-nav .nav-item");
        if(this.sections.length <= 0){
            return ;
        }
        this.makeActive(this.currentActive);
        if(AppConfig.isWidget()){
            window.addEventListener('scroll-iframe', (e) => this.handleScrollIframe(e));
        }else{
            window.addEventListener('scroll', this.handleScroll);
        }
    }
    componentWillUnmount() {
        if(AppConfig.isWidget()){
            window.removeEventListener('scroll-iframe', this.handleScrollIframe);
        }else{
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
    makeActive(link){
        let menuLink = this.menu_links[link];
        let navScrollSpy = document.getElementById("nav-scrollspy");
        if(navScrollSpy !== null){
            let navScrollSpyNav = navScrollSpy.getElementsByClassName("nav");
            if(navScrollSpyNav.length > 0){
                navScrollSpyNav[0].scrollLeft = menuLink.offsetLeft;
            }
        }
        return this.menu_links[link].classList.add("active");
    }
    removeActive(link){
        return this.menu_links[link].classList.remove("active");
    }
    removeAllActive(){
        return [...Array(this.sections.length).keys()].forEach((link) => this.removeActive(link));
    }
    scrollListener(scrollY){
        // let current = this.sections.length - [...this.sections].reverse().findIndex((section) => scrollY >= section.offsetTop - this.sectionMargin ) - 1
        let current = this.sections.length - [...this.sections].reverse().findIndex((section) => {
            let exist= scrollY >= section.offsetTop - this.sectionMargin;
            return exist;
        } ) - 1

        if(current > this.sections.length- 1){
            current = 0;
        }
        if (current !== this.currentActive) {
            this.removeAllActive();
            this.currentActive = current;
            this.makeActive(current);
        }
    }
    handleScroll(){
        this.scrollListener(window.scrollY);
    }
    handleScrollIframe(e){
        this.scrollListener(e.detail);
    }
    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }

    // const sections = document.querySelectorAll(".scrollspy-section");
    // const menu_links = document.querySelectorAll(".parent-top-nav .nav-item");
    // if(sections.length <= 0){
    //     return null;
    // }
    // console.log(sections);
    // console.log(menu_links);
    // const makeActive = (link) => menu_links[link].classList.add("active");
    // const removeActive = (link) => menu_links[link].classList.remove("active");
    // const removeAllActive = () => [...Array(sections.length).keys()].forEach((link) => removeActive(link));
    //
    // const sectionMargin = 40;
    // let currentActive = 0;
    // this.makeActive(this.currentActive);
    // function scrollListener(scrollY){
    //     let current = sections.length - [...sections].reverse().findIndex((section) => scrollY >= section.offsetTop - sectionMargin ) - 1
    //
    //     if(current > sections.length- 1){
    //         current = 0;
    //     }
    //     if (current !== currentActive) {
    //         removeAllActive();
    //         currentActive = current;
    //         makeActive(current);
    //     }
    // }
    // if(AppConfig.isWidget()){
    //     window.addEventListener("scroll-iframe", (e) => {
    //         console.log(e);
    //         console.log(e.detail);
    //         scrollListener(e.detail);
    //     });
    // }else{
    //     window.addEventListener("scroll", (e) => {
    //         scrollListener(window.scrollY);
    //     });
    // }
    // return(
    //     <React.Fragment>
    //         {value.children}
    //     </React.Fragment>
    // )
}
export default MenuScrollSpy;

