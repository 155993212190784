import React, {useEffect, useState} from 'react';
import {getPages, getStore, isPreview} from "../../../application/store/reducer";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Utils from "../../utils/Utils";
import ChangeTableNumberModal from "../../common/ChangeTableNumberModal";
import {updateOrderData} from "../../../application/cart/cartService";
import {Redirect, useHistory} from "react-router-dom";
import ChangeRoomServiceNumberModal from "../../common/ChangeRoomServiceNumberModal";
import LoadingContainer from "../../common/LoadingContainer";
import UserService from "../../../application/user/userService";

function OrderType(props) {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    let orderType = props.match.params.orderType;
    if(orderType !== undefined) orderType = orderType.toUpperCase();
    const history = useHistory();

    let urlSearchParams = new URLSearchParams(history.location.search);

    const loginUser = async () => {
        let provider = urlSearchParams.get("provider");
        if(provider === undefined || !provider){
            return ;
        }
        let username = urlSearchParams.get("username");
        let code = urlSearchParams.get("code");
        let email = urlSearchParams.get("email");
        let options = {}
        if(code) options.code = code;
        if(username) options.username = username;
        if(email) options.email = email;
        try {
            // let response = await props.dispatch(updateOrderChannelContact(provider, options, orderData))
            let response = await props.dispatch(UserService.login(provider, username, code))
        }catch (e) {
            console.log(e);
            throw e;
        }
    }
    useEffect(() => {

        switch (orderType) {
            case "DINE_IN":
                var data = {
                    type: 'DINE_IN'
                }
                var number = null;
                if(props.location.search){
                    let query = props.location.search.substring(1);
                    let qp = Utils.getQueryParams(query);
                    if(qp.table){
                        number = qp.table;
                    }else if(qp.number){
                        number = qp.number;
                    }
                }
                if(!props.preview && !number && show){
                    setLoading(false);
                    return;
                }
                data.number = number;
                props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                    loginUser().then(() => {
                        setRedirect(true);
                    })
                }).catch(error => {
                });
                break;
            case "ROOM_SERVICE":
                var data = {
                    type: 'ROOM_SERVICE'
                }
                var number = null;
                if(props.location.search){
                    let query = props.location.search.substring(1);
                    let qp = Utils.getQueryParams(query);
                    if(qp.number){
                        number = qp.number;
                    }
                }
                if(!props.preview && !number && show){
                    setLoading(false);
                    return;
                }

                data.number = number;
                props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                    setRedirect(true);
                }).catch(error => {
                    setShow(true);
                });
                break;
            case "PICK_UP":
                props.dispatch(updateOrderData({type: orderType}, props.cart)).then((newData) => {
                    loginUser().then(() => {
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    })
                }).catch(error => {
                });
                break;
            case "DELIVERY":
                props.dispatch(updateOrderData({type: orderType}, props.cart)).then((newData) => {
                    setRedirect(true);
                }).catch(error => {
                });
                break;
        }
    }, []);

    const onHideModal = () => {
        var data = {
            type: orderType
        }
        switch (orderType){
            case "ROOM_SERVICE":
                props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                    loginUser().then(() => {
                        setRedirect(true);
                    })
                }).catch(error => {
                    setShow(false);
                });
                break;
            case "DINE_IN":
                props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                    loginUser().then(() => {
                        setRedirect(true);
                    })
                }).catch(error => {
                    setShow(false);
                });
                break;
        }
        setShow(false);
    }
    // if(loading){
    //     return (
    //         <LoadingContainer/>
    //     );
    // }
    // useEffect(() => {
        switch (orderType) {
            case "DINE_IN":
                var data = {
                    type: 'DINE_IN'
                }
                var number = null;
                if(props.location.search){
                    let query = props.location.search.substring(1);
                    let qp = Utils.getQueryParams(query);
                    if(qp.table){
                        number = qp.table;
                    }else if(qp.number){
                        number = qp.number;
                    }
                }

                if(!props.preview){
                    if(number){
                        data.number = number;
                    }else{
                        const onSave = () => {
                            history.push("/");
                        }
                        if(show){
                            return (
                                <ChangeTableNumberModal showLanguages={true} tableNumber={number} show={show}  onSave={onSave} onHide={onHideModal}/>
                            )
                        }
                    }
                }
                
                // props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                //     loginUser().then(() => {
                //         setRedirect(true);
                //         // return (
                //         //     <Redirect to={"/"} />
                //         // )
                //     })
                // }).catch(error => {
                // });
                break;
            case "ROOM_SERVICE":
                var data = {
                    type: 'ROOM_SERVICE'
                }
                var number = null;
                if(props.location.search){
                    let query = props.location.search.substring(1);
                    let qp = Utils.getQueryParams(query);
                    if(qp.number){
                        number = qp.number;
                    }
                }
                if(!props.preview){
                    if(number){
                        data.number = number;
                    }else{
                        const onSave = () => {
                            history.push("/");
                        }
                        if(show){
                            return (
                                <ChangeRoomServiceNumberModal showLanguages={true} roomNumber={number} show={show}  onSave={onSave} onHide={onHideModal}/>
                            )
                        }
                    }
                }
                // props.dispatch(updateOrderData(data), props.cart).then((newData) => {
                //     setRedirect(true);
                //     // return (
                //     //     <Redirect to={"/"} />
                //     // )
                // }).catch(error => {
                //     setShow(true);
                // });
                break;
            case "PICK_UP":

                // props.dispatch(updateOrderData({type: orderType}, props.cart)).then((newData) => {
                //     loginUser().then(() => {
                //         setLoading(false);
                //         // setLoading(false);
                //         // return (
                //         //     <Redirect to={"/"} />
                //         // )
                //     }).catch(() => {
                //         setLoading(false);
                //     })
                //     // return (
                //     //     <Redirect to={"/"} />
                //     // )
                // }).catch(error => {
                // });
                break;
            case "DELIVERY":
                // props.dispatch(updateOrderData({type: orderType}, props.cart)).then((newData) => {
                //     setRedirect(true);
                //     // return (
                //     //     <Redirect to={"/"} />
                //     // )
                // }).catch(error => {
                // });
                break;
        }
    // }, [])

    if(redirect){
        return (
            <Redirect to={"/"} />
        )
    }
    if(loading){
        return (
            <LoadingContainer/>
        );
    }
    return (
        <Redirect to={"/"} />
    )

}
const mapStateToProps = state => ({
    store: getStore(state.store),
    pages: getPages(state.store),
    preview: isPreview(state.store)
})
export default withTranslation()(connect(mapStateToProps)(OrderType))
