import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getUser} from "../../../../application/user/reducer";
import {getLoginProviders} from "../../../../application/store/reducer";
import LoginUserModal from "../LoginUserModal";
import {ReactComponent as GiftSVG} from "../../../../images/icons/gift.svg";
import UserContext from "../../../../application/common/UserContext";

function AdvertUserComponent(props) {
    const [loginModal, setLoginModal] = useState(false);
    if (!props.loginProviders || props.loginProviders.length <= 0) return null;
    if (props.user && props.user.name) return null;
    if(UserContext.getToken()) return null;

    return (
        <>
            {loginModal && (
                <LoginUserModal page={"REGISTER"} onHide={() => setLoginModal(false)} show={true}/>
            )}
            <div className={"container mt-4"}>
                <div className={"card card-advert"}>
                    <div className={"card-body"}>
                        <div>
                            <GiftSVG/>
                        </div>
                        <div>
                            <p>
                                Załóż konto aby dołączyć do stałych gości restauracji i otrzymywać rabaty na
                                zamówienia!
                            </p>
                            <div>
                                <a href={"#"} className={"btn btn-primary stretched-link"} onClick={(e) => {
                                    e.preventDefault();
                                    setLoginModal(true)
                                }}>Załóż konto</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        loginProviders: getLoginProviders(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(AdvertUserComponent))
