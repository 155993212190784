import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {Link, useHistory} from "react-router-dom";
import {getUser, getVouchers} from "../../../../application/user/reducer";
import LoginUserModal from "../../../common/user/LoginUserModal";
import Money from "../../../common/Money";
import UserService from "../../../../application/user/userService";
import AppConfig from "../../../../application/common/AppConfig";

const UserIndexPage = (props) => {
    const [qrcodeBase64, setQrcodeBase64] = useState(null);
    const [loginModal, setLoginModal] = useState(false);
    const history = useHistory();
    const user = props.user;
    useEffect(() => {
        if (!user) {
            props.onHide()
            return;
        }
        const fetchQrcode = async () => {
            const response = await UserService.getQrcode();
            if (response) {
                setQrcodeBase64(response);  // Set the base64 string
            }
            // const base64 = await response.text();  // Fetch the plain text Base64 string
        };

        fetchQrcode();
    }, [user?.id]);
    const logout = async () => {
        try {
            await props.dispatch(UserService.logout());
            history.push("/");
        } catch (err) {
            console.log(err);
            // props.onHide();
        }

    }
    return (

        <>
            <div className={"user"}>
                <div className={"user-header top"}>
                    <>
                        <Link to={"/"} className={"btn-back"}>
                            <BackSVG/>
                        </Link>
                        <h5 className="">
                            {props.user.name}
                        </h5>
                        {props.user?.points && (
                            <div className={"points"}>
                                <div className={"badge text-bg-success"}>{user?.points} pkt</div>
                            </div>
                        )}
                    </>
                </div>
                <div className="container mt-4">
                    {!props.user.verified && (
                        <>
                            <div className={"alert alert-warning"}>
                                Twoje konto nie jest zweryfikowane, <a href={"#"} onClick={(e) => {
                                e.preventDefault();
                                setLoginModal(true);
                            }}>kliknij
                                tutaj aby zweryfikować konto</a>
                            </div>
                            {loginModal && (
                                <LoginUserModal onHide={() => setLoginModal(false)} show={true}/>
                            )}
                        </>
                    )}
                    <div className={"user-card"}>
                        {AppConfig.isUserRegistration() && (
                            <>
                                {qrcodeBase64 && (
                                    <>
                                        <div className={"user-card-qrcode"}>
                                            <img src={`data:image/png;base64,${qrcodeBase64}`} alt="QR Code"/>
                                        </div>
                                        <p className={"user-card-qrcode-info"}>
                                            Zeskanuj kod w restauracji, aby skorzystać z Twoich zniżek
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        {props.user.group_name && (
                            <div className={"badge text-bg-light"}>
                                Grupa klientów: <span className={"text-success"}>{props.user.group_name}</span>
                            </div>
                        )}
                        {(props.user.promotional_credits && props.user.promotional_credits.amount > 0) && (
                            <div className={"badge text-bg-light"}>
                                Dostępne środki w portfelu: <span className={"text-success"}><Money
                                value={props.user.promotional_credits}/></span>
                            </div>
                        )}
                    </div>
                    <div className={"user-menu"}>
                        <div className={"nav nav-pills nav-fill flex-column"}>

                            <li className="nav-item">
                                <Link to={"user/vouchers"} className="nav-link  d-flex justify-content-between align-items-center"
                                      aria-current="vouchers">Twoje vouchery
                                    {(props.vouchers && props.vouchers.length > 0) && (
                                        <span className="badge bg-success">{props.vouchers.length}</span>
                                    )}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"user/delete"} className="nav-link"
                                      aria-current="page">Usuń
                                    konto</Link>
                            </li>
                            <li className="nav-item">

                                <a href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    logout()
                                }} className="nav-link"
                                   aria-current="page">Wyloguj
                                    się</a>
                            </li>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user),
    vouchers: getVouchers(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserIndexPage))
