import React, {useState} from 'react';
import ErrorFormHelper from "../../ErrorFormHelper";
import ErrorFormView from "../../ErrorFormView";
import ErrorFormAlertView from "../../ErrorFormAlertView";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import UserService from "../../../../application/user/userService";
import {getUser} from "../../../../application/user/reducer";
import {getStore} from "../../../../application/store/reducer";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";

function LostPasswordUser(props) {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState("");
    const t = props.t;

    const onShow = () => {
        setErrors([]);
    }
    const TranslationsInitialized = () => {
        t("errors.wrong_login");
        t("errors.username_notempty");
    }
    const resetPassword = async () => {
        setLoading(true);
        try {
            let response = await UserService.resetPassword(email)
            setLoading(false);
            setSuccess(true);
            // props.onSuccess()
        } catch (e) {
            console.log(e);
            setErrors([
                {
                    "code": "wrong_login",
                    "message": "Nie udało się zresetować hasła, niepoprawne dane."
                }
            ])
            setLoading(false);
        }
    }

    return (
        <>
            <div className={"login-box-login"}>
                <h4>
                    Resetowanie hasła
                </h4>
                {success ? (
                    <>
                        <div className="alert alert-light" role="alert">
                            <small>
                                <InfoSVG className={"icon me-2"}/>
                                Na Twój adres email wysłaliśmy wiadomość z linkiem do formularza resetu hasła
                            </small>
                        </div>
                    </>
                ) : (
                    <>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                                   className={"form-control" + ErrorFormHelper(errors, "username")}
                                   placeholder={t("components.apps.loyalty.form.login")}
                                   aria-label="Recipient's username"
                                   aria-describedby="cart-username"/>
                            <label htmlFor="cart-username">{t("components.apps.loyalty.form.login")}</label>
                            <ErrorFormView errors={errors} show_message={true} field="username"/>
                        </div>
                        <button
                            className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            variant="primary" onClick={() => {
                            resetPassword()
                        }}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Resetuj hasło
                        </button>
                    </>
                )}
            </div>
            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    Pamiętasz hasło? <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                    props.setPage("LOGIN");
                }}>
                    Zaloguj się
                </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        store: getStore(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(LostPasswordUser))
