import {getOpenHours, isOpen} from "./StringHelper";
import {
    DateHelper,
    getHourTimeString,
    getWeekDays,
    isTimeAllDay,
    parseDate,
    sameDateWithoutTime,
    setTimeByString
} from "./DateHelper";
import DateService from "../service/DateService";

class AvailableHelper {

    static getAvailability(availabilities, id) {
        let obj = availabilities.filter(availability => availability.id === id);
        if (obj.length > 0) {
            return obj[0];
        }
        return null;
    }

    static getAvailabilities(availabilities, ids) {
        let obj = availabilities.filter(availability => ids.includes(availability.id));
        return obj;
    }

    static isAvailabileDate(availability, date) {
        if (availability !== null && availability !== undefined) {
            if (availability.dates === undefined || availability.dates.length === 0) {
                return true;
            }
            let isAvailable = false;
            availability.dates.forEach(availabilityDate => {
                if (date >= parseDate(availabilityDate.date_from) && date < parseDate(availabilityDate.date_to)) {
                    isAvailable = true;
                    return isAvailable;
                }
            });
            return isAvailable;
        }
        return true;
    }

    static isAvailabileHours(availability, date) {
        if (availability === null) {
            return false;
        }
        if (!isOpen(availability.hours, date)) {
            return false;
        }
        return true;
    }

    static isAvailabile(availability, date) {
        if (availability === undefined || !availability) {
            return true;
        }
        if (Array.isArray(availability)) {
            let availabilities = availability;
            if (availabilities.length === 0) return true;
            return availabilities.some(a => this.isAvailabile(a, date));
        }
        if (!this.isAvailabileDate(availability, date)) {
            return false;
        }
        if (!this.isAvailabileHours(availability, date)) {
            return false;
        }
        return true;
    }

    static getFutureDates = (availability) => {
        let now = DateService.now();
        return availability.dates.map(date => {
            return {
                date_from: parseDate(date.date_from),
                date_to: parseDate(date.date_to)
            }
        }).filter(date => {
            return date.date_to > now;
        }).sort((a, b) => a.date_from - b.date_from)
        // .sort((a, b) => new Date(a.date_from) - new Date(b.date_from))
    }
    static sortOpenHours = (hours) => {
        let weekDays = getWeekDays();
        return hours.sort((a, b) => weekDays.indexOf(a.day_from.toLowerCase()) - weekDays.indexOf(b.day_from.toLowerCase()));
    }
    static getOpenHours = (availability, date) => {
        if (availability === null) {
            return [];
        }
        let openHours = getOpenHours(availability.hours, date);
        if (!availability.dates || availability.dates.length == 0) {
            return openHours;
        }
        let dateMin = new Date(date);
        dateMin.setHours(0, 0, 0);
        let dateMax = new Date(date);
        dateMax.setHours(23, 59, 59)
        let availabilityDates = availability.dates.map(availabilityDate => {
            return {
                date_from: parseDate(availabilityDate.date_from),
                date_to: parseDate(availabilityDate.date_to)
            }
        }).filter(availabilityDate => {
            if (dateMax > availabilityDate.date_from && dateMin < availabilityDate.date_to) {
                return true;
            }
            return false;
        });
        // if(availabilityDates.some(availabilityDate => {
        //     return sameDateWithoutTime(date, availabilityDate);
        // })){
        //     return openHours;
        // }

        let hoursDates = availabilityDates.map(availabilityDate => {
            let hours = {}
            if (sameDateWithoutTime(date, availabilityDate.date_from)) {
                hours.hour_from = (new Date(date));
                hours.hour_from.setHours(availabilityDate.date_from.getHours(), availabilityDate.date_from.getMinutes(), 0);
            } else {
                hours.hour_from = (new Date(date));
                hours.hour_from.setHours(0, 0, 0);
            }
            if (sameDateWithoutTime(date, availabilityDate.date_to)) {
                hours.hour_to = (new Date(date));
                hours.hour_to.setHours(availabilityDate.date_to.getHours(), availabilityDate.date_to.getMinutes(), 0);
                // hours.hour_to = availabilityDate.date_to.getHours() + ":" + availabilityDate.date_to.getMinutes();
            } else {
                hours.hour_to = (new Date(date));
                hours.hour_to.setHours(0, 0, 0);
            }
            return hours;
        })
        let newOpenHours = [];
        openHours.forEach(openHour => {
            let newDateFrom = null;
            let newDateTo = null;
            hoursDates.forEach(hourDate => {
                if (!newDateFrom || newDateFrom > hourDate.hour_from) {
                    newDateFrom = hourDate.hour_from;
                }
                if (!newDateTo || newDateTo < hourDate.hour_to) {
                    newDateTo = hourDate.hour_to;
                }
            })
            let OpenHourDateFrom = setTimeByString(new Date(date), openHour.hour_from);
            if (isTimeAllDay(newDateFrom) || newDateFrom < OpenHourDateFrom) {
                newDateFrom = OpenHourDateFrom;
            }
            let OpenHourDateTo = setTimeByString(new Date(date), openHour.hour_to);
            if (isTimeAllDay(OpenHourDateTo)) OpenHourDateTo.setHours(23, 59, 59)
            if (isTimeAllDay(newDateTo) || newDateTo > OpenHourDateTo) {
                newDateTo = OpenHourDateTo;
            }
            if (newDateTo && newDateFrom) {
                newOpenHours.push({
                    hour_from: getHourTimeString(newDateFrom),
                    hour_to: getHourTimeString(newDateTo)
                })
            }

        })
        return newOpenHours;
    }

    static getHoursAvailable = (availabilities, date) => {
        let availableHours = [];
        if (!date) {
            return;
        }

        availabilities.forEach(availability => {
            let openHours = AvailableHelper.getOpenHours(availability, date);
            openHours.forEach(openHour => {
                let availableHour = availableHours.filter(ah => {
                    return ah.hour_from > openHour.hour_from || ah.hour_to < openHour.hour_to
                })[0];
                if (availableHour === undefined) {
                    availableHours.push(openHour)
                } else {
                    if (availableHour.hour_from > openHour.hour_from) {
                        availableHour.hour_from = openHour.hour_from
                    }
                    if (availableHour.hour_to < openHour.hour_to) {
                        availableHour.hour_to = openHour.hour_to
                    }
                }
            })
        })
        return availableHours;
    }
    static getAllDates = (menus, availabilities) => {
        let dates = [];
        let isAlwaysAvailableMenu = false;
        menus.forEach(menu => {
            if (!menu.pick_date) {
                return;
            }
            if (menu.availability_id) {
                let availability = AvailableHelper.getAvailability(availabilities, menu.availability_id);
                if (!availability || !availability.dates || availability.dates.length === 0) {
                    isAlwaysAvailableMenu = true;
                    return null;
                }
                if (availability && availability.dates && availability.dates.length > 0) {
                    availability.dates.forEach(date => {

                        dates.push({
                            date_from: parseDate(date.date_from).setHours(0, 0, 0),
                            date_to: parseDate(date.date_to).setHours(23, 59, 59)
                        });
                    })
                }
            } else {
                isAlwaysAvailableMenu = true;
                return null;
            }
        });
        if (isAlwaysAvailableMenu) {
            return null;
        }
        return dates;
    }
    static getAllWeekDays = (menus, availabilities) => {
        let weekDays = [];
        menus.forEach(menu => {
            if (!menu.pick_date) {
                return;
            }
            if (menu.availability_id) {
                let availability = AvailableHelper.getAvailability(availabilities, menu.availability_id);
                availability.hours.forEach(hour => {
                    if(!weekDays.includes(hour.day_from)){
                        weekDays.push(hour.day_from);
                    }
                })
            }
        });
        return weekDays;
    }
    static getTime = (i) => {
        return (i >= 24) ? "00" : (i < 10) ? "0" + i : i;
    }
    static isMinuteAvailable = (i, minHours, maxHours, minMinutes, maxMinutes, time) => {
        if (i !== minHours && i !== maxHours) {
            return true;
        }
        if (i === minHours && minMinutes > time) {
            return false;
        }
        if (i === maxHours && maxMinutes < time) {
            return false;
        }
        return true;
    }
    static getDatesToPickDate = (date, availabilities, addMinutesForEstimate) => {
        let minTodayHour = 0;
        let minTodayMinute = 0;
        let dateWithAddExtraMinutes = date;
        // let dateWithAddExtraMinutes = new Date(date.getTime() + 30 * 60000);
        minTodayHour = dateWithAddExtraMinutes.getHours();
        minTodayMinute = dateWithAddExtraMinutes.getMinutes();
        var arr = [];

        let max = 23;

        // let hours = AvailableHelper.getHoursAvailable(availabilities, DateHelper.fromString(date + " 00:00:00"));
        let hours = AvailableHelper.getHoursAvailable(availabilities, date);
        var minTime = hours && hours.length > 0 ? hours.reduce((a, b) => a.hour_from > b.hour_from ? a : b).hour_from : null;
        var maxTime = hours && hours.length > 0 ? hours.reduce((a, b) => a.hour_to > b.hour_to ? a : b).hour_to : null;
        var minHours = minTime ? parseInt(minTime.split(":")[0]) : 0;
        var maxHours = maxTime ? parseInt(maxTime.split(":")[0]) - 1 : 23;
        var minMinutes = minTime ? parseInt(minTime.split(":")[1]) : 0;
        var maxMinutes = maxTime ? parseInt(maxTime.split(":")[1]) : 59;
        if (parseInt(maxHours) <= 0) {
            maxHours = 23;
        }
        if (parseInt(maxMinutes) === 0) {
            maxMinutes = 59;
        }
        if(addMinutesForEstimate > 0){
            minMinutes = minMinutes + addMinutesForEstimate;
            if (minMinutes >= 60) {
                const extraHours = Math.floor(minMinutes / 60);
                minHours += extraHours;
                minMinutes %= 60; // Keep the remaining minutes less than 60
            }
        }

        if(minHours < minTodayHour || (minHours === minTodayHour && minMinutes < minTodayMinute)){
            minHours = minTodayHour;
            minMinutes = minTodayMinute;
            // minHours = minHours > minTodayHour ? parseInt(minHours) : minTodayHour;
            // minMinutes = minMinutes > minTodayMinute ? minMinutes : minTodayMinute;
        }
        maxHours = maxHours <= max ? parseInt(maxHours) : max;
        const createDate = (date, hours, minutes) => {
            let dateTmp = new Date(date.getTime());
            dateTmp.setHours(hours);
            dateTmp.setMinutes(minutes);
            dateTmp.setSeconds(0);
            return dateTmp;
        }
        for (let i = minHours; i <= maxHours; i++) {
            let iS = AvailableHelper.getTime(i);
            if (AvailableHelper.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 0)) {
                arr.push(createDate(dateWithAddExtraMinutes, iS, 0))
            }
            if (AvailableHelper.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 15)) {
                arr.push(createDate(dateWithAddExtraMinutes, iS, 15))
            }
            if (AvailableHelper.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 30)) {
                arr.push(createDate(dateWithAddExtraMinutes, iS, 30))
            }
            if (AvailableHelper.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 45)) {
                arr.push(createDate(dateWithAddExtraMinutes, iS, 45))
            }
        }

        return arr;
    }
    static getAddMinutesToPickDateFromNow = (orderType, estimated_preparation_duration, estimated_delivery_duration) => {
        let todayWithMinStoreMinutes = DateService.now();
        let allAddEstimatedMinutes = AvailableHelper.getAddMinutesToPickDate(orderType, estimated_preparation_duration, estimated_delivery_duration);
        todayWithMinStoreMinutes = new Date(todayWithMinStoreMinutes.getTime() + (allAddEstimatedMinutes)*60000);
        return todayWithMinStoreMinutes;
    }
    static getAddMinutesToPickDate = (orderType, estimated_preparation_duration, estimated_delivery_duration) => {
        let allAddEstimatedMinutes = 0;
        if(estimated_preparation_duration) allAddEstimatedMinutes += estimated_preparation_duration;
        if(orderType == "DELIVERY"){
            if(estimated_delivery_duration) allAddEstimatedMinutes += estimated_delivery_duration;
        }
        return allAddEstimatedMinutes;
    }
}

export default AvailableHelper;