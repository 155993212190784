class ModifierGroupHelper {

    static isAvailabile(modifierGroup){
        if(modifierGroup.status !== 'ENABLED'){
            return false;
        }
        return true;
    }
}


export default ModifierGroupHelper;