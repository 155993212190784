class ApiService {

    static baseUrl = process.env.REACT_APP_API_DOMAIN + '';

    static setStoreDomain(storeDomain){
        if(storeDomain){
            this.baseUrl = this.baseUrl + "/" + storeDomain;
        }
    }
    static getBaseUrl(){
        return this.baseUrl;
    }
    static getHeaders(params){
        let headers = {
            'store-domain': window.location.hostname,
        }

        headers = {...headers, ...params};
        // if(params != null){
        //     headers = [...headers, ...params];
        // }
        return headers;
    }

}
export default ApiService;