import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "./ErrorFormHelper";
import ErrorFormView from "./ErrorFormView";
import ErrorFormAlertView from "./ErrorFormAlertView";
import {updateOrderData} from "../../application/cart/cartService";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getCart} from "../../application/cart/reducer";
import Languages from "./Languages";
import UserService from "../../application/user/userService";
import {getUser} from "../../application/user/reducer";
import Cookies from "universal-cookie/lib";

const cookies = new Cookies();
function PasswordProtectedModal(props) {
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const t = props.t;
    const loading = false;

    const login = async () => {
        if(props.password == password){
            let now = new Date();
            let expirationTime = new Date(now.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds

            cookies.set("passwordLogged", true, {expires: expirationTime});
            props.onLogged();
        }else{
            setErrors([
                {
                    "code": "wrong_login",
                    "message": "Nie udało się zalogować"
                }
            ])
        }
    }

    return(
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={true}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            Hasło wymagane aby otworzyć sklep
                        </Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        <ErrorFormAlertView errors={errors} />
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder={t("Hasło")} aria-label="Recipient's username" aria-describedby="cart-password" />
                            <label htmlFor="cart-password">Hasło</label>
                            <ErrorFormView errors={errors} field="password" />
                        </div>
                    </div>
                    <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} variant="primary" onClick={()=>{login()}}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.login")}
                    </button>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {
    }
}
export default withTranslation()(connect(mapStateToProps)(PasswordProtectedModal))
