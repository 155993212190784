window.CardWidget = (function () {
    "use strict";

    var self = {
            actionURL: "https://cards.bm.pl",
            sendCardsForm: sendCardsForm,
            eventListener: eventListener
        },
        statuses = {
            sendForm: {
                name: "SEND_FORM"
            },
            paymentToken: {
                name: "PAYMENT_TOKEN"
            },
            error: {
                name: "WIDGET_ERROR"
            }
        },
        parseData;

    function sendCardsForm(iframeHandle) {
        iframeHandle.postMessage(JSON.stringify({status: statuses.sendForm.name}), self.actionURL);
    }

    function eventListener(e) {
        let data = e.originalEvent !== undefined ? e.originalEvent.data : e.data;
        parseData = JSON.parse(data);

        if (parseData.status === statuses.paymentToken.name) {
            return parseData;
        }
        if (parseData.status === statuses.error.name) {
            return parseData;
        }
        if (parseData.status === "IS_VALIDATED") {
            return parseData;
        }
    }

    return self;

}).call(this);