import React from 'react';
import {withTranslation} from "react-i18next";
import BaseSearchComponent from "./BaseSearchComponent";
import {getCities} from "../../../application/menu/fetchMenu";

const CitySearchComponent = (props)=>{
    const fetch = async (query) => {
        let response = await getCities(query);
        return response.map((c) => {
            let isOnlyCity = !c.postalCode || response.some(x => x.cityName == c.cityName);
            if(isOnlyCity){
                return {
                    description:c.cityName,
                    postalCode: c.postalCode
                }
            }
            return {
                description:c.cityName,
                postalCode: c.postalCode,
                renderText: () => {
                    return (
                        <>
                            {c.cityName}
                            {c.postalCode && (
                                <small>
                                    , {c.postalCode}
                                </small>
                            )}
                        </>
                    )
                }
            }
        })
    }
    const onChange = (value) => {
        props.onChange(value.description, value.postalCode);
    }
    const onBlur = (value) => {
        props.onBlur(value.description, value.postalCode);
    }


    return (
        <BaseSearchComponent fetch={fetch} value={{description: props.value, postalCode: props.zip}} onBlur={onBlur} onChange={onChange} placeholder={props.placeholder} />
    )
}

export default withTranslation()(CitySearchComponent)