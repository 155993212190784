import {parseDate} from "../common/DateHelper";

class DateService {

    static serverDate = null;
    static dateNow = null;
    static init(serverDate){
        this.serverDate = parseDate(serverDate);
        this.dateNow = new Date();
    }
    static now(){
        if(this.serverDate == null){
            return new Date();
        }
        var difference = new Date() - this.dateNow;
        return new Date(this.serverDate.getTime() + difference);
    }
    static emptyOrNow(date){
        // return date !== null && date ? new Date(date) : this.now();
        let das=  (date !== null && date !== undefined) && date ? parseDate(date) : this.now();
        return das;
    }

}
export default DateService;