import React from 'react';
import {connect} from "react-redux";
import {getStore} from "../../application/store/reducer";
import AppConfig from "../../application/common/AppConfig";
import {Link} from "react-router-dom";


const NavbarMenus = (props)=>{
    if(AppConfig.isWidget()){
        return null;
    }
    let renderStoreLinkView = function(storeLink){
        if(storeLink.link.includes("http") || storeLink.link.includes("www")){
            return (
                <a className="nav-link" href={storeLink.link}>
                    {storeLink.name}
                </a>
            )
        }
        return (
            <Link className="nav-link" to={`${storeLink.link}`}>
                {storeLink.name}
            </Link>
        )
    }
    let linksView = null;
    if(props.links && props.links.length > 0){
        linksView = (
            <ul className="footer-links">
                {
                    props.links.filter(x => x.type === "MENU").sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((storeLink) =>
                        <li className="" key={storeLink.id}>
                            {renderStoreLinkView(storeLink)}
                        </li>
                    )
                }
            </ul>
        )
    }
    return(
        <ul className="navbar-nav">
            {
                props.links.filter(x => x.type === "MENU").sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((storeLink) =>
                    <li className="nav-item" key={storeLink.id}>
                        {renderStoreLinkView(storeLink)}
                    </li>
                )
            }
        </ul>
    )
}

const mapStateToProps = state => ({
})
export default connect(mapStateToProps)(NavbarMenus)