import Cookies from "universal-cookie/lib";
import StorageStore from "./storageStore";

class UserContext {
    static token = null;
    static cookies = new Cookies();
    static initialize(){

    }
    static setToken(token){
        this.token = token;
        if(token !== undefined && token){
            this.cookies.set("utk", token, { path: StorageStore.getBaseName() });
        }else{
            this.cookies.remove("utk", { path: StorageStore.getBaseName() });
        }
    }
    static getToken(){
        if(this.token){
            return this.token;
        }
        const token = this.cookies.get("utk", { path: StorageStore.getBaseName() });
        if(token) return token;
        return null;
    }
}
export default UserContext;
