import React, { Component } from 'react';
import {connect} from "react-redux";
import {
    getCart,
    getCartDeliveryFee,
    getCartSubTotal,
    getCartTotal,
    getNumberItems, getOrderType
} from '../../../application/cart/reducer';
import {getLocationStore} from "../../../application/menu/reducer";
import Money from "../../common/Money";
import {withTranslation} from "react-i18next";
import {getStore} from "../../../application/store/reducer";
import cart from "../Cart";

class CartSummaryData extends Component{


    renderDeliverInfo(){
        const {delivery_fee, locationStore, orderType, total, t, store, cart} = this.props;
        let minPriceView = null;
        let freeDeliveryView = null;

        if(orderType !== 'ROOM_SERVICE' && orderType !== 'DELIVERY'){
            if(store.min_order_price){
                minPriceView = (
                    <div className="d-flex justify-content-between">
                        <div>
                            {t("components.cart.summary.min_order_price")}
                        </div>
                        <div>
                            <Money value={store.min_order_price} />
                        </div>
                    </div>
                );
            }
        }
        if(orderType === 'ROOM_SERVICE'){
            if(store.min_order_price){
                minPriceView = (
                    <div className="d-flex justify-content-between">
                        <div>
                            {t("components.cart.summary.min_order_price")}
                        </div>
                        <div>
                            <Money value={store.min_order_price} />
                        </div>
                    </div>
                );
            }
        }
        if(orderType === 'DELIVERY'){

            if(delivery_fee && delivery_fee.amount > 0){
                if(locationStore.free_delivery_price_money){
                    freeDeliveryView = (
                        <div className="d-flex justify-content-between">
                            <div>
                                {t("components.cart.summary.free_delivery_from")}
                            </div>
                            <div>
                                <Money value={locationStore.free_delivery_price_money} />
                            </div>
                        </div>
                    )
                }
            }else{

                if(total){
                    if(cart.sub_total_delivery_fee && cart.sub_total_delivery_fee.amount > 0){
                    }else{
                        freeDeliveryView = (
                            <div className="d-flex justify-content-between">
                                <strong>{t("components.cart.summary.free_delivery")}</strong>
                            </div>
                        )
                    }
                }
            }
            if(locationStore.min_order_price_money){
                minPriceView = (
                    <div className="d-flex justify-content-between">
                        <div>
                            {t("components.cart.summary.min_order_price")}
                        </div>
                        <div>
                            <Money value={locationStore.min_order_price_money} />
                        </div>
                    </div>
                )
            }
        }
        if(minPriceView === null && minPriceView === null){
            return null;
        }
        return (
            <>
                {minPriceView}
                {freeDeliveryView}
                <hr />
            </>
        )
    }
    renderDiscountMoney(){
        const {t} = this.props;
        if(this.props.cart === undefined || this.props.cart.discount_money === undefined || this.props.cart.discount_money === null || this.props.cart.discount_money.amount === 0){
            return null;
        }
        return (

            <div className="d-flex justify-content-between summary-discount-price">
                <div>
                    {t("common.word.discount")}
                </div>
                <div>
                    -<Money value={this.props.cart.discount_money}/>
                </div>
            </div>
        )
    }
    render(){
        const {delivery_fee, t, cart} = this.props;
        let deliveryView = null;

        if(cart.sub_total_delivery_fee && cart.sub_total_delivery_fee.amount > 0 && cart.discount_delivery_fee && cart.discount_delivery_fee.amount > 0){
            deliveryView = (
                <div className="d-flex justify-content-between">
                    <div>
                        {t("common.word.delivery")}
                    </div>
                    <div>
                        <span className="text-danger">
                            <del>
                            <Money
                                value={cart.sub_total_delivery_fee}
                                currency='PLN' />
                            </del>
                        </span>
                        <Money
                            value={delivery_fee}
                            currency='PLN' />
                    </div>
                </div>
            )
        }else{
            if(delivery_fee && delivery_fee.amount > 0){
                deliveryView = (
                    <div className="d-flex justify-content-between">
                        <div>
                            {t("common.word.delivery")}
                        </div>
                        <div>
                            <Money value={delivery_fee}/>
                        </div>
                    </div>
                )
            }
        }

        return(
            <div>
                {this.renderDeliverInfo()}
                <div className="d-flex justify-content-between">
                    <div>
                        {t("components.order.summary_items")}: {this.props.numberItems}
                    </div>
                    <div>
                        <Money value={this.props.sub_total}/>
                    </div>
                </div>
                {deliveryView}
                {this.renderDiscountMoney()}
                <div className="d-flex justify-content-between summary-total-price">
                    <div>
                        <strong>{t("common.word.sum")}</strong>
                    </div>
                    <div>
                        <strong>
                            <Money value={this.props.total}/>
                        </strong>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    cart: getCart(state.cart),
    total: getCartTotal(state.cart),
    store: getStore(state.store),
    numberItems: getNumberItems(state.cart),
    delivery_fee: getCartDeliveryFee(state.cart),
    sub_total: getCartSubTotal(state.cart),
    orderType: getOrderType(state.cart),
    locationStore: getLocationStore(state.menu)
})

export default withTranslation()(connect(mapStateToProps)(CartSummaryData))