import React from "react";

class CustomFieldUtils {

    static getVisible(name, orderCustomFields, type){
        let orderCustomField = orderCustomFields.filter(orderCustomField => orderCustomField.slug === name && orderCustomField.type === type)[0];
        if(orderCustomField){
            return orderCustomField.visibility;
        }
        switch (name) {
            case "name":
                return "REQUIRED"
            case "phone":
            case "email":
                switch (type) {
                    case "DELIVERY":
                    case "PICK_UP":
                        return "REQUIRED"
                }
                return "VISIBLE"
            case "taxIdNo":
                return "HIDDEN"
            case "acceptMarketing":
                return "HIDDEN"
                // return "VISIBLE"
        }
        return "VISIBLE";
    }
    static isRequired(value){
        return value === "REQUIRED" ? true : false;
    }
    static isVisible(value){
        return value === "VISIBLE" || this.isRequired(value) ? true : false;
    }
}
export default CustomFieldUtils;
