import React, {Component} from 'react';
import {ScrollToTop} from "../../utils/ScrollToTop";
import CartSticky from "../cart/CartSticky";
import StoreInfo from "./components/StoreInfo";
import Footer from "../../layout/Footer";
import CartButton from "../../common/CartButton";
import {getPages, getStore, isPreview} from "../../../application/store/reducer";
import {connect} from "react-redux";
import classNames from "classnames";
import AppConfig from "../../../application/common/AppConfig";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class Page extends Component{
    render(){
        const {match, pages, t} = this.props;
        let pageId = match.params.pageId;
        let page = pages.filter(x => x.slug ==pageId)[0];
        if(page === undefined || !page){
            return (
                <div>
                    <h2>
                        {t("errors.internal.page_not_exist")}
                    </h2>
                </div>
            )
        }

        return (
            <React.Fragment>
                <ScrollToTop/>
                <div className="menu-page">
                    <CartSticky goToCart={this.goToCart}></CartSticky>
                    <StoreInfo/>

                    <div className={classNames('page body-container', {
                        'body-container-cart': AppConfig.isCart(),
                    })}>
                        <div className="container">
                            <h2>{page.title}</h2>
                            <div dangerouslySetInnerHTML={{__html: page.content}} />
                        </div>
                        <Footer/>
                    </div>
                    <div className="btn-submit-bottom fixed-bottom btn-submit-container">
                        <CartButton goToCart={this.goToCart} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => ({
    store: getStore(state.store),
    pages: getPages(state.store),
    preview: isPreview(state.store)
})
export default withTranslation()(connect(mapStateToProps)(Page))
