import React, {Component} from 'react';
import {connect} from "react-redux";
import MenuScrollSpy from "../../../utils/MenuScrollSpy";
import ScrollMenuTo from "../../../utils/ScrollMenuTo";
import ScrollContainer from "react-indiana-drag-scroll";
import PromotionHelper from "../../../../application/common/PromotionHelper";
import {getCartTotal} from "../../../../application/cart/reducer";

class MenuCategoryNav extends Component{

    render(){
        let menuCategories = this.props.categories;
        let menu = this.props.menu;
        let activePromotions = PromotionHelper.getActivePromotionsForCategory(this.props.promotions, this.props.cartTotal);
        return(
            <div className="container">

                <ScrollContainer className="nav nav-tabs-top" hideScrollbars={false}>
                        <MenuScrollSpy key={menu.id}>
                            {activePromotions.map(promotion =>
                                <li className="nav-item" key={'category-promotion-' + promotion.id}>
                                    <ScrollMenuTo to={'category-promotion-' + promotion.id}>
                                        {promotion.name}
                                    </ScrollMenuTo>
                                </li>
                            )}
                            {
                                menuCategories.map((category, i) =>
                                    <li className="nav-item" key={category.id}>
                                        <ScrollMenuTo to={'category-' + category.id}>
                                            {category.name}
                                        </ScrollMenuTo>
                                    </li>
                                )
                            }
                        </MenuScrollSpy>
                </ScrollContainer>
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        cartTotal: getCartTotal(state.cart)
    }
}

export default connect(mapStateToProps)(MenuCategoryNav)