import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Money from "../../../../../common/Money";
import {ReactComponent as CheckCircleSVG} from "../../../../../../images/icons/check-circle.svg";
import {ReactComponent as NoImageSVG} from "../../../../../../images/icons/no-image.svg";
import MenuEditItemViewHelper from "../../MenuShowItem/MenuEditItemViewHelper";
import AppConfig from "../../../../../../application/common/AppConfig";

const MenuItemOptionWithImage = (props) => {
    let optionStateItem = props.stateItem;
    let modifierGroupId = props.modifierGroupId;
    let optionItemView = props.optionItemView;
    let price = optionItemView.price;
    let disableAdd = props.disableAdd ? props.disableAdd : false;
    let singlePick = props.singlePick;
    let quantity = optionStateItem ? optionStateItem.quantity : 0;
    let errors = props.errors;

    let onClickItem = (e) => {

        if (disableAdd) return;
        if(!singlePick){
            props.onUpdate(modifierGroupId, optionItemView, quantity+1);
            return;
        }
        if (optionStateItem) {
            props.onUpdate(modifierGroupId, optionItemView, 0);
        } else {
            props.onUpdate(modifierGroupId, optionItemView, 1);
        }
    }

    const subQuantityItem = () => {
        props.onUpdate(modifierGroupId, optionItemView, quantity-1);
    }
    const addQuantityItem = (modifierGroup, item) => {
        props.onUpdate(modifierGroupId, optionItemView, quantity+1);
    }
    const renderItemInfo = (optionStateItem, optionItemView) => {
        let nameOptions = [];
        if(!optionStateItem.modifier_groups) return null;
        optionStateItem.modifier_groups.forEach(mg => {
            let modifierGroupView = optionItemView.modifier_groups.filter(x => x.id === mg.modifier_group_id)[0];
            mg.selected_items.forEach(mgo => {
                if (mgo.quantity > 0) {
                    let itemView = modifierGroupView.options.filter(x => x.id === mgo.item_id)[0];
                    nameOptions.push(`${mgo.quantity}x${itemView.name}`);
                }
            })
        })
        if (nameOptions.length === 0) return null;
        return (
            <p>
                {nameOptions.join(", ")}
            </p>
        );
    }
    const renderErrors = (errors) => {
        return (
            <>
                {errors.map(error => {
                        return (
                            <p className="product-item-modifiers-errors" key={`${modifierGroupId}-${optionItemView.id}-${error.message}-${error.resource ? error.resource : ""}`}>{error.message} {error.resource ? error.resource : ""}</p>
                        )
                    }
                )}
            </>
        )
    }
    const getErrors = (item) => {
        // let errors = [];
        // item.modifier_groups.forEach(itemModifierGroup => {
        //     if (itemModifierGroup.errors.length > 0) {
        //         itemModifierGroup.errors.forEach(error => {
        //             errors.push({
        //                 message: error.message,
        //                 resource: itemModifierGroup.name
        //             })
        //         })
        //     }
        // });
        return MenuEditItemViewHelper.getErrors(item);
    }
    const renderMultilevelOption = () => {
        if (!optionStateItem) return null;
        let errors = getErrors(optionItemView);
        return (
            <div className={"product-option-multilevel"}>
                {renderItemInfo(optionStateItem, optionItemView)}
                {renderErrors(errors)}
                {optionItemView.is_customize && (
                    <>
                        {errors.length > 0 ? (
                            <button onClick={() => props.onEdit(optionStateItem)}
                                    className={"btn btn-primary w-100"}>
                                Wybierz pozycję
                            </button>
                        ) : (
                            <button onClick={() => props.onEdit(optionStateItem)}
                                    className={"btn btn-primary w-100"}>
                                Dostosuj pozycję
                            </button>
                        )}
                    </>
                )}
            </div>
        )
    }
    return (
        <div className="product-modifier-option1" key={modifierGroupId + "-" + optionItemView.id}>
            <>
                <div className={`product-option-with-image${optionStateItem ? " active" : ""}${errors !== undefined && errors.length > 0 ? " error" : ""}`}>
                    <div className={"check-circle"}>
                        <CheckCircleSVG/>
                    </div>
                    <div className={"product-option-body"} onClick={onClickItem}>
                        <div className={"card-img"}>
                            {optionItemView.image_link ? (
                                <img
                                    src={optionItemView.image_link.default}/>
                            ) : (
                                <NoImageSVG/>
                            )}
                        </div>
                        <div className={"product-option-content"}>
                            <h4>
                                {optionItemView.name}
                            </h4>
                            {optionItemView.description && (
                                <p>{optionItemView.description}</p>
                            )}
                            <div className={"product-option-actions"}>
                                <div className={"price"}>
                                    {optionStateItem ? (
                                        <>
                                            +<Money
                                            value={optionStateItem.total_price}
                                            currency='PLN'/>
                                            {(quantity > 1 || (AppConfig.isShowFreeInfo() && optionStateItem.quantity_free > 0)) && (
                                                <small>
                                                    {" "}
                                                    (
                                                    {quantity > 1 && (
                                                        <>
                                                            <Money
                                                                value={price}
                                                                currency='PLN'/> za 1 szt.
                                                        </>
                                                    )}
                                                    {(AppConfig.isShowFreeInfo() && optionStateItem.quantity_free > 0) && (
                                                        <>
                                                            {quantity > 1 && ", "}
                                                            {optionStateItem.quantity_free} darmowe
                                                        </>
                                                    )}
                                                    )
                                                </small>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Money
                                                value={price}
                                                currency='PLN'/>
                                        </>
                                    )}
                                </div>
                                {!singlePick && (
                                    <div className={"product-option-quantity"} onClick={e => e.stopPropagation()}>
                                        <div className="input-group input-group-sm input-group-quantity">
                                            <div className="input-group-prepend">
                                                <button className="btn  btn-secondary btn-sm" type="button" id="button-addon1" onClick={(e) => subQuantityItem(e)}>-</button>
                                            </div>
                                            <span className={"input-group-text"}>{quantity}</span>
                                            <div className="input-group-append">
                                                <button disabled={disableAdd} className="btn btn-secondary btn-sm" type="button" id="button-addon1" onClick={(e) => addQuantityItem(e)}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {(errors !== undefined && errors.length > 0) && (
                        <div className={"product-option-errors"}>
                            {
                                errors.map((error) =>
                                    <div className="text-danger error" key={`${modifierGroupId}-${optionItemView.id}-${error.message}`}>{error.message}</div>
                                )
                            }
                        </div>
                    )}
                    {renderMultilevelOption()}
                </div>
            </>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {}
}

export default withTranslation()(connect(mapStateToProps)(MenuItemOptionWithImage))