export const FETCH_STORE_PENDING = 'FETCH_STORE_PENDING';
export const FETCH_STORE_ERROR = 'FETCH_STORE_ERROR';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const SET_STORE_LOCALE = 'SET_STORE_LOCALE';

export function fetchStorePending() {
    return {
        type: FETCH_STORE_PENDING
    }
}

export function fetchStoreError(error) {
    return {
        type: FETCH_STORE_ERROR,
        error: error
    }
}

export function fetchStoreSuccess(data) {
    return {
        type: FETCH_STORE_SUCCESS,
        data: data
    }
}


export function setStoreLocale(locale) {
    return {
        type: SET_STORE_LOCALE,
        locale: locale,
    }
}
