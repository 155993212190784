import React from 'react';
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import MenuProductListItem from "./MenuProductListItem";
import ItemHelper from "../../../../application/common/ItemHelper";
import {getAvailabilities, getItems} from "../../../../application/menu/reducer";
import {ReactComponent as BogoIco} from "../../../../images/icons/bogo.svg";
import {ReactComponent as VouchersIco} from "../../../../images/icons/vouchers.svg";
import PromotionHelper from "../../../../application/common/PromotionHelper";
import {getCartTotal} from "../../../../application/cart/reducer";
import ItemViewHelper from "../../../../application/common/ItemViewHelper";
import Utils from "../../../utils/Utils";

const MenuPromotionsCategory = (props)=>{
    const { t } = useTranslation();
    let promotions = props.promotions;
    if(promotions === undefined || !promotions || promotions.length === 0){
        return null;
    }
    let activePromotions = PromotionHelper.getActivePromotionsForCategory(promotions, props.cartTotal);
    const getItemPromotions = (promotion) => {
        return promotion.entities.map(x => {
           return ItemViewHelper.parseItem(ItemHelper.getItem(props.items, x.entity_id), props.availabilities, activePromotions, props.pick_date, undefined);
        }).filter(x => x !== null &&  x !== undefined);
    }
    const renderIcon = (promotion) => {
         switch (promotion.type){
             case "BOGO":
                 return (
                     <BogoIco />
                 )
             case "vouchers":
                 return (
                     <BogoIco />
                 )
         }
        return (
            <BogoIco />
        )
         // return null;
    }
    return (
        <div>
            {activePromotions.map(promotion => {
                return (
                    <section className="menu-category container scrollspy-section" id={'category-promotion-' + promotion.id} key={promotion.id}>
                        <h4>
                            {renderIcon(promotion)}
                            {promotion.name}
                        </h4>
                        <p dangerouslySetInnerHTML={{__html: Utils.nl2brString(promotion.description)}}></p>
                        <div className="menu-items menu-items-grid">
                            {
                                getItemPromotions(promotion).sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                                        return (
                                            <MenuProductListItem promotions={promotions} preview={props.preview} menuId={props.menu.id} addItem={props.addItem} categoryId={item.id} product={item} key={'item-' + item.id + '-' +  promotion.id}/>
                                        )
                                    }
                                )
                            }
                        </div>
                    </section>
                )
            })}
        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        cartTotal: getCartTotal(state.cart),
        availabilities: getAvailabilities(state.menu)
        // type: getOrderType(state.cart),
        // store: getStore(state.store),
        // cart: getCart(state.cart)
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuPromotionsCategory))