import React, {useState} from 'react';
import {connect} from "react-redux";
import Money from "../../../../common/Money";
import {withTranslation} from "react-i18next";
import {getItems, isItemMultiLevel} from "../../../../../application/menu/reducer";
import {ReactComponent as CheckCircleSVG} from "../../../../../images/icons/check-circle.svg";
import {ReactComponent as RemoveSVG} from "../../../../../images/icons/plus-remove.svg";
import {Button} from "react-bootstrap";
import StateItemHelper from "../MenuShowItem/StateItemHelper";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import MenuShowItemSingleV3 from "./MenuShowItemSingleV3";
import ItemHelper from "../../../../../application/common/ItemHelper";
import MenuEditItemViewHelper from "../MenuShowItem/MenuEditItemViewHelper";

const MenuModifierGroupsPickV2 = (props) => {
    const {t, stateItem, modifierGroup, itemView} = props;

    const getRenderHelpQuantityInfo = (modifierGroup) => {
        let additionalInfos = [];
        if (modifierGroup.min_permitted !== null && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== null && modifierGroup.max_permitted === 1) {

        } else {
            if (modifierGroup.min_permitted !== null && modifierGroup.min_permitted > 0) {
                additionalInfos.push({title: t("components.menu.min_permitted"), value: modifierGroup.min_permitted});
            }
            if (modifierGroup.max_permitted !== null) {
                additionalInfos.push({title: t("components.menu.max_permitted"), value: modifierGroup.max_permitted});
            }
            if (modifierGroup.quantity !== null) {
                additionalInfos.push({title: t("components.menu.default_quantity"), value: modifierGroup.quantity});
            }
            if (modifierGroup.charge_above !== null) {
                additionalInfos.push({title: t("components.menu.charge_above"), value: modifierGroup.charge_above});
            }
        }
        let errorView = null;
        if (modifierGroup.errors !== undefined) {
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error) =>
                            <p className="text-danger error" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if (additionalInfos.length !== 0) {
            additionalInfoView = (
                <React.Fragment>
                    <ul className="list-inline">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={additionalInfo.title}>{additionalInfo.title}: <strong>{additionalInfo.value}</strong>
                                </li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    if (modifierGroup.max_permitted !== null && modifierGroup.max_permitted <= 0) {
        return null;
    }

    const editSubLevel = (stateSubItem, id) => {
        let subItem = ItemHelper.getItem(props.items, stateSubItem.item_id)
        props.onChangePage("MODAL_EDIT", {
            image: subItem.image_link ? subItem.image_link.default : undefined
        }, () => {
            const updateSubItem = (newStateItem) => {
                let modifierGroupId = newStateItem.parent_modifier_group_id;
                let copyItem = Object.assign({}, stateItem);
                copyItem.modifier_groups.forEach(mg => {
                    if (mg.modifier_group_id !== modifierGroupId) return;
                    mg.selected_items[id - 1] = newStateItem;
                });
                StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
                props.onUpdate(copyItem);
                props.onChangePage(undefined);
            }
            return (
                <>
                    <MenuShowItemSingleV3 parent={stateItem} onHide={() => props.onChangePage(undefined)}
                                          errors={props.errors} itemId={stateSubItem.item_id} menu={props.menu}
                                          onUpdate={updateSubItem} stateItem={stateSubItem}/>
                </>
            )
        })
    }
    const removeItem = (stateSubItem, id) => {
        let modifierGroupId = stateSubItem.parent_modifier_group_id;
        let copyItem = Object.assign({}, stateItem);
        copyItem.modifier_groups.forEach(mg => {
            if (mg.modifier_group_id !== modifierGroupId) return;
            mg.selected_items.splice(id - 1, 1);
        });
        StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        props.onUpdate(copyItem);
    }
    const selectItem = (stateSubItem, id) => {
        props.onChangePage("PICK_ONE", {modifier_group: modifierGroup, id: id, image: itemView.image_link ? itemView.image_link.default : undefined})
    }
    const renderItemInfo = (optionStateItem, optionItemView) => {
        if(!optionStateItem.modifier_groups) return null;
        let nameOptions = [];
        {
            optionStateItem.modifier_groups.forEach(mg => {
                let modifierGroupView = optionItemView.modifier_groups.filter(x => x.id === mg.modifier_group_id)[0];
                mg.selected_items.forEach(mgo => {
                    if (mgo.quantity > 0) {
                        let itemView = modifierGroupView.options.filter(x => x.id === mgo.item_id)[0];
                        nameOptions.push(`${mgo.quantity}x${itemView.name}`);
                    }
                })
            })
        }
        if (nameOptions.length === 0) return null;
        return (
            <p>
                {nameOptions.join(", ")}
            </p>
        );
    }
    const renderErrors = (errors) => {
        return (
            <>
                {errors.map(error => {
                        return (
                            <p className="product-item-modifiers-errors" key={`${error.message}-${error.resource ? error.resource : ""}`}>{error.message} {error.resource ? error.resource : ""}</p>
                        )
                    }
                )}
            </>
        )
    }
    const renderModifierGroups = (modifierGroup) => {
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? null : modifierGroup.max_permitted;
        const divElements = [];

        let number = 1;
        let stateItemModifierGroups = stateItem.modifier_groups.filter(x => x.modifier_group_id == modifierGroup.id);
        let itemViewModifierGroups = itemView.modifier_groups.filter(x => x.id == modifierGroup.id)[0];

        stateItemModifierGroups.forEach(stateItemModifierGroup => {
            stateItemModifierGroup.selected_items.forEach(optionItem => {
                let optionItemId = number;
                let itemOptionView = itemViewModifierGroups.options.filter(x => x.id == optionItem.item_id)[0];
                let errors = itemOptionView.errors;
                let itemInfo = renderItemInfo(optionItem, itemOptionView);
                divElements.push(
                    <div className="product-modifier-option1"
                         key={`${stateItemModifierGroup.modifier_group_id}-${optionItem.item_id}-${optionItemId}`}>
                        <div className={`product-option-with-image active`}>
                            <div className={"check-circle"}>
                                <CheckCircleSVG/>
                            </div>
                            <div className={"product-option-body"} onClick={() => itemOptionView.is_change_quantity ? selectItem(optionItem, optionItemId) : {}}>
                                <div className={"card-img"}>
                                    {itemOptionView.image_link && (
                                        <img
                                            src={itemOptionView.image_link.default}/>
                                    )}
                                </div>
                                <div className={"product-option-content"}>
                                    <h4>
                                        {itemOptionView.name}
                                    </h4>
                                    {itemOptionView.description && (
                                        <p>{itemOptionView.description}</p>
                                    )}
                                    <div className={"product-option-actions"}>
                                        <div className={"price"}>
                                            <>
                                                +<Money
                                                value={optionItem.total_price}
                                                currency='PLN'/>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(errors.length > 0 || itemInfo || itemOptionView.is_customize || itemOptionView.is_change_quantity) && (
                                <div className={"product-option-multilevel"}>
                                    {itemInfo}
                                    {renderErrors(errors)}
                                    <div className={"d-flex"}>
                                        {itemOptionView.is_customize && (
                                            <>
                                                <button onClick={() => editSubLevel(optionItem, optionItemId)}
                                                        className={"btn btn-sm btn-primary w-100"}>
                                                    Dostosuj
                                                </button>{" "}
                                            </>
                                        )}
                                        {itemOptionView.is_change_quantity && (
                                            <>
                                                <button onClick={() => selectItem(optionItem, optionItemId)}
                                                        className={"btn btn-sm btn-outline-primary w-100"}>
                                                    Zmień
                                                </button>{" "}
                                                <button onClick={() => removeItem(optionItem, optionItemId)}
                                                        className={"btn btn-sm btn-outline-danger btn-icon last"}>
                                                    <RemoveSVG/>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
                number++;
            })
        })
        // Loop to generate 10 div elements
        for (let i = number; i < min_permitted + 1; i++) {
            divElements.push(
                <div className={"product-modifier-option"} key={`picks_${modifierGroup.id}_${i}`}>
                    <Button disabled={i > number} key={`picks_${modifierGroup.id}_${i}`} className={"btn btn-primary w-100 btn-pick"}
                            onClick={() => selectItem(undefined, i)}>Wybierz {modifierGroup.name} #{i}</Button>
                </div>
            );
        }
        return (
            <>
                {divElements}
            </>
        )
    }
    return (
        <fieldset className="form-group">
            <legend>
                {modifierGroup.name} {props.quantity}
            </legend>
            {getRenderHelpQuantityInfo(modifierGroup)}
            <div>
                {renderModifierGroups(modifierGroup)}
            </div>

        </fieldset>
    )
}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuModifierGroupsPickV2))