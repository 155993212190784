import React from 'react';
import {connect} from "react-redux";
import {getCart, getCartPromotions, getContact, getOrderType} from "../../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import {getLoginProviders, getStore} from "../../../application/store/reducer";
import {getUser, getVouchers} from "../../../application/user/reducer";
import UserService from "../../../application/user/userService";
import {getIntegrations} from "../../../application/menu/reducer";
import AdvertUserComponent from "../../common/user/components/AdvertUser";
import AppConfig from "../../../application/common/AppConfig";

const CartApps = (props) => {
    const {t, type, store, user, integrations} = props;

    const removeClient = () => {
        console.log("removeClient");
        props.dispatch(UserService.logout()).then(() => {
            console.log("removeClient #2");
        }).catch(error => {
            console.log(error);
            // props.updateErrors(errors);
            // this.updateErrors(error.errors);
            // this.setState({loading: false, errors:error.errors});
        });
    }
    if (!integrations || integrations.length <= 0) {
        return null;
    }
    let clientsIntegration = integrations.filter(integration => {
        return integration.provider === "GOCLIENT" || integration.provider === "GOCRM";
    });
    if (clientsIntegration.length <= 0 && (!user || !user.name)) {
        return null;
    }

    return (
        <>
            {user && user.name ? (
                <div className={"card card-cart"}>
                    <div className="card-body">
                        <h3>
                            {t("components.apps.loyalty.title")}
                        </h3>
                        <p>
                        <span
                            dangerouslySetInnerHTML={{__html: t("components.apps.loyalty.description_logged", {"name": user.name})}}/>
                            <button className="btn btn-sm"
                                    onClick={(e) => removeClient()}>{t("components.apps.loyalty.description_logged_action")}</button>
                        </p>
                    </div>
                </div>
            ) : clientsIntegration.length > 0 ? (
                <>
                    {AppConfig.isUserRegistration() && (
                        <div className={"card card-cart"}>
                            <AdvertUserComponent/>
                        </div>
                    )}
                </>
            ) : null}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        contact: getContact(state.cart),
        type: getOrderType(state.cart),
        store: getStore(state.store),
        cart: getCart(state.cart),
        user: getUser(state.user),
        vouchers: getVouchers(state.user),
        integrations: getIntegrations(state.menu),
        loginProviders: getLoginProviders(state.store),
        orderPromotions: getCartPromotions(state.cart),
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartApps))