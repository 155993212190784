import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormAlertView from "../../common/ErrorFormAlertView";
import {getLocationRequest} from "../../../application/menu/fetchMenu";
import Utils from "../../utils/Utils";
import CitySearchComponent from "../../common/location/CitySearchComponent";
import StreetSearchComponent from "../../common/location/StreetSearchComponent";
import Loading from "../../common/Loading";
import LoadingContainer from "../../common/LoadingContainer";

function NavbarLocationForm(props){
    const { t } = props;
    const [address, setAddress] = useState(props.address);
    const [errors, setErrors] = useState(props.errors);

    // useEffect(() => {
    //     setAddress(props.address)
    // }, [props.address])
    const handleChangeStreet = (value, check) => {
        let newAddress = {...address, street: value};
        setAddress(newAddress)
        props.onChange(newAddress)
        if(check){
            onCheckLocationAddress(newAddress);
        }
    }
    const handleChangeCity = (value, zip, check) => {
        let newAddress = {...address, city: value, zip_code:zip};
        setAddress(newAddress)
        props.onChange(newAddress)
        if(check){
            onCheckLocationAddress(newAddress);
        }
    }
    const handleChangeBuildNr = (value) => {
        let newAddress = {...address, build_nr: value};
        setAddress(newAddress)
        props.onChange(newAddress)
    }
    const handleChangeFlatNr = (value) => {
        let newAddress = {...address, flat_nr: value};
        setAddress(newAddress)
        props.onChange(newAddress)
    }
    const onCheckLocationAddress = async (addressToCheck) => {
        if(!addressToCheck.city || !addressToCheck.build_nr) return;
        let addressData = {
            city: addressToCheck.city,
            street: addressToCheck.street,
            build_nr: addressToCheck.build_nr,
            flat_nr: addressToCheck.flat_nr,
            country: addressToCheck.country,
            zip_code: addressToCheck.zip_code,
        }
        let data = await getLocationRequest(addressData);
        if(!data.data.data.in_delivery_area){
            let errors = [{
                "field": "address",
                "code": "not_in_delivery_area"
            }];
            setErrors(errors);
            props.onErrors(errors);
        }else{
            setErrors([]);
            props.onErrors([]);
        }
    }
    const onCheckLocation = async () => {
        onCheckLocationAddress(address)
    }
    const handleChangeCountry = (value) => {
        let newAddress = {...address, country: value};
        setAddress(newAddress)
        props.onChange(newAddress)
        onCheckLocationAddress(newAddress)
    }
    let countries = [];
    if(props.store.settings && props.store.settings.countries){
        countries = Utils.countries(props.store.settings.countries);
    }
    let isSelectCities = false;
    if(props.store.settings && props.store.settings.cities_radius){
        isSelectCities = true;
    }

    return (
        <>
            <ErrorFormAlertView errors={errors} field="address" />
            {(countries && countries.length > 1) ?
                <>
                    <div className="form-row row">
                        <div className="form-group col-3">
                            <select value={address.country} onChange={(e) => handleChangeCountry(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.country")} id="country" placeholder={t("common.form.address.country") + "*"} >
                                {countries.map(country => (
                                    <option value={country.key} key={country.key}>{country.value}</option>
                                ))}
                            </select>
                            <label htmlFor="country">{t("common.form.address.country")}</label>
                            <ErrorFormView errors={errors} field="address.country" />
                        </div>
                        <div className={`form-group col-9${address.city ? " focus" : ""}`}>
                            {isSelectCities ?
                                <CitySearchComponent placeholder={t("common.form.address.city") + "*"} onBlur={(value, zip) => handleChangeCity(value, zip, true)} value={address.city}  zip={address.zip_code} onChange={(value, zip) => handleChangeCity(value, zip)} /> :
                                <input type="text" value={address.city} onBlur={onCheckLocation} onChange={(e) => handleChangeCity(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.city")} id="city" placeholder={t("common.form.address.city") + "*"} />
                            }
                            <label htmlFor="city">{t("common.form.address.city")}</label>
                            <ErrorFormView errors={errors} field="address.city" />
                        </div>
                    </div>
                </> :
                <>
                    <div className={`form-group${address.city ? " focus" : ""}`}>
                        {isSelectCities ?
                            <CitySearchComponent placeholder={t("common.form.address.city") + "*"} onBlur={(value, zip) => handleChangeCity(value, zip, true)} value={address.city}  zip={address.zip_code} onChange={(value, zip) => handleChangeCity(value, zip)} /> :
                            <input type="text" value={address.city} onBlur={onCheckLocation} onChange={(e) => handleChangeCity(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.city")} id="city" placeholder={t("common.form.address.city") + "*"} />
                        }
                        <label htmlFor="city">{t("common.form.address.city")}</label>
                        <ErrorFormView errors={errors} field="address.city" />
                    </div>
                </>
            }
            <div className="form-row row">
                <div className={`form-group col-6${address.street ? " focus" : ""}`}>
                    {isSelectCities ?
                        <StreetSearchComponent placeholder={t("common.form.address.street") + "*"} city={address.city} onBlur={(value) => handleChangeStreet(value, true)} value={address.street} onChange={(value) => handleChangeStreet(value)} /> :
                        <input type="text" value={address.street} onBlur={onCheckLocation} onChange={(e) => handleChangeStreet(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.street")} id="street" placeholder={t("common.form.address.street") + "*"} />
                    }
                    <label htmlFor="street">{t("common.form.address.street")}</label>
                    <ErrorFormView errors={errors} field="address.street" />
                </div>
                <div className="form-group col-3">
                    <input type="text" value={address.build_nr} onBlur={onCheckLocation} onChange={(e) => handleChangeBuildNr(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.build_nr")} id="build_nr" placeholder={t("common.form.address.number") + "*"} />
                    <label htmlFor="build_nr">{t("common.form.address.number")}</label>
                    <ErrorFormView errors={errors} field="address.build_nr" />
                </div>
                <div className="form-group col-3">
                    <input type="text" value={address.flat_nr} onChange={(e) => handleChangeFlatNr(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "address.flat_nr")} id="build_nr" placeholder={t("common.form.address.flat_nr") + ""} />
                    <label htmlFor="build_nr">{t("common.form.address.flat_nr")}</label>
                    <ErrorFormView errors={errors} field="address.flat_nr" />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarLocationForm))
