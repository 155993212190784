class PromotionHelper {

    static get(promotions, id){
        let obj = promotions.filter(x => x.id === id);
        if(obj.length > 0){
            return obj[0];
        }
        return null;
    }
    static getForItem(promotions, itemId){
        if(promotions === undefined) return []
        let obj = promotions.filter(x => x.entities.some(y => y.type == "ITEM" && y.entity_id == itemId));
        return obj;
    }
    static getSuggest(promotions, cart){
        if(cart === undefined || cart.total === undefined) return [];
        let activePromotions = this.getActivePromotionsForCategory(promotions, cart.total);
        let usedPromotions = cart.promotions.filter(x => x.value_money && x.value_money.amount);
        let suggestPromotions = activePromotions.filter(x => !usedPromotions.some(y => y.promotion_id == x.id));
        let suggestPromotions1 = [];
        suggestPromotions.forEach(promotion => {
            switch (promotion.type){
                case "BOGO":
                    promotion.entities.forEach(promotionItem => {
                        let orderItem = cart.items.filter(item => item.item_id == promotionItem.entity_id)[0];
                        if(orderItem !== undefined){
                            suggestPromotions1.push(
                                {
                                    "name": promotion.name,
                                    "type": promotion.type,
                                    "info": {
                                        "order_item_id": orderItem.id
                                    },
                                    "item_name": orderItem.name
                                }
                            )
                        }
                    })
                    break;
            }
        });
        return suggestPromotions1;
    }
    static getForItemWithCart(promotions, itemId, cart){
        if(promotions === undefined) return []
        let activePromotions = promotions.filter(x => x.entities.some(y => y.type == "ITEM" && y.entity_id == itemId));
        if(cart === undefined || cart.total === undefined) return activePromotions;
        let priceForPromotionItems = cart.items.filter(x => x.item_id == itemId).reduce((prev, next) => prev + next.total_money.amount, 0);
        let totalAmount = cart.total.amount;
        let priceToCheck = cart.total.amount - priceForPromotionItems;
        return activePromotions.filter(x =>
            !x.min_basket_money || (
                    (x.type == "BOGO" && x.min_basket_money.amount <= totalAmount) ||
                    (x.type == "ITEM_DISCOUNT" && x.min_basket_money.amount <= totalAmount) ||
                    (x.type == "ITEM_FIXED_PRICE" && x.min_basket_money.amount <= priceToCheck)
                )
        );
    }
    static getActivePromotionsForCategory(promotions, totalPrice){
        let totalPriceAmount = (totalPrice !== undefined && totalPrice) ? totalPrice.amount : 0;
        return promotions.filter(x => (!x.min_basket_money || x.min_basket_money.amount <= totalPriceAmount) && (x.type == "BOGO" || x.type == "ITEM_FIXED_PRICE" || x.type == "ITEM_DISCOUNT" ));
    }
    static getList(promotions, ids){
        let obj = promotions.filter(x => ids.includes(x.id));
        return obj;
    }
}


export default PromotionHelper;