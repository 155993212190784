import React, { Component } from 'react';
import {connect} from "react-redux";
import MenuProductListItem from "./MenuProductListItem";
import MenuShowItem from "./MenuShowItem";
import MenuShowItemSingle from "./MenuShowItemSingle";
import ItemHelper from "../../../../application/common/ItemHelper";
import {getItemGroups} from "../../../../application/menu/reducer";
import Utils from "../../../utils/Utils";

class MenuItemsList extends Component{

    state = {
        selected_item: null
    }
    constructor(props) {
        super(props);
        this.showItem = this.showItem.bind(this);
    }

    renderCategoryImg(category){
        if(!category.image_link){
            return null;
        }
        return (
            <div className="card-img-top">
                <img src={category.image_link.default} />
            </div>
        )
    }
    renderDescription(item){
        if(!item.description) return null;
        return (<p dangerouslySetInnerHTML={{__html: Utils.nl2brString(item.description)}}></p>);
    }
    addItem(){

    }
    renderShowItem(item, category, menu, selected_item){
        if(selected_item === null){
            return null;
        }
        if(category.id !== selected_item.category_id){
            return null;
        }
        if(item.type === "ITEM_GROUP"){
            if(item.item.id == selected_item.id){
                let itemGroup = ItemHelper.getItemGroup(this.props.itemGroups, item.item.id);
                return (
                    <MenuShowItemSingle item={item.item} itemGroup={itemGroup} addItem={this.addItem} menu={menu} onHide={this.hideItem} />
                )
            }
        }else{
            if(item.id === selected_item.id){
                return (
                    <MenuShowItemSingle item={item} itemGroup={undefined} addItem={this.addItem} menu={menu} onHide={this.hideItem} />
                // <MenuShowItem id={item.id} menuId={menu.id} addItem={this.addItem} />
                )
            }
        }
        return null;
    }
    hideItem(){
        this.setState({selected_item: null})
    }
    showItem(id, categoryId, menuId){
        if(this.state.selected_item !== null && this.state.selected_item.id === id && this.state.selected_item.category_id === categoryId){
            this.setState({selected_item: null})
        }else{
            this.setState({selected_item: {
                id: id,
                category_id: categoryId
            }})
        }
    }
    render(){
        let menu = this.props.menu;
        let menuCategories = this.props.categories;
        let preview = this.props.preview;

        return(
            <div>
                {
                    menuCategories.map((item) =>
                        <section className="menu-category card-category-list container scrollspy-section" id={'category-' + item.id} key={item.id}>
                            {this.renderCategoryImg(item)}
                            <div className="card-header">
                                <h4>{item.name}</h4>
                                {this.renderDescription(item)}
                            </div>
                            <div className="menu-items menu-items-list">
                                {
                                    item.entities.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((itemEntity) =>
                                        <div className="menu-item-container"  key={'item-' + item.id + '-' +  itemEntity.id}>
                                            <MenuProductListItem promotions={this.props.promotions} preview={preview} menuId={menu.id} addItem={this.showItem} categoryId={item.id} product={itemEntity}/>
                                            {this.renderShowItem(itemEntity, item, menu, this.state.selected_item)}
                                        </div>
                                    )
                                }
                            </div>
                        </section>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        itemGroups: getItemGroups(state.menu)
    }
}

export default connect(mapStateToProps)(MenuItemsList)