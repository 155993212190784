import { useEffect } from 'react';
import IframeWidget from "./IframeWidget";

export function ScrollToTop() {
    useEffect(() => {
        window.scrollTo(0, 0);
        IframeWidget.scrollToTop();
    }, []);
    return null;
}

