import React, {Component, useState} from 'react';
import {Modal} from "react-bootstrap";
import {connect, createSelectorHook} from "react-redux";
import ErrorFormHelper from "./ErrorFormHelper";
import ErrorFormView from "./ErrorFormView";
import ErrorFormAlertView from "./ErrorFormAlertView";
import {updateAddress} from "../../application/cart/cartService";
import {getCart} from "../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import CitySearchComponent from "./location/CitySearchComponent";
import NavbarLocationForm from "../layout/form/NavbarLocationForm";
import {getStore} from "../../application/store/reducer";
import LoadingContainer from "./LoadingContainer";
function ChangeLocationModal(props){
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [address, setAddress] = useState(props.address ? props.address : {});

    const save = (event) => {
        const {location} = props;
        setLoading(true);
        setErrors([]);

        let newAddress = {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            flat_nr: address.flat_nr,
            zip_code: address.zip_code,
            country: location.address.country,
            description: location.address.description,
        }
        props.dispatch(updateAddress(newAddress, props.cart)).then(data => {
            setLoading(false);
            props.onHide();
        }).catch(error => {
            setLoading(false);
            setErrors(error.errors)
        });
    }
    const onShow = () => {
        const {location} = props;
        setLoadingData(true);
        setAddress(location.address)
        setLoadingData(false);
    }
    const {t} = props;
    const onHide = () => {
        setLoadingData(true);
        props.onHide();
    }

    return(

        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.showModal} onHide={onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("components.location_modal.title")}
                        </Modal.Title>
                        <button onClick={onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {(!props.showModal || loadingData) ? (
                            <LoadingContainer/>
                        ) : (
                            <NavbarLocationForm store={props.store} onErrors={(addressErrors) => setErrors(addressErrors)} onChange={(newAddress) => setAddress(newAddress)} errors={errors} address={address} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn-spinner w-100 btn-primary btn btn-submit" + (loading ? ' loading' : '')} variant="primary" onClick={()=>{save()}}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.save")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state)=>{
    return {
        store: getStore(state.store),
        cart: getCart(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(ChangeLocationModal))