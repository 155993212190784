import React from 'react';
import {getLangDisplayName} from "../../lib/i18n";
import {getLanguages, getLocale} from "../../application/store/reducer";
import {connect} from "react-redux";
import {ReactComponent as FlagPL} from "../../images/icons/languages/pl.svg";
import {ReactComponent as FlagEN} from "../../images/icons/languages/en.svg";
import {changeLocale} from "../../application/service/languageService";
import classNames from "classnames";
import {Dropdown} from "react-bootstrap";

const NavbarLanguages = (props)=>{
    let languages = props.languages;
    const getIconLang = function(locale){
        switch (locale) {
            case "pl":
                return (<FlagPL className="icon" />);
            case "en":
                return (<FlagEN className="icon" />);
        }
        return locale;
    }

    if(!languages || languages.length <=0) return null;
    const changeLocaleInternal = function(locale){
        props.dispatch(changeLocale(locale))
    }
    return(
        <>
            <Dropdown className={"navbar-languages"}>
                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    {getIconLang(props.locale)}
                    {/*{getLangDisplayName(props.locale)}*/}
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                    {languages.map(language => {
                        return (
                            <Dropdown.Item key={language} href="#" onClick={() => changeLocaleInternal(language)}>
                                {getIconLang(language)}
                                {getLangDisplayName(language)}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

const mapStateToProps = state => ({
    languages: getLanguages(state.store),
    locale: getLocale(state.store)
})
export default connect(mapStateToProps)(NavbarLanguages)
