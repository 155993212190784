import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ReactComponent as LocationIco} from "../../../images/icons/location.svg";
import {ReactComponent as ClockIco} from "../../../images/icons/clock.svg";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import ErrorFormView from "../../common/ErrorFormView";
import {updateAddress, updateOrderData} from "../../../application/cart/cartService";
import {getAddressName} from "../../../application/common/StringHelper";
import {DateHelper} from "../../../application/common/DateHelper";
import NavbarFormDates from "./NavbarFormDates";
import {getLocationRequest} from "../../../application/menu/fetchMenu";
import ErrorFormAlertView from "../../common/ErrorFormAlertView";
import NavbarLocationForm from "./NavbarLocationForm";
import LocationSearch from "../../common/location/LocationSearch";

function NavbarForm(props){
    const { t } = props;
    const [address, setAddress] = useState(props.location.address);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formAddress, setFormAddress] = useState(!props.googleSearch);
    const [orderType, setOrderType] = useState(props.type);
    const [number, setNumber] = useState(props.cart ? props.cart.number : undefined);
    const [pickDate, setPickDate] = useState(props.cart ? props.cart.pickup_at : undefined);

    // useEffect(() => {
    //     setAddress(props.location.address)
    // }, [props.location.address])

    const renderStoreAddress = (address)=>{
        const { t } = props;
        let addressText = getAddressName(address);
        if(addressText == null){
            addressText = t("common.word.no_address");
        }
        return (
            <>
                <h5>
                    <LocationIco/> {t("models.order.type.PICK_UP")}:
                </h5>
                <p>
                    {addressText}
                </p>
            </>
        );
    }
    const save = async () => {
        setErrors([]);
        setLoading(true);

        let addressData = address ? {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            flat_nr: address.flat_nr,
            zip_code: address.zip_code,
            country: address.country ? address.country : props.location.address.country,
            description: props.location.address.description,
        } : {};

        let failed = false;
        try {
            let data = await props.dispatch(updateAddress(addressData, props.cart))
            // setLoading(false)
            // props.onHide();
        } catch (error){
            setErrors(error.errors);
            failed = true;
            // setLoading(false);
        }
        try {
            let data = {
                type: orderType,
                pickup_at: pickDate,
            }
            if(orderType === 'DINE_IN'){
                data.number = number;
            } else if(orderType === 'ROOM_SERVICE'){
                // data.number = number;
            }
            await props.dispatch(updateOrderData(data, props.cart));
        }catch (error){
            setErrors(error.errors);
            failed = true;
        }
        setLoading(false)
        if(!failed){
            props.onHide();
        }

        // props.dispatch(updateAddress(addressData, props.cart)).then(data => {
        //    setLoading(false)
        //     props.onHide();
        // }).catch(error => {
        //     setErrors(error.errors);
        //     setLoading(false);
        // });
    }
    const changeType = (type) => {
      setOrderType(type);
      setNumber(undefined)
    }
    const changePickDate = (date) => {
        let dateValue = date !== null ? DateHelper.format(date) : "";
        setPickDate(dateValue);
    }

    let types = props.locationStore.types;
 
    const onCheckLocation = async (address) => {

        if(!address){
            setErrors([{
                "field": "address",
                "code": "not_in_delivery_area"
            }])
            return;
        }
        let addressData = {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            flat_nr: address.flat_nr,
            country: address.country,
            zip_code: address.zip_code,
        }
        let data = await getLocationRequest(addressData);
        if(!data.data.data.in_delivery_area){
            setErrors([{
                "field": "address",
                "code": "not_in_delivery_area"
            }])
        }else{
            setErrors([]);
        }
    }
    const onChangeFromSearch = (address) => {
        setAddress(address)
        onCheckLocation(address);
    }

    return (
        <>
            <div className={"form-types"}>
                <div className={"btns"}>
                    <div className={"row row-cols-4"}>
                        {types.map(type => {
                            let nameType = t("models.order.type." + type)
                            return (
                                <div className={"col"} key={type}>
                                    <button className={"btn btn-default w-100" + (orderType == type ? ' active' : '')} onClick={() => changeType(type)}>{nameType}</button>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {orderType == "DELIVERY" && (
                    <div className={"form-section"}>
                        <h5>
                            <LocationIco/> Adres:
                            {props.googleSearch && formAddress ? (
                                <button className={"btn btn-link"} onClick={() => setFormAddress(false)}>{t("layout.navbar.turn_on_search")}</button>

                            ) : props.googleSearch && !formAddress ? (
                                <button className={"btn btn-link"} onClick={() => setFormAddress(true)}>{t("layout.navbar.select_address_manually")}</button>
                            ) : null}
                        </h5>
                        {props.googleSearch && !formAddress ? (
                            <>
                                <ErrorFormAlertView errors={errors} field="address" />
                                <LocationSearch onChange={onChangeFromSearch} store={props.store} location={props.location} address={address} />
                            </>
                        ) : (
                            <>
                            </>
                        )}
                        {formAddress && (
                            <NavbarLocationForm store={props.store} onErrors={(addressErrors) => setErrors(addressErrors)} onChange={(newAddress) => setAddress(newAddress)} errors={errors} address={address} />
                        )}
                    </div>
                )}
                {(!props.preview) && (
                    <>
                        {orderType == "DINE_IN" && (

                            <div className={"form-section"}>
                                <h5>
                                    <ClockIco/> {t("layout.navbar.form.table_number")}:
                                </h5>
                                <div className="form-group">
                                    <input type="text" disabled={(props.store.settings.number_not_editable && props.store.settings.number_not_editable == "true")} value={number}  onChange={(e) => setNumber(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "number")} id="table_number" placeholder={t("layout.navbar.form.table_number") + "*"} />
                                    <label htmlFor="table_number">{t("layout.navbar.form.table_number")}</label>
                                    <ErrorFormView errors={errors} field="number" />
                                </div>
                            </div>
                        )}
                        {orderType == "ROOM_SERVICE" && (
                            <div className={"form-section"}>
                                <h5>
                                    <ClockIco/> {t("layout.navbar.form.room_number")}:
                                </h5>
                                <div className="form-group">
                                    <input disabled={(props.store.settings.number_not_editable && props.store.settings.number_not_editable == "true")} type="text" value={number}  onChange={(e) => setNumber(e.currentTarget.value)} className={"form-control" + ErrorFormHelper(errors, "number")} id="table_number" placeholder={t("layout.navbar.form.table_number") + "*"} />
                                    <label htmlFor="table_number">{t("layout.navbar.form.table_number")}</label>
                                    <ErrorFormView errors={errors} field="number" />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {orderType == "PICK_UP" && (
                    <>
                        {renderStoreAddress(props.store.address)}
                    </>
                )}

                {(!props.preview && props.locationStore.pick_date) && (
                    <div className={"form-section"}>
                        <h5>
                            <ClockIco/> {orderType =="PICK_UP" || orderType =="DINE_IN" ? t("layout.navbar.form.pickup_time") : t("layout.navbar.form.delivery_time")}:
                        </h5>
                        <NavbarFormDates orderType={orderType} locationStore={props.locationStore} onSave={changePickDate} pickDate={pickDate} menus={props.menus} availabilities={props.availabilities} />
                    </div>
                )}
                <button className={"btn-spinner w-100 btn-primary btn btn-submit" + (loading ? ' loading' : '')} variant="primary" onClick={()=>{save()}}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    {t("common.action.save")}
                </button>
            </div>
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarForm))
