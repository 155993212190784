import {getOpenHours, isOpen} from "./StringHelper";
import AvailableHelper from "./AvailableHelper";
import {DateHelper} from "./DateHelper";

class ItemHelper {

    static getItemGroup(itemGroups, id){
        return itemGroups.filter(x => x.items.some(i => i.item_id == id))[0];
    }
    static getItem(items, id){
        let obj = items.filter(item => item.id === id);
        if(obj.length > 0){
            return obj[0];
        }
        return null;
    }
    static getModifierGroup(modiferGroups, id){
        let obj =  modiferGroups.filter(modifierGroup => modifierGroup.id === id);
        if(obj.length > 0){
            return obj[0];
        }
        return null;
    }
    static getModifierGroupQuantityInfo(modifierGroup, parentItem){
        let ovverides = parentItem.quantity_info_overrides;
        let id = modifierGroup.id;
        let quantityInfo = null;
        let modiferGroupOvverides = [];
        if(ovverides !== null){
            modiferGroupOvverides = ovverides.filter(ovveride =>  ovveride.context_type === 'MODIFIER_GROUP' && ovveride.context_id === id.toString());
        }
        if(modiferGroupOvverides.length >0) {
            let modiferGroupOvveride = modiferGroupOvverides[0];
            quantityInfo = modiferGroupOvveride.quantity_info;
            return quantityInfo === undefined ? null : quantityInfo;
        }else{
            if(modifierGroup.quantity_info !== undefined){
                quantityInfo = {}
                if(modifierGroup.quantity_info.max_permitted !== undefined){
                    quantityInfo.max_permitted = modifierGroup.quantity_info.max_permitted;
                }
                if(modifierGroup.quantity_info.min_permitted !== undefined){
                    quantityInfo.min_permitted = modifierGroup.quantity_info.min_permitted;
                }
                if(modifierGroup.quantity_info.charge_above !== undefined){
                    quantityInfo.charge_above = modifierGroup.quantity_info.charge_above;
                }
                return quantityInfo;
            }
        }
        return null;
    }
    static getItemQuantityInfo(item, parentItem, modifierGroup){
        let parentItemId = parentItem !== null ? parentItem.id : null;
        let modifierGroupId = modifierGroup !== null ? modifierGroup.id : null;

        let itemOvverides = null;
        if(parentItemId !== null && modifierGroupId !== null){
            itemOvverides = parentItem.quantity_info_overrides.filter(parentItemQuantityInfoOvveride =>  parentItemQuantityInfoOvveride.context_type === 'ITEM' && parentItemQuantityInfoOvveride.context_id === item.id.toString())[0];
            if(itemOvverides === undefined){
                itemOvverides = modifierGroup.quantity_info_overrides.filter(modifierGroupQuantityInfoOvveride =>  modifierGroupQuantityInfoOvveride.context_type === 'ITEM' && modifierGroupQuantityInfoOvveride.context_id === item.id.toString())[0];
            }
            if(itemOvverides !== undefined && itemOvverides.quantity_info !== undefined){
                return itemOvverides.quantity_info;
            }
        }
        return null;
        // // if(ovverides === undefined || ovverides === null){
        // //     return null;
        // // }
        // let id = item.id;
        // let quantityInfo = null;
        // let itemOvverides = [];
        // if(ovverides !== null){
        //     itemOvverides = ovverides.filter(ovveride =>  ovveride.context_type === 'ITEM' && ovveride.context_id === id.toString());
        // }
        // if(itemOvverides.length >0) {
        //     let itemOvveride = itemOvverides[0];
        //     quantityInfo = itemOvveride.quantity_info;
        //     if(quantityInfo === undefined){
        //         return null;
        //     }
        //     return quantityInfo;
        // }else{
        //     if(item.quantity_info !== undefined){
        //         quantityInfo = {}
        //         if(item.quantity_info.max_permitted !== undefined){
        //             quantityInfo.max_permitted = item.quantity_info.max_permitted;
        //         }
        //         if(item.quantity_info.min_permitted !== undefined){
        //             quantityInfo.min_permitted = item.quantity_info.min_permitted;
        //         }
        //         return quantityInfo;
        //     }
        // }
        // return null;
    }

    static getPriceOvveride(item, parentItem, modifierGroup){
        let parentItemId = parentItem !== null ? parentItem.id : null;
        let modifierGroupId = modifierGroup !== null ? modifierGroup.id : null;
        let itemPriceInfoOverride = null;
        let price = null;
        if(parentItemId !== null && modifierGroupId !== null){
            itemPriceInfoOverride = item.price_info_overrides.filter(priceInfoOverride =>  priceInfoOverride.context_type === 'ITEM' && priceInfoOverride.context_id === parentItemId.toString())[0];
            if(itemPriceInfoOverride === undefined){
                itemPriceInfoOverride = item.price_info_overrides.filter(priceInfoOverride =>  priceInfoOverride.context_type === 'MODIFIER_GROUP' && priceInfoOverride.context_id === modifierGroupId.toString())[0];
            }
            if(itemPriceInfoOverride !== undefined){
                if(itemPriceInfoOverride.price !== null){
                    price = itemPriceInfoOverride.price;
                }
            }
        }
        if(price === null){
            price = item.price !== undefined ? item.price : null;
        }
        if(price !== null){
            price = price.amount;
        }
        return price;
    }
    static isAvailabile(item, availabilities, date){
        if(item.status !== 'ENABLED'){
            return false;
        }
        if(item.availability_id !== null && item.availability_id !== undefined){
            let availability = AvailableHelper.getAvailability(availabilities, item.availability_id);

            if(availability.hours.length > 0 && !isOpen(availability.hours, date)){
                return false;
            }
        }
        if(item.suspension !== undefined && item.suspension.suspend_until !== undefined){
            const suspendUntilDate = DateHelper.fromString(item.suspension.suspend_until);
            if(suspendUntilDate > date){
                return false;
            }
        }
        return true;
    }
    static isMenuAvailabile(menu, date){
        if(menu.availability !== null && menu.availability !== undefined){
            if(!isOpen(menu.availability.hours, date)){
                return false;
            }
        }
        return true;
    }

    static getTodayOpenHours(availability, date){
        if(availability === undefined || availability === null){
            return null;
        }

        let dayOpenHours = getOpenHours(availability.hours, date);
        if(dayOpenHours.length == 0){
            return null;
        }
        return dayOpenHours;
        // if(dayOpenHours.length == 1){
        //     return dayOpenHours[0];
        // }
    }
    static getTodayOpenHoursString(availability, date){
        let openHours = this.getTodayOpenHours(availability, date);
        if(openHours === null){
            return null;
        }
        openHours = openHours.sort((a, b) => {
            return a.hour_from < b.hour_from ? -1 : 1;
        });
        let openHourStrings = openHours.map(x => x.hour_from + " - " + x.hour_to);
        return openHourStrings.join(", ");
    }
}


export default ItemHelper;