import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import Money from "../../../../common/Money";
import {withTranslation} from "react-i18next";
import ItemHelper from "../../../../../application/common/ItemHelper";
import {getItems, isItemMultiLevel} from "../../../../../application/menu/reducer";
import MenuEditItemViewHelper from "./MenuEditItemViewHelper";

const MenuModifierGroupsNew = (props)=>{
    const { t, stateItem, modifierGroup } = props;

    const handleChangeCheckbox = (modifierGroup, item, event)=> {
        let newQuantity = item.quantity === 1 ? 0 : 1;
        props.setQuantityItem(modifierGroup, item, newQuantity);
    }
    const handleChangeRadio = (modifierGroup, item, event)=>{
        props.setQuantityItem(modifierGroup, item, 1);
    }
    const subQuantityItem = (modifierGroup, item) => {
        let newQuantity = item.quantity - 1;
        props.setQuantityItem(modifierGroup, item, newQuantity);
    }
    const addQuantityItem = (modifierGroup, item) => {
        let newQuantity = item.quantity + 1;
        props.setQuantityItem(modifierGroup, item, newQuantity);
    }

    const getModifierGroupItem = (itemModiferGroups, modifigerGroupId, itemId) => {
        let modifierGroup = itemModiferGroups.filter(itemModiferGroup => itemModiferGroup.modifier_group_id === modifigerGroupId)[0];
        if(modifierGroup === undefined){
            return null;
        }
        let modifierGroupItem = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === itemId)[0];
        if(modifierGroupItem === undefined){
            return null;
        }
        return modifierGroupItem;
    }
    const renderInput = (item, modifierGroup, optionItem) => {
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? null : modifierGroup.max_permitted;

        let priceView = null;
        let inputView = null;
        let labelName = null;
        let quantityFreeView = null;
        if(optionItem.price){
            let price = optionItem.price;
            if(optionItem.state && optionItem.state.unit_total_price){
                price = optionItem.state.unit_total_price;
            }
            if(optionItem.quantity_free){
                quantityFreeView = (
                    <small>
                        <br />
                        &nbsp;({optionItem.quantity_free} darmowe)
                    </small>
                )
            }
            priceView =(
                <span className="product-modifier-option-price ml-auto price">
                <Money
                    value={price}
                    currency='PLN' />
                    {quantityFreeView}
            </span>
            )
        }
        if(min_permitted === 1 && max_permitted === 1){
            let checked = optionItem.quantity == 1 ? true : false;
            inputView =  (
                <label className="input-single" htmlFor={`modifierGroupItemId-${modifierGroup.id}-${optionItem.id}`}>
                    <input type="radio" checked={checked} onChange={(e) => handleChangeRadio(modifierGroup, optionItem, e)} name={'modifierGroup[' + modifierGroup.id + '].options'} id={`modifierGroupItemId-${modifierGroup.id}-${optionItem.id}`} value={optionItem.id} />
                    {optionItem.name}
                </label>
            )
        }else if(
            (max_permitted === null || max_permitted > 0)){
            let disableAdd = false;
            if(modifierGroup.max_permitted !== null && modifierGroup.max_permitted <= modifierGroup.quantity){
                disableAdd = true;
            }
            let modifierGroupItem = getModifierGroupItem(item.modifier_groups, modifierGroup.id, optionItem.id);
            let quantity = optionItem.quantity;
            let quantityInfoItemMinPermitted = 0;
            let quantityInfoItemMaxPermitted = 0;
            if(modifierGroupItem !== null){
                quantity = modifierGroupItem.quantity;
            }
            quantityInfoItemMinPermitted = optionItem.min_permitted === undefined ? 0 : optionItem.min_permitted;
            quantityInfoItemMaxPermitted = optionItem.max_permitted === undefined ? null : optionItem.max_permitted;

            if(quantityInfoItemMaxPermitted == 0){
                return null;
            }else if(quantityInfoItemMinPermitted === 0 && quantityInfoItemMaxPermitted ===1){
                inputView = (
                    <label className="input-single">
                        <input type="checkbox" disabled={disableAdd && optionItem.quantity !== 1} checked={optionItem.quantity === 1} onChange={(e) => handleChangeCheckbox(modifierGroup, optionItem, e)} name={'modifierGroup[' + modifierGroup.id + '].options'} id={'modifierGroupItemId-' + optionItem.id} value={optionItem.id} />
                        {optionItem.name}
                    </label>
                );
            }else{
                inputView = (
                    <div className="input-group input-group-quantity">
                        {quantity > 0
                            ?
                            <div className="input-group-prepend">
                                <button type="button" className="btn btn-secondary" id="basic-addon1" onClick={(e) => subQuantityItem(modifierGroup, optionItem, e)}>-</button>
                            </div>
                            : null
                        }
                        {quantity > 0
                            ?
                            <span className="input-group-text">{quantity}</span>
                            : null
                        }
                        <div className="input-group-append">
                            <button disabled={disableAdd} type="button" className="btn btn-secondary" id="basic-addon1" onClick={(e) => addQuantityItem(modifierGroup, optionItem, e)}>+</button>
                        </div>
                    </div>
                );
                labelName = (
                    <span>
                        {optionItem.name}
                    </span>
                )
            }
        }
        let errorView = null;
        if(optionItem.errors !== undefined){
            errorView = (
                <React.Fragment>
                    {
                        optionItem.errors.map((error) =>
                            <p className="text-danger" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="product-modifier-option" key={modifierGroup.id + "-" + optionItem.id}>
                    {inputView}
                    {labelName}
                    {priceView}
                </div>
                {errorView}
            </React.Fragment>
        )
    }
    const getRenderHelpQuantityInfo = (modifierGroup) => {
        let additionalInfos = [];
        if(modifierGroup.min_permitted !== null && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== null && modifierGroup.max_permitted === 1){

        }else{
            if(modifierGroup.min_permitted !== null && modifierGroup.min_permitted > 0){
                additionalInfos.push(t("components.menu.min_permitted") + ": " + modifierGroup.min_permitted);
            }
            if(modifierGroup.max_permitted !== null){
                additionalInfos.push(t("components.menu.max_permitted") + ": " + modifierGroup.max_permitted);
            }
            if(modifierGroup.quantity !== null){
                additionalInfos.push(t("components.menu.default_quantity") + ": " + modifierGroup.quantity);
            }
            if(modifierGroup.charge_above !== null){
                additionalInfos.push(t("components.menu.charge_above") + ": " + modifierGroup.charge_above);
            }
        }
        let errorView = null;
        if(modifierGroup.errors !== undefined){
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error) =>
                            <p className="text-danger" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if(additionalInfos.length !== 0){
            additionalInfoView = (
                <React.Fragment>
                    <ul className="list-inline">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={additionalInfo}>{additionalInfo}</li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    if(modifierGroup.max_permitted !== null && modifierGroup.max_permitted <=0){
        return null;
    }
    const renderItemInfo = (optionItem) => {
        let nameOptions = [];
        {optionItem.modifier_groups.forEach(mg => {
            mg.options.forEach(mgo => {
                if(mgo.quantity > 0){
                    nameOptions.push(`${mgo.quantity}x${mgo.name}`);
                }
            })
        })}
        if(nameOptions.length === 0) return null;
        return (
            <p>
                {nameOptions.join(", ")}
            </p>
        );
    }
    const getErrors = (item) => {
        return MenuEditItemViewHelper.getErrors(item);
    }
    const renderErrors = (errors) => {
        return (
            <>
                {errors.map(error => {
                        return (
                            <p className="text-danger">{error.message} {error.resource ? error.resource : ""}</p>
                        )
                    }
                )}
            </>
        )
    }
    return(
        <fieldset className="form-group">
            <legend>
                {modifierGroup.name} {props.quantity}
            </legend>
            {getRenderHelpQuantityInfo(modifierGroup)}

            {
                modifierGroup.options.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem) => {
                    let item = ItemHelper.getItem(props.items, optionItem.id);
                    let errors = getErrors(optionItem);
                    return (
                        <div key={modifierGroup.id + "-" + optionItem.id}>
                            {renderInput(item, modifierGroup, optionItem)}
                            {(props.itemMultiLevel && optionItem.quantity > 0) && (
                                <>
                                    {renderItemInfo(optionItem)}
                                    {renderErrors(errors)}
                                    {item.modifier_groups.length > 0 && (
                                        <>
                                            {errors.length > 0 ? (
                                                <button onClick={() => props.onEditSubItem(optionItem, modifierGroup.id)} className={"btn btn-outline-danger w-100"}>
                                                    Wybierz pozycję
                                                </button>
                                            ) : (
                                                <button onClick={() => props.onEditSubItem(optionItem, modifierGroup.id)} className={"btn btn-default w-100"}>
                                                    Dostosuj pozycję
                                                </button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )
                })
            }

        </fieldset>
    )
}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuModifierGroupsNew))