import { combineReducers } from 'redux';
// import  cartReducer  from './reducers/cartReducer'
import  menuReducer  from './application/menu/reducer'
import  cartReducer  from './application/cart/reducer'
import  storeReducer  from './application/store/reducer'
import notificationReducer from "./application/notification/reducer";
import locationReducer from "./application/location/reducer";
import userReducer from "./application/user/reducer";

// const rootReducer = combineReducers({
//     cart: cartReducer
// });
const rootReducer = combineReducers({
    cart: cartReducer,
    menu: menuReducer,
    store: storeReducer,
    notification:notificationReducer,
    location:locationReducer,
    user:userReducer
});

export default rootReducer;