import React from 'react';
const Loading = (value)=>{
    let text =  (
        <p>Trwa ładowanie ...</p>
    );
    if(value.children != null)
        text = value.children;

    return(
        <div className="loading-app text-center">
            <div className="loader"></div>
            {text}
        </div>
    )
}

export default Loading;
