import React, {useState} from 'react';
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {withTranslation} from "react-i18next";
import Loading from "../Loading";

const parseObjOrString = (obj) => {
    if(!obj || typeof obj === 'string'){
        return {
            "description": obj !== undefined ? obj : ""
        }
    }
    return obj;
}
const BaseSearchComponent = (props)=>{
    let options = {}
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([
        parseObjOrString(props.value)
    ]);
    let _handleSearch = async (query) => {
        props.onChange({
            description: query
        });
        if(query.length <=1){
            return;
        }
        setLoading(true);

        let response = await props.fetch(query);
        setItems(response);
        setLoading(false);
    }
    let searchOnBlur = (e, v) => {
        let value = e.target.value;
        let selectedItem = selected !== undefined && selected.length > 0 && selected[0] ? selected[0] : {};
        selectedItem.description = value;
        props.onChange(selectedItem)
        props.onBlur(selectedItem);
        // if(selected !== null && selected.length > 0){
        //     selected[0].description = props.filter.address?.full;
        //     setSelected(selected)
        // }
        // if(this.props.location.address !== null){
        //     this.props.dispatch(updateSelectedValue(this.props.location.address.fullAddres));
        // }
    }
    let handleSearchOnChange= async (newSelected) => {
        let selectedItem;
        if(newSelected === undefined || newSelected.length <= 0){
            if(selected === undefined || selected.length <=0){
                props.onChange({description:""});
                setSelected([])
            }else{
                // selectedItem = selected[0];
                // props.onChange(selectedItem);
                // setSelected([selectedItem])
            }
            // let selectedItem = {description:""};
            // props.onChange(selectedItem);
            // setSelected([selectedItem])
            return;
        }else{
            selectedItem = newSelected[0];
        }
        props.onChange(selectedItem);
        setSelected([selectedItem])
    }
    let _renderMenu = (results, menuProps, state) => {
        delete menuProps.newSelectionPrefix;
        delete menuProps.paginationText;
        delete menuProps.renderMenuItemChildren;
        const items = results.map((option, index) => (
            <MenuItem  key={index} option={option} position={index}>
                {option.renderText ? (
                    <>
                        {option.renderText()}
                    </>
                ) : (
                    <>
                        {option.description}
                    </>
                )}
            </MenuItem>
        ));
        return <Menu {...menuProps}>
            {items}
            {/*<div className="dropdown-divider"></div>*/}
            {/*<div className="dropdown-divider"></div>*/}
        </Menu>;
    }
    let handleSearchValue= async (value) => {
        if(!value){
            if(selected[0] !== undefined){
                selected[0] = {description: ""}
                // selected[0].description = ""
            }
            props.onChange({description: ""})
            return;
        }else{
            // props.onChange({description: value});
        }
        if(selected[0] !== undefined){
            // selected[0].description = value
            selected[0] = {description: value}
            setSelected(selected)
        }
    }
  
    return (
        <AsyncTypeahead
            {...options}
            id="typehead"
            isLoading={loading}
            onSearch={_handleSearch}
            minLength={0}
            useCache={false}
            onBlur={searchOnBlur}
            filterBy={(option, props) => {
                return true;
            }}
            options={items}
            labelKey={(option) => {
                return option.description;
            }}
            selected={selected}
            onChange={handleSearchOnChange}
            renderMenu={_renderMenu}
            className={"search-input"}
            placeholder={props.placeholder}
            clearButton={true}
            onInputChange={handleSearchValue}
            searchText={<>
                <Loading>Trwa wyszukiwanie</Loading>
            </>}
            promptText={"Wpisz aby wyszukać"}
            emptyLabel={"Nie znaleziono wyniku, możesz wpisać wartość ręcznie."}
        />
    )
}

export default withTranslation()(BaseSearchComponent)