import {SET_LANGUAGE} from "../store/actions";

export const FETCH_MENU_PENDING = 'FETCH_MENU_PENDING';
export const FETCH_MENU_ERROR = 'FETCH_ITEMS_ERROR';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_LOCATION_SUCCESS = 'FETCH_MENUS_LOCATION_SUCCESS';
export const SET_MENU_LOCALE = 'SET_MENU_LOCALE';

export function fetchMenuPending() {
    return {
        type: FETCH_MENU_PENDING
    }
}

export function fetchMenuError(error) {
    return {
        type: FETCH_MENU_ERROR,
        error: error
    }
}

export function fetchMenusSuccess(menus) {
    return {
        type: FETCH_MENUS_SUCCESS,
        data: menus
    }
}
export function fetchMenusLocationSuccess(menusLocation) {
    return {
        type: FETCH_MENUS_LOCATION_SUCCESS,
        data: menusLocation
    }
}

export function setMenuLocale(locale) {
    return {
        type: SET_MENU_LOCALE,
        locale: locale
    }
}

