import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser, getVouchers} from "../../../../application/user/reducer";
import {Link} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import Utils from "../../../utils/Utils";
import {Button} from "react-bootstrap";
import VoucherModal from "./components/VoucherModal";

const UserVouchersPage = (props) => {
    const [voucher, setVoucher] = useState(undefined);
    let activeVouchers = props.vouchers;
    const isAvailableVoucher = (voucher) => {
        return true;
        // return !props.orderPromotions.some(orderPromotion => voucher.promotion_id == orderPromotion.promotion_id && orderPromotion.reference_id == voucher.id);
        // return true;
    }
    const showVoucher = (voucher) => {
        setVoucher(voucher)
    }
    const renderPromotion = (voucher) => {
        return (
            <div className="voucher-item1" onClick={(e) => showVoucher(voucher)}>
                <div className={"card" + (!isAvailableVoucher(voucher) ? ' disabled' : '')}>
                    {!isAvailableVoucher(voucher) && (
                        <span className={"badge bg-primary"}>Niedostępny</span>
                    )}
                    {/*<img src="http://app.goorder.local/images/1/product/small/fb60acd9-a4a2-4266-ab4f-228ff32c3c19.png"*/}
                    {/*     className="card-img-top" alt="..."/>*/}
                    <div className="card-body">
                        <h5 className="card-title">
                            {voucher.name}
                        </h5>
                        {voucher.description && (
                            <p className="card-text"
                               dangerouslySetInnerHTML={{__html: Utils.nl2brString(voucher.description)}}></p>
                        )}
                        <Button className={"btn btn-primary"}>skorzystaj</Button>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <>
            {voucher && (
                <>
                    <VoucherModal onHide={() => setVoucher(undefined)} show={true} voucher={voucher} />
                </>
            )}
            <div className={"user"}>
                <div className={"user-header top"}>
                    <>
                        <Link to={"/user"} className={"btn-back"}>
                            <BackSVG/>
                        </Link>
                        <h5 className="">
                            Vouchery
                        </h5>
                    </>
                </div>
                <div className={"container mt-2"}>
                    {activeVouchers.length > 0 ? (
                        <section className="vouchers">
                            <div className="rows row-cols-lg-3">
                                {
                                    activeVouchers.sort((a, b) => a.name.localeCompare(b.name)).map(voucher => {
                                            return (
                                                <React.Fragment key={voucher.id}>
                                                    <div>
                                                        {renderPromotion(voucher)}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </section>
                    ) : (
                        <div>
                            Nie posiadasz żadnych voucherów.
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user),
    vouchers: getVouchers(state.user),
})
export default withTranslation()(connect(mapStateToProps)(UserVouchersPage))
