import React, { Component } from 'react';
import {connect} from "react-redux";
import BluemediaClient from "../../../../../vendor/bluemedia";
import ErrorView from "../../../../common/ErrorView";
import ErrorFormAlertView from "../../../../common/ErrorFormAlertView";

class BluemediaCardPaymentMethod extends Component{

    bluemedia_url = process.env.REACT_APP_BLUEMEDIA_URL;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: '',
            error: ''
        };
        this.save = this.save.bind(this);
        this.changeCard = this.changeCard.bind(this);
    }
    addErrorCard(errorMessage){
        this.setState(prevState => ({
            ...prevState,
            error: errorMessage
        }));
    }

    componentDidMount(){
        // const {openDialog} = this.props;
        // if(openDialog){
        //     this.showModal();
        // }
        let that = this;
        window.CardWidget.actionURL = "https://cards-accept.bm.pl";
        var iframeHandle = document.getElementById("bluemedia_card_iframe").contentWindow;
        window.addEventListener("message", function (e) {
            var eventResponse = window.CardWidget.eventListener(e);
            if(eventResponse.status === "PAYMENT_TOKEN"){
                let token = eventResponse.message;
                that.setState({ token: token, loading: false, error:null });
                that.props.updatePaymentSettings({'token': token});
            }else if(eventResponse.status === "WIDGET_ERROR"){
                that.setState({ loading: false });
                that.addErrorCard(eventResponse.message);
            }
        }, false);

    }
    save(){
        this.setState({ loading: true, error:null });
        var iframeHandle = document.getElementById("bluemedia_card_iframe").contentWindow;
        window.CardWidget.sendCardsForm(iframeHandle);
    }
    changeCard(){
        this.setState({ token: null, loading: false, error:null });
        this.props.updatePaymentSettings({'token': null});
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    render(){

        let tokeView = null;
        let tokeAddCard = null;
        let errorView = null;
        if(this.state.token){
            tokeView = (
                <div className="alert alert-success">Karta dodana</div>
            )
            tokeAddCard = (
                <button className="btn btn-primary" onClick={this.changeCard}>Dodaj nową karte</button>
            )
        }else{
            tokeAddCard = (
                <div>
                    <iframe id="bluemedia_card_iframe" src={this.bluemedia_url + "?darkMode=false"}></iframe>
                    <button className="btn btn-primary" onClick={this.save}>Zapisz</button>
                </div>
            )
        }
        if(this.state.error !== null && this.state.error){
            errorView = (
                <ErrorFormAlertView errors={[this.state.error]} />
            )
        }
        return(
            <div>
                {errorView}
                {tokeView}
                {tokeAddCard}
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default connect(mapStateToProps)(BluemediaCardPaymentMethod)