import React from 'react';
import {useTranslation} from "react-i18next";

const OrderStatus = (props)=>{
    const { t } = useTranslation();
    let statusLabel = null;
    switch(props.status){
        case "OPEN":
            statusLabel = "primary";
            break;
        case "ACCEPTED":
        case "CLOSED":
            statusLabel = "success";
            break;
        case "CONFIRMED":
        case "WAITING_FOR_ACCEPTED":
            statusLabel = "warning";
            break;
        case "CANCELED":
        case "REJECTED":
            statusLabel = "danger";
            break;
    }
    return (
        <span className={"badge bg-" + statusLabel}>{t("models.order.status." + props.status)}</span>
    )
}

export default OrderStatus;
