import StorageStore from "./storageStore";

class OrderContext {
    static orderId = null;
    static orderToken = null;
    static initialize(){
        let pathname = window.location.pathname;
        let pathanemSplit = pathname.split("/");
        let startVariableParse = false;
        let i = 0;
        pathanemSplit.forEach(pathVar => {
            if(startVariableParse){
                if(i == 0){
                    this.orderId = pathVar;
                    i++;
                }else if(i == 1){
                    this.orderToken = pathVar;
                    i++;
                }
            }
            if(pathVar == 'order'){
                startVariableParse = true;
            }
        })
    }
    static getOrderId(){
        if(this.orderId){
            return this.orderId;
        }
        return StorageStore.getItem("order_id");
    }
    static cleanOrder(){
        this.orderId = null;
        this.orderToken = null;
        StorageStore.removeItem('order_id');
        StorageStore.removeItem('order_token');
    }
    static setOrderId(id){
        this.orderId = id;
        StorageStore.setItem('order_id', id);
    }
    static setOrderToken(token){
        this.orderToken = token;
        StorageStore.setItem('order_token', token);
    }
    static getOrderToken(){
        if(this.orderToken){
            return this.orderToken;
        }
        return StorageStore.getItem("order_token");
    }
}
export default OrderContext;
