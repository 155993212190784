import React, {Component} from 'react';
import {getAddressName} from "../../../../application/common/StringHelper";
import {Modal} from "react-bootstrap";
import {getStore} from "../../../../application/store/reducer";
import {
    getAvailabilities,
    getHours,
    getMenus,
    getOrganization,
    isStoreOpen
} from "../../../../application/menu/reducer";
import {connect} from "react-redux";
import {ReactComponent as Clock} from "../../../../images/icons/clock.svg";
import {ReactComponent as Marker} from "../../../../images/icons/marker.svg";
import {ReactComponent as Info} from "../../../../images/icons/info.svg";
import {
    DateHelper,
    formatDate,
    getDayOfWeek,
    getWeekDays,
    isTimeAllDay
} from "../../../../application/common/DateHelper";
import AvailableHelper from "../../../../application/common/AvailableHelper";
import {useTranslation, withTranslation} from "react-i18next";

class StoreInfoHours extends Component{
    state = {
        show: false
    };

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };
    renderOpenHoursItem(openHour){
        const { i18n } = this.props;
        let openDayString = getDayOfWeek(openHour.day_from, i18n.language);

        if(openHour.day_from !== openHour.day_to){
            openDayString += " - " + getDayOfWeek(openHour.day_to, i18n.language);
        }
        return (
            <ul className="list-inline">
                <li>
                    <strong>
                        {openDayString}
                    </strong>
                </li>
                <li>
                    {isTimeAllDay(openHour.hour_from) && isTimeAllDay(openHour.hour_to) ? "Cały dzień" : `${openHour.hour_from} - ${openHour.hour_to}`}
                </li>
            </ul>
        )
    }

    renderOpenDays(availabilities, menu){
        let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id);
        if(!availability || !availability.dates || availability.dates.length === 0){
            return null;
        }
        let dates = AvailableHelper.getFutureDates(availability).map((date) => {
            let dateFromS = formatDate(date.date_from);
            let dateToS = formatDate(date.date_to);
            if(dateFromS == dateToS){
                return dateFromS;
            }
            return `${dateFromS} - ${dateToS}`;
        });
        let datesString = dates.join(', ');
        return (
            <div>
                {datesString}
            </div>
        )
    }
    render(){
        const {store, menus, availabilities, t, organization} = this.props;
        let addressView = null;

        if(store.address){
            let addressFullString = getAddressName(store.address);
            addressView = (
                <tr>
                    <td width="1px">
                        <Marker className="icon" />
                    </td>
                    <td>
                        {addressFullString}
                    </td>
                </tr>
            )
        }
        let descriptionView = null;
        if(store.translation.description){
            descriptionView = (
                <tr>
                    <td width="1px">
                        <Info className="icon" />
                    </td>
                    <td>
                        {store.translation.description}
                    </td>
                </tr>
            )
        }
        let companyAddressFullName = organization.address ? getAddressName(organization.address) : "";
        return(

            <React.Fragment>
                <a href="#" className={`${this.props.className ? this.props.className : "link"}`} onClick={this.showModal}>
                    {t("common.word.more_information")}
                </a>
                <Modal dialogClassName="modal-full" show={this.state.show} onHide={this.hideModal}>
                    <div className="">
                        <Modal.Header>
                            <Modal.Title>
                                {t("components.store.modal.title")}
                            </Modal.Title>
                            <button onClick={this.hideModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </Modal.Header>
                    </div>
                    <Modal.Body>
                        <div>
                            <table className="table table-borderless">
                                <tbody>
                                {addressView}
                                <tr>
                                    <td width="1px">
                                        <Clock className="icon" />
                                    </td>
                                    <td>
                                            {
                                                menus.map((menu) => {
                                                    if(menu.availability === null || menu.availability === undefined){
                                                        return null;
                                                    }
                                                    return (
                                                        <div key={menu.id}>
                                                            <h3>
                                                                {menu.translation.name}
                                                            </h3>
                                                            {this.renderOpenDays(availabilities, menu)}

                                                            <ul className="list-unstyled">
                                                                {
                                                                    AvailableHelper.sortOpenHours(menu.availability.hours).map((openHour) => {

                                                                            return (
                                                                                <li key={menu.id + "-" + openHour.day_from + "-"  + openHour.day_to + "-"  + openHour.hour_from + "-"  + openHour.hour_to}>
                                                                                    {this.renderOpenHoursItem(openHour)}
                                                                                </li>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                )
                                            }
                                    </td>
                                </tr>
                                {descriptionView}
                                {(organization.tax_id_no || organization.company_name) && (
                                    <tr>
                                        <td width="1px">
                                            <Info className="icon" />
                                        </td>
                                        <td>
                                            {organization.company_name}
                                            {organization.tax_id_no && (
                                                <>
                                                    , NIP: {organization.tax_id_no}
                                                </>
                                            )}
                                            {companyAddressFullName && (
                                                <>
                                                    , {companyAddressFullName}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        store: getStore(state.store),
        hours: getHours(state.menu),
        menus: getMenus(state.menu),
        organization: getOrganization(state.menu),
        isStoreOpen: isStoreOpen(state.menu),
        availabilities: getAvailabilities(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(StoreInfoHours))