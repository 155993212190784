export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VOUCHERS = 'VOUCHERS';

export const login= (user)=>{
    return {
        type: LOGIN,
        user: user
    };
}
export const vouchers= (vouchers)=>{
    return {
        type: VOUCHERS,
        vouchers: vouchers
    };
}


export const logout= ()=>{
    return {
        type: LOGOUT
    };
}