import React from 'react';
import {getCart, getCartTotal, getNumberItems, getOrderPending} from '../../application/cart/reducer';
import {connect} from "react-redux";
import {isStoreOpen} from "../../application/menu/reducer";
import Money from "./Money";
import {useTranslation} from "react-i18next";

const CartButton = (props)=>{
    const { t } = useTranslation();

    let disabled = props.pending || props.cart.id == null
        // || !props.isOpen
    ;
    let loading = props.pending;
    return(
        <button onClick={props.goToCart} className={"btn-spinner btn btn-primary btn-submit" + (loading ? ' loading' : '')} disabled={disabled}>
            <span className="left spinner-border spinner-border-sm"></span>
            <div className="left btn-submit-quantity">
                {props.numberItems}
            </div>
            {t("components.cart.show_order")}
            <div className="right btn-cart-price">
                <Money value={props.total}/>
            </div>
        </button>
    )
}
const mapStateToProps = state => ({
    total: getCartTotal(state.cart),
    numberItems: getNumberItems(state.cart),
    pending: getOrderPending(state.cart),
    cart: getCart(state.cart),
    isStoreOpen: isStoreOpen(state.menu)
})

export default connect(
    mapStateToProps
)(CartButton);
