import React from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as VouchersIco} from "../../../../../images/icons/vouchers.svg";
import {connect} from "react-redux";
import Utils from "../../../../utils/Utils";

const Vouchers = (props)=>{
    const { t } = useTranslation();
    let vouchers = props.vouchers;
    if(vouchers === undefined || !vouchers || vouchers.length === 0){
        return null;
    }
    let activeVouchers = vouchers;

    const isAvailableVoucher = (voucher) => {
        return !props.orderPromotions.some(orderPromotion => voucher.promotion_id == orderPromotion.promotion_id && orderPromotion.reference_id == voucher.id);
        // return true;
    }
    const  renderImg = (voucher) => {
        if(!voucher.image_link){
            return null;
        }
         return (
             <div className="card-img">
                 <img src={voucher.image_link.small} />
             </div>
         );
    }
    const renderPromotion = (voucher) => {
        return(
            <div className="voucher-item" onClick={(e) => props.useVoucher(voucher)}>
                <div className={"card" + (!isAvailableVoucher(voucher)? ' disabled' : '')}>
                    {!isAvailableVoucher(voucher) && (
                        <span className={"badge bg-primary"}>{t("common.word.used")}</span>
                    )}
                    <img src="http://app.goorder.local/images/1/product/small/fb60acd9-a4a2-4266-ab4f-228ff32c3c19.png" className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">
                            {voucher.name}
                        </h5>
                        {voucher.description && (
                            <p className="card-text" dangerouslySetInnerHTML={{__html: Utils.nl2brString(voucher.description)}}></p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <section className="vouchers">
                <h4>
                    <VouchersIco/>
                    {t("components.vouchers.my")}
                </h4>
                <div className="voucher-items">
                    {
                        activeVouchers.sort((a, b) => a.name.localeCompare(b.name)).map(voucher => {
                                return (
                                    <React.Fragment key={voucher.id}>
                                        {renderPromotion(voucher)}
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                </div>
            </section>
        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
    }
}

export default withTranslation()(connect(mapStateToProps)(Vouchers))