import {setStoreLocale} from "../store/actions";
import i18next from "i18next";
import {setMenuLocale} from "../menu/actions";
import StorageStore from "../common/storageStore";

export function changeLocale(locale) {
    if(i18next && ((i18next.language && i18next.language !== locale) || !i18next.language)){
        i18next.changeLanguage(locale);
    }
    StorageStore.setItem("language", locale);
    // dispatch(setMenuLanguage(locale, cart));
    // dispatch(setStoreLocale(locale, cart));
    return dispatch => Promise.all([
        dispatch(setMenuLocale(locale)),
        dispatch(setStoreLocale(locale)),
    ]);
}

const getTranslation = (locale, field, translations, defaultValue) => {
    if(translations === undefined){
        return defaultValue;
    }
    let translation = translations.filter(translation => translation.locale === locale)[0];
    if(translation && translation[field]){
        return translation[field];
    }
    return defaultValue;
}
export const getElementTranslation = (locale, element, fields) => {
    let translation = {};
    fields.forEach(field => {
        translation[field] = getTranslation(locale, field, element.translations, element[field])
    })
    return translation;
}
export const getElementTranslations = (locale, elements, fields) => {
    return elements.map(element => {
        element.translation = getElementTranslation(locale, element, fields);
        // fields.forEach(field => {
        //     element.translation[field] = getTranslation(locale, field, element.translations, element[field])
        // })
        return element;
    });
}