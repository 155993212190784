import React from 'react';
import {getOrderPending} from '../../../application/cart/reducer';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const CartSubmit = (props)=>{
    const { t } = useTranslation();
    let disabled = props.pending;
    let loading = props.pending;
    return(

        <button onClick={props.saveOrder} className={"w-100 btn btn-spinner btn-primary btn-submit" + (loading ? ' loading' : '')} type="button" disabled={disabled}>
            <span className="left spinner-border spinner-border-sm"></span>
            {t("components.cart.basket.make_order")}
        </button>
    )
}
const mapStateToProps = state => ({
    pending: getOrderPending(state.cart)
})

export default connect(
    mapStateToProps
)(CartSubmit);
