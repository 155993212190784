import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../../application/user/reducer";
import {Route, Switch, useHistory} from "react-router-dom";
import UserIndexPage from "./pages/UserIndexPage";
import UserDeletePage from "./pages/UserDeletePage";
import UserContext from "../../../application/common/UserContext";
import {Button} from "react-bootstrap";
import LoadingContainer from "../../common/LoadingContainer";
import UserVouchersPage from "./pages/UserVouchersPage";
import UserResetPasswordPage from "./pages/UserResetPasswordPage";
import UserActivationPage from "./pages/UserActivationPage";

const LoginPage = (props) => {
    const {match} = props;
    return (
        <>
            <div className={"login-page"}>
                <Switch>
                    <Route path={`${match.url}/reset-password`} component={UserResetPasswordPage}/>
                    <Route path={`${match.url}/activation`} component={UserActivationPage}/>
                </Switch>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user)
})
export default withTranslation()(connect(mapStateToProps)(LoginPage))
