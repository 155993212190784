export const UPDATE_SELECTED_LOCATION = 'UPDATE_SELECTED_LOCATION';
export const UPDATE_SELECTED_VALUE_LOCATION = 'UPDATE_SELECTED_VALUE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const FETCH_LOCATION = 'FETCH_LOCATION';

export function updateSelected(selected) {
    return {
        type: UPDATE_SELECTED_LOCATION,
        selected: selected
    }
}
export function updateLocation(location) {
    return {
        type: UPDATE_LOCATION,
        location: location
    }
}
export function updateSelectedValue(value) {
    return {
        type: UPDATE_SELECTED_VALUE_LOCATION,
        value: value
    }
}