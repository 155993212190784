import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../../application/user/reducer";
import {Route, Switch, useHistory} from "react-router-dom";
import UserIndexPage from "./pages/UserIndexPage";
import UserDeletePage from "./pages/UserDeletePage";
import UserContext from "../../../application/common/UserContext";
import LoadingContainer from "../../common/LoadingContainer";
import UserVouchersPage from "./pages/UserVouchersPage";

const UserPage = (props) => {
    const [loading, setLoading] = useState(UserContext.getToken() ? true : false);
    const history = useHistory();
    const {match} = props;
    useEffect(() => {
        if (!UserContext.getToken()) {
            history.push("/");
        }
        if (!props.user.name) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [props.user.name]);
    if (loading) {
        return (
            <>
                <LoadingContainer>Trwa logowanie ...</LoadingContainer>
            </>
        )
    }
    return (

        <>
            <Switch>
                <Route path={`${match.url}/delete`} component={UserDeletePage}/>
                <Route path={`${match.url}/vouchers`} component={UserVouchersPage}/>
                <Route path={`${match.url}/`} component={UserIndexPage}/>
            </Switch>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserPage))
