import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {ReactComponent as LeftIco} from "../../../../../../images/icons/arrow-left.svg";
import IframeWidget from "../../../../../utils/IframeWidget";
import MenuShowItemSingle from "../../MenuShowItemSingle";
import MenuShowItemSingleV3 from "../MenuShowItemSingleV3";

const MenuItemBaseModal = (props) => {
    return(
        // <Modal dialogClassName="modal-full modal-product modal-product-full" show={true} onHide={props.onHide} onShow={IframeWidget.openModal}>
        <Modal dialogClassName="modal-full modal-product modal-product-full" show={true} onHide={props.onHide} onShow={props.onShow}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        {props.image && (
                            <div className="product-item-img">
                                <img src={props.image} />
                            </div>
                        )}
                    </div>
                    <div className={"col-md-6 modal-product-full-right"}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {}
}

export default withTranslation()(connect(mapStateToProps)(MenuItemBaseModal))