import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "./ErrorFormHelper";
import ErrorFormView from "./ErrorFormView";
import ErrorFormAlertView from "./ErrorFormAlertView";
import {updateOrderData} from "../../application/cart/cartService";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getCart} from "../../application/cart/reducer";
import Languages from "./Languages";
import {getStore} from "../../application/store/reducer";

function ChangeTableNumberModal(props) {
    const [tableName, setTableName] = useState(props.tableName ? props.tableName : "");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const t = props.t;
    const dispatch = useDispatch();

    const onShow = () => {
        setTableName(props.tableName ? props.tableName : "")
        setErrors([]);
    }
    let possibleEditNumber = !(props.store.settings.number_not_editable && props.store.settings.number_not_editable == "true");

    const saveTableNumber = () => {
        if(!possibleEditNumber){
            props.onSave();
            return;
        }
        let data = {
            number: tableName,
            type: "DINE_IN"
        }
        dispatch(updateOrderData(data, props.cart)).then((newData) => {
            // resolve();
            props.onSave();
        }).catch(error => {
            setErrors(error.errors);
            setLoading(false);
        });

    }

    return(
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("common.action.choose_table")}
                        </Modal.Title>
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {props.showLanguages && (
                            <Languages  size={"xl"}/>
                        )}

                        {(!possibleEditNumber && !tableName) ? (
                            <div className={"alert alert-danger"}>
                                {t("errors.number_table_not_null")}
                            </div>
                        ) : (
                            <>
                                <ErrorFormAlertView errors={errors} />
                                <div className="form-group">
                                    <input type="text" disabled={!possibleEditNumber} value={tableName} onChange={e => setTableName(e.target.value)} className={"form-control" + ErrorFormHelper(errors, "number")} id="table_name" placeholder={t("components.table_number_modal.form.table_number") + "*"} />
                                    <label htmlFor="street">{t("components.table_number_modal.form.table_number")}</label>
                                    <ErrorFormView errors={errors} field="number" />
                                </div>
                            </>
                        )}
                    </div>
                    {possibleEditNumber && (
                        <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} variant="primary" onClick={()=>{saveTableNumber()}}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {t("common.action.save")}
                        </button>
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {
        cart: getCart(state.cart),
        store: getStore(state.store),
    }
}
export default withTranslation()(connect(mapStateToProps)(ChangeTableNumberModal))

// export default withTranslation()(ChangeTableNumberModal)