import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import {connect} from "react-redux";
import {getStore} from "../../application/store/reducer";
import AppConfig from "../../application/common/AppConfig";
import {withTranslation} from "react-i18next";
import NavbarMultiStore from "./NavbarMultiStore";

class NavbarCart extends Component{

    state = {
        position: null,
        location: null,
        isLoading: false,
        options:[],
        selected: null,
        type: "DELIVERY"
        // defaultSelected: null
    };
    render() {
        const {store, t} = this.props;
        let isMultiStore = store.settings.cfg_url ? true : false;
        if (this.props.store == null) {
            return (
                <nav className="navbar navbar-light">
                    <Link to="/menu" className="navbar-brand">
                        {t("common.word.loading_inprogress")}
                    </Link>
                </nav>
            );
        }
        let logoView = null;
        if(store.image_link){
            logoView = (
                <img alt="Logo location" src={store.image_link.default}/>
            )
        } else {
            logoView = this.props.store.translation.name;
        }
        if(AppConfig.isWidget()){
            return null;
        }

        let baseBackUrl = store.settings ? store.settings.back_url : undefined;
        let backUrlNative = store.settings ? store.settings.back_url_native : undefined ;

        let backUrl = AppConfig.isNativeMobile() && baseBackUrl ? backUrlNative : baseBackUrl;
        return (
            <nav className="navbar navbar-light navbar-cart">
                <div className="navbar-brand-container">
                    <Link to="/" className="navbar-brand">
                        {logoView}
                    </Link>
                    {(backUrl || AppConfig.isNativeMobile()) && (
                        <NavbarMultiStore backUrl={backUrl} multiStore={isMultiStore} />
                    )}
                </div>
            </nav>
        )
    }
}

const mapStateToProps = state => ({
    store: getStore(state.store)
})
export default withTranslation()(connect(mapStateToProps)(NavbarCart))
