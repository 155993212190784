import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getCart} from "../../application/cart/reducer";
import {getStore} from "../../application/store/reducer";
import LoadingContainer from "./LoadingContainer";
import {getPromotion} from "../../application/menu/fetchMenu";
import Money from "./Money";
import ItemHelper from "../../application/common/ItemHelper";
import {getCategories, getItems} from "../../application/menu/reducer";
import Utils from "../utils/Utils";

function PromotionInfoModal(props) {
    const { t } = props;
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(props.name);
    const [promotion, setPromotion] = useState(undefined);
    const [error, setError] = useState(false);

    const fetchPromotion = async (promotionId) => {
        let promotion = await getPromotion(promotionId);
        return promotion;
    }

    useEffect(() => {
        showPromotionInfo(props.promotionId)
    }, [])
    const showPromotionInfo = async (promotionId) => {
        setLoading(true);
        try {
            let promotionConfig = await fetchPromotion(promotionId)
            console.log(promotionConfig);
            setPromotion(promotionConfig);
            setLoading(false);
        } catch (err) {
            setError("Problem z pobraniem promocji");
            setLoading(false);
        }
    }

    let renderTypes = () => {
        let types = [];
        if (promotion.dine_in) {
            types.push("DINE_IN");
        }
        if (promotion.delivery) {
            types.push("DELIVERY");
        }
        if (promotion.takeaway) {
            types.push("PICK_UP");
        }
        if (promotion.room_service) {
            types.push("ROOM_SERVICE");
        }
        if (types.length <= 0) {
            return undefined;
        }

        return types.map(type => t("models.order.type." + type)).join(", ");
    }
    let types = promotion ? renderTypes() : undefined;

    let renderConditions = (conditions) => {
        let conditionNames = conditions.map(condition => {
            if(condition.type == "ITEM"){
                let item = ItemHelper.getItem(props.items, condition.entity_id);
                if(!item) return undefined;
                return item.translation.name;
            }
            if(condition.type == "CATEGORY"){
                let category = props.categories.filter(categoryItem => categoryItem.id === condition.entity_id)[0];
                if(!category) return undefined;
                return category.translation.name;
            }
            return undefined;
        }).filter(x => x !== undefined);
        return conditionNames.join(", ");
    }

    let getConditions = (conditions) => {
        let conditionNames = conditions.map(condition => {
            if(condition.type == "ITEM"){
                let item = ItemHelper.getItem(props.items, condition.entity_id);
                if(!item) return undefined;
                return item.translation.name;
            }
            if(condition.type == "CATEGORY"){
                let category = props.categories.filter(categoryItem => categoryItem.id === condition.entity_id)[0];
                if(!category) return undefined;
                return category.translation.name;
            }
            return undefined;
        }).filter(x => x !== undefined);
        return conditionNames;
    }
    return (
        <Modal dialogClassName="modal-full" show={true} onHide={props.onHide} onShow={props.onShow}>
            <div className="">
                <Modal.Header>
                    <Modal.Title>
                        Warunki promocji
                    </Modal.Title>
                    <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </Modal.Header>
            </div>
            <Modal.Body>
                {loading ? (
                    <LoadingContainer/>
                ) : (
                    <>
                        <h6>
                            {name}
                        </h6>
                        {promotion.description && (
                            <p>
                                {Utils.nl2brString(promotion.description)}
                            </p>
                        )}
                        {error ? (
                            <div className={"alert alert-danger"}>{error}</div>
                        ) : (
                            <div>
                                <ul className="list-unstyled">
                                    <dl>
                                        {types && (
                                            <>
                                                <dt>Typ zamówienia</dt>
                                                <dd>{types}</dd>
                                            </>
                                        )}
                                        {promotion.min_basket_money && (
                                            <>
                                                <dt>Minimalna wartość zamówienia</dt>
                                                <dd><Money value={promotion.min_basket_money}/></dd>
                                            </>
                                        )}

                                        {promotion.min_condition_quantity && (
                                            <>
                                                <dt>Minimalna ilość pozycji</dt>
                                                <dd>{promotion.min_condition_quantity}</dd>
                                            </>
                                        )}
                                        {(promotion.conditions && promotion.conditions.length > 0) && (
                                            <>
                                                <dt>Wymagane pozycje lub kategorie</dt>
                                                <dd>
                                                    <ul>
                                                        {getConditions(promotion.conditions).map((condition, index) => {
                                                            return (
                                                                <li>
                                                                    {condition}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                    {/*{types && (*/}
                                    {/*    <li>*/}
                                    {/*        <ul className="list-inline">*/}
                                    {/*            <li><strong>Typ zamówienia</strong></li>*/}
                                    {/*            <li>{types}</li>*/}
                                    {/*        </ul>*/}
                                    {/*    </li>*/}
                                    {/*)}*/}
                                    {/*{promotion.min_basket_money && (*/}
                                    {/*    <li>*/}
                                    {/*        <ul className="list-inline">*/}
                                    {/*            <li><strong>Minimalna wartość zamówienia</strong></li>*/}
                                    {/*            <li><Money value={promotion.min_basket_money}/></li>*/}
                                    {/*        </ul>*/}
                                    {/*    </li>*/}
                                    {/*)}*/}
                                    {/*{promotion.min_condition_quantity && (*/}
                                    {/*    <li>*/}
                                    {/*        <ul className="list-inline">*/}
                                    {/*            <li><strong>Minimalna ilość pozycji</strong></li>*/}
                                    {/*            <li>{promotion.min_condition_quantity}</li>*/}
                                    {/*        </ul>*/}
                                    {/*    </li>*/}
                                    {/*)}*/}
                                    {/*{(promotion.conditions && promotion.conditions.length > 0) && (*/}
                                    {/*    <>*/}
                                    {/*        <li>*/}
                                    {/*            <ul className="list-inline">*/}
                                    {/*                <li><strong>Wymagane pozycje lub kategorie</strong></li>*/}
                                    {/*                <li>*/}
                                    {/*                    {renderConditions(promotion.conditions)}*/}
                                    {/*                </li>*/}
                                    {/*            </ul>*/}
                                    {/*        </li>*/}
                                    {/*    </>*/}
                                    {/*)}*/}
                                </ul>

                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: getCart(state.cart),
        store: getStore(state.store),
        items: getItems(state.menu),
        categories: getCategories(state.menu),
    }
}
export default withTranslation()(connect(mapStateToProps)(PromotionInfoModal))

// export default withTranslation()(ChangeTableNumberModal)