import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getItems} from "../../../../application/menu/reducer";
import {ReactComponent as StarIco} from "../../../../images/icons/star.svg";
import {ReactComponent as ArrowRightIco} from "../../../../images/icons/arrow-right.svg";
import Money from "../../../common/Money";
import {getCartTotal} from "../../../../application/cart/reducer";
import {getUser, getVouchers} from "../../../../application/user/reducer";
import UserProductsModal from "../../../common/user/UserProductsModal";

const MenuPromotions = (props)=>{
    const [vouchersModal, setVouchersModal] = useState(false);
    const { t } = useTranslation();
    let promotions = props.promotions;
    if((promotions === undefined || !promotions || promotions.length === 0) && !props.user){
        return null;
    }
    let totalPriceAmount = props.cartTotal ? props.cartTotal.amount : 0;
    let promotionsToGet = promotions.filter(x => x.min_basket_money && x.min_basket_money.amount > totalPriceAmount);
    return (
        <div className={"container promotions"}>
            {/*{props.user && props.user.name && props.vouchers && props.vouchers.length > 0 && (*/}
            {/*    <>*/}
            {/*        <button onClick={() => setVouchersModal(true)} className={"btn btn-promotion"}>*/}
            {/*            <div className={"star"}>*/}
            {/*                <StarIco />*/}
            {/*            </div>*/}
            {/*            <div className={"content"}>*/}
            {/*                Wykorzystaj swoje vouchery*/}
            {/*                /!*{t("components.vouchers.advert.title")}*!/*/}
            {/*                /!*Masz {props.user.points} Punktów, kup voucher!*!/*/}
            {/*                <small>*/}
            {/*                    Posiadasz {props.vouchers.length} voucherów*/}
            {/*                    /!*{t("components.vouchers.advert.your_points", {points: props.user.points})}*!/*/}
            {/*                    /!*Dostępne {props.user.points} punktów.*!/*/}
            {/*                </small>*/}
            {/*            </div>*/}
            {/*            <ArrowRightIco className={"arrow"} />*/}
            {/*        </button>*/}
            {/*        <UserProductsModal show={vouchersModal} onHide={() => setVouchersModal(false)}/>*/}
            {/*    </>*/}
            {/*)}*/}
            {promotionsToGet.map(promotion => {
                return (
                    <button key={promotion.id} className={"btn btn-promotion"}>
                        <div className={"star"}>
                            <StarIco />
                        </div>
                        <div className={"content"}>
                            {promotion.name}
                            <small>
                                Min. wartość koszyka: <Money value={promotion.min_basket_money} />
                            </small>
                        </div>
                        {/*<ArrowRightIco className={"arrow"} />*/}
                    </button>
                )
            })}
        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        cartTotal: getCartTotal(state.cart),
        user: getUser(state.user),
        vouchers: getVouchers(state.user)
        // type: getOrderType(state.cart),
        // store: getStore(state.store),
        // cart: getCart(state.cart)
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuPromotions))