import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "./ErrorFormHelper";
import ErrorFormView from "./ErrorFormView";
import ErrorFormAlertView from "./ErrorFormAlertView";
import {DateHelper, getWeekDay} from "../../application/common/DateHelper";
import DateService from "../../application/service/DateService";
import {withTranslation} from "react-i18next";
import AvailableHelper from "../../application/common/AvailableHelper";
import {getDayOfWeekNumber} from "../../application/common/StringHelper";

class ChangeDateModal extends Component{
    state = {
        errors: [],
        loading: false,
        date: '',
        time: ''
    };

    constructor(props) {
        super(props);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.onShow = this.onShow.bind(this);
        this.save = this.save.bind(this);
    }
    handleChangeDate(event) {
        let time = this.state.time;
        let date = DateHelper.fromString(event.target.value + " " + time + ":00")
        if(this.props.availabilities !== undefined && !AvailableHelper.isAvailabile(this.props.availabilities, date)){
            time = "";
        }
        this.setState({
            date: event.target.value,
            time: time
        });
    }
    handleChangeTime(event) {
        this.setState({
            time: event.target.value
        });
    }
    clearDate(){
        this.setState({
            loading: true,
            errors: [],
            date: "",
            time: ""
        });
        this.save(null);
        // onSave(null).then(x => {
        //     this.setState({
        //         loading: false
        //     })
        //     this.props.onHide();
        // });
    }

    saveHandle(event) {
        const {onSave} = this.props;
        let errors = [];
        if(!this.state.date){
            errors.push({"field":"date","message":"Musisz wybrać datę","code":"date_must_be_not_empty"})
        }
        if(!this.state.time){
            errors.push({"field":"time","message":"Musisz wybrać czas","code":"time_must_be_not_empty"})

        }
        if(errors.length > 0){
            this.setState({
                errors: errors,
                loading: false
            })
            return ;
        }
        this.save(DateHelper.fromString(this.state.date + " " + this.state.time + ":00"));
    }

    save(date) {
        const {onSave} = this.props;
        this.setState({
            errors: [],
            loading: true
        });

        onSave(date).then(x => {
            this.setState({
                loading: false
            })
            this.props.onHide();
        }).catch(error => {
            this.setState({
                errors: error.errors,
                loading: false
            })
        });
    }
    onShow(){
        const {value} = this.props;
        let states = {};
        if(value){
            let date = value;
            if(!(date instanceof Date)){
                date = DateHelper.fromString(value);
            }

            if(this.props.availabilities === undefined || AvailableHelper.isAvailabile(this.props.availabilities, date)){
                states.time = ChangeDateModal.getDefaultTime(date);
                states.date = DateHelper.formatOnlyDate(date);
            }
        }else{
            // states.time = ChangeDateModal.getDefaultTime(DateService.now());
        }
        this.setState(states)
    }

    static getTime(i){
        return (i >=24) ? "00" : (i < 10) ? "0" + i : i;
    }
    getDateFormat(today, i){
        const {t, i18n} = this.props;
        // let weekDayName = i === 0 ? t("common.word.today") : i === 1 ? t("common.word.tomorrow") : getWeekDays(today.getDay());
        let weekDayName = i === 0 ? t("common.word.today") : i === 1 ? t("common.word.tomorrow") : getWeekDay(today, i18n.language);
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10)
        {
            dd='0'+dd;
        }

        if(mm<10)
        {
            mm='0'+mm;
        }
        return weekDayName + ", " + dd+'.'+mm;
    }

    isValidDate(dates, date, weekDays){
        if(weekDays){
            var weekdayNumber = date.getDay();
            let weekDayNumbers = weekDays.map(weekDay => getDayOfWeekNumber(weekDay));
            if(!weekDayNumbers.includes(weekdayNumber)) return false;
        }

        if(dates === null || dates === undefined){
            return true;
        }
        return dates.some(x => {
            return date >= x.date_from && date <= x.date_to;
        })
    }
    buildDateOptions(dates, weekDays) {
        const {t} = this.props;
        var today = DateService.now();
        var arr = [];
        let min = 0;
        let max = 61;
        arr.push(<option key={-1} value={""}>{t("common.word.pick_date")}</option>)
        for (let i = min; i <= max; i++) {
            if(i > 0){
                today.setDate(today.getDate() + 1);
            }
            if(!this.isValidDate(dates, today, weekDays)){
                continue;
            }
            let iValue = DateHelper.formatOnlyDate(today);
            // let selected = false;
            if(this.state.date === iValue){

            }
            arr.push(<option key={i} value={iValue}>{this.getDateFormat(today, i)}</option>)
        }
        return arr;
    }
    isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, time) {
        if(i !== minHours && i !== maxHours){
            return true;
        }
        if(i === minHours && minMinutes > time){
            return false;
        }
        if(i === maxHours && maxMinutes < time){
            return false;
        }
        // if((i !== minHours && i !== maxHours) || ((i === minHours && minMinutes <=time) && (i === maxHours && maxMinutes >= time))) {
        //     return true;
        // }
        return true;
    }
    buildOptions() {
        const {t} = this.props;
        var today = DateService.now();
        let minTodayHour = 0;
        let minTodayMinute = 0;

        if(this.state.date === "" || this.state.date === DateHelper.formatOnlyDate(today)){
            let todayWithMinStoreMinutes = this.props.locationStore ? AvailableHelper.getAddMinutesToPickDateFromNow(this.props.orderType, this.props.locationStore.estimated_preparation_duration, this.props.locationStore.estimated_delivery_duration) : today;
            minTodayHour = todayWithMinStoreMinutes.getHours();
            minTodayMinute = todayWithMinStoreMinutes.getMinutes();
        }

        var arr = [];
        arr.push(<option key={-1} value={""}>{t("common.word.pick_time")}</option>)
        if(!this.state.date){
            return arr;
        }
        let max = 23;
        let hours = AvailableHelper.getHoursAvailable(this.props.availabilities, DateHelper.fromString(this.state.date + " 00:00:00"));
        if(!hours || hours.length <=0) return arr;
        var minTime = hours && hours.length > 0 ? hours.reduce((a,b)=>a.hour_from>b.hour_from?a:b).hour_from : null;
        var maxTime = hours && hours.length > 0 ? hours.reduce((a,b)=>a.hour_to>b.hour_to?a:b).hour_to : null;
        var minHours = minTime ? parseInt(minTime.split(":")[0]) : 0;
        var maxHours = maxTime ? parseInt(maxTime.split(":")[0]) : 23;
        var minMinutes = minTime ? parseInt(minTime.split(":")[1]) : 0;
        var maxMinutes = maxTime ? parseInt(maxTime.split(":")[1]) : 59;
        if(parseInt(maxHours) <= 0){
            maxHours = 23;
        }
        if(parseInt(maxMinutes) === 0){
            maxMinutes = 59;
            maxHours = maxHours - 1;
        }
        let minHourWithEstimate = AvailableHelper.getAddMinutesToPickDate(this.props.orderType, this.props.locationStore.estimated_preparation_duration, this.props.locationStore.estimated_delivery_duration);
        if(minHourWithEstimate > 0){
            minMinutes = minMinutes + minHourWithEstimate;
            if (minMinutes >= 60) {
                const extraHours = Math.floor(minMinutes / 60);
                minHours += extraHours;
                minMinutes %= 60; // Keep the remaining minutes less than 60
            }
            let maxHourWithEstimate = minHourWithEstimate > 15 ? minHourWithEstimate - 15 : 0;
            maxMinutes = maxMinutes - maxHourWithEstimate;
            if (maxMinutes <= 0) {
                const extraHours = Math.floor(maxMinutes / 60);
                maxHours -= extraHours;
                maxMinutes %= 60; // Keep the remaining minutes less than 60
            }
        }

        if(minHours < minTodayHour || (minHours === minTodayHour && minMinutes < minTodayMinute)){
            minHours = minTodayHour;
            minMinutes = minTodayMinute;
            // minHours = minHours > minTodayHour ? parseInt(minHours) : minTodayHour;
            // minMinutes = minMinutes > minTodayMinute ? minMinutes : minTodayMinute;
        }
        maxHours = maxHours <= max ? parseInt(maxHours) : max;
        for (let i = minHours; i <= maxHours; i++) {
            let iS = ChangeDateModal.getTime(i);
            if(this.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 0)){
                let iValue1 = iS + ":" + "00";
                // let i1 = iValue1 + " - " + ChangeDateModal.getTime(i) + ":30";
                let i1 = iValue1;
                arr.push(<option key={iValue1} value={iValue1}>{i1}</option>)
            }
            if(this.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 15)){
                let iValue2 = iS + ":" + "15";
                // let i2 = iValue2 + " - " + ChangeDateModal.getTime(i) + ":45";
                let i2 = iValue2;
                arr.push(<option key={iValue2} value={iValue2}>{i2}</option>)
            }
            if(this.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 30)){
                let iValue3 = iS + ":" + "30";
                // let i3 = iValue3 + " - " + ChangeDateModal.getTime(i + 1) + ":00";
                let i3 = iValue3;
                arr.push(<option key={iValue3} value={iValue3}>{i3}</option>)
            }
            if(this.isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 45)){
                let iValue4 = iS + ":" + "45";
                // let i4 = iValue4 + " - " + ChangeDateModal.getTime(i + 1) + ":15";
                let i4 = iValue4;
                arr.push(<option key={iValue4} value={iValue4}>{i4}</option>)
            }
        }

        return arr;
    }
    render(){
        const {t, title, dates, weekDays, button_clear_title, button_save_title} = this.props;
        // let dates = null;
        return(

            <React.Fragment>
                <Modal dialogClassName="modal-full" show={this.props.showModal} onHide={this.props.onHide} onShow={this.onShow}>
                    <div className="">
                        <Modal.Header>
                            <Modal.Title>
                                {title ? title : t("components.date_modal.title")}
                            </Modal.Title>
                            <button onClick={this.props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </Modal.Header>
                    </div>
                    <Modal.Body>
                        <div>
                            <ErrorFormAlertView errors={this.state.errors} />
                            <div className="form-group">
                                <select value={this.state.date} className={"form-control" + ErrorFormHelper(this.state.errors, "date")} name="date_modal_form_date" onChange={(e) => this.handleChangeDate(e)}>
                                    {this.buildDateOptions(dates, weekDays)}
                                </select>
                                <label htmlFor="date_modal_form_date">{t("components.date_modal.form.date")}</label>
                                <ErrorFormView errors={this.state.errors} field="date" />
                            </div>
                            <div className="form-group">
                                <select value={this.state.time} className={"form-control" + ErrorFormHelper(this.state.errors, "time")} name="date_modal_form_time" onChange={(e) => this.handleChangeTime(e)}>
                                    {this.buildOptions()}
                                </select>
                                <label htmlFor="date_modal_form_time">{t("components.date_modal.form.time")}</label>
                                <ErrorFormView errors={this.state.errors} field="time" />
                            </div>
                        </div>
                        <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (this.state.loading ? ' loading' : '')} variant="primary" onClick={()=>{this.saveHandle()}}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {button_save_title ? button_save_title : t("common.action.save")}
                        </button>
                        <button className={"btn-spinner btn-block btn-default btn btn-submit" + (this.state.loading ? ' loading' : '')} variant="primary" onClick={()=>{this.clearDate()}}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {button_clear_title ? button_clear_title : t("components.date_modal.clear_date")}
                        </button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }

    static getDefaultTime(today){
        // var hours = today.getUTCHours();
        var hours = today.getHours();
        // hours = hours + 1;
        // var minutes = today.getUTCMinutes();
        var minutes = today.getMinutes();
        if(minutes > 0){
            if(minutes <= 15){
                minutes = 15;
            }else if(minutes <= 30){
                minutes = 30;
            }else if(minutes <= 45){
                minutes = 45;
            }else if(minutes <= 60){
                minutes = 15;
                hours = hours + 1;
            }
        }else{
            minutes = "00";
        }
        return ChangeDateModal.getTime(hours) + ":" + minutes;
    }
}
export default withTranslation()(ChangeDateModal)