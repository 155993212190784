import React from "react";

export const FormErrors = ({formErrors}) => {
    if(formErrors == null || formErrors.length <=0){
        return null;
    }
    return (
        <div className="alert alert-danger" role="alert">
            {Object.keys(formErrors).map((fieldName, i) => {
                if(formErrors[fieldName].length > 0){
                    return (
                        <p key={i}>{fieldName} {formErrors[fieldName]}</p>
                    )
                } else {
                    return '';
                }
            })}
        </div>
    )
}