import {FETCH_STORE_ERROR, FETCH_STORE_PENDING, FETCH_STORE_SUCCESS, SET_STORE_LOCALE} from './actions';
import {getElementTranslation} from "../service/languageService";
import i18next from "i18next";
import AgeRestrictedContext from "../common/AgeRestrictedContext";


const initialState = {
    store: {
        name: null,
        tags:[],
        address: null,
        hours:[],
        links: [],
        deliver_zones:[],
        pickup_at: null,
        estimated_preparation_duration:null,
        estimated_delivery_duration:null,
        cancellation_time: 0,
        min_delivery_price: null
    },
    login_providers: [],
    languages: [],
    locale: "pl",
    pages:[],
    pagesAdvertMenu:[],
    pending: true,
    preview: true,
    error: null
}
const getStoreTranslation = (locale, element) => {
    return getElementTranslation(locale, element, ["name", "header", "description", "customerSuccessInfo", "customerRejectInfo"]);
}

const menuReducer= (state = initialState,action)=>{
    switch(action.type) {
        case FETCH_STORE_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_STORE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case SET_STORE_LOCALE:
            let store = state.store;
            store.translation = getStoreTranslation(action.locale, store);
            return {
                ...state,
                locale: action.locale,
                store: store
            }
        case FETCH_STORE_SUCCESS:
            let data = action.data;
            let pageComponents = data.pages.filter(x => {
                var placement = x.settings.placement;
                return placement !== undefined;
            });
            pageComponents.forEach(x => {
                x.placement = x.settings.placement
            })
            var locale = i18next.language;
            // let locale = languages ? languages[0] : "pl";
            data.store.translation = getStoreTranslation(locale, data.store);
            let languages = data.store.translations.map(translation => translation.locale)
            // let preview = action.store.payment_methods !== undefined && action.store.payment_methods.length > 0 ? false : true;
            let preview = data.preview;
            let loginProviders = data.integrations ? data.integrations.map(integration => integration.provider) : [];
            let ageRestrictedDisabled = data.store.settings && data.store.settings.age_restricted_disabled;
            AgeRestrictedContext.initialize(ageRestrictedDisabled);
            return {
                ...state,
                pending: false,
                store: data.store,
                pages: data.pages,
                languages: languages,
                locale: locale,
                pageComponents: pageComponents,
                preview: preview,
                login_providers: loginProviders
            }
        default:
            return state;
    }
}

export default menuReducer;
export const getStore = state => state.store;
export const getPages = state => state.pages;
export const getPageComponents = state => state.pageComponents;
export const getStorePending = state => state.pending;
export const getStoreError = state => state.error;
export const getLanguages = state => state.languages;
export const getLocale = state => state.locale;
export const isPreview = state => state.preview;
export const getLoginProviders = state => state.login_providers;
