import {
    ADD_FLASH, REMOVE_FLASH
} from './actions';

const initialState = {
    messages: []
}

const notificationReducer= (state = initialState,action)=>{
    switch(action.type) {
        case ADD_FLASH:
            let messages = action.messages.map(x => {
                x.id = '_' + Math.random().toString(36).substr(2, 9);;
                return x;
            });

            return {
                ...state,
                messages: state.messages.concat(messages)
            }
        case REMOVE_FLASH:
            return {
                ...state,
                messages: state.messages.filter(item => item.id !== action.message.id)
            }
        default:
            return state;
    }
}

export default notificationReducer;
export const getMessages = state => state.messages;
