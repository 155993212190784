import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {DateHelper} from "../../../application/common/DateHelper";
import DateService from "../../../application/service/DateService";
import AvailableHelper from "../../../application/common/AvailableHelper";
import {padTo2Digits} from "../../../application/common/StringHelper";
import ChangeDateModal from "../../common/ChangeDateModal";
import FormattedTime from "../../common/FormattedTime";

function NavbarFormDates(props){
    const { t } = props;
    const [pickDateModal, setPickDateModal] = useState(false);
    let dates = AvailableHelper.getAllDates(props.menus, props.availabilities);
    let weekDays = AvailableHelper.getAllWeekDays(props.menus, props.availabilities);
    let menuAvailabilities =AvailableHelper.getAvailabilities(props.availabilities, props.menus.map(menu => menu.availability_id));
    let addMinutesForEstimate = AvailableHelper.getAddMinutesToPickDate(props.orderType, props.locationStore.estimated_preparation_duration, props.locationStore.estimated_delivery_duration);
    let btns = AvailableHelper.getDatesToPickDate(AvailableHelper.getAddMinutesToPickDateFromNow(props.orderType, props.locationStore.estimated_preparation_duration, props.locationStore.estimated_delivery_duration), menuAvailabilities, addMinutesForEstimate).slice(0,10);
    let pickDate = props.pickDate ? new Date(props.pickDate) : props.pickDate;
    let pickDateString =  pickDate ? DateHelper.format(pickDate) : pickDate;
    const hidePickDateModal = () => {
        setPickDateModal(false)
    }
    const changePickDate = (date) => {
        props.onSave(date);
    }
    const onChangePickDateModal = async (date) => {
        changePickDate(date)
        hidePickDateModal()
    }

    const renderOptions = () => {
        return (
            <>
                {btns.map(option => {
                    let dateFormat = DateHelper.format(option);
                    return (
                        <div className={"col"} key={dateFormat}>
                            <button className={"btn btn-default w-100" + (isActiveBtn(pickDateString, dateFormat) ? " active" : "")} onClick={() => changePickDate(option)}>{padTo2Digits(option.getHours()) + ':' + padTo2Digits(option.getMinutes())}</button>
                        </div>
                    )
                })}
            </>
        )
    }
    const isActiveBtn = (date, dateToCompare) => {
        if(!date && !dateToCompare) return true;
        if(date == dateToCompare) return true;
        return false;
    }
    const isAnyActiveBtn = !pickDate || btns.some(btn => isActiveBtn(pickDateString, DateHelper.format(btn)));
    return (
        <>
            <div className={"btns"}>
                <div className={"row row-cols-4"}>
                    <div className={"col"}>
                        <button className={"btn btn-default w-100" + (isActiveBtn(pickDateString) ? " active" : "")} onClick={() => changePickDate(null)}>{t("common.word.fastest")}</button>
                    </div>
                    {renderOptions()}
                    <div className={"col"}>
                        <button className={"btn btn-default w-100" + (!isAnyActiveBtn ? " active" : "")} onClick={() => setPickDateModal(true)}>
                            Własny czas
                            {!isAnyActiveBtn && (
                                <small>
                                    <br />
                                    <FormattedTime
                                        value={pickDate}
                                        day="numeric"
                                        month="long"/>
                                </small>
                            ) }
                        </button>
                    </div>
                </div>
            </div>
            <ChangeDateModal orderType={props.orderType} locationStore={props.locationStore} dates={dates} weekDays={weekDays} availabilities={menuAvailabilities} button_clear_title={t("common.action.now")} onSave={onChangePickDateModal} title={t("components.pick_date_modal.title")} value={pickDate} showModal={pickDateModal} onHide={hidePickDateModal} />
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarFormDates))
