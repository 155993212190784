import React from 'react';
import {withTranslation} from "react-i18next";
import BaseSearchComponent from "./BaseSearchComponent";
import {getStreets} from "../../../application/menu/fetchMenu";

const StreetSearchComponent = (props)=>{
    const fetch = async (query) => {
        let response = await getStreets(props.city, query)
        return response.map((c) => {
            return {
                description:c
            }
        })
    }
    const onChange = (value) => {
        props.onChange(value.description);
    }
    const onBlur = (value) => {
        props.onBlur(value.description);
    }


    return (
        <BaseSearchComponent fetch={fetch} value={props.value} onBlur={onBlur} onChange={onChange} placeholder={props.placeholder} />
    )
}

export default withTranslation()(StreetSearchComponent)